import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AutoRefreshPeriscope from "../AutoRefreshPeriscope";

const SpecificLocationTableHeader = ({
	locationValues,
	periscopeSpecificItemAvailabilityLocationState,
	periscopeDataResponse
}) => {
	const appliedFiltersCollection = periscopeSpecificItemAvailabilityLocationState?.appliedFiltersCollection;

	const locationName = appliedFiltersCollection?.location?.name;
	return (
		<div className="specificlocation-table-head">
			<div className="specificlocation-table-header">
				<div className="specificlocation-text-display">
					<Link to="/periscope/item-availability?view=location" style={{ textDecoration: "none" }}>
						<div className="specificlocation-header-display">
							<span data-testid="specificLocationTableHeader" className="table-header-grey-text">
								Item Availability
							</span>
							<img
								className="specificlocation-right-arrow"
								src="/assets/periscope/right-arrow-action.svg"
							/>
						</div>
					</Link>
					<div className="specificlocation-brand-item">
						<span>{locationName || locationValues?.name}</span>
					</div>
				</div>
				<AutoRefreshPeriscope />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeSpecificItemAvailabilityLocationState: state.periscopeSpecificItemAvailabilityLocationState,
	periscopeDataResponse: state.periscopeDataResponse
});

export default connect(mapStateToProps)(SpecificLocationTableHeader);
