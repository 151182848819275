import React, { useState, useEffect, useRef, useCallback } from "react";

// components
import { FormContainer } from "./Meraki";
import { Configurations } from "../components/DeveloperTools/Configurations";
import { Loading } from "../components/_commons/Loading";
import { Topbar } from "../components/_commons/Topbar";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// utils
import { client } from "../client";
import { store } from "../store/configureStore";
import { scroll, extractInitials } from "../atlas-utils";

// graphql
import { GET_BIZ_LOCATION_PLATFORMS_MINI, GET_LOCATIONS_LIST } from "../graphql/developerTools";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBrands } from "../actions/actions";
import { fetchAggregatorTestOrderConfig, triggerTestRequest } from "../actions/developerTools";

const FORM_TABS = [
	{
		label: "Place Test Orders",
		value: "place_test_orders"
	}
];

const LIST_TYPE_VALUES = {
	fulfillment_type: true,
	delivery_type: true,
	payment_type: true,
	discount_type: true,
	"Discount Type": true
};

const DeveloperToolsContainer = ({ developerTools, isMultibrandEnabled = false, brands }) => {
	const { data, loading, triggerResponse, testRequestProcessing } = developerTools;
	const [isFormTouched, setFormTouched] = useState(false);
	const [locationsList, setLocationsList] = useState([]);
	const [platformsList, setPlatformsList] = useState({ loading: false, values: [] });
	const [searchingLocation, setSearchingLocation] = useState(false);
	const [searchLocation, setSearchLocation] = useState({ key: "default", value: "" });
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedBrand, setSelectedBrand] = useState(null);
	const [platformSelected, setSelectedPlatform] = useState(null);
	const [validations, setValidations] = useState({});
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const topRef = useRef();
	const error = false;

	const setData = (updatedData) => {
		store.dispatch({
			type: ActionTypes.UPDATE_AGGREGATOR_TEST_CONFIG_DATA,
			payload: updatedData
		});
	};

	const fetchLocationsList = useCallback(async () => {
		if (isMultibrandEnabled ? selectedBrand : true) {
			try {
				const variables = {
					limit: 50,
					offset: 0,
					filters: [
						{
							field: "is_active",
							value: true
						}
					],
					sort: {
						field: "name",
						order: "ASC"
					},
					search: [searchLocation]
				};
				if (isMultibrandEnabled) {
					variables.brand = selectedBrand.id;
				}
				let resp = await client.query({
					query: GET_LOCATIONS_LIST,
					variables,
					fetchPolicy: "no-cache"
				});
				setLocationsList([...resp.data.stores.objects]);
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setSearchingLocation(false);
		}
	}, [searchLocation, isMultibrandEnabled, selectedBrand]);

	const getBizPlatforms = async (locationId) => {
		if (!locationId) {
			return;
		}

		try {
			setPlatformsList((current) => ({
				...current,
				loading: true
			}));
			const variables = {
				id: parseInt(locationId)
			};

			if (isMultibrandEnabled && selectedBrand) {
				variables.brandId = selectedBrand.id;
			}

			const resp = await client.query({
				query: GET_BIZ_LOCATION_PLATFORMS_MINI,
				variables,
				fetchPolicy: "no-cache"
			});
			if (isMultibrandEnabled) {
				const brandLocation = resp.data.store.brandLocations?.find(
					(loc) => loc.isActive && selectedBrand.id === loc.brand.id
				);
				if (!!brandLocation?.associatedPlatforms?.length) {
					setPlatformsList({
						loading: false,
						values: brandLocation?.associatedPlatforms.map((platformInfo) => ({
							...platformInfo,
							platformName: platformInfo.platform.platformName
						}))
					});
				} else {
					setPlatformsList((current) => ({
						...current,
						loading: false
					}));
				}
			} else {
				if (!!resp.data.store.associatedPlatforms?.length) {
					setPlatformsList({
						loading: false,
						values: resp.data.store.associatedPlatforms.map((platformInfo) => ({
							...platformInfo,
							platformName: platformInfo.platform.platformName
						}))
					});
				} else {
					setPlatformsList((current) => ({
						...current,
						loading: false
					}));
				}
			}
		} catch (error) {
			setPlatformsList((current) => ({
				...current,
				loading: true
			}));
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Failed to fetch associated business platforms.",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const handleBrandSelection = (key, value) => {
		if (!value) {
			setSelectedBrand(null);
			setSelectedLocation(null);
		} else {
			setSelectedBrand(value);
			if (!isFormTouched) {
				setFormTouched(true);
			}
		}
		setSelectedLocation(null);
	};

	const handleLocationSelection = (key, value) => {
		if (!value) {
			setSelectedLocation(null);
		} else {
			if (!isFormTouched) {
				setFormTouched(true);
			}
			getBizPlatforms(value?.id);
			setSelectedLocation(value);
		}
		setSelectedPlatform(null);
	};

	const handleLocationSearch = debounce((searchQuery) => {
		if (searchQuery !== searchLocation.value) {
			setSearchingLocation(true);
			setSearchLocation({
				...searchLocation,
				value: searchQuery
			});
		}
	}, 300);

	const handlePlatformSelection = (key, value) => {
		if (!value || !value.externalId) {
			setSelectedPlatform(null);
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "The selected platform is not configured for the selected location.",
					timeout: 2000,
					error: true
				}
			});
		} else {
			if (!isFormTouched) {
				setFormTouched(true);
			}
			setSelectedPlatform(value);
		}
	};

	const handleFormReset = () => {
		setFormTouched(false);
		store.dispatch({
			type: ActionTypes.RESET_AGGREGATOR_TEST_CONFIG_DATA
		});
		setSelectedBrand(null);
		setSelectedLocation(null);
		setSelectedPlatform(null);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleForm = (field, value) => {
		setData({
			...data,
			keys: [
				...data.keys.map((keyValue) =>
					keyValue.key === field
						? LIST_TYPE_VALUES[keyValue.key]
							? { ...keyValue, selectedValue: value }
							: { ...keyValue, value: value }
						: keyValue
				)
			]
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		if (triggerResponse?.success) {
			handleFormReset();
		} else {
			const exceptionalValueKeys = {
				store_id: true,
				channel: true
			};

			const exceptionalKeysEquivalentValue = {
				store_id: selectedLocation,
				channel: platformSelected
			};

			let isMutationPossible = true;
			const validations = {};

			if (isMultibrandEnabled && !selectedBrand) {
				isMutationPossible = false;
				validations.selectedBrand = "Brand is a required field";
			}

			for (let i = 0; i < data?.keys.length; i++) {
				if (data.keys[i].required && exceptionalValueKeys[data.keys[i].key]) {
					if (!exceptionalKeysEquivalentValue[data.keys[i].key]) {
						isMutationPossible = false;
						validations[data.keys[i].key] = data.keys[i]?.title + " is a required field";
					}
					continue;
				}

				if (
					data.keys[i].required &&
					data.keys[i].type !== "list" &&
					(data.keys[i].value === null || !String(data.keys[i].value))
				) {
					isMutationPossible = false;
					validations[data.keys[i].key] = data.keys[i]?.title + " is a required field";
				}

				if (data.keys[i].required && data.keys[i].type === "list" && !data.keys[i].selectedValue) {
					isMutationPossible = false;
					validations[data.keys[i].key] = data.keys[i]?.title + " is a required field";
				}
			}

			if (!isMutationPossible) {
				setValidations({ ...validations });
				return;
			} else {
				setValidations({});
			}

			const payload = {
				keys: [...data.keys],
				selectedBrand,
				selectedLocation,
				platformSelected
			};
			scroll({ top: topRef.current.offset - 57, left: 0 });
			triggerTestRequest(payload);
		}
	};

	const switchTab = (tab) => {
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	useEffect(() => {
		if (isMultibrandEnabled) {
			fetchBrands("", true);
		}
	}, []);

	useEffect(() => {
		fetchLocationsList();
	}, [searchLocation, selectedBrand]);

	useEffect(() => {
		fetchAggregatorTestOrderConfig();
	}, []);

	const rootElement = document.getElementById("root");
	const loaderHeight = { height: rootElement.scrollHeight };

	return (
		<React.Fragment>
			{testRequestProcessing && (
				<Loading inlineStyle={rootElement.scrollHeight > window.innerHeight ? loaderHeight : undefined} />
			)}
			<div className="developer-tools-section section-container-common" ref={topRef}>
				<div className="credits-section-header integrations-header">
					<div className="header-text">
						<div className="title">Developer Tools</div>
						<div className="subtitle">Tools for building and testing integrations with UrbanPiper</div>
					</div>
				</div>
				<Topbar tabs={FORM_TABS} selectedTab={formTab} switchTab={switchTab} isStickyOnTop={true} />
				<div className="developer-tools-container">
					<FormContainer
						cancel={handleFormReset}
						cancelTitle={"Reset"}
						submit={handleSubmit}
						submitTitle={
							triggerResponse?.success
								? "Try another request"
								: triggerResponse?.error
								? "Try again"
								: "Submit"
						}
						hideActions={loading || !isFormTouched}
						submitClass={triggerResponse?.success ? "W(150px)" : ""}
					>
						{!loading && !triggerResponse?.success && data && formTab === FORM_TABS[0].value && (
							<div className="card-container">
								<div className="card-header">
									<div className="pos-info">
										{data.posName || "Aggregator test order configuration"}
									</div>
								</div>
								<div className={"form-container " + (loading ? "disabled" : "")}>
									<div className="form-row">
										{isMultibrandEnabled && (
											<SelectFilterCustom
												title="Brand"
												options={brands.items.filter((brand) => brand.id !== "all")}
												isLoading={brands.isLoading}
												field="selectedBrand"
												currValue={selectedBrand}
												setFilter={handleBrandSelection}
												labelKey={"name"}
												valueKey={"id"}
												isSearchable={false}
												customLabel={true}
												customOptions={true}
												isClearable={false}
												requiredLabel={true}
												renderLabel={handleBrandsLabelOption}
												renderOptions={handleBrandsLabelOption}
												placeholder="Select Brand"
												validationMessage={validations.selectedBrand || ""}
											/>
										)}
										{data?.keys?.map((key, i) => (
											<Configurations
												index={i}
												config={key}
												configValue={key.value}
												handleForm={handleForm}
												validations={validations}
												key={key.key}
												selectedLocation={selectedLocation}
												locationsList={locationsList}
												handleLocationSearch={handleLocationSearch}
												handleLocationSelection={handleLocationSelection}
												platformsList={platformsList}
												platformSelected={platformSelected}
												handlePlatformSelection={handlePlatformSelection}
												searchingLocation={searchingLocation}
												isMultibrandEnabled={isMultibrandEnabled}
											/>
										))}
										{data?.keys?.length === 0 && (
											<div className="no-items-placeholder">No configurations found!</div>
										)}
									</div>
								</div>
							</div>
						)}
						{!loading && !data && (
							<div className="no-items-placeholder">No aggregator test config found!</div>
						)}
						{loading && (
							<div className="P(10px 0)">
								<div className="shimmer H(60px) Mb(10px) Mt(15px) Mstart(10px) Mend(10px)" />
								<div className="shimmer H(60px) Mb(25px) Mstart(10px) Mend(10px)" />
							</div>
						)}
						{!loading && (triggerResponse?.success || triggerResponse?.error) && (
							<ResponseComponent triggerResponse={triggerResponse} />
						)}
					</FormContainer>
				</div>
			</div>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	brands: store.configItems.brands,
	developerTools: store.developerTools
});
export default connect(mapStateToProps)(DeveloperToolsContainer);

const ResponseComponent = ({ triggerResponse }) => {
	const bottomRef = useRef();
	useEffect(() => {
		scroll({ top: bottomRef.current.offsetTop - 57, left: 0 });
	}, [triggerResponse]);

	return (
		<div
			ref={bottomRef}
			className={
				triggerResponse.success
					? "status-success status-message-container"
					: triggerResponse?.error
					? "status-failure status-message-container"
					: ""
			}
		>
			{triggerResponse.success
				? "Triggered test request was processed successfully!"
				: triggerResponse.error &&
				  (triggerResponse?.message || "Something went wrong while processing the request.")}
		</div>
	);
};
