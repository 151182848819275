import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { connect } from "react-redux";
import { Paginator } from "../../../components/_commons/Paginator";
import { store } from "../../../store/configureStore";
import { scroll } from "../../../atlas-utils";
import SpecificItemIssuePlatformCell from "../../../components/Periscope/ItemIssues/SpecificItemIssuePlatformCell";
import SpecificItemIssueLocationCell from "../../../components/Periscope/ItemIssues/SpecificItemIssueLocationCell";
import TablePlatform from "../../../components/Periscope/ItemIssues/TablePlatform";
import SkeletonLoader from "../../../components/Periscope/SkeletonLoader";
import { ActionTypes } from "../../../actions/_types";
import Popover from "../../../components/_commons/Popover";

const SpecificItemIssueTable = ({
	defaultPlatforms,
	periscopeSpecificItemIssueState,
	periscopeSpecificItemIssueData,
	fetchData,
	tableRef
}) => {
	const data = periscopeSpecificItemIssueData?.data?.objects?.data;
	const loading = periscopeSpecificItemIssueData?.loading;
	const error = periscopeSpecificItemIssueData?.error;
	const offset = periscopeSpecificItemIssueState?.offset;
	const limit = periscopeSpecificItemIssueState?.limit;
	const columns = useMemo(() => {
		return Object.keys(data?.[0] || {}).map((column) => ({
			Header: column,
			accessor: column,
			enableRowSpan: column == "location",
			Cell: (props) => {
				if (column == "location") {
					return (
						<>
							<SpecificItemIssueLocationCell value={props.value} />
						</>
					);
				} else {
					return (
						<>
							<SpecificItemIssuePlatformCell value={props.value} />
						</>
					);
				}
			}
		}));
	}, [data]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, rowSpanHeaders } = useTable(
		{ columns, data },
		useSortBy
	);

	const appliedFilters = periscopeSpecificItemIssueState?.appliedFilters;
	const appliedPlatformNames = appliedFilters?.platform_names;
	let platformsNames =
		!appliedPlatformNames ||
		appliedPlatformNames[0] === "all" ||
		appliedPlatformNames.length === 0 ||
		appliedPlatformNames[0] === "null" ||
		appliedPlatformNames.includes(null)
			? defaultPlatforms
			: appliedPlatformNames;

	let headers = ["location", ...platformsNames];

	const showSortOptions = platformsNames.length > 1;
	const dispatchSortFunction = (sort = null) => {
		if (sort == null) {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
				payload: { sort: null }
			});
		} else {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
				payload: sort
			});
		}
	};

	const handleClickSort = (column) => {
		if (showSortOptions) {
			const { sort } = periscopeSpecificItemIssueState || {};
			let order = "";
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
				payload: { offset: 0 }
			});
			if (sort?.field === column) {
				order = sort?.order === "asc" ? "desc" : null;
			} else {
				order = "asc";
			}
			const newSort = order ? { sort: { field: column, order } } : null;
			dispatchSortFunction(newSort);
		}
	};

	const handlePagination = async (page) => {
		const offset = (page - 1) * limit;
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { offset: offset }
		});

		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const handlePageSize = async (field, size) => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { limit: size.value, offset: 0 }
		});
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const dataCount = periscopeSpecificItemIssueData?.data?.objects?.meta?.totalCount;

	const renderPopover = (obj) => {
		return <div className="info-popover">{obj?.location_name}</div>;
	};

	return (
		<div className="specific-item-issue-table-data ">
			{platformsNames && platformsNames.length > 0 ? (
				<table className="specific-issue-main-table">
					<thead className="selectedissue-thead">
						<tr className="selectedissue-firstrow">
							{headers.map((table) => {
								if (table == "location") {
									return (
										<td
											style={{
												padding: "16px 16px 16px 24px",
												borderRight: "1px solid #EAEAEA",
												minWidth: "274px"
											}}
											className="issues-tablehead-text"
										>
											Location
										</td>
									);
								} else {
									return (
										<td
											key={table}
											style={{
												width: `${100 / (platformsNames.length || 1)}%`
											}}
											className={`issues-tablehead-text ${
												platformsNames.length > 1 ? "issue-show-cursor" : ""
											}`}
											onClick={() => handleClickSort(table)}
										>
											<TablePlatform
												tableName={table}
												text="periscopeSpecificItemIssueState"
												showSortOptions={showSortOptions}
											/>
										</td>
									);
								}
							})}
						</tr>
					</thead>
					{loading || error ? (
						<Loader
							tableData={headers}
							platformCount={100 / (platformsNames.length || 1)}
							error={error}
							refreshBtn={fetchData}
						/>
					) : (
						<>
							<tbody className="specificissue-table-body" style={{ border: "1px solid #EAEAEA" }}>
								{rows.map((row) => {
									prepareRow(row);
									return (
										<tr>
											{row.cells.map((cell) => {
												const missing_description = cell.value.missing_description;
												const missing_image = cell.value.missing_image;
												const missing_image_and_description =
													cell.value.missing_image_and_description;
												const checkBackground =
													missing_description ||
													missing_image ||
													missing_image_and_description;
												return (
													<td
														style={{
															...(cell.column.Header !== "location"
																? {
																		borderBottom: "1px dashed #EAEAEA",
																		width: `${100 / (platformsNames.length || 1)}%`
																	}
																: {
																		border: "1px solid #EAEAEA",
																		padding: "20px 16px 24px 24px"
																	})
														}}
														className={`${checkBackground ? "issue-hover-red" : ""} `}
													>
														{cell.column.Header == "location" &&
														(cell.value.location_name || "").length >= 31 ? (
															<Popover
																data={cell.value}
																renderPopover={renderPopover}
																position={"down-left"}
																showOnHover={true}
																showOnClick
																classes="fit-content"
															>
																{cell.render("Cell")}
															</Popover>
														) : (
															cell.render("Cell")
														)}
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan="100%" style={{ textAlign: "right" }} className="paginator-location">
										<Paginator
											limit={limit}
											offset={offset}
											count={dataCount || 0}
											showPageSize={true}
											goToPage={handlePagination}
											setPageSize={handlePageSize}
										/>
									</td>
								</tr>
							</tfoot>
						</>
					)}
				</table>
			) : (
				""
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	periscopeSpecificItemIssueState: state.periscopeSpecificItemIssueState,
	periscopeSpecificItemIssueData: state.periscopeSpecificItemIssueData
});
export default connect(mapStateToProps)(SpecificItemIssueTable);

const Loader = ({ tableData, platformCount, error = false, refreshBtn }) => {
	let displayErrorDiv = (
		<div className="error-state">
			<div className="load-chart-div">
				<img className="load-chart-error-icon" src="/assets/periscope/loading-error.svg" />
				<div className="load-chart-info">
					<div className="chart-info">
						<span className="chart-main-text">Could not load this chart!</span>
						<span className="chart-para-info">
							We are facing some issues in fetching this data for you.
						</span>
					</div>
					<div className="try-again">
						<img className="load-again-img" src="/assets/periscope/load-again.svg" />
						<span className="try-again-text" onClick={() => refreshBtn()}>
							Try Again
						</span>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<tbody className={`sample-table  ${error == true ? "stop-moving" : ""}`} style={{ position: "relative" }}>
				{error ? displayErrorDiv : ""}
				{[...Array(7)].map((_, index) => {
					return (
						<tr key={index}>
							{tableData.map((table, indexVal) => {
								if (table.toLowerCase() !== "location") {
									return (
										<td
											key={indexVal}
											style={{
												padding: "20px 24px 20px 24px",
												width: `${platformCount}`,
												borderBottom: "1px dashed #EAEAEA"
											}}
										>
											<SkeletonLoader classname={"platform-loader"} />
										</td>
									);
								} else {
									return (
										<td
											key={indexVal}
											className="specific-item-location-loader "
											style={{
												padding: "20px 16px 20px 24px",
												minWidth: "274px",
												borderBottom: "1px solid #EAEAEA",
												borderRight: "1px solid #EAEAEA",
												borderLeft: "1px solid #EAEAEA"
											}}
										>
											<SkeletonLoader classname={"location-loader"} />
										</td>
									);
								}
							})}
						</tr>
					);
				})}
			</tbody>
		</>
	);
};
