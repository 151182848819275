import gql from "graphql-tag";

export const GET_API_ACCOUNTS_LIST = gql`
	query getUsersList($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		users(limit: $limit, offset: $offset, filters: $filters, sort: $sort, api: true) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				fullName
				firstName
				username
				dateJoined
				groups {
					id
					name
				}
			}
		}
	}
`;

export const GET_API_ACCOUNT = gql`
	query getUser($id: Int) {
		user(id: $id) {
			id
			firstName
			fullName
			username
			apiKey
			dateJoined
			isActive
			groups {
				name
				id
			}
			locations {
				id
				name
			}
		}
	}
`;

export const EDIT_API_ACCOUNT = gql`
	mutation editApiAccount(
		$id: Int
		$name: String
		$allLocations: Boolean
		$locations: [Int]
		$roles: [Int]
		$isActive: Boolean
	) {
		saveApiUser(
			input: {
				id: $id
				name: $name
				allLocations: $allLocations
				locations: $locations
				roles: $roles
				isActive: $isActive
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				firstName
				fullName
				username
				apiKey
				dateJoined
				groups {
					id
					name
				}
				locations {
					id
					name
				}
				isActive
			}
		}
	}
`;

export const CREATE_API_ACCOUNT = gql`
	mutation createApiAccount($name: String, $allLocations: Boolean, $locations: [Int], $roles: [Int]) {
		saveApiUser(input: { name: $name, allLocations: $allLocations, locations: $locations, roles: $roles }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				firstName
				username
				apiKey
				dateJoined
				groups {
					id
					name
				}
				locations {
					id
					name
				}
			}
		}
	}
`;
