import React, { useEffect, useRef } from "react";

// third party
import { InvoicePaymentAlert, HorizonText, TextVariant } from "@urbanpiper-engineering/horizon";
import parse from "html-react-parser";
import history from "../history";
import { connect } from "react-redux";
import moment from "moment";

// utils
import { getLoggedInBizId, lS, trackEvent } from "../atlas-utils";

// store
import { store } from "../store/configureStore";

// actions
import { ActionTypes } from "../actions/_types";

const BillingRecovery = ({ billingRecoveryState, access = {} }) => {
	const { modal } = billingRecoveryState;
	const timerRef = useRef();

	useEffect(() => {
		handleOverdueAlerts();
		return () => {
			clearTimeout(timerRef?.current);
		};
	}, [modal?.dismissedAt]);

	const handleOverdueAlerts = () => {
		clearTimeout(timerRef?.current);

		if (!modal.dismissedAt) {
			return;
		}

		if (modal.dueDateDiff >= -3 && modal.dueDateDiff <= 0) {
			// reopen modal every 24 hours (1 day)
			const reOpenModalAt = moment(modal.dismissedAt).add(24, "hours").format();

			const diff = moment().diff(reOpenModalAt, "seconds");

			if (diff >= 0) {
				showModal();
			} else {
				timerRef.current = setTimeout(
					() => {
						showModal();
					},
					Math.abs(diff) * 1000
				);
			}
			return;
		}

		if (modal.dueDateDiff >= 1 && modal.dueDateDiff <= 7) {
			// reopen modal every 1 hour
			const reOpenModalAt = moment(modal.dismissedAt).add(1, "hours").format();

			const diff = moment().diff(reOpenModalAt, "seconds");

			if (diff >= 0) {
				showModal();
			} else {
				timerRef.current = setTimeout(
					() => {
						showModal();
					},
					Math.abs(diff) * 1000
				);
			}
			return;
		}
	};

	const showModal = () => {
		store.dispatch({
			type: ActionTypes.UPDATE_BILLING_RECOVERY_MODAL_STATE,
			payload: {
				isOpen: true
			}
		});
	};

	const updateLs = () => {
		// update local storage
		let billingAlert = lS.get("billing_alert") || {};
		billingAlert = {
			...billingAlert,
			[getLoggedInBizId()]: {
				...(billingAlert[getLoggedInBizId()] ?? {}),
				isOpen: false,
				dismissedAt: moment().format()
			}
		};
		lS.set("billing_alert", billingAlert);
	};

	const handleCancel = () => {
		store.dispatch({
			type: ActionTypes.UPDATE_BILLING_RECOVERY_MODAL_STATE,
			payload: {
				isOpen: false,
				dismissedAt: moment().format()
			}
		});
		updateLs();

		// track event
		trackEvent("billing_dialog_cta", {
			dialog: modal?.milestone,
			source: "atlas"
		});
	};

	const handleSubmit = () => {
		handleCancel();

		// redirect to billing and invoices module
		history.push("/billing");
	};

	const renderModalTitle = () => {
		return <HorizonText variant={TextVariant.HEADING3}>{modal?.header || ""}</HorizonText>;
	};

	const renderModalBody = () => {
		return <HorizonText variant={TextVariant.BODY1}>{modal?.body ? parse(modal?.body) : ""}</HorizonText>;
	};

	return (
		<InvoicePaymentAlert
			className="billing-recovery"
			type={modal?.variant}
			open={modal?.isOpen}
			onClose={handleCancel}
			onSubmit={handleSubmit}
			okText="View Invoices"
			cancelText="I understand"
			title={renderModalTitle()}
			body={renderModalBody()}
			modalProps={{
				keyboard: false,
				footer: (_, { OkBtn, CancelBtn }) => (
					<>
						<CancelBtn />
						{access.isAdmin && <OkBtn />}
					</>
				)
			}}
		/>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	access: store.login.loginDetail.access,
	billingRecoveryState: store.billingRecoveryState
});
export default connect(mapStateToProps)(BillingRecovery);
