import React from "react";

// component
import Placeholder from "./Placeholder";
import { CheckBox } from "./CheckBox";

const FormTable = ({
	idPrefix = "",
	dataSource,
	columns,
	placeholderContent = {
		placeholderImageUrl: "",
		placeholderText: "",
		placeholderButtonClickAction: () => {},
		placeholderButtonContent: "",
		redirectionLink: "",
		redirectionLinkText: ""
	},
	contentTableClass = "",
	isSelectionEnabled = false,
	renderValuesAndFunctions,
	handleAllFieldsSelection,
	handleSingleFieldSelection,
	isAllFieldSelected,
	allPagesSelected = false,
	selectedFields,
	isLoading,
	showShimmerLoader = true,
	readOnly = false
}) => {
	if (isLoading && showShimmerLoader) {
		return (
			<div className="P(10px)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	if (!dataSource || (dataSource?.length === 0 && !isLoading)) {
		const {
			placeholderImageUrl,
			placeholderText,
			placeholderButtonClickAction,
			placeholderButtonContent,
			redirectionLink,
			redirectionLinkText
		} = placeholderContent;
		return (
			<Placeholder
				placeholderImageUrl={placeholderImageUrl}
				placeholderText={placeholderText}
				placeholderButtonClickAction={placeholderButtonClickAction}
				placeholderButtonContent={placeholderButtonContent}
				redirectionLink={redirectionLink}
				redirectionLinkText={redirectionLinkText}
				readOnly={readOnly}
			/>
		);
	}

	return (
		<div className={"form-table " + contentTableClass}>
			<TableHeader
				headerFields={columns.map((column) => ({ title: column.title, dataIndex: column.dataIndex }))}
				isSelectionEnabled={isSelectionEnabled}
				isAllFieldSelected={isAllFieldSelected}
				handleAllFieldsSelection={handleAllFieldsSelection}
				allPagesSelected={allPagesSelected}
				selectedFields={selectedFields}
				readOnly={readOnly}
			/>
			{dataSource &&
				dataSource.map((data, i) => (
					<TableList
						data={data}
						columns={columns}
						isSelectionEnabled={isSelectionEnabled}
						renderValuesAndFunctions={renderValuesAndFunctions}
						key={i}
						handleSingleFieldSelection={handleSingleFieldSelection}
						selectedFields={selectedFields}
						allPagesSelected={allPagesSelected}
						idPrefix={idPrefix}
						readOnly={readOnly}
					/>
				))}
		</div>
	);
};
export default FormTable;

const TableHeader = ({
	headerFields,
	isSelectionEnabled = false,
	isAllFieldSelected = false,
	handleAllFieldsSelection,
	allPagesSelected = false,
	selectedFields = {},
	readOnly = false
}) => {
	return (
		<div className="form-table--header">
			{headerFields.map((headerField, i) => (
				<div key={i} className={"header-field " + headerField.dataIndex}>
					{!readOnly && isSelectionEnabled && i === 0 && (
						<CheckBox
							checked={isAllFieldSelected || allPagesSelected}
							clickHandler={() => handleAllFieldsSelection(!isAllFieldSelected)}
							isPartiallyChecked={Object.keys(selectedFields).length > 0}
							isFullyChecked={isAllFieldSelected || allPagesSelected}
						/>
					)}
					<span>{headerField.title}</span>
				</div>
			))}
		</div>
	);
};

const TableList = ({
	data,
	columns,
	isSelectionEnabled,
	renderValuesAndFunctions,
	selectedFields,
	idPrefix,
	allPagesSelected,
	handleSingleFieldSelection,
	readOnly = false
}) => {
	return (
		<div className="form-table--list">
			{columns.map((column, i) => (
				<div className={"form-table--list-item " + column?.dataIndex} key={i}>
					{!readOnly && isSelectionEnabled && i === 0 && (
						<CheckBox
							checked={
								allPagesSelected
									? "selected"
									: selectedFields?.[idPrefix ? `${idPrefix}${data?.id}` : data?.id]
							}
							clickHandler={() =>
								handleSingleFieldSelection(
									data.id,
									selectedFields?.[idPrefix ? `${idPrefix}${data?.id}` : data?.id] ? false : true
								)
							}
						/>
					)}
					<div className={column.dataIndex + "--container"}>
						{column.render(
							data?.[column?.dataIndex] ||
								data?.[column?.dataIndex] === 0 ||
								data?.[column?.dataIndex] === false
								? data?.[column?.dataIndex]
								: "--",
							renderValuesAndFunctions,
							data
						)}
					</div>
				</div>
			))}
		</div>
	);
};
