// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	GET_MODIFIERS_LIST,
	GET_MODIFIER,
	EDIT_MODIFIER,
	UPDATE_MODIFIER_LOCATION_FIELDS,
	GET_MODIFIER_MENU_SERVICE
} from "../graphql/modifiers";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// constants
import { FOOD_TYPES } from "../client-config";
import { clientMenu } from "../client-menu";

export const fetchModifiersList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().modifiersListState;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().modifiersList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_MODIFIERS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_enabled") {
					// this value is being reversed because the behaviour of
					// is_enabled filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: !appliedFilters[f]
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_enabled",
				value: true
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_MODIFIERS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_MODIFIERS_LIST_SUCCESS,
			payload: { ...resp.data.modifiers }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_MODIFIERS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseModifierData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			const foodObject = FOOD_TYPES.find((fd) => fd.value === result["foodType"]);
			if (foodObject) {
				result["foodType"] = foodObject;
			}
			if (result["tags"]?.length) {
				result.tags = result.tags.map((tag) => ({
					title: tag,
					titleForDisplay: tag
				}));
			}
			result.currentOptionPrice = result?.optionPrice;
			break;
		case "server":
			if (result["foodType"]) {
				result["foodType"] = result["foodType"].value;
			}
			if (result["tags"]) {
				// pick tag's value if newly created else pick tag's title
				result.tags = result.tags.map((tag) => (tag["__isNew__"] ? tag.value : tag.title));
			}
			if (result["nestedOptionGroups"]) {
				result["nestedOptionGroupIds"] = result["nestedOptionGroups"].map((og) => og.id);
				delete result["nestedOptionGroups"];
			}
			if (result["currentOptionPrice"]) {
				delete result["currentOptionPrice"];
			}
		default:
			break;
	}
	return result;
};

export const fetchModifierDetail = async (id, dispatch, name = "", menuId = "") => {
	dispatch({
		type: ActionTypes.GET_MODIFIER_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		if (name) {
			variables.name = name;
		}
		if (menuId) {
			const resp = await clientMenu.query({
				query: GET_MODIFIER_MENU_SERVICE,
				variables: {
					menuId: menuId,
					optionId: String(variables?.id)
				},
				fetchPolicy: "no-cache"
			});
			dispatch({
				type: ActionTypes.GET_MODIFIER_DETAIL_SUCCESS,
				payload: resp.data.option
			});
		} else {
			const resp = await client.query({
				query: GET_MODIFIER,
				variables,
				fetchPolicy: "no-cache"
			});
			dispatch({
				type: ActionTypes.GET_MODIFIER_DETAIL_SUCCESS,
				payload: parseModifierData(resp.data.modifier, "form")
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_MODIFIER_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Modifier details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editModifier = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_MODIFIER_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_MODIFIER,
			variables: parseModifierData(data, "server")
		});
		if (resp.data.saveModifier.status.success) {
			dispatch({
				type: ActionTypes.EDIT_MODIFIER_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Modifier saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_MODIFIERS_LIST,
				payload: resp.data.saveModifier.object
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_MODIFIER_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveModifier.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_MODIFIER_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Modifier details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const updateModifierLocations = async (variables, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_MODIFIER_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: UPDATE_MODIFIER_LOCATION_FIELDS,
			variables
		});
		if (resp.data.updateModifierLocationFields.status.success) {
			dispatch({
				type: ActionTypes.EDIT_MODIFIER_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Modifier's Price at Locations saved!",
					timeout: 2000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_MODIFIER_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.updateModifierLocationFields.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_MODIFIER_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Modifier's Price at Locations",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreModifier = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_MODIFIER,
			variables: parseModifierData(data, "server")
		});
		if (resp.data.saveModifier.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveModifier.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};
