import React, { useEffect, useState } from "react";
import Image from "./Image";
import history from "../../history";

const StatusBox = ({
	status,
	menuInfo = {},
	handleDrawerOpen = () => {},
	handleRetryPosImport = () => {},
	handleRemoveStatus = () => {}
}) => {
	const [isOpen, setIsOpen] = useState(true);
	useEffect(() => setIsOpen(true), [status]);

	const getStatusInfo = () => {
		switch (status) {
			case "inprogress":
				return {
					header: "Menu Import in Progress",
					description: "Your menu is currently being imported and will be updated shortly.",
					imgSrc: "/assets/icons/info.png"
				};
			case "failed":
				return {
					header: "Menu Import Failed",
					description: menuInfo?.message || "Please try again later.",
					imgSrc: "/assets/icons/error-red-icon.svg"
				};
			case "success":
				return {
					header: "Menu Import Completed",
					description: "You can now view your menu.",
					imgSrc: "/assets/icons/success-green-icon.svg"
				};
			default:
				return {
					header: "",
					description: "",
					imgSrc: ""
				};
		}
	};

	const getCTAInfo = () => {
		const errorCode = menuInfo?.errorCode;
		switch (errorCode) {
			case "ERR_LOCATION_CONFIG":
				return {
					ctaText: "Go to Locations",
					btnHandler: () => {
						history.push("/locations");
					}
				};
			case "ERR_POS_CONFIG":
				return {
					ctaText: "Go to POS Settings",
					btnHandler: () => {
						history.push("/integrations");
					}
				};
			case "ERR_CONNECTION":
				return {
					ctaText: "Retry",
					btnHandler: () => {
						handleRetryPosImport();
					}
				};
			default:
				return {
					ctaText: "",
					btnHandler: () => {}
				};
		}
	};

	const { header, description, imgSrc } = getStatusInfo();
	const { ctaText, btnHandler } = getCTAInfo();

	return (
		<>
			{isOpen && (
				<div className={`status-box-wrapper ${status}`}>
					<div className="details-wrapper">
						<Image className="status-box-img" src={imgSrc} alt="Status Icon" />
						<div className="details">
							<p className="detail-header">{header}</p>
							<p className="detail-description">{description}</p>
							{status === "failed" &&
								menuInfo?.report &&
								menuInfo?.report?.structuredIssues?.length > 0 && (
									<span
										className="hyperlink"
										style={{ width: "fit-content" }}
										onClick={() => {
											handleDrawerOpen();
										}}
									>
										View menu errors <img src="/assets/icons/ic_right_arrow_blue.svg" />
									</span>
								)}
							{["ERR_LOCATION_CONFIG", "ERR_POS_CONFIG", "ERR_CONNECTION"].includes(
								menuInfo?.errorCode
							) && (
								<div className="cta-wrapper" onClick={btnHandler}>
									<span className="navigation-title hyperlink">{ctaText} </span>
									<img src="/assets/icons/ic_right_arrow_blue.svg" />
								</div>
							)}
						</div>
					</div>
					{status !== "inprogress" && (
						<Image
							className="close-icon"
							src="/assets/icons/cancel.png"
							alt="remove rule attribute"
							onClick={() => {
								setIsOpen(false);
								handleRemoveStatus();
							}}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default StatusBox;
