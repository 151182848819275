import React, { useEffect, useState, useRef } from "react";
import { FormSidebar } from "../_commons/FormSidebar";
import { SearchFilter } from "../_commons/SearchFilter";
import FormTable from "../_commons/FormTable";
import moment from "moment";
import { CATALOGUE_PLATFORMS_LOGO, PLATFORM_NAME_MAP } from "../../client-config";
import { Paginator } from "../_commons/Paginator";
import { GET_VERSIONS_BY_LOCATIONS } from "../../graphql/menus";
import { clientMenu } from "../../client-menu";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import Popover from "../_commons/Popover";
import { capitaliseText } from "../../atlas-utils";

const columns = [
	{
		title: "NAME",
		dataIndex: "locationName",
		render: (locationName) => {
			return (
				<div>
					<p>{locationName} </p>
				</div>
			);
		}
	},
	{
		title: "VERSIONS",
		dataIndex: "versions",
		render: (item, _, data) => {
			return (
				<div>
					{data?.versionDetails?.map((version) => (
						<div className="version-wrapper">
							<p>Version {version?.version}</p>
						</div>
					))}
				</div>
			);
		}
	},
	{
		title: "LAST PUBLISHED ON",
		dataIndex: "publishedOn",
		render: (item, _, data) => {
			return (
				<div className="publish-details">
					{data?.versionDetails?.map((version) => (
						<div className="publish-details-wrapper">
							<p> {moment(version?.lastPublishedOn).format("DD MMM, YYYY")}</p>
							<p className="time"> {moment(version?.lastPublishedOn).format("h:mm A")}</p>
						</div>
					))}
				</div>
			);
		}
	},
	{
		title: "ASSOC.PLATFORMS",
		dataIndex: "assocPlatforms",
		render: (item, _, data) => {
			return (
				<>
					{data?.versionDetails?.map((version) => (
						<div className="platforms-list">
							{version?.associatedPlatforms?.map((a) => (
								<Popover
									data={a.toLowerCase()}
									showOnHover={true}
									renderPopover={() => <div className="data">{capitaliseText(a)}</div>}
									position={"down-left"}
									showCustomTooltip={true}
									tooltipInfo={a.toLowerCase()}
								>
									<div className="platform">
										<img
											src={
												CATALOGUE_PLATFORMS_LOGO[
													(PLATFORM_NAME_MAP[a.toLowerCase()] || a).toLowerCase()
												] || "/assets/icons/icons8-globe-40.png"
											}
											alt=""
										/>
									</div>
								</Popover>
							))}
						</div>
					))}
				</>
			);
		}
	}
];

const LocationPlatformAssociationDrawer = ({
	isOpen,
	handleClose,
	locationIds = [],
	platforms = [],
	menuId,
	selectedBrand,
	isMultibrandEnabled,
	brands,
	handleBrandsLabelOption
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [locations, setLocations] = useState([]);
	const [offset, setOffset] = useState(0);
	const debouncedSearchRef = useRef();
	const handleMenusListSearch = (field, searchQuery) => {
		setSearchQuery(searchQuery);
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
		fetchLocationPlatformAssociation((page - 1) * 10);
	};
	const fetchLocationPlatformAssociation = async (offset = 0) => {
		try {
			const variables = {
				menuId,
				searchKeyword: searchQuery,
				locations: locationIds,
				platforms,
				limit: 10,
				offset
			};

			const response = await clientMenu.query({
				query: GET_VERSIONS_BY_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});

			setLocations(response?.data?.menu?.getVersionListByLocations);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		fetchLocationPlatformAssociation();
	}, []);
	useEffect(() => {
		if (isOpen) {
			if (debouncedSearchRef.current) {
				clearTimeout(debouncedSearchRef.current);
				debouncedSearchRef.current = setTimeout(() => {
					fetchLocationPlatformAssociation();
				}, 500);
			} else {
				debouncedSearchRef.current = setTimeout(() => {
					fetchLocationPlatformAssociation();
				}, 500);
			}
		}

		return () => {
			if (debouncedSearchRef.current) {
				clearTimeout(debouncedSearchRef.current);
			}
		};
	}, [searchQuery, isOpen]);

	return (
		<FormSidebar
			isOpen={isOpen}
			close={handleClose}
			title="Lunch Menu Location Platform Association"
			subTitle="View brand location association on platforms "
			formSidebarClasses="location-platform-association-drawer"
			hideActions
		>
			<div className="filters-wrapper">
				<div>
					{isMultibrandEnabled && (
						<SelectFilterCustom
							options={brands.items}
							isLoading={false}
							field="brands"
							currValue={selectedBrand}
							setFilter={() => {}}
							labelKey={"name"}
							valueKey={"id"}
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							renderLabel={handleBrandsLabelOption}
							renderOptions={() => {}}
							placeholder="Select brand"
							readOnly={true}
						/>
					)}
				</div>
				<SearchFilter
					placeholder="Search"
					filterOption={{
						field: "name"
					}}
					setFilter={handleMenusListSearch}
					value={searchQuery}
				/>
			</div>
			<div className="location-platform-association-table-wrapper">
				<FormTable columns={columns} dataSource={locations?.objects} contentTableClass="lpa-table" />
				<Paginator
					count={locations?.totalObjectCount || 0}
					limit={10}
					offset={offset}
					goToPage={handlePagination}
				/>
			</div>
		</FormSidebar>
	);
};

export default LocationPlatformAssociationDrawer;
