import React, { useState, useCallback, useEffect } from "react";

// component
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Paginator } from "../_commons/Paginator";
import UserEntityAssociations from "../EntityAssociations/UserEntityAssociations";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_API_ROLE_USERS } from "../../graphql/apiRoles";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { NESTED_ENTITY_TYPES } from "../../client-config";
const API_ROLE_USERS_LIST_HEADER_FIELDS = [
	{ label: "Name", value: "name" },
	{ label: "Username", value: "username" },
	{ label: "Email", value: "email" }
];
const FILTER_INITIAL_STATE = {
	username: "",
	email: ""
};

const Users = ({ roleId, setModalBusy, handleNestedEntity }) => {
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [usersList, setUsersList] = useState({
		objects: [],
		count: 0
	});
	const [associationSidebar, setAssociationSidebar] = useState(false);

	const fetchRoleUsers = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(roleId),
				limit,
				offset
			};
			// sidebar filters
			let filtersObject = [
				{
					field: "is_active",
					value: true
				}
			];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_API_ROLE_USERS,
				variables,
				fetchPolicy: "no-cache"
			});
			setUsersList(resp.data.group.users);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [roleId, limit, offset, appliedFilters]);

	useEffect(() => {
		fetchRoleUsers();
	}, [fetchRoleUsers]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchRoleUsers();
		}
	};

	return (
		<div className={"role-users-tab" + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">Associated Users</div>
					<div className="header-subtext">{usersList.count} user(s) are selected for this role</div>
				</div>
				<div>
					<Button clickHandler={openAssociationSidebar}>Update</Button>
				</div>
			</div>
			<div className="search-container">
				<InputWithLabel
					value={currFilters.username}
					onChange={(e) => setFilter("username", e.target.value)}
					placeholder="Enter Username"
				>
					Username
				</InputWithLabel>
				<InputWithLabel
					value={currFilters.email}
					onChange={(e) => setFilter("email", e.target.value)}
					placeholder="Enter Email"
				>
					Email
				</InputWithLabel>
			</div>
			<Table data={usersList.objects} loading={loading} handleNestedEntity={handleNestedEntity} />
			<Paginator limit={limit} offset={offset} count={usersList.count || 0} goToPage={handlePagination} />
			<UserEntityAssociations
				entityId={parseInt(roleId)}
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
			/>
		</div>
	);
};
export default Users;

export const Table = ({ data, loading, sortList, sortedField, handleNestedEntity }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container associated-users-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						headerFields={API_ROLE_USERS_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							handleNestedEntity={handleNestedEntity}
						/>
					))}
					{data.length === 0 && !loading && (
						<div className="no-items-placeholder">No Associated Users found!</div>
					)}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({ id, fullName, username, email, style, handleNestedEntity }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows items-list-table"
		>
			<div className="at-table-cell at-cell-text name" title={fullName}>
				<a
					className="link-text"
					role="button"
					onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[9], id)}
				>
					{fullName || id}
				</a>
			</div>
			<div className="at-table-cell at-cell-text username">{username}</div>
			<div className="at-table-cell at-cell-text email">{email}</div>
		</animated.div>
	);
};
