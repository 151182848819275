import React from "react";

const CheckMark = ({ height = 10, width = 13, fill = "#3B5FEB", classes = "" }) => {
	return (
		<div className={classes}>
			<svg width={width} height={height} viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M11.5286 0.528625L4 8.05727L1.13802 5.19529L0.195312 6.138L4 9.94269L12.4714 1.47133L11.5286 0.528625Z"
					fill={fill}
				/>
			</svg>
		</div>
	);
};

export default CheckMark;
