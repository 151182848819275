import React, { useState, useRef, useCallback, useEffect } from "react";

// components
import { Topbar } from "../components/_commons/Topbar";
import { Filters } from "../components/_commons/Filters";
import { List } from "../components/Referrals/List";
import Stats from "../components/Referrals/Stats";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";
// clients
import { store } from "../store/configureStore";

// utils
import { scroll } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchReferralsList } from "../actions/referrals";

// constants
const FORM_TABS = [
	{
		label: "List",
		value: "list"
	},
	{
		label: "Stats",
		value: "nps"
	}
];

const ReferralsContainer = ({ referralsList, referralsListState, referralStatsState, configItems }) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [showFilters, setShowFilters] = useState(false);
	const [filterCount, setFilterCount] = useState(0);
	const { currentFilters = {}, appliedFilters = {} } =
		formTab === FORM_TABS[0].value ? referralsListState : referralStatsState;
	const { data } = referralsList;
	const topRef = useRef();

	const switchTab = (tab) => {
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	useEffect(() => {
		let filterCount = 0;
		for (let f in appliedFilters) {
			if (typeof f !== "object" && appliedFilters[f]) {
				filterCount++;
			} else if (appliedFilters[f].value && appliedFilters[f].value != "") {
				filterCount++;
			}
		}
		setFilterCount(filterCount);
	}, [appliedFilters]);

	const updateReferralsListState = useCallback(
		(payload) => {
			store.dispatch({
				type:
					formTab === FORM_TABS[0].value
						? ActionTypes.REFERRALS_LIST_STATE_CHANGE
						: ActionTypes.REFERRALS_STATS_STATE_CHANGE,
				payload
			});
		},
		[formTab]
	);

	const applySearchFilter = useCallback(
		debounce(() => fetchReferralsList(), 500),
		[]
	);

	const handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.REFERRALS_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	const setSearchFilter = useCallback(
		(field, value) => {
			store.dispatch({
				type: ActionTypes.REFERRALS_LIST_SEARCH,
				payload: { [field]: value }
			});
			updateReferralsListState({
				offset: 0
			});
			applySearchFilter();
		},
		[applySearchFilter]
	);

	const filterSidebarCloseHandler = () => {
		setShowFilters(false);
		updateReferralsListState({
			currentFilters: appliedFilters
		});
	};

	const setFilter = useCallback(
		(field, value) => {
			let currentFilters = {
				...referralsListState.currentFilters
			};
			value = value === null ? {} : value;
			currentFilters[field] = value;
			updateReferralsListState({
				currentFilters
			});
		},
		[currentFilters]
	);

	const applyFilters = useCallback(async () => {
		setShowFilters(false);
		updateReferralsListState({
			appliedFilters: {
				...currentFilters
			},
			offset: 0
		});
		await fetchReferralsList();
	}, [currentFilters]);

	const clearFilters = async () => {
		setShowFilters(false);
		updateReferralsListState({
			currentFilters: {},
			appliedFilters: {},
			offset: 0
		});
		await fetchReferralsList();
	};

	return (
		<div className="referrals-section">
			<div className="section-container-common" ref={topRef}>
				<div className="settings-header no-border">
					<div>
						<div className="header-text">Meraki Referrals</div>
						<div className="subheader-text">Central repository of your customer referrals</div>
					</div>
				</div>
				<Topbar tabs={FORM_TABS} selectedTab={formTab} switchTab={switchTab} isStickyOnTop={true} />
				<div className="referrals-container">
					{formTab === FORM_TABS[0].value && (
						<List
							searchKeywords={data.searchKeywords}
							configItems={configItems}
							searchFieldSelected={data.searchFieldSelected}
							handleSearchField={handleSearchField}
							searchFieldValue={data.searchFieldValue}
							setSearchFilter={setSearchFilter}
							showFilters={showFilters}
							setShowFilters={setShowFilters}
							filterCount={filterCount}
						/>
					)}
					{formTab === FORM_TABS[1].value && <Stats />}
					<Filters
						isOpen={showFilters}
						close={filterSidebarCloseHandler}
						apply={applyFilters}
						clear={clearFilters}
						options={data.filters.filter((f) => f.type !== "DATE") || []}
						currentFilters={currentFilters}
						setFilter={setFilter}
					/>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	referralsList: store.referralsList,
	referralsListState: store.referralsListState,
	referralStatsState: store.referralStatsState,
	configItems: store.configItems
});
export default connect(mapStateToProps)(ReferralsContainer);
