import React from "react";
import moment from "moment";
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import TimeSlotFilter from "./TimeSlotFilter";
import { CheckBox } from "../_commons/CheckBox";
import { NewDateCompareFilter, PRESET_TYPES } from "../_commons/NewDateCompareFilter";

const HolidaySchedule = ({
	handleForm,
	readOnly,
	expandedSections,
	holidaySlotData,
	handleSectionExpansion,
	handleSectionOutsideClick,
	handleDateSelection,
	handleClosedDay,
	isClosedAllDay,
	error,
	handleTimeSlots,
	handleDeleteTimeSlot,
	dateFilter,
	setDateFilter = () => {}
}) => {
	const setDateRangeFilter = (payload) => {
		setDateFilter({
			...dateFilter,
			...payload
		});
		handleDateSelection(
			"date",
			payload.currentDateFilter.current.rangeStartDate,
			payload.currentDateFilter.current.rangeEndDate,
			payload
		);
	};

	return (
		<div className="form-content">
			<div className="form-row row-half">
				<InputWithLabel
					value={holidaySlotData?.title}
					onChange={(e) => handleForm("title", e.target.value)}
					validationMessage={(error.fields || {})?.title || ""}
					readOnly={readOnly}
					requiredLabel
				>
					Name
				</InputWithLabel>
				<InputWithLabel
					value={holidaySlotData?.crmTitle}
					onChange={(e) => handleForm("crmTitle", e.target.value)}
					readOnly={readOnly}
					tooltipInfo="This is an internal reference name and will not reflect on the ordering platform."
					showCustomTooltip
				>
					Internal Name
				</InputWithLabel>
			</div>
			<div className="form-row row-half new-date-range-wrapper">
				{dateFilter && (
					<NewDateCompareFilter
						title="Date"
						requiredLabel={true}
						showDropdown={true}
						hideComparison={true}
						currentDateFilter={dateFilter?.currentDateFilter}
						appliedDateFilter={dateFilter?.appliedDateFilter}
						updateState={setDateRangeFilter}
						position={"left"}
						monthsShown={1}
						minDate={moment().add(1, "day")}
						maxDate={moment().add(31, "days")}
						defaultCustomRange={10}
						setDefaultRange={false}
						hidePresetTypes={PRESET_TYPES.map((type) => type.label)}
						validationMessage={""}
						showCustomDateSelection
					/>
				)}
			</div>

			<CheckBox checked={isClosedAllDay} clickHandler={handleClosedDay}>
				<span className="closed-all-day">Closed all day</span>
			</CheckBox>

			{!isClosedAllDay && (
				<>
					<div className="all-brands-banner">
						<img src="/assets/icons/info.png" alt="info" />
						<p>The selected schedules will apply to each day within your chosen date range</p>
					</div>

					<div className="holiday-timeslots">
						{holidaySlotData?.slots &&
							holidaySlotData.slots.map((slot, slotIndex) => (
								<div className="timing-slot-wrapper" key={slotIndex}>
									<TimeSlotFilter
										slot={slot}
										label={"Close from"}
										hoursField="startHours"
										minutesField="startMinutes"
										timeslotIndex={0}
										slotIndex={slotIndex}
										handleTimeSlots={handleTimeSlots}
									/>
									<TimeSlotFilter
										slot={slot}
										label={"Close till"}
										hoursField="endHours"
										minutesField="endMinutes"
										timeslotIndex={0}
										slotIndex={slotIndex}
										handleTimeSlots={handleTimeSlots}
									/>
									{holidaySlotData.slots?.length > 1 && !readOnly && (
										<img
											alt="del"
											className="cancel-timeslot"
											onClick={() => handleDeleteTimeSlot(slotIndex)}
											src="/assets/icons/cancel-light.svg"
										/>
									)}
								</div>
							))}
						<div className="add-slot">
							<Button type="secondary" clickHandler={() => handleForm("addSlot", null)}>
								+ Add Timings
							</Button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default HolidaySchedule;
