import React, { useState, useCallback, useEffect, useMemo } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { CheckBox } from "../_commons/CheckBox";
import { Filters } from "../_commons/Filters";
import { Paginator } from "../_commons/Paginator";
import FormTable from "../_commons/FormTable";
import ModifierGroupCreate from "../../containers/ModifierGroupCreate";
import ModifierGroupEdit from "../../containers/ModifierGroupEdit";

// third party
import { debounce, uniqBy } from "lodash";
import { connect } from "react-redux";

// utils
import { client } from "../../client";
import { findChangedKeysInTwoObjects, getTrackingSource, msaagesArrayToHtml, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import {
	GET_ENTITY_MODIFIER_GROUPS,
	UPDATE_ENTITY_MODIFIER_GROUPS,
	UPDATE_OPTION_OPTION_GROUPS
} from "../../graphql/modifierGroupEntityAssociations";
import { UPDATE_MENU } from "../../graphql/menus";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchItemsDebounced } from "../../actions/actions";
import { TRACKING_STATUS } from "../../client-config";

// services
import NotificationServices from "../../services/NotificationService";

// client
import { clientMenu } from "../../client-menu";

// constants
const MODIFIER_GROUP_TYPES = [
	{ valueForDisplay: "Add-On", value: false },
	{ valueForDisplay: "Variant", value: true }
];
const FILTER_INITIAL_STATE = {
	option_group_title: "",
	crm_title: "",
	is_enabled: true
};
const MODIFIER_GROUPS_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const ModifierGroupEntityAssociations = ({
	entityId,
	entityType,
	isOpen,
	close,
	configItems,
	nestedModifierGroups = [],
	associatedModifierGroups = [],
	nested = false,
	modifier = "",
	isFromMenuSection = false,
	menuDetailsData,
	enabledModifierGroups = {},
	tab = null,
	entitesToBulkAssociate = [],
	isBulkAssociation = false
}) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [itemsLookup, setItemsLookup] = useState({});
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [showFilters, setShowFilters] = useState(false);
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [modifierGroupsData, setModifierGroupsData] = useState(MODIFIER_GROUPS_INITIAL_DATA);
	const [modifierGroupUpdates, setModifierGroupUpdates] = useState(isFromMenuSection ? enabledModifierGroups : {});
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [isCreateModifierGroupOpen, setCreateModifierGroupOpen] = useState(false);
	const [isModifierGroupEditOpen, setModifierGroupEditOpen] = useState(null);
	const [preSelectionModifierGroups, setPreSelectionModifierGroups] = useState([]);
	const [eventTracking, setEventTracking] = useState({
		type: `${entityType.label} first`,
		filters: {}
	});
	const [search, setSearch] = useState("");
	const totalChanges = findChangedKeysInTwoObjects(modifierGroupUpdates, enabledModifierGroups).length;

	const selectedCount = useMemo(() => {
		let count = 0;
		modifierGroupsData.objects.forEach((mg) => {
			if (modifierGroupUpdates[mg.id] !== undefined) {
				if (modifierGroupUpdates[mg.id] === true) {
					count++;
				}
			} else {
				if (mg.isAssociated) {
					count++;
				}
			}
		});
		return count;
	}, [modifierGroupsData, modifierGroupUpdates]);

	const setFilter = (field, value) => {
		const newFilters = {
			...currFilters
		};
		field === "is_type_variant"
			? value === null
				? delete newFilters[field]
				: (newFilters[field] = value)
			: (newFilters[field] = value);
		setCurrFilters(newFilters);
	};

	const fetchData = useCallback(
		async (filters = FILTER_INITIAL_STATE, _offset = offset, searchValue = "") => {
			try {
				setLoading(true);
				let filtersObject = [];
				const variables = {
					entityId: entityId,
					entityType: entityType.value,
					nested: nested,
					limit,
					offset: _offset,
					sort: {
						field: "option_group_title",
						order: "ASC"
					}
				};
				if (isFromMenuSection && searchValue !== "") {
					variables.search = [{ key: "default", value: searchValue }];
				}
				if (menuDetailsData?.menuId) {
					variables.menuId = menuDetailsData.menuId;
					if (preSelectionModifierGroups.length) {
						filtersObject.push({
							field: "pre-selected",
							value: String(preSelectionModifierGroups.join(","))
						});
					}
				}

				// sidebar filters
				Object.keys(filters).forEach((f) => {
					if (!filters[f]) {
						return;
					}
					if (typeof filters[f] === "object") {
						if (filters[f].value) {
							filtersObject.push({
								field: f,
								value: filters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: f,
							value: filters[f]
						});
					}
					// track filters which are being used
					setEventTracking((eventTracking) => {
						return {
							...eventTracking,
							filters: {
								...eventTracking["filters"],
								[f]: true
							}
						};
					});
				});
				if (filtersObject.length > 0) {
					variables.filters = filtersObject;
				}
				const resp = await client.query({
					query: GET_ENTITY_MODIFIER_GROUPS,
					variables,
					fetchPolicy: "no-cache"
				});

				const optionGroupsInMenu = menuDetailsData?.optionGroups || [];
				if (optionGroupsInMenu?.length && resp?.data?.entityOptionGroupAssociations?.objects?.length) {
					resp.data.entityOptionGroupAssociations.objects.forEach((modifierGroup) => {
						const targetOptionGroup = optionGroupsInMenu.find(
							(optionGroup) => String(optionGroup.id) === String(modifierGroup.id)
						);
						if (targetOptionGroup) {
							modifierGroup.titleInMenu = targetOptionGroup?.overrides?.name || "";
						}
					});
				}

				setModifierGroupsData(resp.data.entityOptionGroupAssociations);
				setIsCheckedAll(false);
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[entityId, limit, offset, nested, preSelectionModifierGroups]
	);

	useEffect(() => {
		if (isOpen) {
			fetchData(currFilters);
		}
	}, [fetchData, limit, offset, isOpen]);

	useEffect(() => {
		if (preSelectionModifierGroups.length) {
			// redirect user to first page if a new modifier group is created
			setOffset(0);
			setCurrFilters(FILTER_INITIAL_STATE);
			fetchData(FILTER_INITIAL_STATE);
		}
	}, [preSelectionModifierGroups]);

	const applyFilter = useCallback(
		debounce((filters, offset, searchValue) => fetchData(filters, offset, searchValue), 500),
		[fetchData]
	);

	const handleTitleFilter = (field, value) => {
		const newFilters = {
			...currFilters,
			[field]: value
		};
		setCurrFilters(newFilters);
		setOffset(0);
		applyFilter(newFilters, 0);
	};
	const handleSearch = (value) => {
		setSearch(value);
		applyFilter(currFilters, 0, value);
	};

	const syncData = useCallback(() => {
		setModifierGroupsData({
			...modifierGroupsData,
			objects: modifierGroupsData.objects.map((loc) => {
				if (modifierGroupUpdates[loc.id] !== undefined) {
					return {
						...loc,
						isAssociated: modifierGroupUpdates[loc.id]
					};
				}
				return loc;
			})
		});
		setModifierGroupUpdates({});
	}, [modifierGroupsData, modifierGroupUpdates]);

	const handleClose = useCallback(
		(refresh = false, success = false) => {
			// reset state before closing
			setModifierGroupsData(MODIFIER_GROUPS_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setOffset(0);
			setSearch("");
			setModifierGroupUpdates({});
			setIsCheckedAll(false);
			setEventTracking({
				type: `${entityType.label} first`,
				filters: {}
			});
			close(refresh, success);
		},
		[close, entityType]
	);

	const handleModifierToModifierGroupAssocInMenuService = async () => {
		const menuId = menuDetailsData?.menuId;
		const strigifiedOptionId = String(entityId);

		let modifierGroupUpdatesEditable = {
			...modifierGroupUpdates
		};

		const updatedOptionGroups = [
			...menuDetailsData.optionGroups.map((optGroup) => {
				if (modifierGroupUpdatesEditable[optGroup.id]) {
					delete modifierGroupUpdatesEditable[optGroup.id];
					return {
						...optGroup,
						options: [...optGroup.options]
					};
				} else {
					return { ...optGroup };
				}
			})
		];

		Object.keys(modifierGroupUpdatesEditable).forEach((groupId) => {
			if (modifierGroupUpdates[groupId]) {
				updatedOptionGroups.push({
					id: String(groupId),
					options: [],
					overrides: {
						name: null,
						description: null,
						min: null,
						max: null
					}
				});
			}
		});

		const optionGroupToBeNested = Object.keys(modifierGroupUpdates)
			.filter((id) => {
				if (modifierGroupUpdates[id]) {
					return modifierGroupUpdates[id];
				}
			})
			.map((id) => String(id));

		try {
			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: menuDetailsData.options.map((option) =>
						nested && option.id === strigifiedOptionId
							? {
									...option,
									nestedOptionGroups: [
										//...option.nestedOptionGroups,
										...optionGroupToBeNested
									],
									overrides: {
										...option.overrides,
										image: ""
									}
							  }
							: {
									...option,
									overrides: {
										...option.overrides,
										image: ""
									}
							  }
					),
					optionGroups: [...updatedOptionGroups],
					items: menuDetailsData.items.map((item) => ({
						...item,
						overrides: {
							...item.overrides,
							image: ""
						}
					})),
					categories: menuDetailsData.categories.map((category) => ({
						...category,
						overrides: {
							...category.overrides,
							image: ""
						}
					}))
				}
			};

			const optionOptionGroupAddResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (optionOptionGroupAddResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Updated modifier associations successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: optionOptionGroupAddResp?.data?.updateMenuV2
				});
				// fetchMenuDetails(menuId)
				// fetchParentSectionsList(menuId)
				handleClose(true);
				return true;
			} else {
				NotificationServices.pushNotification({
					message: "Failed to update modifier association",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (e) {
			NotificationServices.pushNotification({
				message: "Failed to update modifier association",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			console.log(e);
		}
	};

	const handleUpdateInMenuService = async () => {
		const menuId = menuDetailsData?.menuId;
		const strigifiedItemId = String(entityId);
		const updatedOptionGroups = [];
		Object.keys(modifierGroupUpdates).forEach((groupId) => {
			if (modifierGroupUpdates[groupId]) {
				updatedOptionGroups.push({
					id: String(groupId).replace("mg_", ""),
					options: [],
					overrides: {
						name: null,
						description: null,
						min: null,
						max: null
					}
				});
			}
		});
		try {
			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: menuDetailsData.options.map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: ""
						}
					})),
					// newly associated modifier group of an item with associated options should persist the already associated options and should not override them with an empty array
					optionGroups: uniqBy(
						[...menuDetailsData.optionGroups, ...updatedOptionGroups],
						(optionGroup) => optionGroup.id
					),
					items: menuDetailsData.items.map((item) =>
						item.id === strigifiedItemId || entitesToBulkAssociate.includes(String(item.id))
							? {
									...item,
									overrides: { ...item.overrides, image: "" },
									optionGroups: isBulkAssociation
										? uniqBy(
												[
													...item.optionGroups,
													...updatedOptionGroups.map((optionGroup) => optionGroup.id)
												],
												(optionGroupId) => optionGroupId
										  )
										: [...updatedOptionGroups.map((optionGroup) => optionGroup.id)]
							  }
							: { ...item, overrides: { ...item.overrides, image: "" } }
					),
					categories: menuDetailsData.categories.map((category) => ({
						...category,
						overrides: {
							...category.overrides,
							image: ""
						}
					}))
				}
			};

			const itemOptionGroupAddResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (itemOptionGroupAddResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: isBulkAssociation
						? `Modifier Groups associated with ${entityType.label.toLowerCase()}s successfully!`
						: "Updated modifier groups successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: itemOptionGroupAddResp?.data?.updateMenuV2
				});
				// fetchMenuDetails(menuId)
				// fetchParentSectionsList(menuId)

				isBulkAssociation ? handleClose(false, true) : handleClose(true);
			} else {
				NotificationServices.pushNotification({
					message: isBulkAssociation
						? "Failed to associate modifier groups. Please try again"
						: "Failed to update modifier group",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (e) {
			NotificationServices.pushNotification({
				message: isBulkAssociation
					? "Failed to associate modifier groups. Please try again"
					: "Failed to update modifier group",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			console.log(e);
		}
		setConfirmLoading(false);
	};

	const handleUpdate = useCallback(async () => {
		const eventMeta = {
			source: getTrackingSource()
		};

		if (entityType.value === "ITEM") {
			eventMeta.associated_entity = entityType.label.toLowerCase();
		}

		try {
			setConfirmLoading(true);
			const variables = {
				itemId: entityId,
				modifierGroupsToAssociate: [],
				modifierGroupsToDisassociate: [],
				optionId: entityId,
				optionGroupsToAssociate: [],
				optionGroupsToDisassociate: [],
				nestedOptionGroupsToAssociate: [],
				nestedOptionGroupsToDisassociate: [],
				menuId: menuDetailsData?.menuId || null
			};
			if (entityType.value === "ITEM") {
				for (let id in modifierGroupUpdates) {
					if (modifierGroupUpdates[id]) {
						variables.modifierGroupsToAssociate.push(parseInt(id));
					} else {
						variables.modifierGroupsToDisassociate.push(parseInt(id));
					}
				}
				if (entityType.event) {
					eventMeta.change_in_associated_entity =
						variables.modifierGroupsToAssociate.length - variables.modifierGroupsToDisassociate.length;
				}
			} else {
				if (nested) {
					for (let id in modifierGroupUpdates) {
						if (modifierGroupUpdates[id]) {
							variables.nestedOptionGroupsToAssociate.push(parseInt(id));
						} else {
							variables.nestedOptionGroupsToDisassociate.push(parseInt(id));
						}
					}
					if (entityType.event) {
						eventMeta.change_in_associated_entity =
							variables.nestedOptionGroupsToAssociate.length -
							variables.nestedOptionGroupsToDisassociate.length;
					}
				} else {
					for (let id in modifierGroupUpdates) {
						if (modifierGroupUpdates[id]) {
							variables.optionGroupsToAssociate.push(parseInt(id));
						} else {
							variables.optionGroupsToDisassociate.push(parseInt(id));
						}
					}
					if (entityType.event) {
						eventMeta.change_in_associated_entity =
							variables.optionGroupsToAssociate.length - variables.optionGroupsToDisassociate.length;
					}
				}
			}
			if (isFromMenuSection) {
				if (entityType.value === "OPTION") {
					handleModifierToModifierGroupAssocInMenuService();
					return;
				}

				handleUpdateInMenuService(variables);
				return;
			}
			const resp = await client.mutate({
				mutation: entityType.value === "ITEM" ? UPDATE_ENTITY_MODIFIER_GROUPS : UPDATE_OPTION_OPTION_GROUPS,
				variables,
				fetchPolicy: "no-cache"
			});

			if (entityType.value === "ITEM") {
				if (resp.data.updateItemModifierGroups.status.success) {
					eventMeta.status = TRACKING_STATUS.SUCCESS;
					trackEvent(entityType.event, eventMeta);

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Modifier groups association updated!",
							timeout: 2000,
							error: false
						}
					});
					syncData();
					handleClose(true);
				} else {
					eventMeta.status = TRACKING_STATUS.FAILURE;
					trackEvent(entityType.event, eventMeta);

					// handle error message
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: msaagesArrayToHtml(resp.data.updateItemModifierGroups.status.messages),
							timeout: 5000,
							error: true
						}
					});
				}
			} else {
				if (resp.data.updateBizItemOptionOptionGroups.status.success) {
					eventMeta.status = TRACKING_STATUS.SUCCESS;
					trackEvent(entityType.event, eventMeta);

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: nested
								? "Nested modifier groups association updated!"
								: "Parent modifier groups association updated!",
							timeout: 2000,
							error: false
						}
					});
					syncData();
					handleClose(true);
				} else {
					eventMeta.status = TRACKING_STATUS.FAILURE;
					trackEvent(entityType.event, eventMeta);
					// handle error message
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: msaagesArrayToHtml(resp.data.updateBizItemOptionOptionGroups.status.messages),
							timeout: 5000,
							error: true
						}
					});
				}
			}
		} catch (error) {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(entityType.event, eventMeta);

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [entityId, entityType, nested, modifierGroupUpdates, fetchData, syncData, handleClose, eventTracking]);

	const handleCheck = (id, toAssociate, title) => {
		if (entityType.value === "OPTION" && associatedModifierGroups.includes(id) && toAssociate) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: `${title} is already a parent modifier group of ${modifier}, remove it from the parent modifier groups to associate.`,
					timeout: 5000,
					error: true
				}
			});
		} else if (entityType.value === "OPTION" && nestedModifierGroups.includes(id) && toAssociate) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: `${title} is already a nested modifier group of ${modifier}, remove it from the nested modifier groups to associate.`,
					timeout: 5000,
					error: true
				}
			});
		} else {
			setModifierGroupUpdates({
				...modifierGroupUpdates,
				[id]: toAssociate
			});
		}
		if (!toAssociate) {
			setIsCheckedAll(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		modifierGroupsData.objects.forEach((mg) => {
			if (entityType.value === "OPTION" && associatedModifierGroups.includes(mg.id) && toCheckAll) {
				return;
			} else if (entityType.value === "OPTION" && nestedModifierGroups.includes(mg.id) && toCheckAll) {
				return;
			}
			updates[isFromMenuSection && entityType.value === "ITEM" ? `mg_${mg.id}` : mg.id] = toCheckAll;
		});
		setModifierGroupUpdates({
			...modifierGroupUpdates,
			...updates
		});
	};

	const updateItemsLookup = (id, title) => {
		setItemsLookup({
			...itemsLookup,
			[id]: title
		});
	};

	const handleFilterStatus = () => {
		setShowFilters(!showFilters);
	};

	const handleFormFilter = () => {
		setOffset(0);
		applyFilter(currFilters, 0);
		setShowFilters(false);
	};

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	const clearFormFilter = () => {
		setShowFilters(false);
		setCurrFilters(FILTER_INITIAL_STATE);
		setOffset(0);
		fetchData();
	};

	const filterCount = useMemo(() => {
		let count = 0;
		for (let f in currFilters) {
			if (["is_enabled", "option_group_title"].includes(f)) {
				continue;
			} else if (f === "is_type_variant") {
				count++;
			} else if (currFilters[f].value && currFilters[f].value != "") {
				count++;
			}
		}
		return count;
	}, [currFilters]);

	const filterOptions = modifierGroupsData.filters.map((f) => {
		if (["is_enabled", "option_group_title"].includes(f.field)) {
			return null;
		}
		if (f.type === "MULTIPLE" && f.field === "related_items") {
			f = {
				...f,
				isAsync: true,
				asyncOptions: configItems.items,
				asyncLookup: itemsLookup,
				updateAsyncLookup: updateItemsLookup,
				handleAsyncSearch: fetchItemsDebounced
			};
		} else if (f.field === "is_type_variant") {
			f = {
				...f,
				values: MODIFIER_GROUP_TYPES
			};
		}
		return f;
	});

	useEffect(() => {
		if (isOpen) {
			setModifierGroupUpdates(enabledModifierGroups);
		}
	}, [isOpen]);

	useEffect(() => {
		// reset state when the drawer is open/closed
		setPreSelectionModifierGroups([]);
	}, [isOpen]);

	const modifierGroupEntityAssociationsColumns = [
		{
			title: "name",
			dataIndex: "optionGroupTitle",
			render: (title, _, data) => {
				let primaryTitle = "";
				let secondaryTitle = "";
				if (data?.titleInMenu && data?.titleInMenu !== data?.optionGroupTitle) {
					primaryTitle = data?.titleInMenu;
					secondaryTitle = data?.optionGroupTitle;
				} else {
					primaryTitle = data?.optionGroupTitle || "";
				}

				return (
					<div className="item-title">
						<span className="primary-name hyperlink hyperlink--black-color">{primaryTitle}</span>
						{secondaryTitle ? <span className="secondary-name">{secondaryTitle}</span> : null}
					</div>
				);
			}
		},
		{
			title: "crm title",
			dataIndex: "crmTitle",
			render: (data) => data
		},
		{
			title: "type",
			dataIndex: "isVariant",
			render: (data) => <span className={data ? "variant" : "add-on"}>{data ? "Variant" : "Add On"}</span>
		}
	];

	const handleItemOptionGroupAssociationSelection = (id) => {
		if (modifierGroupUpdates[isFromMenuSection ? `mg_${id}` : id]) {
			setIsCheckedAll(false);
			setModifierGroupUpdates((current) => ({
				...current,
				[isFromMenuSection ? `mg_${id}` : id]: false
			}));
			return;
		}

		setModifierGroupUpdates((current) => ({
			...current,
			[isFromMenuSection ? `mg_${id}` : id]: true
		}));
	};

	const handleModifierGroupCreationDrawerClose = (modifierGroupId = null) => {
		setCreateModifierGroupOpen(false);
		if (isFromMenuSection && modifierGroupId) {
			setModifierGroupUpdates((current) => ({
				...current,
				[entityType?.value === "OPTION" ? modifierGroupId : `mg_${modifierGroupId}`]: true
			}));
			setPreSelectionModifierGroups((current) => [...current, modifierGroupId]);
		}
		if (modifierGroupId) {
			setModifierGroupEditOpen(modifierGroupId);
		}
	};

	const getFilteredNestedObjects = (data) => {
		return data.filter((entity) => {
			return !nestedModifierGroups.includes(entity?.id) && !associatedModifierGroups.includes(entity?.id);
		});
	};

	return (
		<div className="modifier-group-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Modifier groups"
				subTitle={`Select Modifier groups to associate with this ${entityType.label}`}
				loading={confirmLoading}
				hideActions={true}
				isNested={isBulkAssociation ? false : true}
				headerRight={
					<>
						{isFromMenuSection && (
							<Button
								type="secondary"
								clickHandler={() => {
									setCreateModifierGroupOpen(true);
								}}
							>
								Create Modifier Group
							</Button>
						)}
						<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
							{totalChanges === 0
								? "Update"
								: isFromMenuSection
								? "Update"
								: `Update ${totalChanges} Modifier Group(s)`}
						</Button>
					</>
				}
			>
				<div className="form-content">
					<div className="form-filters-container">
						{isFromMenuSection ? (
							<InputWithLabel
								value={search}
								onChange={(e) => handleSearch(e.target.value)}
								placeholder="Search by title"
							>
								Title
							</InputWithLabel>
						) : (
							<>
								<InputWithLabel
									value={currFilters.option_group_title}
									onChange={(e) => handleTitleFilter("option_group_title", e.target.value)}
									placeholder="Enter Title"
								>
									Title
								</InputWithLabel>
								<InputWithLabel
									value={currFilters.crm_title}
									onChange={(e) => handleTitleFilter("crm_title", e.target.value)}
									placeholder="Enter CRM Title"
								>
									CRM Title
								</InputWithLabel>
							</>
						)}
						<div className={(showFilters ? "active" : "") + " form-filter-btn"}>
							<div className="container" onClick={handleFilterStatus}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div className="filter-title">
									Filter
									{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
								</div>
							</div>
						</div>
					</div>
					<div className="selection">
						<div>
							Selected Modifier Groups (
							{isFromMenuSection ? Object.keys(modifierGroupUpdates).length : selectedCount}/
							{modifierGroupsData.count})
						</div>
					</div>
					{entityType.value === "ITEM" ? (
						<FormTable
							idPrefix={isFromMenuSection ? "mg_" : ""}
							columns={modifierGroupEntityAssociationsColumns}
							dataSource={modifierGroupsData.objects}
							isSelectionEnabled
							handleSingleFieldSelection={handleItemOptionGroupAssociationSelection}
							selectedFields={modifierGroupUpdates}
							isAllFieldSelected={isCheckedAll}
							handleAllFieldsSelection={handleCheckAll}
							isLoading={loading}
						/>
					) : (
						<FormTable
							columns={modifierGroupEntityAssociationsColumns}
							dataSource={getFilteredNestedObjects(modifierGroupsData.objects)}
							isSelectionEnabled
							handleSingleFieldSelection={handleCheck}
							selectedFields={modifierGroupUpdates}
							isAllFieldSelected={isCheckedAll}
							handleAllFieldsSelection={() => handleCheckAll(!isCheckedAll)}
							isLoading={loading}
						/>
					)}
					<Paginator
						limit={limit}
						offset={offset}
						count={modifierGroupsData.count}
						goToPage={handlePagination}
					/>
					<Filters
						isOpen={showFilters}
						close={handleFilterStatus}
						apply={handleFormFilter}
						clear={clearFormFilter}
						options={filterOptions}
						currentFilters={currFilters}
						setFilter={setFilter}
						isFilters={true}
					/>
				</div>
				{isCreateModifierGroupOpen && (
					<ModifierGroupCreate
						isFromMenuSection={isFromMenuSection}
						isOpen={isCreateModifierGroupOpen}
						isNested
						close={handleModifierGroupCreationDrawerClose}
					/>
				)}
				{!!isModifierGroupEditOpen && (
					<ModifierGroupEdit
						isOpen={!!isModifierGroupEditOpen}
						isFromMenuSection={isFromMenuSection}
						isNested
						isMenuFlowIgnored
						match={{
							params: {
								id: isModifierGroupEditOpen
							}
						}}
						closeNestedContainer={() => setModifierGroupEditOpen(null)}
						tab={tab}
					/>
				)}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	configItems: store.configItems,
	menuDetailsData: store.menuDetailsState.menuDetailsData
}))(ModifierGroupEntityAssociations);

const ModifierGroups = ({
	handleCheck,
	loading,
	data = [],
	modifierGroupUpdates,
	nestedModifierGroups = [],
	associatedModifierGroups = [],
	isFromMenuSection
}) => {
	if (data.length === 0 && loading) {
		return (
			<div className="P(10px 0)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}
	if (data.length === 0 && !loading) {
		return <div className="no-items-placeholder">No Modifier groups found!</div>;
	}
	return (
		<div className={(data.length > 0 && loading ? "disabled" : "") + " modifier-groups-container"}>
			{data.map((mg, i) => {
				const status = isFromMenuSection
					? modifierGroupUpdates[mg.id]
					: modifierGroupUpdates[mg.id] === undefined
					? mg.isAssociated
					: modifierGroupUpdates[mg.id];
				let uptoLabel = "";
				if (mg.optionGroupMaxSelectable === 1) {
					uptoLabel = "Variant";
				} else if (mg.optionGroupMaxSelectable > 1) {
					uptoLabel = `Up to ${mg.optionGroupMaxSelectable}`;
				} else {
					uptoLabel = "Up to any number";
				}

				return (
					<div
						key={i}
						className={
							"modifier-group-item" +
							(nestedModifierGroups.includes(mg.id) || associatedModifierGroups.includes(mg.id)
								? " restriction"
								: "")
						}
					>
						<div className="header-row">
							<div className="title">{mg.optionGroupTitle}</div>
							<CheckBox
								checked={status}
								clickHandler={() => handleCheck(mg.id, !status, mg.optionGroupTitle)}
							>
								T
							</CheckBox>
						</div>
						<div className="selectable">{uptoLabel}</div>
						<div className="handle">{mg.crmTitle && `CRM Title: ${mg.crmTitle}`}</div>
					</div>
				);
			})}
		</div>
	);
};
