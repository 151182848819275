import { client } from "../client";
import { ARCHIVE_RESTORE_BRANDS, GET_BRANDS_LIST } from "../graphql/brands";
import { store } from "../store/configureStore";
import { ActionTypes } from "./_types";

// third party
import { debounce } from "lodash";
import { acceptInviteDetail } from "../reducers/acceptInvite";

export const fetchBrandsList = async (offset = 0, active = true, searchQuery = "", sort) => {
	try {
		store.dispatch({
			type: ActionTypes.GET_BRANDS_LIST_REQUEST
		});
		const variables = {
			filters: [
				{
					field: "is_active",
					value: active
				}
			],
			limit: 10,
			offset: offset,
			search: [
				{
					key: "default",
					value: searchQuery || store.getState().brandsListState.searchQuery
				}
			]
		};

		if (!sort) {
			variables.sort = {
				field: "created",
				order: "DESC"
			};
		} else {
			variables.sort = {
				...sort
			};
		}
		const resp = await client.query({
			query: GET_BRANDS_LIST,
			fetchPolicy: "no-cache",
			variables
		});
		if (resp.data.brands) {
			store.dispatch({
				type: ActionTypes.GET_BRANDS_LIST_SUCCESS,
				payload: resp.data.brands
			});
		} else {
			store.dispatch({
				type: ActionTypes.GET_BRANDS_LIST_FAILURE
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_BRANDS_LIST_FAILURE
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Failed to fetch!",
				timeout: 5000,
				error: true
			}
		});
	}
};

export const fetchBrandsListDebounced = debounce(
	(offset = 0, active = true, searchQuery = "", sort) => fetchBrandsList(offset, active, searchQuery, sort),
	500
);

export const archiveRestoreBrand = async (isActive, data) => {
	try {
		const variables = {};
		variables.isActive = isActive;
		variables.brandIds = data?.isMulti
			? [...Object.keys(data.selectedBrands).map((brandId) => parseInt(brandId))]
			: [parseInt(data?.id)];
		const resp = await client.mutate({
			mutation: ARCHIVE_RESTORE_BRANDS,
			variables
		});
		if (resp.data.removeBrandsLocations.status.success) {
			return "success";
		} else {
			return "failure";
		}
	} catch (error) {
		console.log(error);
		return "failure";
	}
};
