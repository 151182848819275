import React, { useEffect, useState } from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";

// assets
import CreateIcon from "../_commons/CreateIcon";
import BulkActionIcon from "../_commons/BulkActionIcon";
import HelpIcon from "../_commons/HelpIcon";
import TableColumnSelector from "../_commons/TableColumnSelector";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import ContextMenu from "../_commons/ContextMenu";
import StatusBox from "../_commons/MenuStatusBox";
import TripleDotIcon from "../_icons/TripleDotIcon";
import ContextMenuButton from "../_commons/ContextMenuButton";
import { HorizonBanner, HorizonCarousel, HorizonChip } from "@urbanpiper-engineering/horizon";
import { lS } from "../../atlas-utils";

export const Header = (props) => {
	const [isContextMenuOpen, setContextMenuOpen] = useState(false);
	const handleOnImportPOS = (e) => {
		setContextMenuOpen(false);
		props.handleMenuPullDrawer(e);
	};

	return (
		<React.Fragment>
			<div className="credits-section-header transactions-list-header">
				<div className="header-text">
					<div className="title">Items</div>
					<div className="subtitle">Central repository for all your items</div>
				</div>
				{!props.hideHeaderSectionRight && (
					<div className="header-action-button">
						<div className="filter-buttons">
							{/* {
							props.dimensions.width > 768 &&
							<div className={(props.filterActive ? "active":"")+" filter-in-header campaign-list-filter"}>
								<div className="container" onClick={props.flipShowFilters}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt=""/>
									<div className="filter-title">
										Filter
										{
											props.filterCount > 0 &&
											<span className="filter-count">{props.filterCount}</span>
										}
									</div>
								</div>
							</div>
						} */}
							{props.isCatalogueManagement && (
								<div className="bulk-actions-container">
									<Link to="/bulk/items">
										<Button type="secondary">
											<BulkActionIcon />
											<span>Bulk Actions</span>
										</Button>
									</Link>
								</div>
							)}
							{props.isCatalogueManagement && (
								<div className="action-buttons-container">
									<Link to="/items/new">
										<Button>
											<CreateIcon />
											<span>Create New</span>
										</Button>
									</Link>
								</div>
							)}
							{
								props.searchKeywords.length > 1 && props.dimensions.width > 768 && (
									<div className="search-input-container">
										<SelectFilter
											options={props.searchKeywords}
											field="searchFieldSelected"
											currValue={props.searchFieldSelected}
											setFilter={props.handleSearchField}
											labelKey="valueForDisplay"
											valueKey="key"
											isSearchable={false}
											isClearable={false}
										/>
										<SearchFilter
											filterOption={{ field: "searchFieldValue" }}
											value={props.searchFieldValue}
											setFilter={props.setFilter}
											placeholder="Search"
										/>
									</div>
								)
								// :
								// props.dimensions.width > 768 && null
								// <SearchFilter
								// 	filterOption={{ field: 'searchFieldValue' }}
								// 	value={props.searchFieldValue}
								// 	setFilter={props.setFilter}
								// 	placeholder="Search"
								// />
							}
							<div className="help-btn-container">
								<Button clickHandler={props.handlePiperAcademy} type="secondary">
									<HelpIcon />
									<span>Help</span>
								</Button>
							</div>
							{props.showImport && (
								<ContextMenuButton
									isOpen={isContextMenuOpen}
									data={[]}
									renderMenuItems={() => (
										<div className="action-item" onClick={handleOnImportPOS}>
											<div class="text">Import from POS</div>
										</div>
									)}
									disableContextMenu={props?.ongoingMenuPull?.status === "inprogress"}
									handleOutsideClick={() => setContextMenuOpen(false)}
									handleOpenMenu={(e) => setContextMenuOpen(true)}
								/>
							)}
						</div>
					</div>
				)}
			</div>

			<BannerComponent {...props} />
			<div className="filters-second-section">
				<div className="section-1">
					{props.isMultibrandEnabled && (
						<SelectFilterCustom
							options={props.brands.items}
							isLoading={props.brands.isLoading}
							field="brands"
							currValue={props.selectedBrand}
							setFilter={(f, value) => props.handleBrand(value)}
							labelKey="name"
							valueKey="id"
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							isClearable={true}
							renderLabel={props.handleBrandsLabelOption}
							renderOptions={props.handleBrandsLabelOption}
							placeholder="Select brand"
						/>
					)}
					{!props?.isMenuEnabled && (
						<SelectFilter
							placeholder="Category"
							options={props.categoryFilter?.[0]?.values}
							labelKey="valueForDisplay"
							valueKey="value"
							field="item_discrete_category_id"
							setFilter={props.setFilter}
						/>
					)}
					{/* <div className={(props.filterActive ? "active":"")+" filter-in-header"}>
					<div className="container" onClick={props.flipShowFilters}>
						<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt=""/>
						<div className="filter-title">
							Filter
							{
								props.filterCount > 0 &&
								<span className="filter-count">{props.filterCount}</span>
							}
						</div>
					</div>
				</div> */}
					<Button type="filter" filterCount={props.filterCount || 0} clickHandler={props.flipShowFilters}>
						Filter
					</Button>
				</div>
				<div className="section-2">
					<SearchFilter
						filterOption={{ field: "searchFieldValue" }}
						value={props.searchFieldValue}
						setFilter={props.setFilter}
						placeholder="Search"
					/>
					<TableColumnSelector
						options={props.tableFields}
						handleColumnSelection={props.handleColumnSelection}
						selectedColumns={props.selectedColumns}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

const BannerComponent = (props) => {
	const itemLogsBanner = lS.get("itemLogsBanner") || {};
	const [showBannerStates, setShowBannerStates] = useState({
		0: props?.ongoingMenuPull?.status || false,
		1: (props?.lastMenuPull?.status && !props?.ongoingMenuPull) || false,
		2: !itemLogsBanner?.[props.bizId]?.banner
	});
	const toggleBannerStatus = (key, value = false) => {
		if (key in showBannerStates) {
			setShowBannerStates((prev) => ({ ...prev, [key]: value }));
		}
	};
	const fetchitemLogsBanner = () => {
		let sessions = new Set();
		if (!itemLogsBanner[props.bizId]) {
			itemLogsBanner[props.bizId] = { ...itemLogsBanner[props.bizId], sessions: [], banner: false };
		}
		if (itemLogsBanner?.[props.bizId]?.sessions) {
			sessions = new Set([...(itemLogsBanner?.[props.bizId].sessions || {})]);
		}
		if (props.bizId && !itemLogsBanner?.[props.bizId]?.sessions) {
			itemLogsBanner[props.bizId] = { sessions, banner: false };
		}
		if (itemLogsBanner && !itemLogsBanner?.[props.bizId]?.banner) {
			const today = new Date();
			const day = today.getDate();
			sessions.add(`${day}`);
			itemLogsBanner[props.bizId]["sessions"] = [...sessions];
			if (sessions.size >= 4) {
				itemLogsBanner[props.bizId]["banner"] = true;
				toggleBannerStatus(2);
			}
			lS.set("itemLogsBanner", itemLogsBanner);
		}
	};

	const dismissItemLogs = () => {
		toggleBannerStatus(2);
		itemLogsBanner[props.bizId]["banner"] = true;
		lS.set("itemLogsBanner", itemLogsBanner);
	};

	useEffect(() => {
		fetchitemLogsBanner();
	}, []);

	const singleBanner = (Object.keys(showBannerStates) || []).filter((curr) => {
		return showBannerStates[curr];
	});
	useEffect(() => {
		setShowBannerStates({
			0: props?.ongoingMenuPull?.status,
			1: props?.lastMenuPull?.status && !props?.ongoingMenuPull,
			2: !itemLogsBanner?.[props.bizId]?.banner
		});
	}, [
		props?.ongoingMenuPull?.status,
		props?.lastMenuPull?.status,
		!props?.ongoingMenuPull,
		itemLogsBanner?.[props.bizId]?.banner
	]);

	const ongoingMenuPullStatus = (
		<div className="box-wrapper">
			<StatusBox
				status={props?.ongoingMenuPull?.status}
				handleRemoveStatus={() => {
					toggleBannerStatus(0);
				}}
			/>
		</div>
	);
	const lastMenuPullStatus = (
		<div className="box-wrapper">
			<StatusBox
				status={props?.lastMenuPull?.status}
				menuInfo={props?.lastMenuPull}
				handleDrawerOpen={props?.handleMenuPosDrawerOpen}
				handleRemoveStatus={() => toggleBannerStatus(1)}
			/>
		</div>
	);
	const itemLogsIntro = (
		<div className="item-logs-banner">
			<HorizonBanner
				status="info"
				onClose={() => dismissItemLogs()}
				bannerHeading={<span>Item logs are here! Open any item and check the last tab for updates.</span>}
			/>
		</div>
	);
	const carouselItems = [ongoingMenuPullStatus, lastMenuPullStatus, itemLogsIntro];

	return (
		<>
			{(singleBanner || []).length == 1 ? (
				<>{carouselItems[Number(singleBanner[0])]}</>
			) : (
				<HorizonCarousel>
					{carouselItems.map((item, index) => {
						if (item && showBannerStates[index]) {
							return <> {item} </>;
						}
					})}
				</HorizonCarousel>
			)}
		</>
	);
};
