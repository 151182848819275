import React, { useState } from "react";

// third party
import { Link } from "react-router-dom";

// components
import { Button } from "../_commons/Button";

//assets
import BulkActionIcon from "../_commons/BulkActionIcon";
import CreateIcon from "../_commons/CreateIcon";

export const Header = (props) => {
	return (
		<>
			<div className="credits-section-header transactions-list-header">
				<div className="header-text">
					<div className="title">Customers</div>
					<div className="subtitle">
						<span>List of all your Customers</span>
					</div>
				</div>
				<div className="header-action-button">
					<div className="filter-buttons">
						<div className="bulk-actions-container M(0px)">
							<Link to="/bulk/customers">
								<Button type="secondary">
									<BulkActionIcon />
									<span>Bulk Actions</span>
								</Button>
							</Link>
						</div>
						<div className="action-buttons-container">
							<Link to="/customers/new">
								<Button>
									<CreateIcon />
									<span>Add Customer</span>
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
