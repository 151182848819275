import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";

// component
import ContextMenu from "../_commons/ContextMenu";
import FoodTypeIndicator from "../_commons/FoodTypeIndicator";
import FormTable from "../_commons/FormTable";
import Image from "../_commons/Image";

// utils
import { fixedToTwoDecimal, printCurrency, truncateText } from "../../atlas-utils";
import { FOODTYPE_MAPS } from "../../client-config";
import LightningIcon from "../_icons/LightningIcon";

const itemsTableColumn = [
	{
		title: "Item Name",
		dataIndex: "item",
		render: (data, renderValuesAndFunctions) => (
			<div className="item-details-container">
				<Image
					src={data?.imageUrl || "/assets/empty-dish.jpg"}
					alt={data?.name}
					className="item-img"
					errorOverrideImage="/assets/empty-dish.jpg"
				/>
				<FoodTypeIndicator foodType={FOODTYPE_MAPS[data?.type?.split("-")?.join("_")?.toUpperCase()]} />
				<div className="item-title" title={data?.name}>
					<span
						className="hyperlink hyperlink--black-color"
						onClick={() =>
							renderValuesAndFunctions?.isMenuLocked
								? () => {}
								: renderValuesAndFunctions?.handleItemDetailsClick(data?.id, {
										price: data?.price,
										imageUrl: data?.imageUrl,
										name: data?.name,
										categoryId: data?.categoryId,
										subCategoryId: data?.subCategoryId
									})
						}
					>
						{truncateText(data?.name, 25)}
					</span>
				</div>
			</div>
		)
	},
	// {
	// 	title: 'Category',
	// 	dataIndex: 'category',
	// 	render: (data) => (data)
	// },
	{
		title: "Price",
		dataIndex: "price",
		render: (data, renderValuesAndFunctions) => (
			<div>
				{printCurrency(renderValuesAndFunctions.currencySymbol)}
				{fixedToTwoDecimal(data)}
			</div>
		)
	},
	{
		title: "Rules",
		dataIndex: "activeRules",
		render: (data) => (
			<>
				{data?.length > 0 ? (
					<div className="number-of-rules">
						<LightningIcon fillColor={"#6D7AA8"} />
						{data?.length}
					</div>
				) : (
					"--"
				)}
			</>
		)
	},
	{
		title: "Issues",
		dataIndex: "issues",
		render: (data) => data
	},
	{
		title: "",
		dataIndex: "contextMenu",
		render: (item, renderValuesAndFunctions) =>
			renderValuesAndFunctions?.isMenuLocked ? null : (
				<ContextMenu
					isOpen={renderValuesAndFunctions.contextMenuId === item?.id}
					handleOutsideClick={
						item?.id === renderValuesAndFunctions.contextMenuId
							? renderValuesAndFunctions.handleContextMenuOutsideClick
							: () => {}
					}
					renderMenuItems={() =>
						renderValuesAndFunctions.renderMenuItems({
							id: item?.id,
							price: item?.price,
							imageUrl: item?.imageUrl,
							name: item?.name
						})
					}
					handleOpenMenu={() => renderValuesAndFunctions.handleOpenContextMenu(item?.id)}
				/>
			)
	}
];

const ItemsList = ({
	categoryId,
	itemsData = [],
	showHideAddItemsDrawer,
	isRenderedFromSubCategory = false,
	subCategoryName = "",
	categories,
	categoryName,
	parentSections,
	handleItemDetailsClick = () => {},
	handleEntityDeletionForm,
	handleDetailsEditSection,
	subCategoryId,
	isMenuLocked,
	currencySymbol,
	handleAllFieldsSelection,
	handleSingleFieldSelection,
	isAllFieldSelected,
	selectedFields,
	numberOfItemsSelected = 0,
	showCopyMenuSection,
	openItemCreationForm = () => {}
}) => {
	const [itemsDataState, setItemsDataState] = useState([]);
	const [isLoading, setLoading] = useState(isRenderedFromSubCategory ? true : false);
	const [contextMenuId, setContextMenuId] = useState(undefined);

	const [isSectionDropdownOpen, setIsSectionDropdownOpen] = useState(false);
	const handleSectionMenu = () => {
		setIsSectionDropdownOpen(!isSectionDropdownOpen);
	};

	const renderAddItemsMenu = () => {
		return (
			<React.Fragment>
				<div
					className="action-item"
					onClick={() => {
						openItemCreationForm(categoryId);
					}}
				>
					<div className="text">Create New Item</div>
				</div>
				<div className="action-item" onClick={() => updateItemAdditionDrawerState()}>
					<div className="text">Add from Existing Items</div>
				</div>
				<div className="action-item" onClick={() => showCopyMenuSection(categoryId)}>
					<div className="text">Copy from Another Menu</div>
				</div>
			</React.Fragment>
		);
	};

	const updateItemAdditionDrawerState = () => {
		const selectedItemsUnderCategory = new Map();

		if (isRenderedFromSubCategory) {
			itemsDataState.forEach((item) => {
				selectedItemsUnderCategory.set(item.id, true);
			});
		} else {
			itemsData.forEach((item) => {
				selectedItemsUnderCategory.set(item.id, true);
			});
		}
		const tempData = {
			categoryId: categoryId,
			selectedItemsUnderCategory,
			categoryDetail: parentSections.find((category) => category.id === categoryId)
		};
		showHideAddItemsDrawer(true, tempData);
	};

	const renderMenuItems = (record) => {
		return (
			<React.Fragment>
				<div
					className="action-item"
					onClick={() =>
						handleItemDetailsClick(record?.id, {
							price: record?.price,
							imageUrl: record?.imageUrl,
							name: record?.name,
							categoryId,
							subCategoryId
						})
					}
				>
					Edit Item Listing
				</div>
				<div
					className="action-item"
					onClick={() => {
						handleEntityDeletionForm({ id: record?.id, type: "item", categoryId, subCategoryId });
						setContextMenuId(undefined);
					}}
					style={{ color: "#ff425c" }}
				>
					Remove Item
				</div>
			</React.Fragment>
		);
	};

	const handleOpenContextMenu = (id) => {
		if (!contextMenuId || id !== contextMenuId) {
			setContextMenuId(id);
			return;
		}
		setContextMenuId(undefined);
	};

	const handleContextMenuOutsideClick = () => {
		setContextMenuId(undefined);
	};

	const renderSubsectionContextMenuItems = (record) => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => handleDetailsEditSection(record?.id, true)}>
					Edit Subsection
				</div>
				<div
					className="action-item"
					onClick={() => {
						handleEntityDeletionForm({ id: record?.id, type: "section" });
						setContextMenuId(undefined);
					}}
					style={{ color: "#ff425c" }}
				>
					Delete Subsection
				</div>
			</React.Fragment>
		);
	};

	useEffect(() => {
		if (isRenderedFromSubCategory) {
			const searchResult = parentSections.find((category) => category.id === categoryId);
			if (searchResult?.items) {
				setItemsDataState(searchResult.items);
				setLoading(false);
			}
		}
	}, [categoryId, parentSections]);

	const renderValuesAndFunctions = {
		handleItemDetailsClick,
		handleContextMenuOutsideClick,
		handleOpenContextMenu,
		renderMenuItems,
		contextMenuId,
		isMenuLocked,
		currencySymbol
	};

	return (
		<div className="menu-item-list">
			<div className={`add-items-cta ${isRenderedFromSubCategory ? "justify-space-between" : ""}`}>
				{!isLoading && (
					<>
						{isRenderedFromSubCategory && (
							<span className="sub-category-details">
								<span className="sub-category-name">{subCategoryName}</span>
								<span className="items-length">
									{(isRenderedFromSubCategory ? itemsDataState : itemsData).length} items
								</span>
							</span>
						)}
						{numberOfItemsSelected === 0 ? (
							<div className="actions-container">
								{!isMenuLocked && (
									<ContextMenu
										isOpen={isSectionDropdownOpen}
										renderMenuItems={renderAddItemsMenu}
										handleOpenMenu={handleSectionMenu}
										handleOutsideClick={() => setIsSectionDropdownOpen(false)}
										clickEvent="mousedown"
									>
										<span className="hyperlink action-content">
											<span className="plus">+</span>
											<span>Add Item</span>
										</span>
									</ContextMenu>
								)}
								{isRenderedFromSubCategory && !isMenuLocked && (
									<ContextMenu
										isOpen={contextMenuId === subCategoryId}
										handleOutsideClick={
											subCategoryId === contextMenuId ? handleContextMenuOutsideClick : () => {}
										}
										renderMenuItems={() => renderSubsectionContextMenuItems({ id: subCategoryId })}
										handleOpenMenu={() => handleOpenContextMenu(subCategoryId)}
									/>
								)}
							</div>
						) : null}
					</>
				)}
			</div>
			{(isRenderedFromSubCategory ? itemsDataState : itemsData).length === 0 ? (
				<div className="empty-items-placeholder">
					<Image
						src="/assets/icons/icon-menu-placeholder.svg"
						alt="no items found"
						className="empty-items-icon"
					/>
					<span className="empty-items-text">No items found in this section</span>
				</div>
			) : (
				<FormTable
					isSelectionEnabled={true}
					columns={itemsTableColumn}
					dataSource={(isRenderedFromSubCategory ? itemsDataState : itemsData).map((item) => ({
						id: item.id, // for form table checkbox
						item: {
							name: item.name,
							imageUrl: item.imageUrl || "",
							id: item.id,
							price: item?.price,
							type: item?.type,
							categoryId,
							subCategoryId
						},
						crmTitle: item.crmName,
						category: categoryName,
						categoryId: categoryId,
						issues: "--",
						price: item.price,
						contextMenu: {
							name: item.name,
							imageUrl: item.imageUrl || "",
							id: item.id,
							price: item?.price
						},
						activeRules: item?.activeRules
					}))}
					isLoading={isLoading}
					placeholderContent={{ placeholderText: "No items found in this section" }}
					renderValuesAndFunctions={renderValuesAndFunctions}
					selectedFields={selectedFields}
					isAllFieldSelected={isAllFieldSelected}
					handleAllFieldsSelection={handleAllFieldsSelection}
					handleSingleFieldSelection={handleSingleFieldSelection}
				/>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	categories: store.menuDetailsState.menuDetailsData.categories,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
});
export default connect(mapStateToProps)(ItemsList);
