import React, { useMemo } from "react";
import { connect } from "react-redux";
import { store } from "../../../store/configureStore";
import { lS, scroll } from "../../../atlas-utils";
import ItemTable from "./ItemTable";
import LocationTable from "./LocationTable";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ActionTypes } from "../../../actions/_types";

const ItemAvailabilityTable = ({
	periscopeItemAvailabilityState,
	defaultPlatforms,
	selectedBrand,
	configItems,
	tableRef,
	refreshBtn,
	periscopeCountryWiseSupportedPlatforms,
	login
}) => {
	const appliedFilters = periscopeItemAvailabilityState?.appliedFilters;
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const viewValue = queryParams?.get("view");
	const tableSelected = viewValue || "item";

	const platformCount = useMemo(() => {
		const platforms = appliedFilters?.platform?.value || defaultPlatforms;
		const values = platforms.split(",");
		const totalPercent = 100 / values.length;
		return { percent: `${totalPercent}%`, count: values.length };
	}, [appliedFilters?.platform, defaultPlatforms]);

	const tableData = useMemo(() => {
		const platforms = appliedFilters?.platform?.value || defaultPlatforms;
		return platforms.split(",");
	}, [appliedFilters?.platform, defaultPlatforms]);

	const locationTableData = useMemo(() => {
		let locationTableValues = ["location"];
		if (selectedBrand?.id === "all") {
			locationTableValues.push("brand");
		}
		const platforms = appliedFilters?.platform?.value || defaultPlatforms;
		const values = platforms.split(",");
		return [...locationTableValues, ...values];
	}, [appliedFilters?.platform, defaultPlatforms]);
	const country = (login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country).toLowerCase();
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (periscopeCountryWiseSupportedPlatforms?.[country] ||
			lS.get("periscopeCountryWisePlatforms")?.[country])?.[platform.platformName.toLowerCase()]?.enabled;
	});

	const platformsDict = useMemo(() => {
		if (bizPlatforms && bizPlatforms.length > 0) {
			return bizPlatforms.reduce((acc, platform) => {
				const value = platform.platformName.toLowerCase();
				if (!acc[value]) {
					acc[value] = platform;
				}
				return acc;
			}, {});
		}
	}, [bizPlatforms]);

	const limit = periscopeItemAvailabilityState.limit;
	const handlePagination = async (page) => {
		const offset = (page - 1) * limit;
		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
			payload: { offset: offset }
		});
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const handlePageSize = async (field, size) => {
		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
			payload: { limit: size.value }
		});
		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
			payload: { offset: 0 }
		});
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	return (
		<>
			<div data-testid="item-table-data" className="table-display-item-availability">
				{tableSelected == "item" ? (
					<>
						<ItemTable
							tableData={tableData}
							defaultPlatforms={defaultPlatforms}
							platformCount={platformCount}
							handlePagination={handlePagination}
							handlePageSize={handlePageSize}
							refreshBtn={refreshBtn}
						/>
					</>
				) : (
					<>
						<LocationTable
							tableData={locationTableData}
							defaultPlatforms={defaultPlatforms}
							platformCount={platformCount}
							platformsDict={platformsDict}
							handlePagination={handlePagination}
							handlePageSize={handlePageSize}
							selectedBrand={selectedBrand}
							refreshBtn={refreshBtn}
						/>
					</>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	login: state.login,
	periscopeItemAvailabilityData: state.periscopeItemAvailabilityData,
	periscopeCountryWiseSupportedPlatforms: state.periscopeCountryWiseSupportedPlatforms,
	periscopeItemAvailabilityState: state.periscopeItemAvailabilityState
});

export default connect(mapStateToProps)(ItemAvailabilityTable);
