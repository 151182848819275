// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_DISCOUNTS_LIST, GET_DISCOUNT, EDIT_ZOMATO_DISCOUNT, EDIT_UBEREATS_DISCOUNT } from "../graphql/discounts";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// constant
export const DISCOUNT_MUTATIONS = {
	zomato: {
		mutation: EDIT_ZOMATO_DISCOUNT,
		field: "saveZomatoDiscount"
	},
	ubereats: {
		mutation: EDIT_UBEREATS_DISCOUNT,
		field: "saveUberEatsDiscount"
	}
};

export const fetchDiscountsList = async (platform) => {
	const { limit, offset, sort, appliedFilters, appliedDateFilter } = store.getState().discountsListState;
	if (platform !== undefined) {
		store.dispatch(toggleGlobalLoader(true));
		store.dispatch({
			type: ActionTypes.GET_DISCOUNTS_LIST_REQUEST
		});
		try {
			const variables = {
				limit,
				offset
			};
			variables.entityType = platform;
			// sidebar filters
			let filtersObject = [];
			let processedActiveFilter = false;
			Object.keys(appliedFilters).forEach((f) => {
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push(appliedFilters[f]);
					}
				} else {
					if (f === "is_active") {
						// this value is being reversed because the behaviour of
						// is_active filter has been changed to work as if it's
						// is_archived.
						filtersObject.push({
							field: f,
							value: !appliedFilters[f]
						});
						processedActiveFilter = true;
					} else {
						filtersObject.push({
							field: f,
							value: appliedFilters[f]
						});
					}
				}
			});
			// always fetch only active/enabled items, unless filter is already applied by the user
			if (!processedActiveFilter) {
				filtersObject.push({
					field: "is_active",
					value: true
				});
			}
			// date filter
			Object.keys(appliedDateFilter).forEach((f) => {
				if (appliedDateFilter[f].dateFilter) {
					filtersObject.push({
						field: f,
						value: appliedDateFilter[f].dateFilter
					});
				}
			});
			// sort
			if (sort.field !== "" && sort.order !== "") {
				variables.sort = sort;
			}
			// set filter
			variables.filters = filtersObject;
			const resp = await client.query({
				query: GET_DISCOUNTS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});
			store.dispatch({
				type: ActionTypes.GET_DISCOUNTS_LIST_SUCCESS,
				payload: { ...resp.data.platformDiscounts }
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.GET_DISCOUNTS_LIST_FAILURE,
				error
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	}
};

const parseDiscountData = (to = "form", data, selectedPlatform) => {
	let result = { ...data };
	switch (to) {
		case "form":
			if (result["platformName"]) {
				result.platformName = selectedPlatform;
			}
			if (result["zomatoDiscountType"]) {
				result.type = selectedPlatform.discountTypes.find((dt) => dt.value === result.zomatoDiscountType);
				delete result["zomatoDiscountType"];
			}
			if (result["uberEatsDiscountType"]) {
				result.type = selectedPlatform.discountTypes.find((dt) => dt.value === result.uberEatsDiscountType);
				delete result["uberEatsDiscountType"];
			}
			if (result["startsOn"]) {
				result["startsOn"] = result["startsOn"].split("T").join(" ");
			}
			if (result["expiresOn"]) {
				result["expiresOn"] = result["expiresOn"].split("T").join(" ");
			}
			break;
		case "server":
			if (result["type"]) {
				result["type"] = result["type"].value;
			}
			if (result["startsOn"]) {
				result["startsOn"] = result["startsOn"].split(" ").join("T");
			}
			if (result["expiresOn"]) {
				result["expiresOn"] = result["expiresOn"].split(" ").join("T");
			}
			if (result["timingsGroup"]) {
				result["timingsGroup"] = parseInt(result["timingsGroup"].id);
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchDiscountDetail = async (id, selectedPlatform, dispatch) => {
	if (selectedPlatform !== undefined) {
		dispatch({
			type: ActionTypes.GET_DISCOUNT_DETAIL_REQUEST
		});
		try {
			const variables = {
				id,
				entityType: selectedPlatform.value
			};
			const resp = await client.query({
				query: GET_DISCOUNT,
				variables,
				fetchPolicy: "no-cache"
			});
			dispatch({
				type: ActionTypes.GET_DISCOUNT_DETAIL_SUCCESS,
				payload: parseDiscountData("form", resp.data.platformDiscount, selectedPlatform)
			});
		} catch (error) {
			console.log(error);
			dispatch({
				type: ActionTypes.GET_DISCOUNT_DETAIL_FAILURE,
				error: {
					message: "There was an error while fetching Discount details"
				}
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	}
};

export const editDiscountDetail = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_DISCOUNT_DETAIL_REQUEST
	});
	try {
		const api = DISCOUNT_MUTATIONS[data.platformName.value.toLowerCase()];
		if (api) {
			const resp = await client.mutate({
				mutation: api.mutation,
				variables: parseDiscountData("server", data)
			});
			if (resp.data[api.field].status.success) {
				dispatch({
					type: ActionTypes.EDIT_DISCOUNT_DETAIL_SUCCESS
				});
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Discount saved!",
						timeout: 2000,
						error: false
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_DISCOUNTS_LIST,
					payload: {
						discount: resp.data[api.field].object,
						selectedPlatform: data.platformName
					}
				});
				return true;
			} else {
				// handle error message
				dispatch({
					type: ActionTypes.EDIT_DISCOUNT_DETAIL_FAILURE,
					error: parseErrorMessages(resp.data[api.field].status.messages)
				});
			}
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_DISCOUNT_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Discount details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreDiscount = async (data) => {
	try {
		const api = DISCOUNT_MUTATIONS[data.platformName.value.toLowerCase()];
		if (api) {
			const resp = await client.mutate({
				mutation: api.mutation,
				variables: parseDiscountData("server", data)
			});
			if (resp.data[api.field].status.success) {
				return true;
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data[api.field].status.messages),
						timeout: 5000,
						error: true
					}
				});
				return false;
			}
		}
	} catch (error) {
		throw error;
	}
};
