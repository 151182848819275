// utils
import { lS, isLoggedin, getLoggedInBizId } from "../atlas-utils";

// types
import { ActionTypes } from "../actions/_types";

export const MODAL_INIT_STATE = {
	isOpen: false,
	type: "",
	header: "",
	body: "",
	dismissedAt: "",
	dueDateDiff: null,
	milestone: "",
	variant: ""
};

const INITIAL_STATE = {
	lodaing: false,
	status: undefined,
	data: {},
	modal: isLoggedin()
		? lS.get("billing_alert")?.[getLoggedInBizId()]
			? lS.get("billing_alert")?.[getLoggedInBizId()]
			: MODAL_INIT_STATE
		: MODAL_INIT_STATE,
	error: {}
};

export const billingRecoveryState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_BILLING_RECOVERY_STATE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.UPDATE_BILLING_RECOVERY_MODAL_STATE:
			return {
				...state,
				modal: {
					...state.modal,
					...action.payload
				}
			};
		case ActionTypes.RESET_BILLING_RECOVERY_STATE:
			return {
				loading: false,
				status: undefined,
				data: {},
				modal: MODAL_INIT_STATE,
				error: {}
			};
		default:
			return state;
	}
};
