import React, { useRef } from "react";

// components
import { chartTooltip } from "./Tooltip";
import Loader from "./Loader";

// third party
import { ResponsiveBar } from "@nivo/bar";

// utils
import { commifyNumbers, getSuffixedNumber } from "../../atlas-utils";

// helpers
import { getReadableDateFilter } from "../../helpers/analytics";

// constants
import { ANALYTICS_DEFAULT_COLORS } from "../../client-config";

const Bar = ({
	height = 600,
	data = [],
	metric = "",
	keys = [],
	loading = false,
	indexBy,
	enableGridX = false,
	isInteractive = true,
	enableGridY = true, // lines
	showAxisBottom = true,
	showAxisLeft = true,
	marginTop = 25,
	marginBottom = 120,
	marginLeft = 70,
	marginRight = 25,
	groupMode = "stacked", // stacked, grouped, grouped-stacked
	layout = "vertical", // vertical, horizontal
	minValue = "auto",
	maxValue = "auto",
	valueFormat,
	padding = 0.3,
	innerPadding = 10,
	colors,
	showValueOnBar = false,
	customOuterLabel = false,
	animate = true,
	renderCustomOuterLabel,
	axisBottomLegend,
	axisBottomTickRotation = 0,
	axisLeftLegend,
	axisLeftTickValues,
	units = "",
	suffixedYValues = false,
	currencySymbol = "",
	currency = "",
	enableLegends = true,
	legendItemWidth = 150,
	customLegends = false,
	tooltipPosition = "sides", // top
	patternIds = [], // [{ match: { id: 'which bar' }, id: 'lines/dots' }]
	applDateFilter,
	placeholder = "No data available"
}) => {
	const tooltipRef = useRef(null);

	const customLegendSymbolShape = (props) => {
		const { x, y, fill, borderWidth, borderColor } = props;
		return (
			<rect
				x={x}
				y={y}
				rx={2}
				ry={2}
				fill={fill}
				strokeWidth={borderWidth}
				stroke={borderColor}
				width={15}
				height={15}
			/>
		);
	};

	const handleCustomOuterLabel = ({ bars }) =>
		bars.map((props, i) => {
			const { width, height, x, y } = props;
			// return (
			// 	<text
			// 		key={i}
			// 		transform={`translate(${(width ? x : 0) + width + 16}, ${y + height / 2})`}
			// 		textAnchor="left"
			// 		fontSize={14}
			// 		fontWeight={600}
			// 		dominantBaseline="central"
			// 	>
			// 		{renderCustomOuterLabel(props)}
			// 	</text>
			// );
			return renderCustomOuterLabel(props, i, data);
		});

	const handleMouseHover = (event, action, id, value, color, fill, indexValue) => {
		const xPos = event.pageX - 50;
		const yPos = event.pageY - 100;

		if (!tooltipRef.current) {
			return;
		}

		tooltipRef.current.style.transform = `translate(${xPos}px, ${yPos}px)`;
		tooltipRef.current.style.transition = `transform 200ms ease-out`;

		if (action === "move") {
			return;
		}

		tooltipRef.current.style.display = "flex";
		tooltipRef.current.style.visibility = "visible";
		tooltipRef.current.innerHTML = chartTooltip(
			{ id, value, color, fill, indexValue, units, currency },
			"bar",
			groupMode
		);
	};

	const handleMouseLeave = () => {
		if (tooltipRef.current) {
			tooltipRef.current.style.display = "none";
			tooltipRef.current.style.visibility = "hidden";
		}
	};

	return (
		<React.Fragment>
			<div
				className={"nivo-chart-bar " + groupMode + (data.length > 0 && loading ? " disabled" : "")}
				style={{ height: height }}
			>
				{data.length > 0 && (
					<ResponsiveBar
						data={data}
						keys={keys}
						indexBy={indexBy}
						margin={{
							top: marginTop,
							right: marginRight,
							bottom: enableLegends
								? axisBottomLegend
									? marginBottom + 20
									: marginBottom - 20
								: axisBottomLegend
									? customLegends
										? marginBottom - 40
										: marginBottom - 60
									: customLegends
										? marginBottom - 75
										: marginBottom - 95,
							left: axisLeftLegend ? marginLeft : marginLeft - 10
						}}
						layout={layout}
						enableGridX={enableGridX}
						enableGridY={enableGridY}
						isInteractive={isInteractive}
						groupMode={groupMode === "grouped-stacked" ? "grouped" : groupMode}
						minValue={minValue}
						maxValue={maxValue}
						valueFormat={valueFormat}
						padding={padding}
						innerPadding={["grouped", "grouped-stacked"].includes(groupMode) ? innerPadding : 0.1}
						colors={colors?.length ? colors : ANALYTICS_DEFAULT_COLORS}
						enableLabel={showValueOnBar}
						animate={animate}
						axisBottom={
							showAxisBottom
								? {
										tickSize: 5,
										tickPadding: 5,
										tickRotation: axisBottomTickRotation,
										legend: axisBottomLegend,
										legendOffset: customLegends ? 65 : 50,
										legendPosition: "middle"
									}
								: null
						}
						axisLeft={
							showAxisLeft
								? {
										tickSize: 5,
										tickPadding: 10,
										tickRotation: 0,
										legend: axisLeftLegend,
										legendOffset: -60,
										legendPosition: "middle",
										tickValues: axisLeftTickValues,
										format: (value) =>
											suffixedYValues || value >= 10000
												? getSuffixedNumber(value)
												: layout === "vertical" && units
													? `${value}${units}`
													: value
									}
								: null
						}
						tooltip={(props) => chartTooltip({ ...props, units, currencySymbol, metric }, "bar", groupMode)}
						legends={
							enableLegends
								? [
										{
											dataFrom: "keys",
											anchor: "bottom",
											direction: "row",
											justify: false,
											translateX: 0,
											translateY: axisBottomLegend ? 100 : 90,
											itemsSpacing: 10,
											itemDirection: "left-to-right",
											itemWidth: legendItemWidth,
											itemHeight: 20,
											itemOpacity: 1,
											symbolSize: 15,
											symbolShape: customLegendSymbolShape
										}
									]
								: []
						}
						barComponent={
							groupMode === "grouped-stacked"
								? (props) => (
										<CustomBar
											props={props}
											handleMouseHover={handleMouseHover}
											handleMouseLeave={handleMouseLeave}
											colors={colors}
										/>
									)
								: undefined
						}
						defs={[
							{
								id: "dots",
								type: "patternDots",
								background: "inherit",
								color: "#ffffff",
								size: 4,
								padding: 1,
								stagger: true
							},
							{
								id: "lines",
								type: "patternLines",
								background: "inherit",
								color: "#ffffff",
								rotation: -45,
								lineWidth: 3,
								spacing: 8
							}
						]}
						fill={patternIds}
						theme={{
							grid: {
								line: {
									// cartesian grid
									strokeWidth: 1,
									strokeDasharray: "4 4"
								}
							},
							axis: {
								domain: {
									// x and y axis lines
									line: {
										stroke: "#dddddd",
										strokeWidth: 1
									}
								},
								ticks: {
									// x and y axis ticks
									text: {
										fontSize: 14
									},
									line: {
										stroke: "#dddddd"
									}
								},
								legend: {
									// x and Y axis legends
									text: {
										fontSize: 12,
										fontWeight: "bold"
									}
								}
							},
							legends: {
								// legends info of bars
								text: {
									fontSize: 12
								}
							}
						}}
						layers={[
							"grid",
							"axes",
							"bars",
							"markers",
							"legends",
							"annotations",
							customOuterLabel ? handleCustomOuterLabel : ""
						]}
					/>
				)}
				{data.length === 0 && loading && <Loader />}
				{data.length === 0 && !loading && <div className="no-items-placeholder">{placeholder}</div>}
				<div className="custom-tooltip" ref={tooltipRef}></div>
			</div>
			{customLegends && data.length > 0 && (
				<CustomLegends
					data={data}
					groupMode={groupMode}
					keys={keys}
					applDateFilter={applDateFilter}
					marginLeft={marginLeft}
					colors={colors}
					legendItemWidth={legendItemWidth}
				/>
			)}
		</React.Fragment>
	);
};
export default Bar;

const CustomBar = ({ props, handleMouseHover, handleMouseLeave, colors }) => {
	const {
		bar: { width, height, x, y, data }
	} = props;
	const dataset = data?.data?.dataset || {};
	const selectedData = dataset?.[data?.id] || {};
	const heightScalePercentage = height / data?.value;
	let currCalculatedBarHeight = [];
	let lastCalculatedBarHeight = [];

	const getYCoordinate = (yCoordinate, barHeights = []) => {
		let y = yCoordinate;
		if (barHeights?.length === 0) {
			return y;
		}
		barHeights.forEach((ht) => {
			y = y - ht;
		});
		return `${y}`;
	};

	return (
		<g>
			{data?.fill && (
				<defs aria-hidden="true">
					<pattern
						id="lines"
						width="11.313708498984761"
						height="11.31370849898476"
						patternUnits="userSpaceOnUse"
					>
						<rect
							width="11.313708498984761"
							height="11.31370849898476"
							fill="inherit"
							stroke="rgba(255, 0, 0, 0.1)"
							strokeWidth="0"
						></rect>
						<path
							d="
						M -11.313708498984761 11.31370849898476 L 11.313708498984761 -11.31370849898476
						M -11.313708498984761 22.62741699796952 L 22.627416997969522 -11.31370849898476
						M 0 22.62741699796952 L 22.627416997969522 0
					"
							strokeWidth="3"
							stroke="#ffffff"
							strokeLinecap="square"
						></path>
					</pattern>
					{selectedData &&
						Object.keys(selectedData)?.length &&
						Object.keys(selectedData).map((key, i) => (
							<pattern
								key={i}
								id={`lines.bg.${colors?.length > 0 ? colors[i] : ANALYTICS_DEFAULT_COLORS[i]}`}
								width="11.313708498984761"
								height="11.31370849898476"
								patternUnits="userSpaceOnUse"
							>
								<rect
									width="11.313708498984761"
									height="11.31370849898476"
									fill={colors?.length > 0 ? colors[i] : ANALYTICS_DEFAULT_COLORS[i]}
									stroke="rgba(255, 0, 0, 0.1)"
									strokeWidth="0"
								></rect>
								<path
									d="M -11.313708498984761 11.31370849898476 L 11.313708498984761 -11.31370849898476
										M -11.313708498984761 22.62741699796952 L 22.627416997969522 -11.31370849898476
										M 0 22.62741699796952 L 22.627416997969522 0"
									strokeWidth="3"
									stroke="#ffffff"
									strokeLinecap="square"
								></path>
							</pattern>
						))}
				</defs>
			)}
			{selectedData &&
				Object.keys(selectedData)?.length &&
				Object.keys(selectedData).map((key, i) => {
					const barHeight = `${selectedData[key] * heightScalePercentage}`;
					const yCoordinate = y + height - barHeight;
					currCalculatedBarHeight = [...lastCalculatedBarHeight];
					lastCalculatedBarHeight.push(barHeight);
					return (
						<rect
							key={i}
							width={width}
							height={barHeight}
							fill={
								data.fill
									? colors?.length > 0
										? `url(#lines.bg.${colors[i]})`
										: `url(#lines.bg.${ANALYTICS_DEFAULT_COLORS[i]})`
									: colors?.length > 0
										? colors[i]
										: ANALYTICS_DEFAULT_COLORS[i]
							}
							x={x}
							y={getYCoordinate(yCoordinate, currCalculatedBarHeight)}
							onMouseEnter={(e) =>
								handleMouseHover(
									e,
									"enter",
									key,
									selectedData[key],
									colors?.length > 0 ? colors[i] : ANALYTICS_DEFAULT_COLORS[i],
									data.fill
										? colors?.length > 0
											? `url(#lines.bg.${colors[i]})`
											: `url(#lines.bg.${ANALYTICS_DEFAULT_COLORS[i]})`
										: null,
									data.indexValue
								)
							}
							onMouseMove={(e) =>
								handleMouseHover(
									e,
									"move",
									key,
									selectedData[key],
									colors?.length > 0 ? colors[i] : ANALYTICS_DEFAULT_COLORS[i],
									data.fill
										? colors?.length > 0
											? `url(#lines.bg.${colors[i]})`
											: `url(#lines.bg.${ANALYTICS_DEFAULT_COLORS[i]})`
										: null,
									data.indexValue
								)
							}
							onMouseLeave={handleMouseLeave}
						/>
					);
				})}
		</g>
	);
};

const CustomLegends = ({ data, groupMode, keys, applDateFilter, marginLeft, colors, legendItemWidth }) => {
	let legends = [];
	if (groupMode === "grouped-stacked") {
		legends = [
			...new Set(data.map((obj) => obj.dataset && Object.keys(obj.dataset[Object.keys(obj.dataset)[0]])).flat())
		];
	} else {
		legends = keys;
	}
	const currDF = getReadableDateFilter();
	const applDF = applDateFilter?.compare?.dateFilter ? getReadableDateFilter(true) : "";

	return (
		<div className="custom-legends-bar" style={{ marginLeft }}>
			<div className="row">
				{legends.length > 0 && applDateFilter?.compare?.dateFilter && (
					<div
						className="date-filter"
						style={{
							width: currDF === "Today" ? "58.78px" : currDF === "Yesterday" ? "124.75px" : "auto",
							textAlign: "right"
						}}
					>
						{currDF}
					</div>
				)}
				{legends?.length > 0 &&
					legends
						?.filter((legend) => (applDateFilter?.compare?.dateFilter ? !legend?.includes("*") : legend))
						?.map((legend, i) => (
							<svg
								className="legend-color"
								width={legendItemWidth}
								height={15}
								key={i}
								fill="transparent"
							>
								<g style={{ opacity: 1 }}>
									<rect
										rx={2}
										ry={2}
										fill={
											colors?.length > 0
												? colors?.[
														groupMode !== "grouped-stacked" &&
														applDateFilter?.compare?.dateFilter
															? i * 2
															: i
													]
												: ANALYTICS_DEFAULT_COLORS[i]
										}
										width={15}
										height={15}
									/>
									<text
										dx={22}
										dy={12}
										fill="#333333"
										style={{ fontSize: "12px", pointerEvents: "none", userSelect: "none" }}
									>
										{legend}
									</text>
								</g>
							</svg>
						))}
			</div>
			{legends.length > 0 && applDateFilter?.compare?.dateFilter && (
				<div className="row">
					<div className="date-filter">{applDF}</div>
					{legends
						?.filter((legend) => (groupMode !== "grouped-stacked" ? legend?.includes("*") : legend))
						?.map((legend, i) => (
							<svg
								className="legend-color"
								width={legendItemWidth}
								height={15}
								key={i}
								fill="transparent"
							>
								<defs aria-hidden="true">
									<pattern
										id={`legends.lines.bg.${
											colors?.length > 0
												? colors[groupMode !== "grouped-stacked" ? i * 2 + 1 : i]
												: ANALYTICS_DEFAULT_COLORS[
														groupMode !== "grouped-stacked" ? i * 2 + 1 : i
													]
										}`}
										width="4.242640687119286"
										height="4.242640687119285"
										patternUnits="userSpaceOnUse"
									>
										<rect
											width="4.242640687119286"
											height="4.242640687119285"
											fill={
												colors?.length > 0
													? colors[groupMode !== "grouped-stacked" ? i * 2 + 1 : i]
													: ANALYTICS_DEFAULT_COLORS[
															groupMode !== "grouped-stacked" ? i * 2 + 1 : i
														]
											}
											stroke="rgba(255, 0, 0, 0.1)"
											strokeWidth="0"
										></rect>
										<path
											d="M -4.242640687119286 4.242640687119285 L 4.242640687119286 -4.242640687119285
												M -4.242640687119286 8.48528137423857 L 8.485281374238571 -4.242640687119285
												M 0 8.48528137423857 L 8.485281374238571 0"
											strokeWidth="1"
											stroke="#ffffff"
											strokeLinecap="square"
										></path>
									</pattern>
								</defs>
								<g style={{ opacity: 1 }}>
									<rect
										rx={2}
										ry={2}
										fill={
											colors?.length > 0
												? `url(#legends.lines.bg.${
														colors[groupMode !== "grouped-stacked" ? i * 2 + 1 : i]
													})`
												: `url(#lines.bg.${
														ANALYTICS_DEFAULT_COLORS[
															groupMode !== "grouped-stacked" ? i * 2 + 1 : i
														]
													})`
										}
										width={15}
										height={15}
									/>
									<text
										dx={22}
										dy={12}
										fill="#333333"
										style={{ fontSize: "12px", pointerEvents: "none", userSelect: "none" }}
									>
										{legend.replace("*", "")}
									</text>
								</g>
							</svg>
						))}
				</div>
			)}
		</div>
	);
};
