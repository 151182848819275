import React from "react";
import { connect } from "react-redux";
import AutoRefreshPeriscope from "./AutoRefreshPeriscope";

const MainHeader = ({ classname = "", headerText = "" }) => {
	return (
		<div className={classname}>
			<div className="title-peri"> {headerText} </div>
			<AutoRefreshPeriscope />
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeDataResponse: state.periscopeDataResponse
});

export default connect(mapStateToProps)(MainHeader);
