import React, { Component, useRef, useState } from "react";

// components
import { Header } from "../components/CategoriesList/Header";
import { Table } from "../components/CategoriesList/Table";
import { Filters } from "../components/_commons/Filters";
import SidebarContainer from "../components/_commons/SidebarContainer";
import { DownArrow } from "../components/_commons/DownArrow";
import AssociatedItems from "../components/CategoryEdit/AssociatedItems";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// clients
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { debounce } from "lodash";
import { DndProvider, useDrag } from "react-dnd";

// utils
import { adjustNestedContainer } from "../atlas-utils";

// actions
import { fetchBrands, fetchParentCategoryIds } from "../actions/actions";
import { fetchCategoriesList } from "../actions/categories";
import { ActionTypes } from "../actions/_types";

// constant
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { CATEGORIES_ITEMS_LIST_HEADER_FIELDS, CATEGORY_TYPES, NESTED_ENTITY_TYPES } from "../client-config";
import Placeholder from "../components/_commons/Placeholder";
import history from "../history";
import CreateIcon from "../components/_commons/CreateIcon";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

@connect((store) => ({
	categoriesListState: store.categoriesListState,
	categoriesList: store.categoriesList,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access,
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))
export class CategoriesList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			storesLookup: {},
			selectedCategory: undefined,
			name: "",
			archivedFilter: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE,
			updatingId: undefined
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "categories_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.categoriesListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Categories list
		await fetchCategoriesList(undefined, this.props.brands.selectedBrand);

		//fetch Parent Categories
		await fetchParentCategoryIds();

		// fetch brands associated to the biz
		if (this.props.isMultibrandEnabled) {
			await fetchBrands("", true);
		}

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.search !== prevProps.location.search) {
			const reloadCl = queryString.parse(this.props.location.search).reloadCl;
			if (reloadCl && reloadCl === "true") {
				if (!this.props.categoriesList.loading) {
					fetchCategoriesList(undefined, this.props.brands.selectedBrand);
				}
				this.props.history.push(this.props.location.pathname);
			}
		}
	}

	switchCategory = (selectedCategory) => {
		store.dispatch({
			type: ActionTypes.UPDATE_CATEGORIES_LIST_STATE,
			payload: {
				selectedCategory
			}
		});
	};

	renderMainCategory = (category, hasAccess = false) => {
		if (category?.id !== this.props.categoriesList?.selectedCategory?.id) {
			return (
				<div className="text">
					{category.name}
					{this.state.updatingId === category.id ? (
						<div className="loader-container">
							<div className={"loader "}>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					) : null}
				</div>
			);
		}
		return (
			<div className="custom-category-sidebar-item">
				<div className="text">
					{category.name}
					{this.state.updatingId === category.id ? (
						<div className="loader-container">
							<div className={"loader "}>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					) : null}
				</div>
				{hasAccess && (
					<div>
						<Link to={`/categories/edit/${category.id}`}>
							<img className="action-item" src="/assets/icons/icon-edit-dark.svg" />
						</Link>
					</div>
				)}
			</div>
		);
	};

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatecategoriesListState = (payload) => {
		store.dispatch({
			type: ActionTypes.CATEGORIES_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatecategoriesListState({
			currentFilters: this.props.categoriesListState.appliedFilters
		});
	};

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatecategoriesListState({
			appliedFilters: {
				...this.props.categoriesListState.currentFilters
			}
		});

		// set tracking related info
		const eventName = "categories_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchCategoriesList(undefined, this.props.brands.selectedBrand);

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().categoriesListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: "mixpanel",
		// 	eventName,
		// 	eventMeta
		// });
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updatecategoriesListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchCategoriesList(undefined, this.props.brands.selectedBrand);
				this.setState({ archivedFilter: false });
			}
		);
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.categoriesListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatecategoriesListState({
			currentFilters
		});
	};

	setSearchFilter = (field, value) => {
		let currentFilters = {
			...this.props.categoriesListState.currentFilters
		};
		this.setState({
			[field]: value
		});
		currentFilters[field] = value;
		this.updatecategoriesListState({
			currentFilters
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => this.applyFilters(), 500);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "categories"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	handleBrand = (value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_BRAND,
			payload: value
		});
		fetchCategoriesList(undefined, value);
	};

	render() {
		const { categoriesList, categoriesListState, configItems, access, brands, isMultibrandEnabled } = this.props;
		const { currentFilters } = categoriesListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "name") {
				if (f === "is_active" && currentFilters[f]) {
					filterCount++;
				} else if (currentFilters[f].value && currentFilters[f].value !== "") {
					filterCount++;
				}
			}
		}

		let filterOptions = categoriesList.filters;
		let searchFilterOption = [];
		if (filterOptions) {
			searchFilterOption = filterOptions.find((f) => f.field === "name");
			filterOptions = filterOptions.filter((f) => f.field !== "name");
		}

		const placeholderContent = {
			placeholderText: "No categories created yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Create New</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/categories/new");
			},
			redirectionLink: "/piper-academy/categories",
			redirectionLinkText: "learn more about categories",
			size: "medium"
		};

		return (
			<div className="categories-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
				{configItems.dimensions.width > 768 && (
					<Filters
						isOpen={this.state.showFilters}
						close={this.filterSidebarCloseHandler}
						apply={this.applyFilters}
						clear={this.clearFilters}
						options={filterOptions}
						currentFilters={currentFilters}
						setFilter={this.setFilter}
					/>
				)}
				<Header
					filterCount={filterCount}
					flipShowFilters={this.flipShowFilters}
					filterActive={this.state.showFilters}
					dimensions={configItems.dimensions}
					filterOption={searchFilterOption}
					setFilter={this.setSearchFilter}
					handlePiperAcademy={this.handlePiperAcademy}
					value={this.state.name}
					isCatalogueManagement={access.isCatalogueManagement}
					brands={brands}
					handleBrand={this.handleBrand}
					isMultibrandEnabled={this.props.isMultibrandEnabled}
					isActive={!currentFilters?.is_active}
				/>
				{!categoriesList.loading && !categoriesList.data.length ? (
					<Placeholder {...placeholderContent} />
				) : (
					<SidebarContainer
						tabs={categoriesList.data || []}
						selectedTab={categoriesList.selectedCategory ? categoriesList.selectedCategory.id : undefined}
						switchTab={this.switchCategory}
						valueField="id"
						labelField="name"
						setUpdatingId={(id) => this.setState({ updatingId: id })}
						renderTab={(tab, ref, isDragging) =>
							this.renderMainCategory(tab, ref, isDragging, access.isCatalogueManagement)
						}
						entity={!categoriesList.loading ? "Categories" : ""}
						archived={this.state.archivedFilter ? "archived" : ""}
						dragEnabled={true}
						hasAccess={access.isCatalogueManagement}
					>
						{categoriesList.loading ? (
							<div className="P(10px)">
								<div className="shimmer H(60px) Mb(10px)" />
								<div className="shimmer H(60px) Mb(10px)" />
							</div>
						) : !categoriesList.selectedCategory ? (
							<div className="no-items-placeholder">No Sub-categories or Items found!</div>
						) : (
							<CategoryDetail
								selectedCategory={categoriesList.selectedCategory}
								currencySymbol={this.props.biz.currencySymbol}
								archived={this.state.archivedFilter ? "archived" : ""}
								nestedEntity={this.state.nestedEntity}
								nestedRef={this.nestedRef}
								handleNestedEntity={this.handleNestedEntity}
								hasAccess={access.isCatalogueManagement}
								applyFilters={this.applyFilters}
								isMultibrandEnabled={isMultibrandEnabled}
							/>
						)}
					</SidebarContainer>
				)}
			</div>
		);
	}
}

const CategoryDetail = ({
	selectedCategory,
	currencySymbol,
	archived,
	nestedEntity,
	nestedRef,
	handleNestedEntity,
	hasAccess = false,
	isMultibrandEnabled
}) => {
	const [openSubcat, setOpenSubcat] = useState(undefined);
	const [refresh, setRefresh] = useState(false);
	const closeNestedContainerAndRefreshValues = () => {
		handleNestedEntity(false);
		setRefresh((prevValue) => !prevValue);
	};

	const [itemsCount, setItemsCount] = useState(null);

	if (selectedCategory.subcategories.length > 0) {
		return (
			<React.Fragment>
				<div className="header-text-container">
					<div className="header-text">Subcategories & Items</div>
					{/* <div className='header-subtext'>subtext</div> */}
				</div>
				{selectedCategory.subcategories.map((subcat, i) => (
					<Subcategory
						key={i}
						currencySymbol={currencySymbol}
						archived={archived}
						openSubcat={openSubcat}
						onClick={(id) => setOpenSubcat(id)}
						nestedEntity={nestedEntity}
						nestedRef={nestedRef}
						handleNestedEntity={handleNestedEntity}
						hasAccess={hasAccess}
						closeNestedContainerAndRefreshValues={closeNestedContainerAndRefreshValues}
						refresh={refresh}
						isMultibrandEnabled={isMultibrandEnabled}
						{...subcat}
					/>
				))}
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<div className="header-text-container">
					<div className="header-text">All Items</div>
					<div className="header-subtext">
						{itemsCount === 0 ? 0 : itemsCount ? itemsCount : "--"} Item(s)
					</div>
				</div>
				<AssociatedItems
					categoryId={selectedCategory.id}
					showActions={false}
					categoryName={selectedCategory.name}
					headerFields={CATEGORIES_ITEMS_LIST_HEADER_FIELDS.filter((column) =>
						isMultibrandEnabled ? true : column.value !== "brands"
					)}
					classes="categories-list-table-container"
					RenderTable={Table}
					archived={archived}
					refresh={refresh}
					underRootCategory={true}
					emptyScreenType="actionable"
					openNestedEntity={(e, type, id) => handleNestedEntity(true, type, id)}
					setItemsCount={setItemsCount}
				/>
				<NestedEntityContainer
					show={nestedEntity.show}
					type={nestedEntity.type}
					id={nestedEntity.id}
					closeNestedContainer={closeNestedContainerAndRefreshValues}
					nestedRef={nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</React.Fragment>
		);
	}
};

const Subcategory = ({
	id,
	name,
	sortOrder,
	merchantRefId,
	onClick,
	openSubcat,
	archived,
	nestedEntity,
	nestedRef,
	handleNestedEntity,
	hasAccess = false,
	refresh,
	closeNestedContainerAndRefreshValues,
	isMultibrandEnabled
}) => {
	const showItems = openSubcat === id;
	const handleClick = () => {
		onClick(showItems ? undefined : id);
	};

	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: CATEGORY_TYPES.CATEGORYLIST,
			item: { id, name, sortOrder, merchantRefId, subcategoriesCount: -1 },
			collect: (monitor) => ({
				isDragging: monitor.isDragging()
			})
		}),
		[id, name, merchantRefId, sortOrder]
	);

	return (
		<div className="subcat-container">
			<div
				className="subcat-header"
				onClick={handleClick}
				style={{ cursor: isDragging ? "move" : "pointer" }}
				ref={drag}
			>
				<div className="subcat-name" onClick={(e) => e.stopPropagation()}>
					<Link to={`/categories/edit/${id}`} className={"link-text " + archived}>
						<span className="hyperlink hyperlink--black-color">{name}</span>
					</Link>
				</div>
				<div onClick={(e) => e.stopPropagation()} className="subcat-actions">
					{hasAccess && (
						<Link to={`/categories/edit/${id}`}>
							<img className="action-item" src="/assets/icons/icon-edit-dark.svg" />
						</Link>
					)}
					<span className="show-items" onClick={handleClick}>
						<DownArrow expanded={showItems} classes="subcat-down-arrow" />
					</span>
				</div>
			</div>
			{showItems && (
				<div className="subcat-items">
					<AssociatedItems
						categoryId={id}
						categoryName={name}
						showActions={false}
						headerFields={CATEGORIES_ITEMS_LIST_HEADER_FIELDS.filter((column) =>
							isMultibrandEnabled ? true : column.value !== "brands"
						)}
						classes="categories-list-table-container"
						refresh={refresh}
						RenderTable={Table}
						archived={archived}
						underRootCategory={false}
						emptyScreenType="actionable"
						openNestedEntity={(e, type, id) => handleNestedEntity(true, type, id)}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={closeNestedContainerAndRefreshValues}
						nestedRef={nestedRef}
						isNested={false}
						isForeignSource={true}
					/>
				</div>
			)}
		</div>
	);
};
