import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { SelectFilterCustom } from "../../../components/_commons/SelectFilterCustom";
import { connect } from "react-redux";
import { fetchBizPlatforms, fetchCities, fetchStores, fetchStoresDebounced } from "../../../actions/actions";
import { store } from "../../../store/configureStore";
import SpecificItemTableHeader from "../../../components/Periscope/ItemAvailability/SpecificItemTableHeader";
import { SelectFilter } from "../../../components/_commons/SelectFilter";
import { Filters } from "../../../components/_commons/Filters";
import SelectedItemTable from "./SelectedItemTable";
import { SearchFilter } from "../../../components/_commons/SearchFilter";
import {
	fetchDebouncedPersicopeEnabledCities,
	fetchDebouncedSpecificItemTableMetrics,
	fetchMenuCategoriesValues,
	fetchPeriscopeEnabledCities,
	fetchPeriscopeEnabledLocations,
	fetchSpecificItemTableMetrics
} from "../../../actions/periscope";
import { lS, scroll } from "../../../atlas-utils";
import { ActionTypes } from "../../../actions/_types";
import { periscopeFilterEvent } from "../../../helpers/periscope";

const SpecificItemTable = ({
	configItems,
	periscopeSpecificItemAvailabilityItemState,
	login,
	periscopeDataResponse,
	isMultibrandEnabled,
	periscopeFilters,
	periscopeCountryWiseSupportedPlatforms
}) => {
	const location = useLocation();
	const firstRender = useRef(true);
	const { platform, details, platformId } = location.state || {};
	const stores = configItems?.stores?.items;
	const currentFilters = periscopeSpecificItemAvailabilityItemState?.currentFilters;
	const appliedFilters = periscopeSpecificItemAvailabilityItemState?.appliedFilters;
	const menuCategoriesFilterValues = periscopeSpecificItemAvailabilityItemState?.menuCategoriesFilterValues;
	const currentFiltersCollection = periscopeSpecificItemAvailabilityItemState?.currentFiltersCollection;
	const appliedFiltersCollection = periscopeSpecificItemAvailabilityItemState?.appliedFiltersCollection;
	const sort = periscopeSpecificItemAvailabilityItemState?.sort;
	const appliedPlatformNames = appliedFilters?.platform_names;
	const country = (login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country).toLowerCase();
	const bizPlatformsLoading = configItems?.bizPlatforms?.isLoading;

	const cities = periscopeFilters?.cities;
	const periscopeLocationIds = periscopeFilters?.locations;

	const [filteredLocations, setFilteredLocations] = useState([]);

	useEffect(() => {
		if (!stores || !periscopeLocationIds) return;

		const locations = stores.filter((store) => {
			return periscopeLocationIds.includes(String(store?.id)) || store?.id === "all";
		});
		setFilteredLocations(locations);
	}, [stores, periscopeLocationIds]);

	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (periscopeCountryWiseSupportedPlatforms?.[country] ||
			lS.get("periscopeCountryWisePlatforms")?.[country])?.[platform.platformName.toLowerCase()]?.enabled;
	});

	const [optionUpdates, setOptionUpdates] = useState({
		platform_names: {}
	});

	const [isCheckedAll, setIsCheckedAll] = useState({ platform_names: false });
	const handleCheck = (state, field, option, prop) => {
		let _currentFilters = { ...currentFilters };

		const _optionUpdates = {
			...optionUpdates,
			[field]: {
				...optionUpdates[field],
				[option.id]: state
			}
		};
		let lowerCasePlatformName = option.platformName.toLowerCase();
		setOptionUpdates(_optionUpdates);
		const _isCheckedAllUpdates = { ...isCheckedAll };
		_isCheckedAllUpdates[field] = false;
		setIsCheckedAll(_isCheckedAllUpdates);

		if (state) {
			_currentFilters[field] = [String(lowerCasePlatformName), ..._currentFilters[field]];
			_currentFilters[field] = _currentFilters[field].filter((item) => item !== "all");
			_currentFilters[field] = [...new Set(_currentFilters[field])];
		} else {
			_currentFilters[field] = _currentFilters[field].filter((item) => item !== String(lowerCasePlatformName));
			if (_currentFilters[field].length === 0) {
				handleCheckAll(true, field);
				return;
			}
		}
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: { currentFilters: _currentFilters }
		});
	};

	const handleCheckAll = (state, field, options, props) => {
		let _currentFilters = { ...currentFilters };
		const _optionUpdates = { ...optionUpdates };
		const _isCheckedAllUpdates = { ...isCheckedAll };
		_isCheckedAllUpdates[field] = state;
		_currentFilters[field] = state ? ["all"] : [];
		_optionUpdates[field] = {};
		setOptionUpdates(_optionUpdates);
		setIsCheckedAll(_isCheckedAllUpdates);

		if (_currentFilters.platform_names && _currentFilters.platform_names.length == 0) {
			_currentFilters.platform_names[0] = "all";
		}
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: { currentFilters: _currentFilters }
		});
	};

	const handleSelectFilterState = (isOpen = false, field = null) => {
		if (!isOpen) {
			const currentPlatforms = currentFilters?.platform_names || [];
			const appliedPlatforms = appliedFilters?.platform_names || [];
			let currentFiltersChanged = false;
			if (currentPlatforms?.length !== appliedPlatforms?.length) {
				currentFiltersChanged = true;
			} else {
				currentPlatforms.forEach((platform, index) => {
					if (platform != appliedPlatforms[index]) {
						currentFiltersChanged = true;
						return;
					}
				});
			}
			if (currentFiltersChanged) {
				store.dispatch({
					type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
					payload: { appliedFilters: { ...appliedFilters, ...currentFilters }, offset: 0, sort: null }
				});
			}
		}
	};

	const [selectedCity, setSelectedCity] = useState({
		value: "all",
		valueForDisplay: "All Cities",
		__typename: "CityType"
	});
	const setInitialOffset = () => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: { offset: 0 }
		});
	};
	const handleCityFilter = (val) => {
		periscopeFilterEvent("city");
		setSelectedCity(val);
		setInitialOffset();
	};

	const [status, setStatus] = useState("all");
	const handleStatusFilter = (val) => {
		periscopeFilterEvent("status");
		if (val?.value) {
			setStatus(val.value);
		} else {
			setStatus(val);
		}
		setInitialOffset();
	};

	const [sortby, setSortby] = useState({ valueForDisplay: "most_offline", valueForDisplay: "Most Offline" });
	const handleSortByFilter = (val) => {
		setSortby(val);
		setInitialOffset();
	};

	const [showFilters, setShowFilters] = useState(false);

	const filterSidebarCloseHandler = () => {
		setShowFilters(false);
		let currentFiltersCollection = { ...appliedFiltersCollection };
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: { currentFiltersCollection }
		});
	};
	const applyFilters = () => {
		setInitialOffset();
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_ITEM_FILTERS_CHANGE,
			payload: currentFiltersCollection
		});
		setShowFilters(false);
	};

	const clearFilters = () => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: { offset: 0, limit: 10 }
		});

		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_ITEM_INITIAL_STATE
		});
		setStatus("all");
		setShowFilters(false);
		setSortby({ valueForDisplay: "most_offline", valueForDisplay: "Most Offline" });
	};

	const setFilterr = (field, value) => {
		let currentFiltersCollection = {
			...periscopeSpecificItemAvailabilityItemState.currentFiltersCollection,
			[field]: value
		};
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: { currentFiltersCollection }
		});
	};

	const [searchQuery, setSearchQuery] = useState("");
	const handleSearch = (field, value) => {
		setSearchQuery(value);
		setInitialOffset();
		textDebouncedSpecificItemTableMetrics(value);
	};

	const offset = periscopeSpecificItemAvailabilityItemState?.offset;
	const limit = periscopeSpecificItemAvailabilityItemState?.limit;

	const commonVariables = () => {
		const locationValue = appliedFiltersCollection?.location?.id || "all";
		const platformsNames =
			appliedPlatformNames?.[0] === "all" || !appliedPlatformNames?.length
				? defaultPlatforms
				: appliedPlatformNames;
		return {
			bizId: String(login?.loggedInbizDetail?.id),
			itemName: details?.item,
			brand: isMultibrandEnabled ? details?.brand_id : null,
			platforms: platformsNames,
			sortBy: sortby?.value,
			city: String(selectedCity?.value || "all"),
			status: status,
			location: String(locationValue) || "all",
			sort: sort,
			limit: limit
		};
	};
	const periscopeDataFetch = periscopeDataResponse?.periscopeDataFetch;
	const fetchData = (periscopeDataFetch = false) => {
		const variables = {
			...commonVariables(),
			offset: offset,
			search: searchQuery
		};
		variables?.platforms?.length && fetchSpecificItemTableMetrics(variables, periscopeDataFetch);
	};

	const textDebouncedSpecificItemTableMetrics = (text) => {
		const variables = {
			...commonVariables(),
			offset: 0,
			search: text
		};
		variables?.platforms?.length && fetchDebouncedSpecificItemTableMetrics(variables);
	};

	useEffect(() => {
		fetchData();
	}, [appliedPlatformNames, sortby, selectedCity, status, appliedFiltersCollection, limit, offset, sort]);

	useEffect(() => {
		if (periscopeDataFetch) {
			fetchData(periscopeDataFetch);
		}
	}, [periscopeDataFetch]);

	const menuCategories = useMemo(() => {
		return (menuCategoriesFilterValues || []).map((menu) => ({
			id: menu,
			name: menu
		}));
	}, [menuCategoriesFilterValues]);

	const handleStoresDebounced = (searchText) => {
		let includeAll = true;
		if (searchText) {
			includeAll = false;
		}
		fetchStoresDebounced(searchText, 50, null, includeAll);
	};

	const filterOptions = [
		{
			field: "location",
			valueForDisplay: "Location",
			values: filteredLocations,
			labelKey: "name",
			valueKey: "id",
			type: "SINGLE_STRICT",
			isAsync: true,
			handleAsyncSearch: handleStoresDebounced,
			isLoading: configItems?.stores?.isLoading
		},
		{
			field: "menuCategories",
			valueForDisplay: " Menu Category",
			values: menuCategories,
			labelKey: "name",
			valueKey: "id",
			type: "SINGLE_STRICT"
		}
	];

	const filterCount = useMemo(() => {
		const allKeys = Object.keys(appliedFiltersCollection || {});
		return allKeys.reduce((acc, key) => {
			if (appliedFiltersCollection?.[key]?.value || appliedFiltersCollection?.[key]?.id) {
				acc += 1;
			}
			return acc;
		}, 0);
	}, [appliedFiltersCollection]);

	const tableRef = useRef(null);

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		}
		fetchBizPlatforms(false, false, true);
		fetchStores("", 20, null, true);

		fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true);
		fetchPeriscopeEnabledLocations(String(login?.loggedInbizDetail?.id));

		const bizId = String(login?.loggedInbizDetail?.id);
		fetchMenuCategoriesValues({ bizId: bizId }, "item");
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 157, left: 0 });
		}
	}, []);

	const previousFilterLocationRef = useRef(null);
	useEffect(() => {
		const locationValue = periscopeSpecificItemAvailabilityItemState?.appliedFiltersCollection?.location?.id;
		if (
			!firstRender.current &&
			previousFilterLocationRef.current !== null &&
			previousFilterLocationRef.current !== locationValue
		) {
			periscopeFilterEvent("location");
		}
		previousFilterLocationRef.current = locationValue;
	}, [periscopeSpecificItemAvailabilityItemState?.appliedFiltersCollection?.location?.id]);

	const previousFilterPlatformRef = useRef(null);
	useEffect(() => {
		const platformValue = periscopeSpecificItemAvailabilityItemState?.appliedFilters?.platform_names.join(",");
		if (
			!firstRender.current &&
			previousFilterPlatformRef.current !== null &&
			previousFilterPlatformRef.current !== platformValue
		) {
			periscopeFilterEvent("platform");
		}
		previousFilterPlatformRef.current = platformValue;
	}, [periscopeSpecificItemAvailabilityItemState?.appliedFilters?.platform_names.join(",")]);

	const defaultPlatforms = useMemo(() => {
		let allPlatforms = bizPlatforms.map((platform) => {
			return platform.platformName.toLowerCase();
		});
		if (allPlatforms[0] == "all platforms") {
			allPlatforms.shift();
		}
		return allPlatforms;
	}, [bizPlatforms]);

	const [initialCall, setInitialCall] = useState(true);
	useEffect(() => {
		if (initialCall) {
			let data;
			if (platformId) {
				data = { [platformId]: true };
				setIsCheckedAll({
					platform_names: false
				});
				setInitialCall(false);
			}
			setOptionUpdates({
				platform_names: data
			});
		}
	}, [currentFilters?.platform_names, bizPlatforms]);

	const displayStatus = {
		offline: "Offline",
		online: "Online",
		partial_offline: "Partially Offline"
	};

	useEffect(() => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: {
				currentFilters: { platform_names: [platform] },
				appliedFilters: { platform_names: [platform] }
			}
		});
	}, []);

	const handleCitySearch = (text) => {
		let includeAll = true;
		if (text) {
			includeAll = false;
		}
		fetchDebouncedPersicopeEnabledCities(String(login?.loggedInbizDetail?.id), includeAll, text);
	};

	const handleFilterState = (isOpen, field) => {
		let filters = {
			cities: () => {
				fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true);
			}
		};
		if (isOpen) {
			const filterAction = filters[field];
			filterAction();
		}
	};
	return (
		<div className="periscope-container">
			<div className="specific-table-div" ref={tableRef}>
				<div className="specific-table-container">
					<SpecificItemTableHeader
						refreshBtn={fetchData}
						item={details?.item}
						brand={details?.brand}
						dataTestId="SpecificItemTableHeader"
					/>
					<div className="specific-item-filters-div">
						<div className="specific-item-left-filters">
							<div className="specificitem-platform-filter" style={{ width: "200px" }}>
								<SelectFilterCustom
									options={bizPlatforms?.filter((plf) => plf.id !== "all") || []}
									field={"platform_names"}
									labelKey={"platformName"}
									valueKey={"id"}
									iconKey={"image"}
									multi={true}
									showCheckBox={true}
									requiredLabel={true}
									showSelectAllOption
									showIcon={true}
									ellipsizedLength={40}
									isLoading={bizPlatformsLoading}
									optionUpdates={optionUpdates}
									handleCheck={handleCheck}
									isCheckedAll={isCheckedAll}
									handleCheckAll={handleCheckAll}
									placeholder={"Select platforms"}
									selectAllPlaceholder="All Platforms"
									countDisplayText={"platform"}
									handleSelectFilterState={handleSelectFilterState}
								/>
							</div>
							<div className="city-filter" style={{ width: "180px" }}>
								<SelectFilterCustom
									options={cities}
									field="cities"
									currValue={selectedCity || cities[0]}
									setFilter={(f, value) => {
										handleCityFilter(value);
									}}
									labelKey={"valueForDisplay"}
									valueKey={"value"}
									isSearchable={true}
									placeholder="All Cities"
									handleSearch={handleCitySearch}
									handleSelectFilterState={handleFilterState}
								/>
							</div>
							<div data-testid="status-id" style={{ width: "185px" }}>
								<SelectFilter
									options={[
										{ value: "all", valueForDisplay: "All" },
										{ value: "offline", valueForDisplay: "Offline" },
										{ value: "partial_offline", valueForDisplay: "Partially Offline" },
										{ value: "online", valueForDisplay: "Online" }
									]}
									field="status"
									currValue={{
										value: status,
										valueForDisplay: (
											<span>
												<span className="status-heading" style={{ color: "#8A8A8A" }}>
													Status
												</span>
												<span className="item-availability-status">
													{displayStatus[status] || "All"}
												</span>
											</span>
										)
									}}
									labelKey="valueForDisplay"
									valueKey="value"
									setFilter={(f, v) => handleStatusFilter(v)}
									isSearchable={false}
									isClearable={false}
								/>
							</div>

							<div style={{ width: "185px" }}>
								<SelectFilter
									options={[
										{ value: "most_offline", valueForDisplay: "Most Offline" },
										{ value: "A-Z", valueForDisplay: "A-Z" },
										{ value: "Z-A", valueForDisplay: "Z-A" },
										{ value: "lowest_rated", valueForDisplay: "Lowest Rated" },
										{ value: "highest_rated", valueForDisplay: "Highest Rated" }
									]}
									field="sortby"
									currValue={{
										value: sortby,
										valueForDisplay: (
											<span>
												<span className="status-heading" style={{ color: "#8A8A8A" }}>
													Sort by
												</span>
												<span className="item-availability-status">
													{sortby.valueForDisplay}
												</span>
											</span>
										)
									}}
									labelKey="valueForDisplay"
									valueKey="value"
									setFilter={(f, v) => handleSortByFilter(v)}
									isSearchable={false}
									isClearable={false}
								/>
							</div>
							<div
								style={{ width: "90px" }}
								className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}
							>
								{configItems?.dimensions?.width > 768 && (
									<Filters
										isOpen={showFilters}
										close={filterSidebarCloseHandler}
										options={filterOptions}
										apply={applyFilters}
										clear={clearFilters}
										currentFilters={currentFiltersCollection}
										setFilter={(f, value) => setFilterr(f, value)}
									/>
								)}
								<div className="container" onClick={() => setShowFilters((prev) => !prev)}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
									<div className="filter-title">
										Filter
										{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
									</div>
								</div>
							</div>
						</div>

						<div className="specificitem-right-filters">
							<SearchFilter
								placeholder="Search"
								filterOption={{
									field: "name"
								}}
								setFilter={handleSearch}
								value={searchQuery}
							/>
						</div>
					</div>
					<div className="specific-item-table">
						<SelectedItemTable tableRef={tableRef} defaultPlatforms={defaultPlatforms} />
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	periscopeSpecificItemAvailabilityItemState: state.periscopeSpecificItemAvailabilityItemState,
	periscopeSpecificItemAvailabilityItemData: state.periscopeSpecificItemAvailabilityItemData,
	login: state.login,
	periscopeDataResponse: state.periscopeDataResponse,
	isMultibrandEnabled: state.login.loggedInbizDetail.isMultibrandEnabled,
	periscopeCountryWiseSupportedPlatforms: state.periscopeCountryWiseSupportedPlatforms,
	periscopeFilters: state.periscopeFilters
});

export default connect(mapStateToProps)(SpecificItemTable);
