import React, { useEffect, useState, useRef, useImperativeHandle } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Accordion } from "../components/_commons/Accordion";
import { Loading } from "../components/_commons/Loading";
import { Topbar } from "../components/_commons/Topbar";

// client
import { store } from "../store/configureStore";

// utils
import history from "../history";
import { scroll, trackEvent } from "../atlas-utils";

// third party
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

// constant
import { syllabus } from "../piperAcademy";

const PiperAcademyContainer = ({
	match,
	piperAcademyState,
	userEmail = "",
	isNested = false,
	isForeignSource = false,
	showCourses = false,
	allCourses = true,
	closeNestedContainer,
	connectedRef,
	showTopBar = false,
	tabs = [],
	defaultSelectedTab = 0
}) => {
	const [isFormOpen, setFormState] = useState(false);
	const [formTab, setFormTab] = useState(tabs.length > 0 ? tabs?.[defaultSelectedTab]?.value : null);
	const [expandedCourses, setExpandedCourses] = useState({});
	const { location = "", currCourse, currLesson, start = "" } = piperAcademyState;
	const query = new URLSearchParams(useLocation().search);
	const topRef = useRef();

	useEffect(() => {
		if ((isNested || isForeignSource) && location) {
			setTimeout(() => setFormState(true), 60);
			store.dispatch({
				type: "UPDATE_PIPER_ACADEMY_STATE",
				payload: {
					currCourse: syllabus.courses.find((course) => course.paths.includes(location)),
					currLesson: syllabus.lessons.find((lesson) => lesson.path === location)
				}
			});
			setExpandedCourses({
				...expandedCourses,
				[syllabus.courses.find((course) => course.paths.includes(location)).code]: true
			});
		} else if (match.params.id) {
			const lesson = syllabus.lessons.find((lesson) => lesson.id === match.params.id);
			if (lesson) {
				store.dispatch({
					type: "UPDATE_PIPER_ACADEMY_STATE",
					payload: {
						currCourse: syllabus.courses.find(
							(course) => course.paths.includes(lesson?.path) && course.code === lesson.courses[0]
						),
						currLesson: lesson,
						start: ""
					}
				});
				setExpandedCourses({
					...expandedCourses,
					[syllabus.courses.find(
						(course) => course.paths.includes(lesson?.path) && course.code === lesson.courses[0]
					)?.code]: true
				});
			} else {
				history.push("/piper-academy");
			}
		} else if (allCourses && !currCourse && !currLesson) {
			store.dispatch({
				type: "UPDATE_PIPER_ACADEMY_STATE",
				payload: {
					currCourse: syllabus.courses[0],
					currLesson: syllabus.lessons.find((lesson) => lesson.courses.includes(syllabus.courses[0].code)),
					start: ""
				}
			});
			setExpandedCourses({
				...expandedCourses,
				[syllabus.courses[0].code]: true
			});
		} else if (currCourse && currLesson) {
			setExpandedCourses({
				...expandedCourses,
				[currCourse.code]: true
			});
		}
	}, [allCourses]);

	useEffect(() => {
		if (currCourse?.title && currLesson?.title) {
			trackEvent("get_started_with_atlas", {
				course: currCourse?.title,
				lesson: currLesson?.title
			});
		}
	}, [currCourse, currLesson]);

	const handleExpand = (code, state = false) => {
		let courses = Object.keys(expandedCourses).map((course) => ({
			[course]: false
		}));
		setExpandedCourses({
			...courses,
			[code]: state
		});
	};

	const handleLesson = (course, lesson) => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				currCourse: course,
				currLesson: lesson,
				start: ""
			}
		});
		if (!location) {
			history.push(`/piper-academy/${lesson.id}`);
		}
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleCancel = () => {
		setFormState(false);
		setTimeout(() => {
			if (isNested || isForeignSource) {
				closeNestedContainer();
			}
		}, 100);
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleTimestamp = (url = "", timestamp = [], lessonId = "") => {
		// extract shareId from lesson url
		const regex = /share\/([a-zA-Z0-9-]+)\/embed/g;
		const match = regex.exec(url);
		const shareId = match?.[1];

		// convert timestamp to seconds
		let time = timestamp.length > 1 ? Number(timestamp?.[0]) * 60 + Number(timestamp?.[1]) : "";

		const iframe = document.querySelector(".trainn-iframe");
		if (iframe && shareId && time) {
			iframe.contentWindow.postMessage(
				{
					shareId,
					event: "trainn.seek",
					time
				},
				"*"
			);
			// scroll to the top
			if (topRef?.current) {
				scroll({ top: topRef?.current?.offset - 57, left: 0 });
			}
		}
		if (!location) {
			const path = `/piper-academy/${lessonId}` + (timestamp.length ? `?start=${timestamp.join(":")}` : "");
			history.push(path);
		}
	};

	const switchTab = (tab) => {
		setFormTab(tab.value);
	};

	return (
		<div className="piper-academy">
			{allCourses && !isNested && !isForeignSource ? (
				<PiperAcademy
					topRef={topRef}
					showInDrawer={isNested || isForeignSource}
					currCourse={currCourse}
					currLesson={currLesson}
					start={start}
					syllabus={syllabus}
					expandedCourses={expandedCourses}
					userEmail={userEmail}
					handleExpand={handleExpand}
					handleLesson={handleLesson}
					handleTimestamp={handleTimestamp}
					query={query}
				/>
			) : (
				<FormSidebar
					isOpen={isFormOpen}
					close={handleCancel}
					isNested={isNested}
					hideHeader={!showTopBar}
					headerLeft={
						<React.Fragment>
							<img className="help-icon" src="/assets/header-icons/icon-help.svg" alt="" />
							<div className="settings-header no-border">
								<div className="header-text">
									{currLesson?.newVersion
										? currLesson?.newVersion?.title || "START HERE"
										: currLesson?.title || "START HERE"}
								</div>
								<div className="subheader-text">{currCourse?.title || "with Piper Academy"}</div>
							</div>
						</React.Fragment>
					}
					hideActions={true}
				>
					{showTopBar && (
						<Topbar tabs={tabs} selectedTab={formTab} switchTab={switchTab} isStickyOnTop={true} />
					)}
					{!showTopBar && (
						<PiperAcademy
							topRef={topRef}
							showInDrawer={isNested || isForeignSource}
							showCourses={showCourses}
							currCourse={currCourse}
							currLesson={currLesson}
							start={start}
							syllabus={syllabus}
							location={location}
							expandedCourses={expandedCourses}
							userEmail={userEmail}
							handleExpand={handleExpand}
							handleLesson={handleLesson}
							handleTimestamp={handleTimestamp}
							query={query}
						/>
					)}
					{tabs.length > 0 &&
						tabs.map((tab) =>
							formTab === tab.value ? (
								tab.render === "default" ? (
									<PiperAcademy
										topRef={topRef}
										hideHeader={true}
										showInDrawer={isNested || isForeignSource}
										showCourses={showCourses}
										currCourse={currCourse}
										currLesson={currLesson}
										start={start}
										syllabus={syllabus}
										location={location}
										expandedCourses={expandedCourses}
										userEmail={userEmail}
										handleExpand={handleExpand}
										handleLesson={handleLesson}
										handleTimestamp={handleTimestamp}
										query={query}
									/>
								) : (
									tab.render()
								)
							) : null
						)}
				</FormSidebar>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	piperAcademyState: store.piperAcademyState,
	userEmail: store.login.loginDetail.email
});
export default connect(mapStateToProps)(PiperAcademyContainer);

const PiperAcademy = ({
	topRef,
	hideHeader = false,
	showInDrawer = false,
	showCourses = false,
	currCourse = {},
	currLesson = {},
	start = "",
	syllabus = {},
	location = "",
	expandedCourses = {},
	userEmail = "",
	handleExpand,
	handleLesson,
	handleTimestamp,
	query
}) => {
	return (
		<div className={!showInDrawer ? "section-container-common" : "drawer-container"} ref={topRef}>
			{!hideHeader && (
				<div className="header-container">
					{showInDrawer ? (
						<React.Fragment>
							<img className="help-icon" src="/assets/header-icons/icon-help.svg" alt="" />
							<div className="settings-header no-border">
								<div className="header-text">
									{currLesson?.newVersion
										? currLesson?.newVersion?.title || "START HERE"
										: currLesson?.title || "START HERE"}
								</div>
								<div className="subheader-text">{currCourse.title || "with Piper Academy"}</div>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<img className="piper-academy-icon" src="/assets/icons/icon-piper-academy.svg" alt="" />
							<div className="settings-header no-border">
								<div className="header-text">
									GET <span>STARTED</span>
								</div>
								<div className="subheader-text">with Atlas</div>
							</div>
						</React.Fragment>
					)}
				</div>
			)}
			<div className="iframe-container">
				<div className="video-container">
					<div className="video-player">
						<iframe
							src={
								currLesson
									? currLesson?.newVersion
										? `${currLesson?.newVersion?.url}${
												userEmail && !userEmail.includes("urbanpiper.com")
													? `?ee=${userEmail}`
													: ""
											}`
										: `${currLesson.url}${
												userEmail && !userEmail.includes("urbanpiper.com")
													? `?ee=${userEmail}`
													: ""
											}`
									: "https://urbanpiper.portal.trainn.co/share/fea766fc-d6c8-4f4a-b0f7-03a71f5152cc/embed"
							}
							title="Piper Academy"
							className="trainn-iframe"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
							onLoad={() => {
								setTimeout(() => {
									if (!currLesson.isExternalUrl) {
										handleTimestamp(
											currLesson?.newVersion ? currLesson?.newVersion?.url : currLesson?.url,
											start ? start?.split(":") : query.get("start")?.split(":"),
											currLesson.id
										);
									}
								}, 1200);
							}}
						></iframe>
						<Loading />
					</div>
					{currCourse && currLesson && (
						<div className="video-info">
							{!showInDrawer && (
								<div className="title">
									<span>
										{currLesson?.newVersion ? currLesson?.newVersion?.title : currLesson?.title}
									</span>
								</div>
							)}
							{/* <div className="description">{currLesson.description}</div> */}
							{Object.keys(
								currLesson?.newVersion
									? (currLesson?.newVersion?.chapters ?? {})
									: (currLesson?.chapters ?? {})
							).length > 0 && (
								<div className="title">
									<span>Contents:</span>
								</div>
							)}
							<div className="chapters">
								{(currLesson?.newVersion ? currLesson?.newVersion?.chapters : currLesson?.chapters) &&
									Object.keys(
										currLesson?.newVersion ? currLesson?.newVersion?.chapters : currLesson?.chapters
									).map((chap, i) => (
										<div className="chapter-info" key={i}>
											<div
												className="timestamp"
												onClick={() =>
													handleTimestamp(
														currLesson?.newVersion
															? currLesson?.newVersion?.url
															: currLesson?.url,
														chap.split(":"),
														currLesson.id
													)
												}
											>
												{chap}
											</div>
											<div className="name">
												{currLesson?.newVersion
													? currLesson?.newVersion?.chapters[chap]
													: currLesson?.chapters[chap]}
											</div>
										</div>
									))}
							</div>
						</div>
					)}
				</div>
				{(!showInDrawer || showCourses) && (
					<div className="syllabus">
						{syllabus.courses
							.filter((course) => (location ? course.paths.includes(location) : course))
							.map((course, i) => (
								<Accordion
									key={i}
									title={course.title}
									id={course.code}
									isExpanded={expandedCourses[course.code]}
									handleExpand={handleExpand}
								>
									{syllabus.lessons
										.filter((lesson) =>
											location
												? lesson.path === location && lesson.courses.includes(course.code)
												: lesson.courses.includes(course.code)
										)
										.map((lesson, j) => {
											if (lesson?.newVersion) {
												return (
													<div
														key={j}
														className={
															"lesson-container" +
															(currCourse?.code === course.code &&
															currLesson?.newVersion?.title === lesson?.newVersion?.title
																? " selected"
																: "")
														}
														onClick={() => handleLesson(course, lesson)}
													>
														<img
															src={`/assets/icons/icon-play${
																currCourse?.code === course.code &&
																currLesson?.newVersion?.title ===
																	lesson?.newVersion?.title
																	? "-selected"
																	: ""
															}.svg`}
														/>
														<div className="title">{lesson?.newVersion?.title}</div>
													</div>
												);
											} else {
												return (
													<div
														key={j}
														className={
															"lesson-container" +
															(currCourse?.code === course.code &&
															currLesson?.title === lesson.title
																? " selected"
																: "")
														}
														onClick={() => handleLesson(course, lesson)}
													>
														<img
															src={`/assets/icons/icon-play${
																currCourse?.code === course.code &&
																currLesson?.title === lesson.title
																	? "-selected"
																	: ""
															}.svg`}
														/>
														<div className="title">{lesson.title}</div>
													</div>
												);
											}
										})}
								</Accordion>
							))}
					</div>
				)}
			</div>
		</div>
	);
};
