// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_CHARGES_LIST, GET_CHARGE, EDIT_CHARGE } from "../graphql/charges";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// constants
import { APPLICABLE_ON_OPTIONS } from "../containers/ChargeCreate";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchChargesList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().chargesListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CHARGES_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (f === "applicable_on" && appliedFilters[f] !== null) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_active") {
					// this value is being reversed because the behaviour of
					// is_active filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: !appliedFilters[f]
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_active",
				value: true
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_CHARGES_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_CHARGES_LIST_SUCCESS,
			payload: { ...resp.data.charges }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CHARGES_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseChargeData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			const applicableOnObject = APPLICABLE_ON_OPTIONS.find((obj) => obj.label === result["applicableOn"]);
			const fieldTypeObject = applicableOnObject
				? applicableOnObject?.types?.find((obj) => obj.value === result["fieldType"].toUpperCase())
				: null;
			if (applicableOnObject) {
				result["applicableOn"] = applicableOnObject;
			}
			if (fieldTypeObject) {
				result["fieldType"] = fieldTypeObject;
			}
			if (result["platforms"]) {
				result["excludedPlatforms"] = result.platforms.filter((plf) => plf.isChargeAssociated === false);
			}
			break;
		case "server":
			if (result["applicableOn"]) {
				result["applicableOn"] = result["applicableOn"].value;
			}
			if (result["fieldType"]) {
				result["fieldType"] = result["fieldType"].value;
			}
			if (result["excludedPlatforms"]) {
				result["excludedPlatforms"] = result["excludedPlatforms"].map((plf) => plf.platformName);
			}
			if (result["fulfillmentModes"]) {
				result["fulfillmentModes"] = result["fulfillmentModes"].map((fm) => fm.id);
			}
			if (result["merchant_ref_id"] === "") {
				result["merchant_ref_id"] = "-1";
			}
			if (result["value"] === null || result["value"] === "") {
				result["value"] = 0;
			}
			if (result["rangeValues"] && result["rangeValues"].length) {
				result["rangeValues"] = result["rangeValues"].map((range) => {
					if (range.min === "") {
						range.min = null;
					}
					if (range.max === "") {
						range.max = null;
					}
					if (range.value === "") {
						range.value = null;
					}
					return range;
				});
			}
		default:
			break;
	}
	return result;
};

export const fetchChargeDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_CHARGE_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_CHARGE,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_CHARGE_DETAIL_SUCCESS,
			payload: parseChargeData(resp.data.charge, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_CHARGE_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Charge details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editCharge = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_CHARGE_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_CHARGE,
			variables: parseChargeData(data, "server")
		});
		if (resp.data.saveCharge.status.success) {
			dispatch({
				type: ActionTypes.EDIT_CHARGE_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Charge saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_CHARGES_LIST,
				payload: resp.data.saveCharge.object
			});
			return true;
		} else {
			// handle error message
			if (resp.data.saveCharge.status.messages.length && resp.data.saveCharge.status.messages[0].field === null) {
				dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.saveCharge.status.messages[0].message,
						timeout: 3000,
						error: true
					}
				});
			}
			dispatch({
				type: ActionTypes.EDIT_CHARGE_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveCharge.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_CHARGE_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Charge details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreCharge = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_CHARGE,
			variables: parseChargeData(data, "server")
		});
		if (resp.data.saveCharge.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveCharge.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};
