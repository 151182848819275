import React, { useEffect, useRef, useState } from "react";

// third party
import { connect } from "react-redux";

// graphql
import { GET_MENUS_LIST_MINI, GET_MENU_ITEMS, GET_PARENT_SECTIONS_LIST, UPDATE_MENU } from "../../graphql/menus";

// client
import { clientMenu } from "../../client-menu";

// component
import { FormSidebar } from "../_commons/FormSidebar";
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";
import Image from "../_commons/Image";
import EntityAccordion from "../_commons/EntityAccordion";
import FoodTypeIndicator from "../_commons/FoodTypeIndicator";
import FormTable from "../_commons/FormTable";
import { RadioButton } from "../_commons/RadioButton";
import { CheckBox } from "../_commons/CheckBox";
import { Paginator } from "../_commons/Paginator";

// services
import NotificationServices from "../../services/NotificationService";

// utils
import { removeProp, trackEvent } from "../../atlas-utils";

// actions
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";
import { TRACKING_EVENT_NAMES } from "../../client-config";

// constant
const FOODTYPE_SELECTOR_OPTIONS = [
	{
		value: "veg",
		valueForDisplay: "Vegetarian"
	},
	{
		value: "non-veg",
		valueForDisplay: "Non Vegetarian"
	},
	{
		value: "egg",
		valueForDisplay: "Eggetarian"
	},
	{
		value: "all",
		valueForDisplay: "Any Food type"
	}
];

const CopyMenu = ({
	isOpen,
	hideCopyMenuSection,
	menuId,
	brandId,
	menuDetailsData,
	setCopyMenuAvailableMenusCount = () => {},
	copyItemsToCategoryId = null
}) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [menusListData, setMenusListData] = useState([[]]);
	const [loadingStates, setLoadingStates] = useState({
		initialLoad: true,
		loading: true
	});
	const [selectedMenu, setSelectedMenu] = useState("");
	const [selectedMenuSectionsData, setSelectedMenuSectionsData] = useState([]);
	const [selectedEntities, setSelectedEntities] = useState({});
	const [offset, setOffset] = useState(0);
	const [objectsCount, setObjectsCount] = useState(0);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [entitySearchKeyword, setEntitySearchKeyword] = useState("");
	const [selectedFoodType, setSelectedFoodType] = useState(null);
	const [expandedAccordions, setExpandedAccordions] = useState({});
	const debouncedSearchRef = useRef();
	const debouncedEntitySearchRef = useRef();

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
	};
	// Utility: Ensure uniqueness using Set
	const ensureUnique = (arr) =>
		Array.from(new Set(arr.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

	const ensureImageField = (overrides) => ({
		...overrides,
		image: overrides?.image || ""
	});

	const handleMenuCopyImport = async () => {
		// Process selected menu sections
		const sanitizedMenuSections = removeProp(selectedMenuSectionsData, "__typename");
		try {
			// Fetch menu items from the selected menu
			const menuItemsResp = await clientMenu.mutate({
				mutation: GET_MENU_ITEMS,
				variables: { id: selectedMenu }
			});

			if (!menuItemsResp?.data?.menuV2) {
				throw new Error("Failed to fetch menu details");
			}

			let selectedMenuDetails;
			if (menuItemsResp?.data?.menuV2) {
				selectedMenuDetails = {
					...removeProp(menuItemsResp?.data?.menuV2, "__typename")
				};
			}

			// Initialize menu update variables
			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData?.name,
					description: menuDetailsData?.description,
					brand: menuDetailsData?.brand,
					options: ensureUnique([
						...(menuDetailsData.options || []),
						...selectedMenuDetails.options.map((option) => ({
							...option,
							overrides: {
								...option.overrides,
								image: ""
							}
						}))
					]),
					optionGroups: ensureUnique([
						...(menuDetailsData.optionGroups || []),
						...selectedMenuDetails.optionGroups
					]),
					items: ensureUnique([
						...(menuDetailsData.items || []),
						...selectedMenuDetails.items
							.filter((item) => selectedEntities[item?.id])
							.map((item) => ({
								...item,
								overrides: { ...item.overrides, image: "" }
							}))
					]),
					categories: ensureUnique([...(menuDetailsData.categories || [])])
				}
			};

			const selectedItems = [];
			const selectedOptionGroups = [];
			const selectedOptions = [];

			sanitizedMenuSections.forEach((category) => {
				const filteredItems = category.items.filter((item) => selectedEntities[item?.id]);

				const formattedItems = filteredItems.map((item) => {
					const menuItem = menuItemsResp.data.menuV2.items.find((menuItem) => menuItem.id === item.id);
					return {
						id: String(item.id),
						optionGroups: menuItem?.optionGroups || item?.optionGroups || [],
						overrides: {
							name: menuItem?.overrides?.name || item.name || "",
							description: menuItem?.overrides?.description || "",
							price: menuItem?.overrides?.price || item.price || 0,
							imageUrl: menuItem?.overrides?.imageUrl || item.imageUrl || "",
							isRecommended: menuItem?.overrides?.isRecommended || false,
							image: ""
						}
					};
				});

				selectedItems.push(...formattedItems);

				// Collect related option groups
				formattedItems.forEach((item) => {
					selectedOptionGroups.push(
						...item.optionGroups.map((groupId) =>
							menuItemsResp.data.menuV2.optionGroups.find((group) => group.id === groupId)
						)
					);
				});
			});

			// Collect related options
			selectedOptionGroups.forEach((group) => {
				selectedOptions.push(
					...group.options.map((optionId) =>
						menuItemsResp.data.menuV2.options.find((option) => option.id === optionId)
					)
				);
			});

			// Ensure unique items, option groups, and options
			menuUpdationVariables.menuData.items = ensureUnique([
				...menuUpdationVariables.menuData.items,
				...selectedItems
			]);

			menuUpdationVariables.menuData.optionGroups = ensureUnique([
				...menuUpdationVariables.menuData.optionGroups,
				...selectedOptionGroups
			]);

			menuUpdationVariables.menuData.options = ensureUnique([
				...menuUpdationVariables.menuData.options,
				...selectedOptions
			]);

			// Utility: Add or update a category in the menu data
			const updateOrinsertCategory = (categories, categoryId, items, overrides = {}) => {
				const categoryIndex = categories.findIndex((cat) => cat.id === categoryId);
				const uniqueItems = Array.from(new Set([...(categories[categoryIndex]?.items || []), ...items]));

				if (categoryIndex > -1) {
					categories[categoryIndex].items = uniqueItems;
				} else {
					categories.push({
						id: categoryId,
						items: uniqueItems,
						overrides: {
							name: overrides.name || "",
							description: overrides.description || "",
							image: overrides.image || ""
						},
						subCategories: []
					});
				}
			};

			// Add items to the specified category or original categories
			if (copyItemsToCategoryId) {
				const itemsToAdd = Object.keys(selectedEntities)
					.filter((itemId) => selectedEntities[itemId])
					.map((itemId) => String(itemId));

				const categoryIds = Array.from(
					new Set(
						selectedMenuSectionsData?.reduce((acc, cat) => {
							acc.push(cat?.id);
							cat.subCategories.forEach((subCategory) => {
								acc.push(subCategory.id);
							});
							return acc;
						}, [])
					)
				);

				const filteredItemsToAdd = itemsToAdd.filter((itemId) => !categoryIds.includes(itemId));

				updateOrinsertCategory(
					menuUpdationVariables.menuData.categories,
					copyItemsToCategoryId,
					filteredItemsToAdd
				);
			} else {
				sanitizedMenuSections.forEach((category) => {
					let includeCategory = false;
					if (selectedEntities[category?.id]) {
						includeCategory = true;
					}
					const filteredItems = category.items.filter((item) => selectedEntities[item?.id]);
					if (filteredItems.length) {
						includeCategory = true;
					}

					const filteredSubCategories = category.subCategories.filter(
						(subCategory) => selectedEntities[subCategory?.id]
					);

					if (filteredSubCategories.length) {
						includeCategory = true;
					}
					if (includeCategory) {
						// Check if the category already exists
						const existingCategory = menuUpdationVariables.menuData.categories.find(
							(existing) => existing.id === category?.id
						);

						if (existingCategory) {
							// Merge items and subCategories, avoiding duplicates
							existingCategory.items = Array.from(
								new Set([...existingCategory.items, ...filteredItems.map((item) => String(item.id))])
							);

							existingCategory.subCategories = Array.from(
								new Set([
									...existingCategory.subCategories,
									...filteredSubCategories.map((subCategory) => String(subCategory?.id))
								])
							);
						} else {
							// Add new category
							menuUpdationVariables.menuData.categories.push({
								id: category?.id,
								items: [...filteredItems.map((item) => String(item.id))],
								subCategories: [...filteredSubCategories.map((subCategory) => String(subCategory?.id))],
								overrides: {
									name: "",
									description: "",
									image: ""
								}
							});
						}
					}
				});
			}

			menuUpdationVariables.menuData.options = menuUpdationVariables.menuData.options.map((option) => ({
				...option,
				overrides: ensureImageField(option.overrides)
			}));

			menuUpdationVariables.menuData.categories = menuUpdationVariables.menuData.categories.map((category) => ({
				...category,
				overrides: ensureImageField(category.overrides)
			}));

			menuUpdationVariables.menuData.items = menuUpdationVariables.menuData.items.map((item) => ({
				...item,
				overrides: ensureImageField(item.overrides)
			}));

			menuUpdationVariables.menuData = removeProp(menuUpdationVariables.menuData, "__typename");

			const response = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (response?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Copied selected menu entities successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: response.data.updateMenuV2
				});
				trackEvent(TRACKING_EVENT_NAMES.NEW_SECTION_CREATION, {
					type: "copy_from_existing_menu"
				});
				hideCopyMenuSection();
			} else {
				throw new Error("Copy menu failed");
			}
		} catch (e) {
			console.error(e);
			NotificationServices.pushNotification({
				message: "Copy menu failed",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	};

	const handleMenuCopy = async () => {
		const santisedSelectedMenuSectionsData = removeProp(selectedMenuSectionsData, "__typename");
		try {
			const menuItemsVariable = {
				id: selectedMenu
			};

			const menuItemsResp = await clientMenu.mutate({
				mutation: GET_MENU_ITEMS,
				variables: menuItemsVariable
			});
			let selectedMenuDetails;

			if (menuItemsResp?.data?.menuV2) {
				selectedMenuDetails = {
					...removeProp(menuItemsResp?.data?.menuV2, "__typename")
				};
			} else {
				NotificationServices.pushNotification({
					message: "Failed to get menu details of the selected menu.",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				throw Error("Failed to fetch menu details");
			}

			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: selectedMenuDetails.options.map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: ""
						}
					})),
					optionGroups: selectedMenuDetails.optionGroups,
					items: selectedMenuDetails.items
						.filter((item) => selectedEntities[item?.id])
						.map((item) => ({
							...item,
							overrides: { ...item.overrides, image: "" }
						})),
					categories: []
				}
			};

			santisedSelectedMenuSectionsData.forEach((category) => {
				let includeCategory = false;
				if (selectedEntities[category?.id]) {
					includeCategory = true;
				}
				const filteredItems = category.items.filter((item) => selectedEntities[item?.id]);
				if (!!filteredItems.length) {
					includeCategory = true;
				}

				const filteredSubCategories = category.subCategories.filter(
					(subCategory) => selectedEntities[subCategory?.id]
				);

				if (!!filteredSubCategories.length) {
					includeCategory = true;
				}

				if (includeCategory) {
					menuUpdationVariables.menuData.categories.push({
						id: category?.id,
						items: [...filteredItems.map((item) => String(item.id))],
						subCategories: [...filteredSubCategories.map((subCategory) => String(subCategory?.id))],
						overrides: {
							name: "",
							description: "",
							image: ""
						}
					});
				}
			});

			const appendCategoryToMenuResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});
			if (appendCategoryToMenuResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Copied selected menu entities successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: appendCategoryToMenuResp?.data?.updateMenuV2
				});
				trackEvent(TRACKING_EVENT_NAMES.NEW_SECTION_CREATION, {
					type: "copy_from_existing_menu"
				});
				hideCopyMenuSection();
			} else {
				NotificationServices.pushNotification({
					message: "Copy menu failed",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (e) {
			console.log(e);
			NotificationServices.pushNotification({
				message: "Copy menu failed",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	};

	const handleSubmissions = () => {
		if (currentStep === 2) {
			handleMenuCopyImport();
		}
		setCurrentStep((current) => {
			if (currentStep === 1) {
				return 2;
			}
			return current;
		});
	};

	const fetchMenusList = async (searchKeyword = "") => {
		setLoadingStates((current) => ({
			...current,
			loading: true
		}));

		try {
			const variables = {
				brands: brandId && brandId !== "undefined" ? [String(brandId)] : [],
				status: "active",
				limit: 10,
				offset: searchKeyword ? 0 : offset,
				searchKeyword: searchKeyword,
				excludeMenuId: menuId
			};

			const respMenu = await clientMenu.query({
				query: GET_MENUS_LIST_MINI,
				variables,
				fetchPolicy: "no-cache"
			});
			if (respMenu?.data?.menus) {
				setObjectsCount(respMenu?.data?.menus?.totalObjectCount);
				if (!searchKeyword) {
					setCopyMenuAvailableMenusCount(respMenu?.data?.menus?.totalObjectCount);
				}
				setMenusListData(
					(respMenu?.data?.menus?.objects ?? []).map((menu) => ({
						...menu,
						menuInfo: {
							name: menu.name,
							menuId: menu.menuId,
							imageUrl: menu.imageUrl,
							brand: menu?.brandName
						},
						itemCount: menu?.metadata?.itemCount || 0,
						categoryCount: menu?.metadata?.categoryCount || 0
					}))
				);
			}
			setLoadingStates((current) => ({
				...current,
				initialLoad: false
			}));
		} catch (e) {
			console.log(e);
		}
		setLoadingStates((current) => ({
			...current,
			loading: false
		}));
	};

	const fetchMenusListDebounced = (searchKeyword) => {
		if (debouncedSearchRef.current) {
			clearTimeout(debouncedSearchRef.current);
		}
		debouncedSearchRef.current = setTimeout(() => {
			fetchMenusList(searchKeyword);
		}, 500);
	};

	const handleSearch = (value) => {
		setSearchKeyword(value);
		fetchMenusListDebounced(value);
	};

	const fetchSectionsDetails = async (searchKeyword = "") => {
		setLoadingStates((current) => ({
			...current,
			loading: true
		}));
		try {
			const variables = {
				menuId: selectedMenu,
				limit: 100,
				offset: 0,
				searchKeyword
			};
			const respSections = await clientMenu.query({
				query: GET_PARENT_SECTIONS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});
			setSelectedMenuSectionsData(respSections?.data?.categories?.objects ?? []);

			const categories = respSections?.data?.categories?.objects ?? [];
			let subCategoriesMapping = {};
			selectedMenuSectionsData.forEach((section) => {
				section.subCategories.forEach((subCategory) => {
					subCategoriesMapping[subCategory?.id] = true;
				});
			});
			for (let i = 0; i < categories.length; i++) {
				if (!subCategoriesMapping[categories[i]?.id]) {
					setExpandedAccordions({
						[categories[i]?.id]: true
					});
					break;
				}
			}
		} catch (e) {
			console.log(e);
		}

		setLoadingStates((current) => ({
			...current,
			loading: false
		}));
	};

	const handleFoodTypeSelection = (field, value) => {
		setSelectedFoodType(value);
	};

	const fetchSectionsDetailsDebounced = (searchKeyword) => {
		if (debouncedEntitySearchRef.current) {
			clearTimeout(debouncedEntitySearchRef.current);
		}
		debouncedEntitySearchRef.current = setTimeout(() => {
			fetchSectionsDetails(searchKeyword);
		}, 500);
	};

	const handleItemSearch = (value) => {
		setEntitySearchKeyword(value);
		fetchSectionsDetailsDebounced(value);
	};

	const handleClose = () => {
		if (currentStep === 2) {
			setCurrentStep(1);
			return;
		}
		setCurrentStep(1);
		hideCopyMenuSection();
	};

	useEffect(() => {
		// if(isOpen) {
		fetchMenusList();
		// }
	}, [isOpen, offset]);

	useEffect(() => {
		if (selectedMenu && currentStep === 2) {
			fetchSectionsDetails();
		}
	}, [currentStep]);

	useEffect(() => {
		if (isOpen) {
			// Reset states when the component is reopened
			setCurrentStep(1);
			setSelectedMenu("");
			setSelectedMenuSectionsData([]);
			setSelectedEntities({});
			setSearchKeyword("");
			setEntitySearchKeyword("");
			setOffset(0);
			setExpandedAccordions({});
		}
	}, [isOpen]);

	const STEPWISE_COMPONENTS = {
		1: () => (
			<ExistingMenusSelection
				isLoading={loadingStates.loading && loadingStates.initialLoad}
				menusListData={menusListData}
				selectedMenu={selectedMenu}
				setSelectedMenu={setSelectedMenu}
				offset={offset}
				handlePagination={handlePagination}
				objectsCount={objectsCount}
				searchKeyword={searchKeyword}
				handleSearch={handleSearch}
			/>
		),
		2: () => (
			<SectionsItemsSelection
				isLoading={loadingStates?.loading}
				selectedMenuSectionsData={selectedMenuSectionsData}
				selectedEntities={selectedEntities}
				setSelectedEntities={setSelectedEntities}
				itemSearchKeyword={entitySearchKeyword}
				selectedFoodType={selectedFoodType}
				handleItemSearch={handleItemSearch}
				handleFoodTypeSelection={handleFoodTypeSelection}
				expandedAccordions={expandedAccordions}
				setExpandedAccordions={setExpandedAccordions}
			/>
		)
	};

	return (
		<FormSidebar
			isOpen={isOpen}
			close={handleClose}
			title={currentStep === 1 ? "Copy from Existing Menu" : "Copy Sections & Items"}
			subTitle="Add existing sections and items to your new menu"
			submitTitle={currentStep === 1 ? "Next: Select Sections" : "Save"}
			submit={handleSubmissions}
			submitButtonWidth={200}
			loading={loadingStates.loading && loadingStates.initialLoad}
			disabled={
				(currentStep === 1 && !selectedMenu) ||
				(currentStep === 2 && Object.keys(selectedEntities).length === 0)
			}
			cancelTitle={currentStep === 1 ? "Cancel" : "Previous"}
		>
			<div className="copy-menu-container">{STEPWISE_COMPONENTS[currentStep]()}</div>
		</FormSidebar>
	);
};
export default CopyMenu;

const ExistingMenusSelection = ({
	isLoading,
	menusListData,
	selectedMenu,
	setSelectedMenu,
	handlePagination,
	offset,
	objectsCount,
	handleSearch,
	searchKeyword
}) => {
	const menusTableColumn = [
		{
			title: "menu name",
			dataIndex: "menuInfo",
			render: (data) => (
				<>
					<RadioButton
						clickHandler={() => setSelectedMenu(data?.menuId)}
						checked={data?.menuId === selectedMenu}
					>
						<div className="menu-image-title">
							<div className="menu-image-container">
								<Image
									src={data?.imageUrl ? data?.imageUrl : "/assets/icons/icon-menu-placeholder.svg"}
									alt={data?.name}
									className={data?.imageUrl ? "menu-icon" : ""}
								/>
							</div>
							<div className="menu-info-container">
								<span className="menu-title">{data?.name || "--"}</span>
								<span className="brand">{data?.brand || "--"}</span>
							</div>
						</div>
					</RadioButton>
				</>
			)
		},
		{
			title: "sections",
			dataIndex: "categoryCount",
			render: (data) => String(data)
		},
		{
			title: "items",
			dataIndex: "itemCount",
			render: (data) => (
				<>
					<Image src="/assets/icons/icon-fork-spoon-mini.svg" alt="items count" className="item-icon" />
					{data}
				</>
			)
		},
		{
			title: "status",
			dataIndex: "publishStatus",
			render: (data) => <span className={`status-block ${data}`}>{data}</span>
		}
	];
	return (
		<div className="existing-menus-selection">
			<div className="form-row row-half">
				{/* <SelectFilter
					options={[]}
					field={'locations'}
					labelKey="valueForDisplay"
					valueKey="value"
					isSearchable={false}
					isClearable={false}
				/> */}
				<SearchFilter
					placeholder="Search"
					filterOption={{
						field: "name"
					}}
					setFilter={(f, v) => handleSearch(v)}
					value={searchKeyword}
				/>
			</div>
			<FormTable
				isLoading={isLoading}
				placeholderContent={{
					placeholderText: "No menus found!"
				}}
				columns={menusTableColumn}
				dataSource={menusListData}
			/>
			<Paginator limit={10} offset={offset} goToPage={handlePagination} count={objectsCount} />
		</div>
	);
};

const SectionsItemsSelection = ({
	isLoading,
	selectedMenuSectionsData,
	selectedEntities,
	setSelectedEntities,
	itemSearchKeyword,
	selectedFoodType,
	handleItemSearch,
	handleFoodTypeSelection,
	expandedAccordions,
	setExpandedAccordions
}) => {
	let subCategoriesMapping = {};
	selectedMenuSectionsData.forEach((section) => {
		section.subCategories.forEach((subCategory) => {
			subCategoriesMapping[subCategory?.id] = true;
		});
	});

	const [isAllFieldSelected, setIsAllFieldSelected] = useState(false);
	const [showMenuCopyInfo, setShowMenuCopyInfo] = useState(true);

	const handleExpand = (id) => {
		setExpandedAccordions((current) => {
			const updatedState = {
				...current
			};
			if (updatedState[id]) {
				delete updatedState[id];
				return updatedState;
			}

			updatedState[id] = true;

			return updatedState;
		});
	};

	const hideMenuCopyInfo = () => {
		setShowMenuCopyInfo(false);
	};

	const handleEntitySelection = (type, id, state) => {
		/*
			Here, has to be handled in a nested structure and therefore the accordion expansion should also be handled simultaneously.
			There will be 3 types - parent_section, sub_section, item and 3 of them has to be handled in a separate manner.
			1. When the selected / deselected entity is a parent_section - check whether it holds items or sub section, if it holds sub sections then
			iterate through every subsection and select them as well as items under them.
			2. If it's a sub section just selected all items under them and try to get details about the parent section, if the parent section doesn't have any other
			subsection then show that entire section is selected.
			3. If an item is selected then check if all the corresponding items are present then select the parent section as well.
		*/

		// setSelectedEntities(current => ({
		// 	...current,
		// 	[id]: state,
		// }))
		const updatedSelectedEntities = {
			...selectedEntities
		};
		const selectedOpenAccordions = {
			...expandedAccordions
		};

		if (type === "parent_section") {
			const selectedSectionInfo = selectedMenuSectionsData?.find((section) => section?.id === id);

			updatedSelectedEntities[id] = state;
			selectedOpenAccordions[id] = state;

			if (!state) {
				delete updatedSelectedEntities[id];
				delete selectedOpenAccordions[id];
			}

			if (selectedSectionInfo.items.length === 0 && selectedSectionInfo.subCategories.length === 0) {
				setSelectedEntities(updatedSelectedEntities);
				return true;
			}

			if (!!selectedSectionInfo.items.length) {
				selectedSectionInfo.items.forEach((item) => {
					if (state) {
						updatedSelectedEntities[item?.id] = true;
						selectedOpenAccordions[item?.id] = true;
					} else if (updatedSelectedEntities[item?.id]) {
						delete updatedSelectedEntities[item?.id];
						delete selectedOpenAccordions[item?.id];
					}
				});
				setSelectedEntities(updatedSelectedEntities);
				setExpandedAccordions(selectedOpenAccordions);
			} else {
				// here else block points to section holding subsection
				selectedSectionInfo.subCategories.forEach((subCategory) => {
					if (state) {
						updatedSelectedEntities[subCategory?.id] = true;
						selectedOpenAccordions[subCategory?.id] = true;
					} else if (updatedSelectedEntities[subCategory?.id]) {
						delete updatedSelectedEntities[subCategory?.id];
					}

					selectedMenuSectionsData
						.find((section) => section?.id === subCategory?.id)
						.items.forEach((item) => {
							if (state) {
								updatedSelectedEntities[item?.id] = true;
								selectedOpenAccordions[item?.id] = true;
							} else if (updatedSelectedEntities[item?.id]) {
								delete updatedSelectedEntities[item?.id];
							}
						});
				});
				setSelectedEntities(updatedSelectedEntities);
				setExpandedAccordions(selectedOpenAccordions);
			}
		}

		if (type === "sub_section") {
			const subSectionInfo = selectedMenuSectionsData.find((section) => section?.id === id);
			updatedSelectedEntities[id] = state;
			selectedOpenAccordions[id] = state;

			if (!state) {
				delete updatedSelectedEntities[id];
				delete selectedOpenAccordions[id];
			}

			subSectionInfo.items.forEach((item) => {
				if (state) {
					updatedSelectedEntities[item?.id] = true;
				} else if (updatedSelectedEntities[item?.id]) {
					delete updatedSelectedEntities[item?.id];
				}
			});
			setSelectedEntities(updatedSelectedEntities);
			setExpandedAccordions(selectedOpenAccordions);
		}

		if (type === "item") {
			updatedSelectedEntities[id] = state;
			if (!state) {
				delete updatedSelectedEntities[id];
			}
			setSelectedEntities(updatedSelectedEntities);
		}
	};

	const handleAllFieldSelection = () => {
		const state = !isAllFieldSelected;
		const updatedSelectedEntities = {
			...selectedEntities
		};
		const selectedOpenAccordions = {
			...expandedAccordions
		};
		const filteredParentSections = selectedMenuSectionsData.filter((section) => !subCategoriesMapping[section?.id]);
		filteredParentSections.forEach((parentSection) => {
			const id = parentSection?.id;
			const selectedSectionInfo = selectedMenuSectionsData?.find((section) => section?.id === id);

			updatedSelectedEntities[id] = state;
			selectedOpenAccordions[id] = state;

			if (!state) {
				delete updatedSelectedEntities[id];
				delete selectedOpenAccordions[id];
			}

			// if((selectedSectionInfo.items.length === 0) && (selectedSectionInfo.subCategories.length === 0)) {
			// 	setSelectedEntities(updatedSelectedEntities);
			// 	return true;
			// }

			if (!!selectedSectionInfo.items.length) {
				selectedSectionInfo.items.forEach((item) => {
					if (state) {
						updatedSelectedEntities[item?.id] = true;
						selectedOpenAccordions[item?.id] = true;
					} else if (updatedSelectedEntities[item?.id]) {
						delete updatedSelectedEntities[item?.id];
						delete selectedOpenAccordions[item?.id];
					}
				});
				setSelectedEntities(updatedSelectedEntities);
				setExpandedAccordions(selectedOpenAccordions);
			} else {
				// here else block points to section holding subsection
				selectedSectionInfo.subCategories.forEach((subCategory) => {
					if (state) {
						updatedSelectedEntities[subCategory?.id] = true;
						selectedOpenAccordions[subCategory?.id] = true;
					} else if (updatedSelectedEntities[subCategory?.id]) {
						delete updatedSelectedEntities[subCategory?.id];
					}

					selectedMenuSectionsData
						.find((section) => section?.id === subCategory?.id)
						.items.forEach((item) => {
							if (state) {
								updatedSelectedEntities[item?.id] = true;
								selectedOpenAccordions[item?.id] = true;
							} else if (updatedSelectedEntities[item?.id]) {
								delete updatedSelectedEntities[item?.id];
							}
						});
				});
			}
		});
		setSelectedEntities(updatedSelectedEntities);
		setExpandedAccordions(selectedOpenAccordions);
		setIsAllFieldSelected(!isAllFieldSelected);
	};

	if (isLoading && selectedMenuSectionsData?.length === 0) {
		return (
			<div className="sections-items-selection">
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			</div>
		);
	}
	if (selectedMenuSectionsData?.length === 0) {
		return (
			<div className="sections-items-selection">
				{showMenuCopyInfo && (
					<div className="menu-structure-info">
						<div className="info-icon-container">
							<Image src="/assets/icons/info.png" className="info-icon" />
						</div>
						<div className="info-text">
							<div className="header">All details will be copied along with the menu structure</div>
							<div className="sub-header">
								Any edited prices, modifiers and modifier groups, images, etc. will be added to your
								menu
							</div>
						</div>
						<Image src="/assets/icons/cancel.png" className="icon-close" onClick={hideMenuCopyInfo} />
					</div>
				)}
				<div className="selector-search-container">
					<div className="form-row row-half">
						{/* <SelectFilter
							options={FOODTYPE_SELECTOR_OPTIONS}
							field={"foodtype"}
							labelKey="valueForDisplay"
							valueKey="value"
							isSearchable={false}
							isClearable={false}
							placeholder="Food Type"
							currValue={selectedFoodType}
							setFilter={handleFoodTypeSelection}
						/> */}
						<SearchFilter
							placeholder="Search"
							filterOption={{
								field: "name"
							}}
							setFilter={(f, v) => handleItemSearch(v)}
							value={itemSearchKeyword}
						/>
					</div>
				</div>
				<div className="P(20px)" style={{ display: "flex", justifyContent: "center" }}>
					<strong>Empty section!</strong>
				</div>
			</div>
		);
	}

	return (
		<div className={`sections-items-selection ${isLoading ? "disabled" : ""}`}>
			{showMenuCopyInfo && (
				<div className="menu-structure-info">
					<div className="info-icon-container">
						<Image src="/assets/icons/info.png" className="info-icon" />
					</div>
					<div className="info-text">
						<div className="header">All details will be copied along with the menu structure</div>
						<div className="sub-header">
							Any edited prices, modifiers and modifier groups, images, etc. will be added to your menu
						</div>
					</div>
					<Image src="/assets/icons/cancel.png" className="icon-close" onClick={hideMenuCopyInfo} />
				</div>
			)}
			<div className="selector-search-container">
				<div className="form-row row-half">
					{/* <SelectFilter
						options={FOODTYPE_SELECTOR_OPTIONS}
						field={'foodtype'}
						labelKey="valueForDisplay"
						valueKey="value"
						isSearchable={false}
						isClearable={false}
						placeholder='Food Type'
						currValue={selectedFoodType}
						setFilter={handleFoodTypeSelection}
					/> */}
					<SearchFilter
						placeholder="Search"
						filterOption={{
							field: "name"
						}}
						setFilter={(f, v) => handleItemSearch(v)}
						value={itemSearchKeyword}
					/>
				</div>
			</div>
			<div className="selection-cta">
				<span className={`hyperlink ${isAllFieldSelected ? "deselect" : ""}`} onClick={handleAllFieldSelection}>
					{isAllFieldSelected ? "Deselect All" : "Select All"}
				</span>
			</div>
			<div className="expandable-collapsible-menu-contents">
				{selectedMenuSectionsData
					.filter((section) => !subCategoriesMapping[section?.id])
					.map((section, i) => (
						<EntityAccordion
							title={
								<span className="section-title-container">
									{section?.name}
									<span className="section-items-count">
										{section?.subCategories?.length} subsections, {section?.items?.length} items
									</span>
								</span>
							}
							isExpanded={expandedAccordions[section.id]}
							handleExpand={() => {
								handleExpand(section.id);
							}}
							isSelectable
							handleFieldSelection={() =>
								handleEntitySelection("parent_section", section?.id, !selectedEntities[section?.id])
							}
							isSelected={!!selectedEntities[section?.id]}
							key={i}
							showBorderBottom
						>
							{!!(section?.items ?? []).length
								? section?.items.map((item, i) => (
										<Item
											title={item.name}
											foodType={item.foodType}
											imageUrl={item.imageUrl || ""}
											key={i}
											handleFieldSelection={() =>
												handleEntitySelection("item", item?.id, !selectedEntities[item?.id])
											}
											checked={!!selectedEntities[item?.id]}
										/>
									))
								: section.subCategories.map((subCategory, i) => (
										<EntityAccordion
											title={
												<span className="section-title-container">
													{subCategory?.name}
													<span className="section-items-count">
														{
															(
																selectedMenuSectionsData?.find(
																	(section) => section.id === subCategory?.id
																)?.items ?? []
															).length
														}{" "}
														items
													</span>
												</span>
											}
											isExpanded={expandedAccordions[subCategory.id]}
											handleExpand={() => {
												handleExpand(subCategory.id);
											}}
											isSelectable
											handleFieldSelection={() =>
												handleEntitySelection(
													"sub_section",
													subCategory?.id,
													!selectedEntities[subCategory?.id]
												)
											}
											key={i}
											className="subcategories-container"
											isSelected={!!selectedEntities[subCategory?.id]}
										>
											{(
												selectedMenuSectionsData?.find(
													(section) => section.id === subCategory?.id
												)?.items ?? []
											).map((item, i) => (
												<Item
													title={item.name}
													foodType={item.foodType}
													imageUrl={item.imageUrl || ""}
													key={i}
													handleFieldSelection={() =>
														handleEntitySelection(
															"item",
															item?.id,
															!selectedEntities[item?.id]
														)
													}
													checked={!!selectedEntities[item?.id]}
												/>
											))}
										</EntityAccordion>
									))}
						</EntityAccordion>
					))}
			</div>
		</div>
	);
};

const Item = ({ title, foodType, imageUrl, key, handleFieldSelection, checked }) => (
	<div className="item-details-container">
		<div className="item-info">
			<Image
				src={imageUrl || "/assets/empty-dish.jpg"}
				alt={title}
				className="item-image"
				errorOverrideImage="/assets/empty-dish.jpg"
			/>
			<FoodTypeIndicator foodType={foodType} />
			<div className="item-title">{title}</div>
		</div>
		<CheckBox clickHandler={handleFieldSelection} checked={checked} />
	</div>
);
