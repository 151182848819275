import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AutoRefreshPeriscope from "../AutoRefreshPeriscope";

const SpecificItemTableHeader = ({ item, brand, refreshBtn, dataTestId, periscopeDataResponse }) => {
	return (
		<div className="specificitem-table-head">
			<div className="specificitem-table-header">
				<div className="text-dislplay-specificitem">
					<Link to="/periscope/item-availability" style={{ textDecoration: "none" }}>
						<div className="specificitem-header-display">
							<span data-testid={dataTestId} className="table-header-grey-text">
								Item Availability
							</span>
							<img className="specificitem-right-arrow" src="/assets/periscope/right-arrow-action.svg" />
						</div>
					</Link>
					<div className="specificitem-brand-item">
						<span>
							{item ? item : ""}
							{brand ? `(${brand})` : ""}
						</span>
					</div>
				</div>
				<AutoRefreshPeriscope />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeDataResponse: state.periscopeDataResponse
});
export default connect(mapStateToProps)(SpecificItemTableHeader);
