import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// components
import { Header } from "../components/TransactionDetails/Header";
import { Summary } from "../components/TransactionDetails/Summary";
import PaymentGateway from "../components/TransactionDetails/PaymentGateway";
import { InStoreSummary } from "../components/TransactionDetails/InStoreSummary";
import { StatusLogs } from "../components/TransactionDetails/StatusLogs";
import StatusUpdate from "../components/TransactionDetails/StatusUpdate";
import { Webhooks } from "../components/TransactionDetails/Webhooks";
import Upstream from "../components/TransactionDetails/Upstream";
import Downstream from "../components/TransactionDetails/Downstream";
import { InfiniteTopBar } from "../components/SiteComp";
import { Invoice, invoiceStyle } from "../components/TransactionDetails/Invoice";
import { GlobalConfirmModal } from "../components/SiteComp";
import { FormSidebar } from "../components/_commons/FormSidebar";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import Reconciliation from "../components/TransactionDetails/Reconciliation";
import { SelectFilter } from "../components/_commons/SelectFilter";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// utils
import history from "../history";
import { adjustNestedContainer, scroll } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";
import { TRACK_EVENT } from "../atlas-utils/tracking";

// graphql
import {
	GET_TRANSACTION_ONLINE,
	GET_TRANSACTION_IN_STORE,
	UPDATE_ORDER_STATUS,
	FETCH_EXTERNAL_LOGS,
	GET_BIZ_CHANNELS
} from "../graphql/transactions";

// constants
import { STORE_TYPES, ORDER_STATUS_MAP } from "../client-config";
import { Loading } from "../components/_commons/Loading";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const TRANSACTION_DETAILS_TABS = ["Summary", "Lifecycle", "Webhooks", "Upstream", "Downstream"];

const TRANSACTION_DETAILS_TABS_RECON = ["Summary", "Lifecycle", "Webhooks", "Upstream", "Downstream", "Reconciliation"];

@connect(
	(store) => ({
		transactionsListState: store.transactionsListState,
		transactionDetails: store.transactionDetails,
		biz: store.login.loggedInbizDetail,
		userEmail: store.login.loginDetail.email,
		access: store.login.loginDetail.access
	}),
	null,
	null,
	{ withRef: true }
)
export class TransactionDetails extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.paymentRef = React.createRef();
		this.statusRef = React.createRef();
		this.state = {
			tabs: [...TRANSACTION_DETAILS_TABS],
			selectedTab: "Summary",
			showStatusUpdateModal: false,
			statusUpdateMsg: "",
			nextStatus: "",
			isUpdatingStatus: false,
			paymentTransaction: {
				isOpen: false,
				data: {}
			},
			statusUpdates: {
				isOpen: false,
				id: ""
			},
			isFormOpen: false,
			isModalBusy: false,
			externalLogs: [],
			nestedEntity: NESTED_ENTITY_INITIAL_STATE,
			bizChannels: {},
			isModalOpen: false,
			showReconciliationActions: false,
			unavailableItemsOptions: {
				items: [],
				options: []
			}
		};
		this.defaultPrevLocation = {
			pathname: "/orders",
			title: "Orders"
		};
	}

	async componentDidMount() {
		setTimeout(() => this.setState({ isFormOpen: true }), 60);
		this.storeType = STORE_TYPES[0].type;
		if (this.props.match.params.type === "in-store") {
			this.storeType = STORE_TYPES[1].type;
		}
		await this.fetchTransaction(this.props);
		await this.fetchBizPlatformsList();

		// Downstream
		if (this.props.match.params.type === "online") {
			await this.fetchExternalLogs(this.props);
		}

		// set tracking related info
		// this.trackEvent(
		// 	'transaction_detail_view',
		// 	{ mode: this.storeType }
		// );
	}

	trackEvent = (eventName, meta = {}) => {
		if (eventName) {
			const eventMeta = {
				...meta,
				transaction_id: this.props.match.params.id
			};
			PubSub.publish(TRACK_EVENT, {
				tracker: "mixpanel",
				eventName,
				eventMeta
			});
		}
	};

	componentWillReceiveProps(nextProps) {
		if (this.props.match.params.id != nextProps.match.params.id) {
			this.fetchTransaction(nextProps);
		} else if (this.props.match.params.type != nextProps.match.params.type) {
			this.fetchTransaction(nextProps);
		}
	}

	switchTab = (selectedTab) => {
		this.setState({
			selectedTab
		});
	};

	handleCancel = () => {
		if (this.state.nestedEntity.show) {
			this.nestedRef.current.getWrappedInstance().handleCancel();
			return;
		} else if (this.state.paymentTransaction.isOpen) {
			this.paymentRef.current.handleClose();
		} else if (this.state.statusUpdates.isOpen) {
			this.statusRef.current.handleClose();
		} else {
			if (!this.state.isModalBusy) {
				this.setState({ isFormOpen: false });
				setTimeout(() => {
					if (this.props.isNested || this.props.isForeignSource) {
						this.props.closeNestedContainer();
					} else {
						history.push("/orders");
					}
				}, 100);
			} else if (this.state.selectedTab === this.state.tabs[2]) {
				this.nestedRef.current.closeHistoryDetailView();
			} else {
				this.nestedRef.current.closeLogDetailView();
			}
		}
	};

	handleViewCustomerDetail = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewCustomerDetail.bind(this);

	fetchTransaction = async (props, fetchPolicy = "no-cache", updateList = false) => {
		store.dispatch({
			type: "GET_TRANSACTION_DETAILS_REQUEST"
		});
		try {
			if (this.storeType === STORE_TYPES[0].type) {
				const resp = await client.query({
					query: GET_TRANSACTION_ONLINE,
					variables: {
						id: props.match.params.id
					},
					fetchPolicy
				});

				const orderItems = resp?.data?.order?.items || [];
				orderItems.forEach((orderItem) => {
					if (orderItem?.orderItemOptions?.length && orderItem?.optionsToAdd?.length) {
						orderItem.orderItemOptions.forEach((orderItemOption) => {
							orderItem.optionsToAdd.forEach((optionToAdd) => {
								if (Number(optionToAdd.id) === orderItemOption.id) {
									optionToAdd.quantity = orderItemOption.quantity;
								}
							});
						});
					}
				});

				if (resp?.data?.order?.channel === "SWIGGY" || resp?.data?.order?.channel === "ZOMATO") {
					this.setState({
						tabs: this.props?.biz?.isReconEnabled
							? [...TRANSACTION_DETAILS_TABS_RECON]
							: [...TRANSACTION_DETAILS_TABS]
					});
					if (this.props.fromReconciliationPayoutSheet) {
						this.setState({
							selectedTab: "Reconciliation"
						});
					}
				}

				if (updateList) {
					if (resp.data.order?.id && resp.data.order?.status) {
						store.dispatch({
							type: "UPDATE_TRANSACTION_LIST",
							payload: {
								orderId: resp.data.order?.id,
								status: resp.data.order?.status
							}
						});
					}
				}
				store.dispatch({
					type: "GET_TRANSACTION_DETAILS_SUCCESS",
					payload: resp.data.order
				});
			} else if (this.storeType === STORE_TYPES[1].type) {
				const resp = await client.query({
					query: GET_TRANSACTION_IN_STORE,
					variables: {
						id: props.match.params.id
					},
					fetchPolicy
				});
				store.dispatch({
					type: "GET_TRANSACTION_DETAILS_SUCCESS",
					payload: resp.data.purchase
				});
			}
		} catch (error) {
			console.log(error.message);
			store.dispatch({
				type: "GET_TRANSACTION_DETAILS_FAILURE",
				error
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	fetchExternalLogs = async (props) => {
		try {
			const variables = { id: parseInt(props.match.params.id) };
			const resp = await client.query({
				query: FETCH_EXTERNAL_LOGS,
				variables,
				fetchPolicy: "no-cache"
			});
			this.setState({ externalLogs: resp.data.order.externalLogs });
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	printInvoice = () => {
		const frame = document.getElementById("InvoiceFrame");
		if (frame) {
			const doc = frame.contentWindow.document;
			doc.open();
			doc.write(
				invoiceStyle +
					"\n" +
					ReactDOMServer.renderToStaticMarkup(
						<Invoice
							biz={this.props.biz}
							data={this.props.transactionDetails.data || {}}
							storeType={this.storeType}
						/>
					)
			);
			doc.close();
			frame.contentWindow.print();
		}
		// this.trackEvent('transaction_print_invoice');
	};

	handleStatusUpdateModal = () => {
		this.setState({
			showStatusUpdateModal: !this.state.showStatusUpdateModal
		});
	};

	handleStatusUpdateMsg = (e) => {
		this.setState({
			statusUpdateMsg: e.target.value
		});
	};

	changeNextStatus = (nextStatus) => {
		this.setState({
			nextStatus,
			showStatusUpdateModal: true,
			statusUpdateMsg: ""
		});
	};

	updateOrderStatus = async () => {
		this.setState({
			isUpdatingStatus: true
		});
		try {
			const resp = await client.mutate({
				mutation: UPDATE_ORDER_STATUS,
				variables: {
					orderId: this.props.transactionDetails.data.id,
					newStatus: this.state.nextStatus,
					message: this.state.statusUpdateMsg,
					unavailableItemIds:
						this.state.nextStatus === "CANCELLED" ? this.state.unavailableItemsOptions.items : [],
					unavailableOptionIds:
						this.state.nextStatus === "CANCELLED" ? this.state.unavailableItemsOptions.options : []
				}
			});
			if (resp.data.updateOrderStatus.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Status update successfully",
						timeout: 5000,
						error: false
					}
				});
				await this.fetchTransaction(this.props, "network-only", true);
				this.setState({
					showStatusUpdateModal: false
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		this.setState({
			isUpdatingStatus: false
		});

		// track this event
		// this.trackEvent(
		// 	'transaction_status_change',
		// 	{ status: this.state.nextStatus },
		// );
	};

	fetchBizPlatformsList = async () => {
		try {
			const variables = {
				includeHubChannels: true,
				includeUrbanpiper: false,
				includeInternalChannels: true
			};
			const resp = await client.query({
				query: GET_BIZ_CHANNELS,
				variables,
				fetchPolicy: "no-cache"
			});
			let bizChannels = resp.data.bizChannels.reduce(
				(accumulator, current) => ({
					...accumulator,
					[current.value.toUpperCase()]: current.valueForDisplay
				}),
				{}
			);
			this.setState({
				bizChannels
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	openPaymentGatewaySidebar = () => {
		this.setState({
			paymentTransaction: {
				isOpen: true,
				data: this.props.transactionDetails.data.paymentTransaction || {}
			}
		});
	};

	openStatusUpdateSidebar = (id) => {
		this.setState({
			statusUpdates: {
				isOpen: true,
				id
			}
		});
	};

	closeSidebar = () => {
		this.setState({
			paymentTransaction: {
				isOpen: false,
				data: {}
			},
			statusUpdates: {
				isOpen: false,
				id: ""
			}
		});
	};

	renderTitle = () => {
		return (
			<Header
				loading={this.props.transactionDetails.loading}
				data={this.props.transactionDetails.data || {}}
				printInvoice={this.printInvoice}
				storeType={this.storeType}
				changeNextStatus={this.changeNextStatus}
				selectedTab={this.state.selectedTab}
				updateModalState={this.updateModalState}
				showReconciliationActions={this.state.showReconciliationActions}
				readOnly={!this.props.access?.isAdmin}
			/>
		);
	};

	updateModalState = (state) => {
		this.setState({
			isModalOpen: state
		});
	};

	updateReconciliationActionVisibility = (state) => {
		this.setState({
			showReconciliationActions: state
		});
	};

	fetchPayoutSheetDetails = () => {
		if (this.props.fromReconciliationPayoutSheet) {
			this.props.fetchPayoutSheetDetails(this.props.payoutId);
		}
	};

	handleItemOptionSelect = (field, values) => {
		const { unavailableItemsOptions } = this.state;
		this.setState({
			unavailableItemsOptions: {
				...unavailableItemsOptions,
				[field]: values.map((value) => parseInt(value.id))
			}
		});
	};

	scrollDown = () => {
		setTimeout(() => {
			const formContainer = document.getElementsByClassName("discard-confirm-modal")[0];
			if (formContainer) {
				scroll({ top: formContainer.scrollHeight, left: 0 }, formContainer);
			}
		}, 275);
	};

	render() {
		const {
			tabs,
			selectedTab,
			isFormOpen,
			externalLogs,
			nestedEntity,
			bizChannels,
			isModalOpen,
			unavailableItemsOptions
		} = this.state;
		const { transactionDetails, isNested = false, userEmail, setReconStatusActionTriggered } = this.props;
		const { loading, data, error } = transactionDetails;
		const optionsToAdd = [];

		if (data?.items) {
			data.items.forEach((item) => {
				item.optionsToAdd.forEach((option) => {
					optionsToAdd.push(option);
				});
			});
		}

		return (
			<div className="transaction-details-container">
				<FormSidebar
					isOpen={isFormOpen}
					close={this.handleCancel}
					title={this.renderTitle()}
					hideActions={true}
					isNested={isNested}
				>
					<div style={{ display: "none" }}>
						<iframe
							style={{
								height: "100%",
								width: "100%",
								border: "0px"
							}}
							id="InvoiceFrame"
							src="about:blank"
						></iframe>
					</div>
					<div className="transaction-section">
						{!loading && error ? (
							<div className="P(200px) Ta(c) C(#c0392b)">Sorry! Failed to fetch order details.</div>
						) : (
							<React.Fragment>
								{this.storeType === STORE_TYPES[0].type && (
									<Topbar
										tabs={tabs}
										selectedTab={selectedTab}
										switchTab={this.switchTab}
										isStickyOnTop={true}
										hiddenTabs={[
											userEmail?.includes("@urbanpiper.com") ? "" : "Upstream",
											externalLogs?.length > 0 ? "" : "Downstream"
										]}
									/>
								)}
								{selectedTab === tabs[0] && this.storeType === STORE_TYPES[0].type && (
									<Summary
										loading={loading}
										data={data || {}}
										trackEvent={this.trackEvent}
										handleNestedEntity={this.handleNestedEntity}
										openSidebar={this.openPaymentGatewaySidebar}
										bizChannels={bizChannels}
									/>
								)}
								{selectedTab === tabs[0] && this.storeType === STORE_TYPES[1].type && (
									<InStoreSummary loading={loading} data={data || {}} />
								)}
								{selectedTab === tabs[1] && (
									<StatusLogs
										loading={loading}
										data={data}
										userEmail={userEmail}
										handleNestedEntity={this.handleNestedEntity}
										openSidebar={this.openStatusUpdateSidebar}
									/>
								)}
								{selectedTab === tabs[2] && (
									<Webhooks
										orderId={this.props.match.params.id}
										nestedRef={this.nestedRef}
										setModalBusy={(state) => this.setState({ isModalBusy: state })}
									/>
								)}
								{selectedTab === tabs[3] && <Upstream payload={data ? data.aggregatorPayload : ""} />}
								{selectedTab === tabs[4] && (
									<Downstream
										data={externalLogs || []}
										nestedRef={this.nestedRef}
										setModalBusy={(state) => this.setState({ isModalBusy: state })}
									/>
								)}
								{selectedTab === tabs[5] && (
									<Reconciliation
										isModalOpen={isModalOpen}
										updateModalState={this.updateModalState}
										orderId={this.props.match.params.id}
										channel={data?.channel}
										updateReconciliationActionVisibility={this.updateReconciliationActionVisibility}
										handleCancel={this.handleCancel}
										setReconStatusActionTriggered={setReconStatusActionTriggered}
										isNested={isNested}
										fetchPayoutSheetDetails={this.fetchPayoutSheetDetails}
									/>
								)}
							</React.Fragment>
						)}
					</div>
					<GlobalConfirmModal
						backdropHandler={this.handleStatusUpdateModal}
						confirmBtnEnabled={this.state.statusUpdateMsg.trim()}
						show={this.state.showStatusUpdateModal}
						confirmHandler={this.updateOrderStatus}
						cancelHandler={this.handleStatusUpdateModal}
						modalBusy={this.state.isUpdatingStatus}
						customButtons={!this.state.showStatusUpdateModal}
					>
						{this.state.isUpdatingStatus && <Loading />}
						<div
							className="discard-confirm-modal"
							style={{ display: this.state.showStatusUpdateModal ? "block" : "none" }}
						>
							<div className="title">
								Update order status to{" "}
								{ORDER_STATUS_MAP[this.state.nextStatus] || this.state.nextStatus}
							</div>
							<div className="modal-text">Please provide a message</div>
							<div className="save-campaign-title">
								<input
									onChange={this.handleStatusUpdateMsg}
									value={this.state.statusUpdateMsg}
									type="text"
									placeholder="Update message"
								/>
							</div>
							{this.state.nextStatus === "CANCELLED" && (
								<React.Fragment>
									<div className="selectors-header">
										Please select unavailable items & modifiers for this order (if any)
									</div>
									<div className="associated-items-options-selectors">
										<SelectFilter
											title="Unavailable Items"
											placeholder="Select items"
											currValue={unavailableItemsOptions.items}
											multi={true}
											options={data?.items}
											labelKey="title"
											valueKey="id"
											setFilter={this.handleItemOptionSelect}
											field="items"
											scrollDown={this.scrollDown}
										/>
										<SelectFilter
											title="Unavailable Modifiers"
											placeholder="Select items"
											currValue={unavailableItemsOptions.options}
											multi={true}
											options={optionsToAdd}
											labelKey="title"
											valueKey="id"
											setFilter={this.handleItemOptionSelect}
											field="options"
											scrollDown={this.scrollDown}
										/>
									</div>
								</React.Fragment>
							)}
						</div>
					</GlobalConfirmModal>
					<PaymentGateway
						isOpen={this.state.paymentTransaction.isOpen}
						close={this.closeSidebar}
						data={this.state.paymentTransaction.data}
						nestedRef={this.paymentRef}
					/>
					<StatusUpdate
						isOpen={this.state.statusUpdates.isOpen}
						close={this.closeSidebar}
						id={this.state.statusUpdates.id}
						nestedRef={this.statusRef}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => this.handleNestedEntity(false)}
						nestedRef={this.nestedRef}
					/>
				</FormSidebar>
			</div>
		);
	}
}

const Topbar = ({ tabs, selectedTab, switchTab, isStickyOnTop, hiddenTabs = [] }) => {
	const index = tabs.indexOf(selectedTab);
	return (
		<InfiniteTopBar
			clickHandler={(e, i) => switchTab(tabs[i])}
			tabs={tabs}
			selected={index}
			isStickyOnTop={isStickyOnTop}
			hiddenTabs={hiddenTabs}
		/>
	);
};
