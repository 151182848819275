// third party
import moment from "moment";
import { PaymentVariant } from "@urbanpiper-engineering/horizon";

// store
import { store } from "../store/configureStore";

// utils
import { lS, getLoggedInBizId } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";
import { MODAL_INIT_STATE } from "../reducers/billingRecovery";

const handleBillingAlertModal = (resp = {}) => {
	const {
		data: { modal, due_date }
	} = resp;
	let billingAlert = lS.get("billing_alert") || {};
	const bizId = getLoggedInBizId();
	const dueDateDiff = moment(`${moment().format("YYYY-MM-DD")}T23:59:59`).diff(moment(due_date), "days");
	const milestone =
		dueDateDiff >= -30 && dueDateDiff <= -16
			? "-30"
			: dueDateDiff >= -15 && dueDateDiff <= -8
				? "-15"
				: dueDateDiff >= -7 && dueDateDiff <= -4
					? "-7"
					: dueDateDiff;

	if (modal?.show && modal?.header === billingAlert?.[bizId]?.header && billingAlert?.[bizId]?.dismissedAt) {
		const payload = {
			type: modal?.type,
			variant: modal?.type === "error" ? PaymentVariant.Overdue : PaymentVariant.Pending,
			header: modal?.header,
			body: modal?.message,
			milestone: `t${milestone > 0 ? `+${milestone}` : milestone}`,
			dueDateDiff
		};
		// update redux state
		store.dispatch({
			type: ActionTypes.UPDATE_BILLING_RECOVERY_MODAL_STATE,
			payload
		});
		// update local storage
		billingAlert = {
			...billingAlert,
			[bizId]: {
				...(billingAlert[bizId] ?? {}),
				...payload
			}
		};
		lS.set("billing_alert", billingAlert);
		return;
	}
	if (modal && !modal?.show) {
		// update redux state
		store.dispatch({ type: ActionTypes.UPDATE_BILLING_RECOVERY_MODAL_STATE, payload: { ...MODAL_INIT_STATE } });
		// update local storage
		delete billingAlert[bizId];
		lS.set("billing_alert", billingAlert);
		return;
	}
	if (modal && modal?.show) {
		const payload = {
			isOpen: modal?.show,
			type: modal?.type,
			variant: modal?.type === "error" ? PaymentVariant.Overdue : PaymentVariant.Pending,
			header: modal?.header,
			body: modal?.message,
			dueDateDiff,
			dismissedAt: ""
		};

		// update redux state
		store.dispatch({
			type: ActionTypes.UPDATE_BILLING_RECOVERY_MODAL_STATE,
			payload
		});
		// update local storage
		lS.set("billing_alert", {
			...billingAlert,
			[bizId]: payload
		});
		return;
	}
};

export const fetchBizBillingStatus = async () => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const token = store.getState().login.loginDetail.token;
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		};
		const res = await fetch(process.env.REACT_APP_API_URL_BIZ_BILLING, { headers });
		const resp = await res.json();
		if (resp.status === "success") {
			store.dispatch({
				type: ActionTypes.UPDATE_BILLING_RECOVERY_STATE,
				payload: resp
			});
			handleBillingAlertModal(resp);
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 2000,
					error: true
				}
			});
		}
		store.dispatch(toggleGlobalLoader(false));
		return resp;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
		store.dispatch(toggleGlobalLoader(false));
	}
};
