import React, { useState } from "react";

// third party
import { Link } from "react-router-dom";

// components
import CreateIcon from "../_commons/CreateIcon";
import { Button } from "../_commons/Button";
import HelpIcon from "../_commons/HelpIcon";

const Header = ({
	handlePiperAcademy,
	isAdmin = false,
	hideHeaderSectionRight = false,
	onboardingFlowEnabled = false
}) => {
	return (
		<div className="brands-list-header credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Brands</div>
				<div className="subtitle">All your brands listed</div>
			</div>
			{!hideHeaderSectionRight && (
				<div className="header-action-button">
					<div className="action-buttons-container">
						<Button clickHandler={handlePiperAcademy} type="secondary">
							<HelpIcon />
							<span>Help</span>
						</Button>
						{isAdmin && (
							<Link to={onboardingFlowEnabled ? "/onboarding/brands" : "/brands/new"}>
								<Button>
									<CreateIcon />
									<span>Create New</span>
								</Button>
							</Link>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
export default Header;
