import React from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";

export const Configurations = ({
	platform,
	options = [],
	handleForm,
	validations = {},
	hideTitle = false,
	selectedLocation = {},
	booleanActions = false,
	isReadOnlyEnabled = false,
	isInternalUser = false
}) => {
	return (
		<React.Fragment>
			{options.map((opt, i) => {
				if (opt.type === "choice") {
					return (
						<SelectFilter
							key={i}
							title={opt.shortDesc}
							options={opt.choices}
							field={opt.key}
							currValue={opt.value}
							setFilter={(field, value) => handleForm(field, value, platform)}
							labelKey="label"
							valueKey="value"
							validationMessage={validations[opt.key] || ""}
							readOnly={isReadOnlyEnabled && !opt.isEditable}
						/>
					);
				} else if (opt.type === "boolean" && booleanActions) {
					if ((opt.value === "" || opt.value === null) && selectedLocation?.id !== "default") {
						return (
							<div className="custom-switch">
								{!hideTitle && opt.shortDesc && (
									<div className={"meta-info"}>
										<span>{opt.shortDesc}</span>
									</div>
								)}
								<div className="default">Default</div>
								<div
									className="link-text hover Mt(10px)"
									onClick={() => handleForm(opt.key, false, platform)}
								>
									Update
								</div>
							</div>
						);
					} else {
						if (opt.key === "toyou_skip_manual_menu_verification" && !isInternalUser) {
							return null;
						} else {
							return (
								<div className="custom-switch">
									<Switch
										key={i}
										title={!hideTitle ? opt.shortDesc : ""}
										checked={String(opt.value) === "true"}
										clickHandler={() =>
											handleForm(opt.key, !(String(opt.value) === "true"), platform)
										}
										validationMessage={validations[opt.key] || ""}
										readOnly={isReadOnlyEnabled && !opt.isEditable}
									/>
									{selectedLocation?.id !== "default" && (
										<div
											className="link-text hover Mt(10px)"
											onClick={() => handleForm(opt.key, null, platform)}
										>
											Reset to Default
										</div>
									)}
								</div>
							);
						}
					}
				} else if (opt.type === "boolean") {
					return (
						<Switch
							key={i}
							title={!hideTitle ? opt.shortDesc : ""}
							checked={String(opt.value) === "true"}
							clickHandler={() => handleForm(opt.key, !(String(opt.value) === "true"), platform)}
							validationMessage={validations[opt.key] || ""}
							readOnly={isReadOnlyEnabled && !opt.isEditable}
						/>
					);
				} else if (opt.type === "decimal") {
					return (
						<InputWithLabel
							key={i}
							type="number"
							value={opt.value || ""}
							onChange={(e) =>
								handleForm(opt.key, e.target.value ? Number(e.target.value) : null, platform)
							}
							validationMessage={validations[opt.key] || ""}
							placeholder={opt.placeholder || ""}
							readOnly={isReadOnlyEnabled && !opt.isEditable}
						>
							{opt.shortDesc}
						</InputWithLabel>
					);
				} else if (opt.type === "char") {
					return (
						<InputWithLabel
							key={i}
							value={opt.value || ""}
							onChange={(e) => handleForm(opt.key, e.target.value, platform)}
							validationMessage={validations[opt.key] || ""}
							placeholder={opt.placeholder || ""}
							readOnly={isReadOnlyEnabled && !opt.isEditable}
						>
							{opt.shortDesc}
						</InputWithLabel>
					);
				} else if (opt.type === "int") {
					return (
						<InputWithLabel
							key={i}
							type="number"
							value={opt.value || ""}
							onChange={(e) =>
								handleForm(opt.key, e.target.value ? parseInt(e.target.value) : null, platform)
							}
							validationMessage={validations[opt.key] || ""}
							placeholder={opt.placeholder || ""}
							readOnly={isReadOnlyEnabled && !opt.isEditable}
						>
							{opt.shortDesc}
						</InputWithLabel>
					);
				}
			})}
		</React.Fragment>
	);
};
