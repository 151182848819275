import gql from "graphql-tag";

export const GET_ENTITY_MODIFIERS = gql`
	query entityOptionAssociations(
		$entityId: Int!
		$entityType: OptionAssociationEntityType!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$menuId: String
		$search: [SearchArgument]
	) {
		entityOptionAssociations(
			entityId: $entityId
			entityType: $entityType
			limit: $limit
			offset: $offset
			sort: $sort
			filters: $filters
			menuId: $menuId
			search: $search
		) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			count
			hasPrevious
			hasNext
			objects {
				id
				optionTitle
				optionPrice
				isAssociated
				crmTitle
				foodType
			}
		}
	}
`;

export const UPDATE_ENTITY_MODIFIERS = gql`
	mutation updateEntityModifiers(
		$entityId: Int!
		$entityType: OptionAssociationEntityType!
		$optionsToAssociate: [Int]
		$optionsToDisassociate: [Int]
		$associateAll: Boolean
		$disassociateAll: Boolean
	) {
		updateEntityOptions(
			input: {
				entityId: $entityId
				entityType: $entityType
				optionsToAssociate: $optionsToAssociate
				optionsToDisassociate: $optionsToDisassociate
				associateAll: $associateAll
				disassociateAll: $disassociateAll
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
