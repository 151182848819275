import React from "react";

const AutoRefresh = ({ style = {}, variations = {}, hideText = true }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
			<path
				d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12H20C20 16.411 16.411 20 12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C14.206 4 16.2027 4.90048 17.6504 6.34961L15 9H22V2L19.0605 4.93945C17.2518 3.12627 14.7573 2 12 2Z"
				fill="#AAAAAA"
				{...variations}
			/>
			{!hideText && (
				<path
					d="M11.038 12.128L10.716 13.248H13.054L12.732 12.128C12.5827 11.6427 12.438 11.1433 12.298 10.63C12.1673 10.1167 12.0367 9.608 11.906 9.104H11.85C11.7287 9.61733 11.598 10.1307 11.458 10.644C11.3273 11.148 11.1873 11.6427 11.038 12.128ZM7.972 17L10.954 7.844H12.858L15.84 17H14.132L13.418 14.522H10.338L9.624 17H7.972Z"
					fill="#AAAAAA"
					{...variations}
				/>
			)}
		</svg>
	);
};

export default AutoRefresh;
