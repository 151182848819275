// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_TAXES_LIST, GET_TAX, EDIT_TAX } from "../graphql/taxes";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";
import { getApplicableOn } from "../containers/TaxCreate";

// constants
import { APPLICABLE_ON_OPTIONS } from "../containers/TaxCreate";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchTaxesList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().taxesListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_TAXES_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (f === "applicable_on" && appliedFilters[f] !== null) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_active") {
					// this value is being reversed because the behaviour of
					// is_active filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: !appliedFilters[f]
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_active",
				value: true
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_TAXES_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_TAXES_LIST_SUCCESS,
			payload: { ...resp.data.taxes }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_TAXES_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseTaxData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			if (result["applicableOn"] === APPLICABLE_ON_OPTIONS[0].label) {
				result["applicableOn"] = APPLICABLE_ON_OPTIONS[0];
			} else if (result["applicableOn"].includes("derived_field.charge")) {
				result["applicableCharge"] = result["applicableOn"].split(".")[2];
				result["applicableOn"] = APPLICABLE_ON_OPTIONS[1];
			}
			break;
		case "server":
			if (result["applicableOn"]) {
				result["applicableOn"] = getApplicableOn(result["applicableOn"], result);
			}
			if (result["applicableCharge"]) {
				delete result["applicableCharge"];
			}
			if (result["merchant_ref_id"] === "") {
				result["merchant_ref_id"] = "-1";
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchTaxDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_TAX_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_TAX,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_TAX_DETAIL_SUCCESS,
			payload: parseTaxData(resp.data.tax, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_TAX_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Tax Rate details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editTax = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_TAX_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_TAX,
			variables: parseTaxData(data, "server")
		});
		if (resp.data.saveTax.status.success) {
			dispatch({
				type: ActionTypes.EDIT_TAX_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Tax Rate saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_TAXES_LIST,
				payload: resp.data.saveTax.object
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_TAX_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveTax.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_TAX_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Tax Rate details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreTax = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_TAX,
			variables: parseTaxData(data, "server")
		});
		if (resp.data.saveTax.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveTax.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};
