import React, { Component, useEffect, useImperativeHandle, useState } from "react";

import { connect } from "react-redux";

// components
import { FormContainer } from "./Meraki";
import { InfiniteTopBar } from "../components/SiteComp";
import General from "../components/BusinessProfile/General";
import Billing from "../components/BusinessProfile/Billing";

// third party
import Compressor from "compressorjs";
import { debounce } from "lodash";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// utils
import { scroll, formatDate, removeProp, extractInitials, getPrinterCompatibleImages } from "../atlas-utils";

// graphql
import { GET_LOCATIONS_LIST } from "../graphql/hub";
import { GET_CONFIGURATION_VALUES, GET_PAYMENT_GATEWAYS_LIST } from "../graphql/meraki";
import { UPLOAD_IMAGE } from "../graphql/misc";

// actions
import { fetchTranslation, updateTranslation, toggleGlobalLoader, fetchBrands } from "../actions/actions";
import { ActionTypes } from "../actions/_types";
import { updateGetAndSetBizInfo } from "../actions/updateBizInfo";
import {
	editConfigurationValues,
	editMerakiAppSettings,
	fetchMerakiAppSettings,
	parseMerakiData
} from "../actions/meraki";
import { Modal } from "../components/_commons/Modal";
import { CheckBox } from "../components/_commons/CheckBox";

// constant
const TRANSLATION_FIELDS_MAP = {
	name: "biz_name"
};
const BIZ_LEVEL_CONFIG_KEY_VALUES = [
	"business_info_config_enforce_opt_location",
	"business_info_config_multi_option",
	"gecoding_api_key",
	"fcm_server_key",
	"enable_auto_catalog_backup"
];

const ENABLE_SERVICES_KEY_VALUES = {
	multibrand: {
		booleanName: "isMultibrandEnabled",
		title: "Enable Multi-Brands?",
		warningMessage: "Enabling Multi-Brands can't be reverted in future.",
		entityName: "Multi-Brands"
	},
	moc: {
		booleanName: "isMenuOverCatalogueEnabled",
		title: "Enable Menu V2 ?",
		warningMessage: "Enabling Menu V2 can't be reverted in future.",
		entityName: "Menu V2"
	}
};

@connect((store) => ({
	biz: store.login.loggedInbizDetail,
	userInfo: store.login.loginDetail,
	supportedLanguages: store.login.loggedInbizDetail.supportedLanguages,
	dimensions: store.configItems.dimensions,
	merakiDetails: store.merakiDetails,
	brands: store.configItems.brands
}))
export class BusinessProfile extends Component {
	constructor(props) {
		super(props);
		this.headerRef = React.createRef();
		let { biz, supportedLanguages = [] } = this.props;
		this.state = {
			tabs: ["General", "Billing"],
			selectedTab: "General",
			isFormTouched: false,
			biz,
			bizForm: {
				addressLine1: biz.addressLine1,
				addressLine2: biz.addressLine2,
				city: biz.city,
				state: biz.state,
				country: biz.country,
				postalCode: biz.postalCode,
				contactPersonName: biz.contactPersonName,
				billingPhones: biz.billingPhones,
				billingEmails: biz.billingEmails,
				contactEmails: biz.contactEmails,
				bizWebsite: biz.bizWebsite,
				currency: biz.currency,
				timezone: biz.timezone,
				name: biz.name,
				id: biz.id,
				isInventoryIntegrationEnabled: biz.isInventoryIntegrationEnabled,
				bizIsdCode: biz.bizIsdCode,
				gstin: biz.gstin,
				tin: biz.tin,
				registeredBusinessName: biz.registeredBusinessName,
				fssaiLicenseNumber: biz.fssaiLicenseNumber,
				description: biz.description,
				seoDescription: biz.seoDescription,
				eisSupportEnabled: biz.eisSupportEnabled,
				isHubEnabled: biz.modulesEnabled.includes("Hub") ? true : false,
				isOrderingEnabled: biz.modulesEnabled.includes("Meraki") ? true : false,
				isPrimeEnabled: biz.modulesEnabled.includes("Prime") ? true : false,
				isCometEnabled: biz.modulesEnabled.includes("Comet") ? true : false,
				isEisEnabled: biz.modulesEnabled.includes("EIS") ? true : false,
				isPrismEnabled: biz.modulesEnabled.includes("PRISM") ? true : false,
				isReconEnabled: biz.isReconEnabled,
				isMultibrandEnabled: biz.isMultibrandEnabled,
				isPgServiceEnabled: biz.isPgServiceEnabled,
				isMenuOverCatalogueEnabled: biz.isMenuOverCatalogueEnabled,
				isBaseMenuValidationEnabled: biz.isBaseMenuValidationEnabled
			},
			locationsList: {
				isLoading: false,
				data: biz.isMultibrandEnabled ? [] : [{ id: "default", title: "Default" }]
			},
			currLocation: biz.isMultibrandEnabled ? null : { id: "default", title: "Default" },
			configurationValues: {},
			name: {
				lang: supportedLanguages.length ? supportedLanguages[0].value : "",
				value: "",
				showActionBtn: false
			},
			searchLocation: {
				key: "default",
				value: ""
			},
			paymentGatewaysList: [],
			selectedPaymentConfig: null,
			showPaymentGatewayContainer: false,
			isPaymentGatewayFormTouched: false,
			updatedPaymentGateways: {},
			isConfirmationModalOpen: false,
			enableServiceConfimationModalKey: "",
			confirmLoading: true,
			loading: false
		};
	}

	async componentDidMount() {
		// fetch configuration values
		await this.fetchConfigurationValues(this.state?.currLocation?.id, this.props?.brands?.selectedBrand);
		// fetch locations list
		await this.fetchLocationsList("", this.props?.brands?.selectedBrand);
		// check whether meraki settings are available
		const resp = await fetchMerakiAppSettings(
			this.state.currLocation?.id,
			false,
			this.props?.brands?.selectedBrand
		);
		if (resp) {
			await this.fetchPaymentGatewaysList(resp);
			this.setState({
				showPaymentGatewayContainer: true
			});
		} else {
			await this.fetchPaymentGatewaysList();
			this.setState({
				showPaymentGatewayContainer: false
			});
		}

		// fetch brands
		if (this.props?.biz?.isMultibrandEnabled) {
			await fetchBrands("");
		}
	}

	getBizLevelData = async (selectedBrand) => {
		await this.fetchConfigurationValues(null, selectedBrand);
		// check whether meraki settings are available
		const resp = await fetchMerakiAppSettings(null, false, selectedBrand);
		if (resp) {
			await this.fetchPaymentGatewaysList(resp);
			this.setState({
				showPaymentGatewayContainer: true
			});
		} else {
			await this.fetchPaymentGatewaysList();
			this.setState({
				showPaymentGatewayContainer: false
			});
		}
	};

	fetchLocationsList = async (searchQuery = "", brand) => {
		try {
			this.setState({ locationsList: { ...this.state.locationsList, isLoading: true } });
			const variables = {
				limit: 50,
				offset: 0,
				filters: [
					{
						field: "is_active",
						value: true
					}
				],
				sort: {
					field: "name",
					order: "ASC"
				},
				search: [
					{
						key: "default",
						value: searchQuery || ""
					}
				]
			};
			if (this.state?.bizForm?.isMultibrandEnabled) {
				variables.brand = brand?.id || this.props.brands.selectedBrand?.id || null;
			}
			let resp = await client.query({
				query: GET_LOCATIONS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});
			if (!this.state?.bizForm?.isMultibrandEnabled) {
				resp.data.stores.objects.unshift({ id: "default", title: "Default" });
			}
			this.setState({
				locationsList: {
					isLoading: false,
					data: resp.data.stores.objects
				}
			});
		} catch (error) {
			this.setState({ locationsList: { ...this.state.locationsList, isLoading: false } });
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	fetchPaymentGatewaysList = async (paymentGateway = null) => {
		this.setState({
			confirmLoading: this.state.paymentGatewaysList.length ? false : true
		});
		try {
			const resp = await client.query({
				query: GET_PAYMENT_GATEWAYS_LIST,
				fetchPolicy: "no-cache"
			});
			this.setState({
				paymentGatewaysList: [...resp.data.paymentGateways.filter((pg) => pg.forDropdown === true)]
			});
			if (paymentGateway) {
				const currentPgConfig = resp.data.paymentGateways.find(
					(pg) => pg.value?.toLowerCase() === paymentGateway?.toLowerCase()
				);
				this.setState({
					selectedPaymentConfig: currentPgConfig,
					showPaymentGatewayContainer: true
				});

				if (currentPgConfig) {
					this.setState({
						updatedPaymentGateways: {
							[currentPgConfig?.kvConfigName]: true
						}
					});
				}
			} else {
				this.setState({
					selectedPaymentConfig: null,
					showPaymentGatewayContainer: false
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		this.setState({
			confirmLoading: false
		});
	};

	fetchConfigurationValues = async (locationId = "default", brand) => {
		store.dispatch(toggleGlobalLoader(true));
		this.setState({ loading: true });
		try {
			let variables = {
				keys: [
					"business_info_config_enforce_opt_location",
					"business_info_config_multi_option",
					"enable_auto_catalog_backup",
					"dash_enabled",
					"dash_enable_auto_assign",
					"gecoding_api_key",
					"fcm_server_key",
					"enable_reconciliation"
				]
			};
			if (locationId !== "default") {
				variables.locationId = locationId;
				variables.keys = variables.keys.filter((k) => BIZ_LEVEL_CONFIG_KEY_VALUES.includes(k) === false);
			}

			variables.brandId = !brand || brand?.id === "all" ? null : parseInt(brand?.id);

			let resp = await client.query({
				query: GET_CONFIGURATION_VALUES,
				variables,
				fetchPolicy: "no-cache"
			});
			let configurationValues = {};
			resp.data.configurationValues.forEach((config) => {
				configurationValues[config.key] =
					config.value === "1" ? true : config.value === "0" ? false : config.value;
			});
			this.setState({
				configurationValues: {
					...this.state.configurationValues,
					...configurationValues
				}
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		this.setState({ loading: false });
		store.dispatch(toggleGlobalLoader(false));
	};

	switchTab = (selectedTab) => {
		this.setState({
			selectedTab
		});
		// scroll to the top
		if (this.tableRef) {
			scroll({ top: this.tableRef.offsetTop - 57, left: 0 });
		}
	};

	validateBizForm = (bizForm) => {
		let requiredFields = ["addressLine1", "addressLine2", "city", "country", "state", "postalCode"];
		let missingFields = requiredFields.filter((field) => {
			if (bizForm[field]) return false;
			else return true;
		});
		if (missingFields.length !== 0) {
			alert("Address fields cannot be empty ");
			return false;
		} else {
			return true;
		}
	};

	updateBizForm = (updatedBizForm, isFormTouched = true) => {
		this.setState({
			bizForm: {
				...this.state.bizForm,
				...updatedBizForm
			},
			isFormTouched: updatedBizForm?.isMultibrandEnabled ? false : isFormTouched
		});
	};

	updateConfigValues = (field, value) => {
		this.setState({
			configurationValues: {
				...this.state.configurationValues,
				[field]: value
			},
			isFormTouched: true
		});
	};

	handleCurrLocation = async (field, value) => {
		if (value !== null) {
			this.setState({ [field]: value, loading: true });
			const resp = await fetchMerakiAppSettings(value?.id, true, this.props?.brands?.selectedBrand);
			if (resp) {
				const currentPgConfig = this.state.paymentGatewaysList.find(
					(pg) => pg.value?.toLowerCase() === resp?.toLowerCase()
				);
				this.setState({
					selectedPaymentConfig: currentPgConfig,
					showPaymentGatewayContainer: true
				});
				if (currentPgConfig) {
					this.setState({
						updatedPaymentGateways: {
							[currentPgConfig?.kvConfigName]: true
						}
					});
				}
			} else {
				this.setState({
					selectedPaymentConfig: null,
					showPaymentGatewayContainer: false
				});
			}
			await this.fetchConfigurationValues(value?.id, this.props?.brands?.selectedBrand);
		}
	};

	saveBizForm = async () => {
		let configurations = [];
		Object.keys(this.state.configurationValues).forEach((config) =>
			configurations.push({
				key: config,
				value:
					typeof this.state.configurationValues[config] === "boolean"
						? this.state.configurationValues[config]
							? "1"
							: "0"
						: this.state.configurationValues[config]
			})
		);
		let variables = { configurations };
		if (
			(!this.props?.biz?.isMultibrandEnabled && this.state?.currLocation?.id !== "default") ||
			(this.props?.biz?.isMultibrandEnabled && this.state?.currLocation !== null)
		) {
			variables.locationId = this.state?.currLocation?.id || null;
			variables.configurations = variables.configurations.filter(
				(config) => BIZ_LEVEL_CONFIG_KEY_VALUES.includes(config.key) === false
			);
		}
		variables.brandId =
			!this.props.brands?.selectedBrand?.id || this.props.brands?.selectedBrand?.id === "all"
				? null
				: this.props.brands?.selectedBrand?.id;

		const {
			isFormTouched,
			isPaymentGatewayFormTouched,
			showPaymentGatewayContainer,
			selectedPaymentConfig,
			currLocation,
			updatedPaymentGateways
		} = this.state;
		const sanitisedData = removeProp(this.props?.merakiDetails?.appsData, "__typename");
		sanitisedData.brandId =
			!this.props.brands?.selectedBrand?.id || this.props.brands?.selectedBrand?.id === "all"
				? null
				: this.props.brands?.selectedBrand?.id;
		sanitisedData.paymentGateway = selectedPaymentConfig?.value ? selectedPaymentConfig.value?.toLowerCase() : null;

		const pgConfigs = [];

		(sanitisedData?.businessConfigurations ?? []).forEach((config) => {
			if (!sanitisedData.paymentGateway) {
				pgConfigs.push({
					gatewayName: config?.name,
					configData: [
						...config?.keys?.map((configKey) => ({
							key: configKey?.key,
							value: configKey.key === "is_pg_disabled" ? true : configKey?.value,
							type: configKey.type
						}))
					]
				});
			} else if (updatedPaymentGateways[config?.name]) {
				pgConfigs.push({
					gatewayName: config?.name,
					configData: [
						...config?.keys?.map((configKey) => ({
							key: configKey?.key,
							value: configKey?.value,
							type: configKey.type
						}))
					]
				});
			}
		});

		sanitisedData.pgServiceConfigurations = [...pgConfigs];

		if (isFormTouched && isPaymentGatewayFormTouched) {
			const saveConfigValues = await editConfigurationValues(variables);
			const resp1 = await updateGetAndSetBizInfo(this.state.bizForm);
			const resp2 = await editMerakiAppSettings(sanitisedData, currLocation?.id, true);
			if (resp1 && resp2) {
				this.setState({
					isFormTouched: false,
					isPaymentGatewayFormTouched: false
				});
			} else if (resp1) {
				this.setState({
					isFormTouched: false
				});
			} else if (resp2) {
				this.setState({
					isPaymentGatewayFormTouched: false
				});
			}
		} else if (isFormTouched) {
			const saveConfigValues = await editConfigurationValues(variables);
			const resp = await updateGetAndSetBizInfo(this.state.bizForm);
			if (resp) {
				this.setState({
					isFormTouched: false
				});
				const resp = await fetchMerakiAppSettings(this.state.currLocation?.id);
				if (resp) {
					await this.fetchPaymentGatewaysList(resp);
					this.setState({
						showPaymentGatewayContainer: true
					});
				} else {
					await this.fetchPaymentGatewaysList();
					this.setState({
						showPaymentGatewayContainer: false
					});
				}
			}
		} else if (isPaymentGatewayFormTouched) {
			const resp = await editMerakiAppSettings(sanitisedData, currLocation?.id, true);
			if (resp) {
				this.setState({
					isPaymentGatewayFormTouched: false
				});
				// refresh configs
				store.dispatch({
					type: ActionTypes.GET_MERAKI_SETTINGS_DETAIL_SUCCESS,
					payload: {
						appsData: parseMerakiData(resp.object, "form")
					}
				});
			}
		}
	};

	handleCancel = async () => {
		const { biz } = this.props;
		this.setState({
			bizForm: {
				...this.state.bizForm,
				name: biz.name,
				id: biz.id,
				isInventoryIntegrationEnabled: biz.isInventoryIntegrationEnabled,
				contactPersonName: biz.contactPersonName,
				billingEmails: biz.billingEmails,
				contactEmails: biz.contactEmails,
				billingPhones: biz.billingPhones,
				bizWebsite: biz.bizWebsite,
				timezone: biz.timezone,
				bizIsdCode: biz.bizIsdCode,
				currency: biz.currency,
				fssaiLicenseNumber: biz.fssaiLicenseNumber,
				registeredBusinessName: biz.registeredBusinessName,
				gstin: biz.gstin,
				tin: biz.tin,
				addressLine1: biz.addressLine1,
				addressLine2: biz.addressLine2,
				city: biz.city,
				state: biz.state,
				country: biz.country,
				postalCode: biz.postalCode,
				description: biz.description,
				seoDescription: biz.seoDescription,
				eisSupportEnabled: biz.eisSupportEnabled,
				isHubEnabled: biz.modulesEnabled.includes("Hub") ? true : false,
				isOrderingEnabled: biz.modulesEnabled.includes("Meraki") ? true : false,
				isPrimeEnabled: biz.modulesEnabled.includes("Prime") ? true : false,
				isCometEnabled: biz.modulesEnabled.includes("Comet") ? true : false,
				isEisEnabled: biz.modulesEnabled.includes("EIS") ? true : false,
				isPrismEnabled: biz.modulesEnabled.includes("PRISM") ? true : false
			},
			isFormTouched: false,
			isPaymentGatewayFormTouched: false
		});
		this.headerRef.current.resetImage();
		const resp = await fetchMerakiAppSettings(
			this.state.currLocation?.id,
			false,
			this.props?.brands?.selectedBrand
		);
		if (resp) {
			const currentPgConfig = this.state.paymentGatewaysList.find(
				(pg) => pg.value?.toLowerCase() === resp?.toLowerCase()
			);
			this.setState({
				selectedPaymentConfig: currentPgConfig,
				showPaymentGatewayContainer: true
			});
			if (currentPgConfig) {
				this.setState({
					updatedPaymentGateways: {
						[currentPgConfig?.kvConfigName]: true
					}
				});
			}
		} else {
			this.setState({
				selectedPaymentConfig: null,
				showPaymentGatewayContainer: false
			});
		}
		this.fetchConfigurationValues(this.state?.currLocation?.id, this.props?.brands?.selectedBrand);
	};

	handleLanguage = async (lang, field) => {
		const resp = await fetchTranslation(null, "BIZ", TRANSLATION_FIELDS_MAP[field], lang.value);
		if (resp) {
			this.setState({
				[field]: {
					lang: lang.value,
					value: resp.value,
					showActionBtn: false
				}
			});
		}
	};

	handleTranslation = (field, value) => {
		if (this.state[field].lang === "en") {
			this.setState({
				bizForm: {
					...this.state.bizForm,
					[field]: value
				},
				isFormTouched: true
			});
		} else {
			this.setState({
				[field]: {
					...this.state[field],
					value: value,
					showActionBtn: true
				}
			});
		}
	};

	saveTranslation = async (field, value) => {
		const resp = await updateTranslation(null, "BIZ", TRANSLATION_FIELDS_MAP[field], this.state[field].lang, value);
		if (resp && !resp.status) {
			store.dispatch({
				type: ActionTypes.UPDATE_LOGGEDIN_BIZ_FAILURE,
				error: resp.error
			});
		} else if (resp && resp.status) {
			setTimeout(() => {
				this.setState({
					[field]: {
						...this.state[field],
						showActionBtn: false
					}
				});
				store.dispatch({
					type: "RESET_TRANSLATION"
				});
			}, 3000);
		}
	};

	handleLocationSearch = debounce((searchQuery) => {
		if (searchQuery !== this.state.searchLocation.value) {
			this.setState({
				searchLocation: {
					...this.state.searchLocation,
					value: searchQuery
				}
			});
			this.fetchLocationsList(searchQuery);
		}
	}, 300);

	handlePaymentGatewayToggle = (state) => {
		this.setState({
			showPaymentGatewayContainer: state,
			isPaymentGatewayFormTouched: true
		});
	};

	handlePaymentConfig = (f, config) => {
		this.setState({
			selectedPaymentConfig: config,
			isPaymentGatewayFormTouched: true
		});
	};

	handleConfigForm = (field, value, platform) => {
		this.setState({
			updatedPaymentGateways: {
				// ...this.state.updatedPaymentGateways,
				[platform]: true
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_MERAKI_APPS_CONFIGURATIONS,
			payload: {
				platform: platform,
				field: field,
				value: value
			}
		});
		this.setState({
			isPaymentGatewayFormTouched: true
		});
	};

	handleConfirmationModalClose = () => {
		if (!ENABLE_SERVICES_KEY_VALUES[this.state.enableServiceConfimationModalKey]?.booleanName) {
			this.setState({
				isConfirmationModalOpen: false
			});
			return;
		}
		this.updateBizForm(
			{ [ENABLE_SERVICES_KEY_VALUES[this.state.enableServiceConfimationModalKey]?.booleanName]: false },
			false
		);
		this.setState({
			isConfirmationModalOpen: false,
			enableServiceConfimationModalKey: ""
		});
	};

	handleServiceEnable = () => {
		this.setState(
			{
				isConfirmationModalOpen: false,
				isFormTouched: true
			},
			() => {
				this.saveBizForm();
			}
		);
	};

	handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	handleBrand = async (brand) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_BRAND,
			payload: brand
		});
		this.setState({
			locationsList: {
				isLoading: false,
				data: []
			},
			currLocation: null
		});
		this.fetchLocationsList("", brand);
		this.getBizLevelData(brand);
	};

	renderEnableServiceConfirmationModal = (field, value, entityType) => {
		this.updateBizForm({ [field]: value });
		this.setState({
			isConfirmationModalOpen: true,
			enableServiceConfimationModalKey: entityType
		});
	};

	render() {
		const {
			tabs,
			selectedTab,
			name,
			bizForm,
			isFormTouched,
			configurationValues,
			locationsList,
			currLocation,
			showPaymentGatewayContainer,
			paymentGatewaysList,
			selectedPaymentConfig,
			isPaymentGatewayFormTouched,
			isConfirmationModalOpen,
			enableServiceConfimationModalKey,
			confirmLoading,
			loading
		} = this.state;

		const { biz, supportedLanguages = [], merakiDetails, brands } = this.props;
		const lockedFields = {
			tin: biz.tin,
			gstin: biz.gstin,
			addressLine1: biz.addressLine1,
			addressLine2: biz.addressLine2,
			city: biz.city,
			state: biz.state,
			country: biz.country,
			postalCode: biz.postalCode
		};
		return (
			<div>
				<div className="bizprofile-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
					<Header biz={biz} bizForm={bizForm} updateBizForm={this.updateBizForm} ref={this.headerRef} />
					<div className="profile-container">
						<FormContainer
							cancel={this.handleCancel}
							submit={this.saveBizForm}
							submitTitle="Save"
							hideActions={!isFormTouched && !isPaymentGatewayFormTouched}
						>
							<div className="form-content">
								<General
									biz={biz}
									bizForm={bizForm}
									loading={loading}
									configValues={configurationValues}
									updateBizForm={this.updateBizForm}
									handleConfigValues={this.updateConfigValues}
									locationsList={locationsList}
									currLocation={currLocation}
									handleLocationSearch={this.handleLocationSearch}
									handleCurrLocation={this.handleCurrLocation}
									showLanguages={true}
									currSelectedLang={name}
									handleLanguage={this.handleLanguage}
									handleTranslation={this.handleTranslation}
									saveTranslation={this.saveTranslation}
									handleBrandsLabelOption={this.handleBrandsLabelOption}
									brands={brands}
									handleBrand={this.handleBrand}
									renderEnableServiceConfirmationModal={this.renderEnableServiceConfirmationModal}
								/>
								<Billing
									bizForm={bizForm}
									configValues={configurationValues}
									updateBizForm={this.updateBizForm}
									handleConfigValues={this.updateConfigValues}
									lockedFields={lockedFields}
									currLocation={currLocation}
									locationsList={locationsList}
									handleLocationSearch={this.handleLocationSearch}
									handleCurrLocation={this.handleCurrLocation}
									merakiDetails={merakiDetails}
									showPaymentGatewayContainer={showPaymentGatewayContainer}
									selectedPaymentConfig={selectedPaymentConfig}
									paymentGatewaysList={paymentGatewaysList}
									handlePaymentGatewayToggle={this.handlePaymentGatewayToggle}
									handlePaymentConfig={this.handlePaymentConfig}
									handleConfigForm={this.handleConfigForm}
									bizName={this.props.biz.name}
									handleBrandsLabelOption={this.handleBrandsLabelOption}
									brands={brands}
									handleBrand={this.handleBrand}
									confirmLoading={confirmLoading}
								/>
							</div>
						</FormContainer>
					</div>
				</div>
				<EnableServiceConfirmationModal
					close={this.handleConfirmationModalClose}
					handleServiceEnable={this.handleServiceEnable}
					isOpen={isConfirmationModalOpen}
					enableServiceConfimationModalKey={enableServiceConfimationModalKey}
				/>
			</div>
		);
	}
}

const Header = React.forwardRef((props, ref) => {
	const { biz } = props;
	const modulesEnabled = biz.modulesEnabled || [];
	const { updateBizForm, bizForm } = props;
	let [bizLogo, setbizLogo] = useState(biz.logo && "https://" + biz.logo.split("://")[1]);
	let imageInput = null;
	let imageId = null;
	let openImageUploader = () => {
		imageInput.click();
	};
	let handleImageFile = async (e) => {
		let file = e.target.files[0];
		if (!file) {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Upload an image from your device",
					timeout: 3000,
					error: true
				}
			});
			return;
		}

		// get image formats to support printer compatibility
		const { base64Image, starLineImage, escPosImage } = await getPrinterCompatibleImages(file);
		if (!base64Image || !starLineImage || !escPosImage) {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: base64Image
						? "Something went wrong."
						: "Image size is too large, please keep it below 1 MB",
					timeout: 3000,
					error: true
				}
			});
			return;
		}

		if (file && file.size > 1047520) {
			const result = await new Promise((resolve, reject) => {
				new Compressor(file, {
					quality: 0.6,
					maxWidth: 4000,
					success: resolve,
					error: reject
				});
			});
			if (result && result.size < 1047520) {
				file = result;
			} else {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Image size is too large, please keep it below 1 MB",
						timeout: 3000,
						error: true
					}
				});
				return;
			}
		}

		try {
			store.dispatch(toggleGlobalLoader(true));
			let _variable = {
				image: file
			};
			// this is prevent uploading junk images.
			if (imageId) {
				_variable.id = imageId;
			}
			let resp = await client.mutate({
				mutation: UPLOAD_IMAGE,
				variables: _variable
			});
			store.dispatch(toggleGlobalLoader(false));
			let imageUrl = resp.data.uploadImage.image.replace("http://", "https://");
			let urlToBeSaved = imageUrl.match(/(gallery_images).*/g);
			setbizLogo(imageUrl);
			if (urlToBeSaved.length > 0) {
				updateBizForm({
					logo: urlToBeSaved[0],
					base64Image,
					starLineImage,
					escPosImage
				});
			}
		} catch (error) {
			store.dispatch(toggleGlobalLoader(false));
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Image upload failed. Try again.",
					timeout: 5000,
					error: true
				}
			});
		}
	};

	const resetImage = () => {
		setbizLogo(biz.logo && "https://" + biz.logo.split("://")[1]);
	};

	useImperativeHandle(
		ref,
		() => ({
			resetImage
		}),
		[resetImage]
	);

	return (
		<div className="bizprofile-header">
			<div className="header-details">
				{bizLogo ? (
					<div style={{ backgroundImage: `url(${bizLogo})` }} className="avatar">
						<input
							accept="image/*"
							className="D(n)"
							ref={(ref) => (imageInput = ref)}
							type="file"
							onChange={handleImageFile}
							onClick={() => {
								imageInput.value = null;
							}}
						/>
						<img
							src={`/assets/icons/icon-edit-light.svg`}
							className="avatar-edit"
							id="profilePic"
							onClick={openImageUploader}
							title="Edit"
						/>
					</div>
				) : (
					<div className="avatar-text">
						{biz.name[0]}
						<input
							accept="image/*"
							className="D(n)"
							ref={(ref) => (imageInput = ref)}
							type="file"
							onChange={handleImageFile}
							onClick={() => {
								imageInput.value = null;
							}}
						/>
						<img
							src={`/assets/icons/icon-edit-light.svg`}
							className="avatar-edit"
							id="profilePic"
							onClick={openImageUploader}
							title="Edit"
						/>
					</div>
				)}
				<div className="profile-details">
					<div className="header-text">{biz.name}</div>
					<div className="header-sub-text">Signed up since: {formatDate(biz.created, "DD MMM, YYYY")}</div>
					<div>
						{modulesEnabled.map((module, i) => {
							return <span key={i}>{module.toUpperCase()}</span>;
						})}
						{!biz.isActive && <span style={{ backgroundColor: "#ff425c" }}>DEACTIVATED</span>}
						{biz.isTest && <span style={{ backgroundColor: "#3e495f" }}>TEST</span>}
					</div>
				</div>
			</div>
		</div>
	);
});

const Topbar = ({ tabs, selectedTab, switchTab, isStickyOnTop }) => {
	let index = tabs.indexOf(selectedTab);
	return (
		<InfiniteTopBar
			clickHandler={(e, i) => switchTab(tabs[i])}
			tabs={tabs}
			selected={index}
			isStickyOnTop={isStickyOnTop}
		/>
	);
};

const EnableServiceConfirmationModal = ({
	close,
	isOpen,
	handleServiceEnable,
	enableServiceConfimationModalKey = ""
}) => {
	const [isChecked, setChecked] = useState(false);

	return (
		<Modal
			isOpen={isOpen}
			classes="business-profile-confirmation-modal"
			title={ENABLE_SERVICES_KEY_VALUES[enableServiceConfimationModalKey]?.title}
			submitTitle="Enable"
			showSubmitAction
			showCancelAction
			disabled={!isChecked}
			close={close}
			submitAction={handleServiceEnable}
			cancelTitle="Close"
		>
			<div className="action-details">
				<div className="enable-action-warning">
					{ENABLE_SERVICES_KEY_VALUES[enableServiceConfimationModalKey]?.warningMessage ??
						"Enabling this services cannot be disabled later"}
				</div>
				<div className="confirm-process">
					<CheckBox
						checked={isChecked}
						clickHandler={() => {
							setChecked((current) => !current);
						}}
					/>
					Proceed with enabling{" "}
					{ENABLE_SERVICES_KEY_VALUES[enableServiceConfimationModalKey]?.entityName ?? "this"} module for this
					business.
				</div>
			</div>
		</Modal>
	);
};
