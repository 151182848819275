import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import history from "../history";

// component
import Breadcrumbs from "../components/_commons/Breadcrumbs";
import { Button } from "../components/_commons/Button";
import { Topbar } from "../components/_commons/Topbar";
import MenuConfiguration from "../components/MenuEdit/MenuConfiguration";
import PreviewCart from "../components/MenuEdit/PreviewCart";
import CustomisableItemDrawer from "../components/MenuEdit/CustomisableItemDrawer";
import AddItemsToSection from "../components/MenuEdit/AddItemsToSection";
import ReOrderItems from "../components/MenuEdit/ReOrderItems";
import CreateSection from "../components/MenuEdit/CreateSection";
import CopyMenu from "../components/MenuEdit/CopyMenu";
import Placeholder from "../components/_commons/Placeholder";
import TestCart from "../components/MenuEdit/TestCart";
import PublishHistory from "../components/MenuEdit/PublishHistory";
import ItemEdit from "./ItemEdit";
import EntityDeleteConfirmationModal from "../components/MenuEdit/EntityDeleteConfirmationModal";
import MultiActionsButton from "../components/_commons/MultiActionsButton";
import MenuPublish from "../components/MenusList/MenuPublish";
import PublishedVersionRestoreModal from "../components/MenuEdit/PublishedVersionRestoreModal";
import CancelScheduledPublishModal from "../components/MenuEdit/CancelScheduledPublishModal";
import Image from "../components/_commons/Image";
import MenuRules from "../components/MenuEdit/MenuRules";
import MenuPreviewConfigurationSelectionDrawer from "../components/MenuEdit/MenuPreviewConfigurationSelectionDrawer";
import PendingChangesWarningModal from "../components/MenuEdit/PendingChangesWarningModal";

// actions
import {
	fetchLocationsList,
	fetchMenuDetails,
	fetchParentSectionsList,
	fetchParentSectionsListDebounced,
	fetchPlatformsList,
	getMenuPreview,
	handleDestructiveSave,
	getMenuPreviewValidationStatus,
	resetTestCart
} from "../actions/menus";
import { handleOnboardingFlowAction } from "../actions/actions";

// client
import { store } from "../store/configureStore";
import { clientMenu } from "../client-menu";
import { client } from "../client";

// graphql
import { UPDATE_MENU } from "../graphql/menus";
import { CREATE_CATEGORY } from "../graphql/categories";

// utils
import { capitaliseText, formatDate, humanizeString, trackEvent } from "../atlas-utils";

// services
import NotificationServices from "../services/NotificationService";

// types
import { ActionTypes } from "../actions/_types";
import FixErrorsDrawer from "../components/MenuEdit/FixErrorsDrawer";
import StatusAlertBox from "../components/_commons/StatusAlertBox";
import { useLocation } from "react-router-dom";
import VersionPreview from "../components/MenuEdit/VersionPreview";
import { TRACKING_EVENT_NAMES, ONBOARDING_FLOWS } from "../client-config";
import { DRAGGABLE_ENTITY_TYPES } from "../components/_commons/DraggableModifierAccordion";

const SECTION_CREATION_DATA_INIT_STATE = {
	name: "",
	description: "",
	image: null
};

const MenuEdit = ({
	match,
	menuDetailsState,
	parentSectionDetailsData,
	isSectionsLoading,
	selectedParentSection,
	publishHistoryListsData,
	menuPreviewPlatformsLocationsList,
	menuPublishValidationStatus,
	isFromDspIntegration = false,
	dspIntegration,
	isMultibrandEnabled = false,
	menuTestCart,
	flowData
}) => {
	const { menuDetailsData, isLoading, isGlobalLoading } = menuDetailsState;
	const { selectedPublishedVersion, selectedPublishedVersionDetails } = publishHistoryListsData;
	const { description, categories } = menuDetailsData;
	const menuTitle = menuDetailsData?.name || "";
	const [isCartPreview, setIsCartPreview] = useState(false);
	const [isCreateSectionOpen, setIsCreateSectionOpen] = useState(false);
	const [currClickedSection, setCurrClickedSection] = useState(undefined);
	const [isCopyMenuOpen, setIsCopyMenuOpen] = useState(false);
	const [copyItemsToCategoryId, setCopyItemsToCategoryId] = useState(null);
	const [isReorderMenuDrawerOpen, setIsReorderMenuDrawerOpen] = useState(false);
	const [isCustomisableItemDrawerOpen, setIsCustomisableItemDrawerOpen] = useState({ isOpen: false, itemId: null });
	const [isAddItemsDrawerOpen, setIsAddItemsDrawerOpen] = useState(false);
	const [isTestCartOpen, setIsTestCartOpen] = useState(false);
	const [sectionCreationData, setSectionCreationData] = useState(SECTION_CREATION_DATA_INIT_STATE);
	const [isSubSectionCreation, setIsSubSectionCreation] = useState(false);
	const [temporaryPersistedData, setTemporaryPersistedData] = useState();
	const [parentSectionSearchKey, setParentSectionSearchKey] = useState("");
	const [entityDeletionState, setEntityDeletionState] = useState();
	const [copyMenuAvailableMenusCount, setCopyMenuAvailableMenusCount] = useState(0);
	const [menuPublishStates, setMenuPublishStates] = useState({
		type: "instant",
		isOpen: false
	});
	const [restoreVersionModalState, setResoreVersionModalState] = useState({
		isOpen: false,
		menuVersion: undefined
	});
	const [cancelScheduledPublishModalState, setCancelScheduledPublishModalState] = useState({ isOpen: false });
	const [isPreviewMenuConfigSelectionDrawerOpen, setPreviewMenuConfigSelectionDrawerOpen] = useState(false);

	const [isFixErrorDrawerOpen, setIsFixErrorDrawerOpen] = useState(false);
	const [isEditSchedulePublishFlow, setIsEditSchedulePublishFlow] = useState(false);

	const location = useLocation();
	const isPOSmenuType = menuDetailsData?.metadata?.menuType === "POS_INTEGRATED";

	const isMenuLocked = menuDetailsData?.metadata?.lockStatus === "ACTIVE";

	const isOnboardingFlowEnabled = flowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;

	const SECTION_TABS = [
		{
			label: isMenuLocked || selectedPublishedVersion ? "Preview" : "Configuration",
			value: "config"
		},
		// {
		// 	label: 'Category Scheduling',
		// 	value: 'categoryScheduling',
		// },
		{
			label: "Rules",
			value: "rules"
		},
		{
			label: "Publish History",
			value: "history"
		}
	];
	const SECTION_TABS_VERSION = [
		{
			label: "Preview",
			value: "version"
		},
		{
			label: "Logs",
			value: "history"
		}
	];
	const [selectedTab, setSelectedTab] = useState(SECTION_TABS[0].value);

	const renderRestoreVersionModal = (isVisible = false, menuVersion, refresh = false) => {
		setResoreVersionModalState({
			isOpen: isVisible,
			menuVersion
		});

		if (refresh) {
		}
	};

	const renderCancelScheduledPublishModal = () => {
		setCancelScheduledPublishModalState({
			isOpen: true
		});
	};

	const updatedSelectedSection = (id) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_PARENT_SECTION,
			payload: id
		});
	};

	const showHideMenuPublishDrawer = (
		isVisible = false,
		type = "instant",
		respState = "",
		isEditSchedulePublish = false
	) => {
		setMenuPublishStates({
			isOpen: isVisible,
			type
		});
		setIsEditSchedulePublishFlow(isEditSchedulePublish);

		if (respState === "success") {
			fetchMenuDetails(menuId);
			fetchParentSectionsList(menuId, parentSectionSearchKey);
		}
	};

	const menuId = match?.params?.id || 0;
	const isEmptySection =
		parentSectionSearchKey === "" && !(parentSectionDetailsData?.objects ?? []).length && !isSectionsLoading;

	const switchToPublishHistoryListView = () => {
		store.dispatch({
			type: ActionTypes.UPDATE_PUBLISHED_VERSIONS_STATE,
			payload: {
				selectedPublishedVersion: null
			}
		});
		setSelectedTab("history");
	};

	const handleMenuListViewRedirection = () => {
		if (isMenuLocked) {
			return;
		}
		if (!!menuDetailsData.metadata?.pendingChangesCount) {
			store.dispatch({
				type: ActionTypes.MENU_PENDING_CHANGES_MODAL_STATE,
				payload: true
			});

			store.dispatch({
				type: ActionTypes.MENU_PENDING_CHANGES_TARGET_PATH_UPDATE,
				payload: "/menus"
			});
		}
	};

	const connectedLinks = [
		isFromDspIntegration
			? {
					to: `/hub/integrations/${match?.params?.dsp}/edit`,
					title: `${capitaliseText(match?.params?.dsp)} Integration`,
					onClick: isMenuLocked ? () => {} : handleMenuListViewRedirection
				}
			: {
					to: isMenuLocked
						? "/menus"
						: !!menuDetailsData.metadata?.pendingChangesCount
							? `/menus/edit/${menuId}`
							: "/menus",
					title: "Menus",
					onClick: isMenuLocked ? () => {} : handleMenuListViewRedirection
				}
	];

	const connectedLinksVersionHistoryDetails = [
		{
			to: isMenuLocked
				? "/menus"
				: !!menuDetailsData.metadata?.pendingChangesCount
					? `/menus/edit/${menuId}`
					: "/menus",
			title: "Menus",
			onClick: isMenuLocked ? () => {} : handleMenuListViewRedirection
		},
		{
			to: `/menus/edit/${menuId}`,
			onClick: switchToPublishHistoryListView,
			title: menuTitle
		}
	];

	const showHideMenuReorderDrawer = (isVisible, refresh = false) => {
		setIsReorderMenuDrawerOpen(isVisible);
		if (!isVisible && refresh) {
			fetchParentSectionsList(menuId, parentSectionSearchKey);
		}
	};

	const showHideTestCart = (isVisible) => {
		setIsTestCartOpen(isVisible);
	};

	const showHideCreateSection = (isVisible, isSubSectionCreation = false, id, refresh = false) => {
		if (!isVisible && refresh) {
			setIsCreateSectionOpen(isVisible);
			setTemporaryPersistedData();
			fetchMenuDetails(menuId);
			// fetchMenuDetailsMetaData(menuId)
			fetchParentSectionsList(menuId, parentSectionSearchKey);
			return;
		}
		setIsSubSectionCreation(isSubSectionCreation);
		setSectionCreationData(SECTION_CREATION_DATA_INIT_STATE);
		setIsCreateSectionOpen(isVisible);
		setTemporaryPersistedData({
			parentSectionInfo: parentSectionDetailsData?.objects?.find((parentSection) => parentSection?.id === id)
		});
	};

	const showCopyMenuSection = (categoryId = null) => {
		if (categoryId) {
			setCopyItemsToCategoryId(categoryId);
		}
		setIsCopyMenuOpen(true);
	};

	const hideCopyMenuSection = () => {
		setIsCopyMenuOpen(false);
		fetchMenuDetails(menuId);
		// fetchMenuDetailsMetaData(menuId)
		setParentSectionSearchKey("");
		fetchParentSectionsList(menuId, "");
		setCopyItemsToCategoryId(null);
	};

	const showHideAddItemsDrawer = (isVisible, tempData = null, refresh = false) => {
		setIsAddItemsDrawerOpen(isVisible);
		if (tempData) {
			setTemporaryPersistedData({
				addItemsTempData: {
					...tempData // Here data about category id and current item will be provided
				}
			});
		}
		if (refresh) {
			setTemporaryPersistedData(null);
			fetchMenuDetails(menuId);
			// fetchMenuDetailsMetaData(menuId)
			fetchParentSectionsList(menuId, parentSectionSearchKey);
		}
	};

	const showHideCartPreview = (isPreview) => {
		setPreviewMenuConfigSelectionDrawerOpen(true);
		// setIsCartPreview(isPreview)
	};

	const showHideCustomisableItemDrawer = (isOpen = false, itemId) => {
		if (!isOpen) {
			setIsCustomisableItemDrawerOpen({ isOpen, itemId: null });
			return;
		}
		setIsCustomisableItemDrawerOpen({ isOpen, itemId });
	};

	// category listing - START

	const handleParentSectionsSearch = (field, value) => {
		fetchParentSectionsListDebounced(menuId, value);
		setParentSectionSearchKey(value);
	};
	// category listing - END

	// item addition to a section - START
	const handleItemAdditionToSelectedSection = async (selectedItemIds) => {
		try {
			let indexOfParentSection = null;
			if (temporaryPersistedData?.addItemsTempData?.categoryId) {
				indexOfParentSection = menuDetailsData?.categories?.findIndex(
					(section) => section.id === temporaryPersistedData?.addItemsTempData?.categoryId
				);
			} else {
				NotificationServices.pushNotification({
					message: "Couldn't identify the selected section",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				return;
			}

			if (menuDetailsData?.categories?.[indexOfParentSection]?.subCategories.length !== 0) {
				NotificationServices.pushNotification({
					message: "Sections with subsections cannot hold items in them",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				return;
			}
			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: menuDetailsData.options.map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: ""
						}
					})),
					optionGroups: menuDetailsData.optionGroups,
					items: menuDetailsData.items,
					categories: menuDetailsData.categories
				}
			};
			menuUpdationVariables.menuData.categories = [
				...menuUpdationVariables.menuData.categories.map((category) => ({
					...category,
					overrides: {
						...category.overrides,
						image: ""
					}
				}))
			];
			menuUpdationVariables.menuData.categories[indexOfParentSection] = {
				...menuUpdationVariables.menuData.categories[indexOfParentSection],
				items: [...selectedItemIds]
			};
			/*TODO: Find the root cause, it is related to already selected items in the selectedItemIds
			 * 	For now, we are removing the duplicates items due to urgency
			 */
			menuUpdationVariables.menuData.items = uniqBy(
				[
					...menuUpdationVariables.menuData.items.map((item) => ({
						...item,
						overrides: {
							...item.overrides,
							image: ""
						}
					})),
					...selectedItemIds.map((item) => ({
						id: item,
						optionGroups: [],
						overrides: {
							name: null,
							description: null,
							image: "",
							price: null,
							isRecommended: null
						}
					}))
				],
				(item) => item.id
			);

			const addItemsToSectionResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (addItemsToSectionResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Added items successfully",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: addItemsToSectionResp?.data?.updateMenuV2
				});
				showHideAddItemsDrawer(false, null, true);
				trackEvent(TRACKING_EVENT_NAMES.NEW_ITEM_CREATION, {});
			}
		} catch (e) {
			console.log(e);
		}
	};
	// item addition to a section - END

	// section creation and updation functions -- START
	const handleSectionCreationForm = (field, value) => {
		setSectionCreationData((current) => ({
			...current,
			[field]: value
		}));
	};

	const handleSectionCreation = async () => {
		if (!temporaryPersistedData?.parentSectionInfo && isSubSectionCreation) {
			NotificationServices.pushNotification({
				message: "Failed to find parent section!",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});

			return;
		}
		try {
			const parentSectionInfo = temporaryPersistedData.parentSectionInfo;
			const variables = {
				name: sectionCreationData.name,
				description: sectionCreationData.description,
				sortOrder: 0,
				merchantRefId: "-1"
			};
			if (sectionCreationData.image) {
				variables.image = sectionCreationData.image;
			}

			// if (isSubSectionCreation) {
			// 	variables.parent = parseInt(parentSectionInfo.id);
			// }
			if (sectionCreationData.timingsGroup) {
				variables.timingsGroup = parseInt(sectionCreationData.timingsGroup.id);
			}
			const resp = await client.mutate({
				mutation: CREATE_CATEGORY,
				variables
			});
			let menuUpdationVariables = {};
			if (resp.data.saveCategory.status.success) {
				if (isSubSectionCreation && parentSectionInfo.id) {
					menuUpdationVariables = {
						id: menuId,
						menuData: {
							image: null,
							imageUrl: menuDetailsData?.imageUrl,
							name: menuDetailsData.name,
							description: menuDetailsData.description,
							brand: menuDetailsData.brand,
							options: menuDetailsData.options.map((option) => ({
								...option,
								overrides: {
									...option.overrides,
									image: ""
								}
							})),
							optionGroups: menuDetailsData.optionGroups,
							items: menuDetailsData.items.map((item) => ({
								...item,
								overrides: { ...item.overrides, image: "" }
							})),
							categories: [
								...(menuDetailsData?.categories.map((category) => ({
									...category,
									overrides: { ...category.overrides, image: "" }
								})) ?? []),
								{
									id: resp.data.saveCategory.object.id,
									items: [
										...(isSubSectionCreation &&
										!!temporaryPersistedData?.parentSectionInfo?.items.length
											? temporaryPersistedData?.parentSectionInfo?.items.map((item) =>
													String(item.id)
												)
											: [])
									],
									subCategories: [],
									overrides: {
										name: "",
										description: "",
										image: ""
									}
								}
							]
						}
					};
					menuUpdationVariables.menuData.categories = menuUpdationVariables.menuData.categories.map(
						(category) =>
							category?.id === parentSectionInfo?.id
								? {
										...category,
										items:
											isSubSectionCreation && !!parentSectionInfo?.items.length
												? []
												: [...parentSectionInfo?.items],
										subCategories: [
											...category?.subCategories,
											String(resp.data.saveCategory.object.id)
										]
									}
								: {
										...category
									}
					);
				} else {
					menuUpdationVariables = {
						id: menuId,
						menuData: {
							image: null,
							imageUrl: menuDetailsData?.imageUrl,
							name: menuDetailsData.name,
							description: menuDetailsData.description,
							brand: menuDetailsData.brand,
							options: menuDetailsData.options.map((option) => ({
								...option,
								overrides: {
									...option.overrides,
									image: ""
								}
							})),
							optionGroups: menuDetailsData.optionGroups,
							items: menuDetailsData.items.map((item) => ({
								...item,
								overrides: { ...item.overrides, image: "" }
							})),
							categories: [
								{
									id: resp.data.saveCategory.object.id,
									items: [],
									subCategories: [],
									overrides: {
										name: "",
										description: "",
										image: ""
									}
								},
								...(menuDetailsData?.categories.map((category) => ({
									...category,
									overrides: { ...category.overrides, image: "" }
								})) ?? [])
							]
						}
					};
				}

				const appendCategoryToMenuResp = await clientMenu.mutate({
					mutation: UPDATE_MENU,
					variables: menuUpdationVariables
				});

				if (appendCategoryToMenuResp?.data?.updateMenuV2) {
					NotificationServices.pushNotification({
						message: "Added section successfully!",
						timeout: 5000,
						type: "success",
						isClosable: true,
						theme: "dark"
					});
					const categoriesList = appendCategoryToMenuResp?.data?.updateMenuV2?.categories ?? [];
					if (isSubSectionCreation) {
						setCurrClickedSection(parentSectionInfo.id);
					} else {
						setCurrClickedSection(categoriesList[0]?.id);
					}
					store.dispatch({
						type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
						payload: appendCategoryToMenuResp?.data?.updateMenuV2
					});
					showHideCreateSection(false, false, null, true);
				} else {
					NotificationServices.pushNotification({
						message: "Failed to create section",
						timeout: 5000,
						type: "error",
						isClosable: true,
						theme: "dark"
					});
				}
			} else {
				NotificationServices.pushNotification({
					message: "Failed to add category on Atlas",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleDetailsEditSection = (id, isOpen = false, refresh = false) => {
		if (!isOpen) {
			setTemporaryPersistedData();
			return;
		}
		setTemporaryPersistedData({
			isEditSectionMode: true,
			editSectionId: id
		});
	};

	const handleItemDetailsClick = (itemId, menuItemInfo) => {
		setTemporaryPersistedData({
			itemId,
			menuItemInfo: { ...menuItemInfo, image: "" }
		});
	};

	const handleItemOverridesUpdate = async (itemInfo) => {
		try {
			const newOverrideEntities = {
				...itemInfo
			};
			delete newOverrideEntities.id;

			if (!newOverrideEntities?.image) {
				newOverrideEntities.image = null;
			}

			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: menuDetailsData.options.map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: null
						}
					})),
					optionGroups: menuDetailsData.optionGroups,
					items: menuDetailsData.items.map((item) =>
						item.id === itemInfo?.id
							? {
									...item,
									overrides: {
										...item.overrides,
										...newOverrideEntities
									}
								}
							: { ...item, overrides: { ...item.overrides, image: null } }
					),
					categories: menuDetailsData.categories.map((category) => ({
						...category,
						overrides: {
							...category.overrides,
							image: null
						}
					}))
				}
			};

			const itemOverridesUpdateResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (itemOverridesUpdateResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Updated item details successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				trackEvent(TRACKING_EVENT_NAMES.MENU_ITEMS_BASIC_INFO_UPDATE, {
					status: "success"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: itemOverridesUpdateResp?.data?.updateMenuV2
				});
				fetchParentSectionsList(menuId);
				setParentSectionSearchKey("");
				handleItemEditClose();
				return true;
			}
		} catch (e) {
			NotificationServices.pushNotification({
				message: "Failed to modify item details",
				timeout: 5000,
				type: "failure",
				isClosable: true,
				theme: "dark"
			});
			trackEvent(TRACKING_EVENT_NAMES.MENU_ITEMS_BASIC_INFO_UPDATE, {
				status: "failure"
			});
			console.log(e);
		}
	};

	const handleModifierSortOrderUpdate = async (reOrderedMenuEntities, entityId, entityType) => {
		const _menuDetailsData = cloneDeep(menuDetailsData);

		try {
			if (entityType === DRAGGABLE_ENTITY_TYPES.MODIFIER_GROUP) {
				const reOrderedMenuModifierGroupsIds = reOrderedMenuEntities.map((modifierGroup) => modifierGroup.id);
				_menuDetailsData.items.find((item) => item.id === String(entityId)).optionGroups =
					reOrderedMenuModifierGroupsIds;
			} else if (entityType === DRAGGABLE_ENTITY_TYPES.MODIFIER) {
				const reOrderedMenuModifiersIds = reOrderedMenuEntities.map((modifier) => modifier.id);
				_menuDetailsData.optionGroups.find((optionGroup) => optionGroup.id === String(entityId)).options =
					reOrderedMenuModifiersIds;
			} else if (entityType === DRAGGABLE_ENTITY_TYPES.NESTED_MODIFIER_GROUP) {
				const reOrderedMenuNestedModifierGroupsIds = reOrderedMenuEntities.map(
					(nestedModifierGroup) => nestedModifierGroup.id
				);
				_menuDetailsData.options.find((option) => option.id === String(entityId)).nestedOptionGroups =
					reOrderedMenuNestedModifierGroupsIds;
			}

			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: _menuDetailsData?.imageUrl,
					name: _menuDetailsData.name,
					description: _menuDetailsData.description,
					brand: _menuDetailsData.brand,
					options: _menuDetailsData.options.map((option) => {
						option.overrides.image = null;
						return option;
					}),
					optionGroups: _menuDetailsData.optionGroups,
					items: _menuDetailsData.items.map((item) => {
						item.overrides.image = null;
						return item;
					}),
					categories: _menuDetailsData.categories.map((category) => {
						category.overrides.image = null;
						return category;
					})
				}
			};

			const modifierSortOrderResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (modifierSortOrderResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: `Updated ${humanizeString(entityType)} sort order successfully!`,
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});

				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: modifierSortOrderResp?.data?.updateMenuV2
				});

				return true;
			}
		} catch (error) {
			console.log(error);
			NotificationServices.pushNotification({
				message: `Failed to update ${humanizeString(entityType)} sort order`,
				timeout: 5000,
				type: "failure",
				isClosable: true,
				theme: "dark"
			});
			return false;
		}
	};

	const handleEntityDissociation = async (entityId, parentEntityId, entityType = null) => {
		const _menuDetailsData = cloneDeep(menuDetailsData);

		try {
			if (entityType === DRAGGABLE_ENTITY_TYPES.MODIFIER_GROUP) {
				_menuDetailsData.items.forEach((item) => {
					if (item.id === String(parentEntityId)) {
						item.optionGroups = item.optionGroups.filter((optionGroup) => optionGroup !== String(entityId));
					}
				});
			} else if (entityType === DRAGGABLE_ENTITY_TYPES.MODIFIER) {
				_menuDetailsData.optionGroups.forEach((optionGroup) => {
					if (optionGroup.id === String(parentEntityId)) {
						optionGroup.options = optionGroup.options.filter((option) => option !== String(entityId));
					}
				});
			} else if (entityType === DRAGGABLE_ENTITY_TYPES.NESTED_MODIFIER_GROUP) {
				_menuDetailsData.options.forEach((option) => {
					if (option.id === String(parentEntityId)) {
						option.nestedOptionGroups = option.nestedOptionGroups.filter(
							(optionGroup) => optionGroup !== String(entityId)
						);
					}
				});
			}
			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: _menuDetailsData?.imageUrl,
					name: _menuDetailsData.name,
					description: _menuDetailsData.description,
					brand: _menuDetailsData.brand,
					options: _menuDetailsData.options.map((option) => {
						option.overrides.image = null;
						return option;
					}),
					optionGroups: _menuDetailsData.optionGroups,
					items: _menuDetailsData.items.map((item) => {
						item.overrides.image = null;
						return item;
					}),
					categories: _menuDetailsData.categories.map((category) => {
						category.overrides.image = null;
						return category;
					})
				}
			};

			const entityDissoiationResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (entityDissoiationResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: `Removed ${humanizeString(entityType)} successfully!`,
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});

				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: entityDissoiationResp?.data?.updateMenuV2
				});

				return true;
			}
		} catch (error) {
			console.log(error);
			NotificationServices.pushNotification({
				message: `Failed to remove ${humanizeString(entityType)} group`,
				timeout: 5000,
				type: "failure",
				isClosable: true,
				theme: "dark"
			});
			return false;
		}
	};

	const handleItemEditClose = () => {
		setTemporaryPersistedData();
	};

	const handleEntityDeletionForm = (data, reset = false) => {
		if (reset) {
			setEntityDeletionState();
			return;
		}
		setEntityDeletionState({
			...data,
			isOpen: true
		});
	};

	// fetch preview validation status
	const fetchPreviewValidationStatus = async (menuId, locationId, platform) => {
		if (menuId && locationId && platform) {
			const getValidationdata = (interval) => {
				setTimeout(async () => {
					const res = await getMenuPreviewValidationStatus(menuId, locationId, platform);
					if (res?.status === "SUCCESS" || res?.status === "FAILURE" || res?.status === "ERROR") {
						interval = 80;
					} else if (res?.status === "PENDING") {
						if (interval < 80) {
							getValidationdata(interval + 5);
						}
					}
				}, interval * 1000);
			};
			getValidationdata(0);
		}
	};
	const handleResetTestCart = async () => {
		if (menuTestCart?.data?.cartId) {
			await resetTestCart({ cartId: menuTestCart?.data?.cartId });
		}
	};

	const handleMenuPreviewLocationSelection = (field, value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_MENU_PREVIEW_LOCATION,
			payload: value
		});
		if (
			!isPreviewMenuConfigSelectionDrawerOpen &&
			menuPreviewPlatformsLocationsList?.selectedPlatform?.platformName
		) {
			getMenuPreview(
				menuId,
				value?.id,
				menuPreviewPlatformsLocationsList?.selectedPlatform?.platformName?.toLowerCase(),
				selectedPublishedVersion ? String(selectedPublishedVersion) : null
			);
		}
		if (isCartPreview) {
			fetchPreviewValidationStatus(
				menuId,
				value?.id,
				menuPreviewPlatformsLocationsList?.selectedPlatform?.platformName?.toLowerCase()
			);
			handleResetTestCart();
		}
	};

	const handleMenuPreviewPlatformSelection = (field, value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_MENU_PREVIEW_PLATFORM,
			payload: value
		});
		if (!isPreviewMenuConfigSelectionDrawerOpen && menuPreviewPlatformsLocationsList?.selectedLocation?.id) {
			getMenuPreview(
				menuId,
				menuPreviewPlatformsLocationsList?.selectedLocation?.id,
				value?.platformName?.toLowerCase(),
				selectedPublishedVersion ? String(selectedPublishedVersion) : null
			);
		}
		if (isCartPreview) {
			fetchPreviewValidationStatus(
				menuId,
				menuPreviewPlatformsLocationsList?.selectedLocation?.id,
				value?.platformName?.toLowerCase()
			);
			handleResetTestCart();
		}
	};

	const handleMenuPreview = () => {
		setPreviewMenuConfigSelectionDrawerOpen(false);
		if (selectedPublishedVersion) {
			if (
				menuPreviewPlatformsLocationsList?.selectedLocation?.id &&
				menuPreviewPlatformsLocationsList?.selectedPlatform?.platformName
			) {
				getMenuPreview(
					menuId,
					menuPreviewPlatformsLocationsList?.selectedLocation?.id,
					menuPreviewPlatformsLocationsList?.selectedPlatform?.platformName?.toLowerCase(),
					selectedPublishedVersion || selectedPublishedVersion === 0 ? String(selectedPublishedVersion) : null
				);
			}
		} else {
			setTimeout(() => {
				setIsCartPreview(true);
				if (
					menuPreviewPlatformsLocationsList?.selectedLocation?.id &&
					menuPreviewPlatformsLocationsList?.selectedPlatform?.platformName
				) {
					getMenuPreview(
						menuId,
						menuPreviewPlatformsLocationsList?.selectedLocation?.id,
						menuPreviewPlatformsLocationsList?.selectedPlatform?.platformName?.toLowerCase()
					);

					fetchPreviewValidationStatus(
						menuId,
						menuPreviewPlatformsLocationsList?.selectedLocation?.id,
						menuPreviewPlatformsLocationsList?.selectedPlatform?.platformName?.toLowerCase()
					);
				}
			}, 100);
		}
	};

	// section creation and updation function -- END

	// filtering out sub sections from main sections list
	let subCategories = {};
	(parentSectionDetailsData?.objects ?? []).forEach((parentCategory) => {
		parentCategory.subCategories.forEach((subCategory) => {
			subCategories[subCategory?.id] = true;
		});
	});

	const restructuredParentSections = (parentSectionDetailsData?.objects ?? []).filter(
		(section) => !subCategories[section?.id]
	);

	const selectedParentSectionData = parentSectionDetailsData?.objects?.find(
		(section) => section?.id === selectedParentSection
	);
	const handleVersionPreviewLoad = () => {
		setPreviewMenuConfigSelectionDrawerOpen(true);
	};
	const selectedTabComponentMap = {
		config: () => (
			<MenuConfiguration
				menuId={menuId}
				showHideCreateSection={showHideCreateSection}
				showHideMenuReorderDrawer={showHideMenuReorderDrawer}
				showHideAddItemsDrawer={showHideAddItemsDrawer}
				parentSections={parentSectionDetailsData?.objects}
				selectedSection={selectedParentSection}
				currClickedSection={currClickedSection}
				setCurrClickedSection={setCurrClickedSection}
				updatedSelectedSection={updatedSelectedSection}
				filteredParentSections={restructuredParentSections}
				parentSectionSearchKey={parentSectionSearchKey}
				handleParentSectionsSearch={handleParentSectionsSearch}
				handleItemDetailsClick={handleItemDetailsClick}
				handleEntityDeletionForm={handleEntityDeletionForm}
				handleDetailsEditSection={handleDetailsEditSection}
				selectedParentSectionData={selectedParentSectionData}
				isSectionsLoading={isSectionsLoading}
				metaData={menuDetailsData?.metadata}
				isMenuLocked={isMenuLocked || selectedPublishedVersion || isPOSmenuType}
				showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
				showCopyMenuSection={showCopyMenuSection}
				temporaryPersistedData={temporaryPersistedData}
				setTemporaryPersistedData={setTemporaryPersistedData}
				handleItemAdditionToSelectedSection={handleItemAdditionToSelectedSection}
			/>
		),
		history: () => (
			<PublishHistory
				menuId={menuId}
				renderRestoreVersionModal={renderRestoreVersionModal}
				showHideMenuPublishDrawer={showHideMenuPublishDrawer}
				metaData={menuDetailsData?.metadata}
				isOnboardingFlowEnabled={isOnboardingFlowEnabled}
			/>
		),
		rules: () => (
			<MenuRules
				menuId={menuId}
				brandId={menuDetailsData?.brand}
				isPOSmenuType={isPOSmenuType}
				parentSections={parentSectionDetailsData?.objects}
			/>
		),
		categoryScheduling: () => <div>categoryScheduling {selectedTab}</div>,
		version: () => (
			<VersionPreview
				showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
				handleVersionPreviewLoad={handleVersionPreviewLoad}
				handleMenuPreviewLocationSelection={handleMenuPreviewLocationSelection}
				handleMenuPreviewPlatformSelection={handleMenuPreviewPlatformSelection}
				selectedPublishedVersionDetails={selectedPublishedVersionDetails}
				setCurrClickedSection={setCurrClickedSection}
				currClickedSection={currClickedSection}
			/>
		)
	};

	const placeholderContent = {
		placeholderText: "Start building your section!",
		placeholderSubtext: "Create sections to add items in to your menu.",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
		placeholderButtonContent: <>+ New Menu Section</>,
		placeholderButtonClickAction: () => {
			const eventData = {
				type: "start_from_scratch_button"
			};
			trackEvent(TRACKING_EVENT_NAMES.NEW_SECTION_CREATION, eventData);
			showHideCreateSection(true);
		},
		// redirectionLink: "/piper-academy/items",
		// redirectionLinkText: "Learn more about Menus",
		size: "medium",
		secondaryButtonContent: copyMenuAvailableMenusCount ? <>Copy from Existing Menu</> : null,
		secondaryButtonClickAction: () => showCopyMenuSection()
	};
	useEffect(() => {
		fetchMenuDetails(menuId);
		// fetchMenuDetailsMetaData(menuId)
		fetchParentSectionsList(menuId);
	}, [menuId]);

	useEffect(() => {
		fetchParentSectionsList(
			menuId,
			"",
			selectedPublishedVersion || selectedPublishedVersion === 0 ? String(selectedPublishedVersion) : null
		);
	}, [selectedPublishedVersion]);

	useEffect(() => {
		return () => {
			switchToPublishHistoryListView();
		};
	}, []);

	useEffect(() => {
		if (!parentSectionDetailsData?.object?.length) {
			handleEntityDeletionForm(null, true);
		}
	}, [parentSectionDetailsData?.objects]);

	useEffect(() => {
		if (isPreviewMenuConfigSelectionDrawerOpen) {
			fetchLocationsList(0, "", menuDetailsData?.brand, menuId);
			fetchPlatformsList();
		}
	}, [isPreviewMenuConfigSelectionDrawerOpen]);

	// useEffect(() => {
	// 	if (location?.pathname?.includes("/config")) {
	// 		setSelectedTab(SECTION_TABS[0].value);
	// 	} else if (location?.pathname?.includes("/rules")) {
	// 		setSelectedTab(SECTION_TABS[1].value);
	// 	} else if (location?.pathname?.includes("/history")) {
	// 		setSelectedTab(SECTION_TABS[2].value);
	// 	}
	// }, [location]);

	if (isLoading && (parentSectionDetailsData?.objects ?? []).length === 0) {
		return (
			<div className="menu-edit-container section-container-common">
				<React.Fragment>
					<Breadcrumbs
						connectedLinks={selectedPublishedVersion ? connectedLinksVersionHistoryDetails : connectedLinks}
					/>
					<Header
						match={match}
						title={menuTitle}
						subTitle={description}
						showHideCartPreview={showHideCartPreview}
						isLoading={isLoading}
						parentSectionSearchKey={parentSectionSearchKey}
						renderRestoreVersionModal={renderRestoreVersionModal}
						renderCancelScheduledPublishModal={renderCancelScheduledPublishModal}
						isFromDspIntegration={isFromDspIntegration}
						dspIntegration={dspIntegration}
						isMultibrandEnabled={isMultibrandEnabled}
						isOnboardingFlowEnabled={isOnboardingFlowEnabled}
					/>
					<div className="P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				</React.Fragment>
			</div>
		);
	}

	if (isCartPreview) {
		return (
			<div className="menu-edit-container section-container-common">
				<PreviewCart
					showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
					showHideCartPreview={() => setIsCartPreview(false)}
					showHideTestCart={showHideTestCart}
					showHideMenuPublish={showHideMenuPublishDrawer}
					renderCancelScheduledPublishModal={renderCancelScheduledPublishModal}
					menuTitle={menuDetailsData?.name}
					locationsData={menuPreviewPlatformsLocationsList?.locationsData}
					handleMenuPreviewLocationSelection={handleMenuPreviewLocationSelection}
					handleMenuPreviewPlatformSelection={handleMenuPreviewPlatformSelection}
					platformsData={menuPreviewPlatformsLocationsList?.platformsData}
					handleItemOverridesUpdate={handleItemOverridesUpdate}
					menuId={menuId}
					fetchPreviewValidationStatus={fetchPreviewValidationStatus}
					selectedLocation={menuPreviewPlatformsLocationsList?.selectedLocation}
					selectedPlatform={menuPreviewPlatformsLocationsList?.selectedPlatform}
					filteredParentSections={restructuredParentSections}
					selectedSection={selectedParentSection}
					updatedSelectedSection={updatedSelectedSection}
					selectedParentSectionData={selectedParentSectionData}
					menuDetailsData={menuDetailsData}
					parentSectionDetailsData={parentSectionDetailsData}
					handleDetailsEditSection={handleDetailsEditSection}
					currClickedSection={currClickedSection}
					setCurrClickedSection={setCurrClickedSection}
				/>
				<MenuPublish
					isOpen={menuPublishStates?.isOpen}
					showHideMenuPublish={showHideMenuPublishDrawer}
					menuId={menuId}
					publishType={menuPublishStates?.type}
					brandId={menuDetailsData?.brand}
					handleItemOverridesUpdate={handleItemOverridesUpdate}
					handleDetailsEditSection={handleDetailsEditSection}
					menuDetailsData={menuDetailsData}
					parentSectionDetailsData={parentSectionDetailsData}
					isPOSmenuType={isPOSmenuType}
					isEditSchedulePublishFlow={isEditSchedulePublishFlow}
				/>
				{isCustomisableItemDrawerOpen?.isOpen && (
					<CustomisableItemDrawer
						isOpen={isCustomisableItemDrawerOpen?.isOpen}
						showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
						itemId={isCustomisableItemDrawerOpen?.itemId}
						menuId={menuId}
						selectedLocation={menuPreviewPlatformsLocationsList?.selectedLocation}
						selectedPlatform={menuPreviewPlatformsLocationsList?.selectedPlatform}
						isMenuLocked={isMenuLocked}
					/>
				)}
				{isTestCartOpen && (
					<TestCart
						isTestCartOpen={isTestCartOpen}
						showHideTestCart={showHideTestCart}
						menuId={menuId}
						selectedLocation={menuPreviewPlatformsLocationsList?.selectedLocation}
						selectedPlatform={menuPreviewPlatformsLocationsList?.selectedPlatform}
					/>
				)}
				<CancelScheduledPublishModal
					isOpen={cancelScheduledPublishModalState?.isOpen}
					setCancelScheduledPublishModalState={setCancelScheduledPublishModalState}
					menuId={menuId}
				/>
			</div>
		);
	}

	if (isEmptySection) {
		return (
			<div className="menu-edit-container section-container-common">
				<React.Fragment>
					<Breadcrumbs
						connectedLinks={selectedPublishedVersion ? connectedLinksVersionHistoryDetails : connectedLinks}
					/>
					<Header
						match={match}
						title={menuTitle}
						subTitle={description}
						showHideCartPreview={showHideCartPreview}
						showHideTestCart={showHideTestCart}
						isLoading={isLoading || isGlobalLoading}
						metadata={menuDetailsData?.metadata}
						menuId={menuId}
						parentSectionSearchKey={parentSectionSearchKey}
						isEmptySection
						selectedPublishedVersion={selectedPublishedVersion}
						renderRestoreVersionModal={renderRestoreVersionModal}
						scheduledPublishDetails={menuDetailsData?.scheduledPublishDetails}
						renderCancelScheduledPublishModal={renderCancelScheduledPublishModal}
						isMenuLocked={isMenuLocked}
						menuLockedBy={menuDetailsData?.metadata?.lockedBy}
						isFromDspIntegration={isFromDspIntegration}
						dspIntegration={dspIntegration}
						isMultibrandEnabled={isMultibrandEnabled}
						isOnboardingFlowEnabled={isOnboardingFlowEnabled}
					/>
					<Placeholder {...placeholderContent} />

					<CreateSection
						isOpen={isCreateSectionOpen}
						hideCreateSection={() => showHideCreateSection(false)}
						sectionCreationData={sectionCreationData}
						handleSectionCreationForm={handleSectionCreationForm}
						handleSectionCreation={handleSectionCreation}
					/>

					<CopyMenu
						isOpen={isCopyMenuOpen}
						hideCopyMenuSection={hideCopyMenuSection}
						menuId={menuId}
						brandId={menuDetailsData?.brand}
						menuDetailsData={menuDetailsData}
						setCopyMenuAvailableMenusCount={setCopyMenuAvailableMenusCount}
						copyItemsToCategoryId={copyItemsToCategoryId}
					/>
					<PublishedVersionRestoreModal
						isOpen={restoreVersionModalState?.isOpen}
						close={(refresh = false) => renderRestoreVersionModal(false, undefined, refresh)}
						pendingChangesCount={menuDetailsData?.metadata?.pendingChangesCount}
						menuId={menuId}
						version={restoreVersionModalState?.menuVersion}
						setSelectedTab={setSelectedTab}
						switchToPublishHistoryListView={switchToPublishHistoryListView}
					/>
					<PendingChangesWarningModal
						menuId={menuId}
						pendingChangesCount={menuDetailsData?.metadata?.pendingChangesCount}
						handleDestructiveSave={handleDestructiveSave}
						parentSectionSearchKey={parentSectionSearchKey}
					/>
				</React.Fragment>
			</div>
		);
	}

	if (isMenuLocked) {
		return (
			<div className="menu-edit-container section-container-common">
				<React.Fragment>
					<Breadcrumbs
						connectedLinks={selectedPublishedVersion ? connectedLinksVersionHistoryDetails : connectedLinks}
					/>
					<Header
						match={match}
						title={selectedPublishedVersion ? `Version ${selectedPublishedVersion}` : menuTitle}
						subTitle={selectedPublishedVersion ? "" : description}
						showHideCartPreview={showHideCartPreview}
						showHideTestCart={showHideTestCart}
						isLoading={isLoading || isGlobalLoading}
						metadata={menuDetailsData?.metadata}
						menuId={menuId}
						parentSectionSearchKey={parentSectionSearchKey}
						showHideMenuPublishDrawer={showHideMenuPublishDrawer}
						selectedPublishedVersion={selectedPublishedVersion}
						renderRestoreVersionModal={renderRestoreVersionModal}
						scheduledPublishDetails={menuDetailsData?.scheduledPublishDetails}
						renderCancelScheduledPublishModal={renderCancelScheduledPublishModal}
						isMenuLocked={isMenuLocked}
						menuLockedBy={menuDetailsData?.metadata?.lockedBy}
						isFromDspIntegration={isFromDspIntegration}
						dspIntegration={dspIntegration}
						isMultibrandEnabled={isMultibrandEnabled}
						isOnboardingFlowEnabled={isOnboardingFlowEnabled}
					/>
					{selectedTabComponentMap?.config()}
					<CustomisableItemDrawer
						isOpen={isCustomisableItemDrawerOpen?.isOpen}
						showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
						itemId={isCustomisableItemDrawerOpen?.itemId}
						menuId={menuId}
						selectedLocation={menuPreviewPlatformsLocationsList?.selectedLocation}
						selectedPlatform={menuPreviewPlatformsLocationsList?.selectedPlatform}
						isMenuLocked={isMenuLocked || isPOSmenuType}
					/>
				</React.Fragment>
			</div>
		);
	}

	return (
		<div className="menu-edit-container section-container-common">
			<React.Fragment>
				<Breadcrumbs
					connectedLinks={selectedPublishedVersion ? connectedLinksVersionHistoryDetails : connectedLinks}
				/>
				<Header
					match={match}
					title={selectedPublishedVersion ? `Version ${selectedPublishedVersion}` : menuTitle}
					subTitle={selectedPublishedVersion ? "" : description}
					showHideCartPreview={showHideCartPreview}
					showHideTestCart={showHideTestCart}
					isLoading={isLoading || isGlobalLoading}
					metadata={menuDetailsData?.metadata}
					menuId={menuId}
					parentSectionSearchKey={parentSectionSearchKey}
					showHideMenuPublishDrawer={showHideMenuPublishDrawer}
					selectedPublishedVersion={selectedPublishedVersion}
					renderRestoreVersionModal={renderRestoreVersionModal}
					scheduledPublishDetails={menuDetailsData?.scheduledPublishDetails}
					renderCancelScheduledPublishModal={renderCancelScheduledPublishModal}
					isFromDspIntegration={isFromDspIntegration}
					dspIntegration={dspIntegration}
					isMultibrandEnabled={isMultibrandEnabled}
					isOnboardingFlowEnabled={isOnboardingFlowEnabled}
				/>
				<Topbar
					tabs={selectedPublishedVersion ? SECTION_TABS_VERSION : SECTION_TABS}
					selectedTab={selectedTab}
					switchTab={(tab) => {
						// window.history.replaceState(
						// 	{},
						// 	"",
						// 	`/menus/edit/${menuId}/${tab.value}`
						// );
						if (tab.value === "rules") {
							trackEvent(TRACKING_EVENT_NAMES.MENU_RULES_TAB_VIEWS, {});
						}
						if (tab.value === "history") {
							trackEvent(TRACKING_EVENT_NAMES.MENU_PUBLISH_HISTORY_TAB_VIEWS, {});
						}
						setSelectedTab(tab.value);
					}}
					isStickyOnTop
					hiddenTabs={isFromDspIntegration ? ["rules", "history"] : []}
				/>
				{menuPublishValidationStatus?.menuId === menuId && (
					<StatusAlertBox
						totalWarnings={menuPublishValidationStatus?.totalWarnings}
						totalErrors={menuPublishValidationStatus?.totalErrors}
						handleFixErrorDrawerOpen={() => setIsFixErrorDrawerOpen(true)}
						status={menuPublishValidationStatus?.status}
						message={menuPublishValidationStatus?.menuPublishValidationStatusData?.message}
					/>
				)}
				{selectedTabComponentMap[selectedTab]()}
				{isFixErrorDrawerOpen && (
					<FixErrorsDrawer
						isOpen={isFixErrorDrawerOpen}
						menuTitle={menuDetailsData?.name}
						menuId={menuId}
						handleDrawerClose={() => setIsFixErrorDrawerOpen(false)}
						handleItemOverridesUpdate={handleItemOverridesUpdate}
						isFixPublishMenuFlow
						isFromMenuSection
						menuDetailsData={menuDetailsData}
						parentSectionDetailsData={parentSectionDetailsData}
						handleDetailsEditSection={handleDetailsEditSection}
						publishType={menuPublishStates?.type}
					/>
				)}
				{isAddItemsDrawerOpen && (
					<AddItemsToSection
						isAddItemsDrawerOpen={isAddItemsDrawerOpen}
						showHideAddItemsDrawer={showHideAddItemsDrawer}
						handleItemAdditionToSelectedSection={handleItemAdditionToSelectedSection}
						temporaryPersistedData={temporaryPersistedData}
						menuDetailsData={menuDetailsData}
						menuId={menuId}
						brand={menuDetailsData?.brand}
						categoryId={
							temporaryPersistedData?.addItemsTempData?.subCategoryId ||
							temporaryPersistedData?.addItemsTempData?.categoryId
						}
					/>
				)}

				<ReOrderItems
					isOpen={isReorderMenuDrawerOpen}
					closeReOrderItem={(refresh) => showHideMenuReorderDrawer(false, refresh)}
					restructuredParentSections={restructuredParentSections}
					parentSections={parentSectionDetailsData?.objects}
					menuDetailsData={menuDetailsData}
					menuId={menuId}
				/>
				<CreateSection
					isOpen={isCreateSectionOpen}
					hideCreateSection={() => showHideCreateSection(false)}
					sectionCreationData={sectionCreationData}
					handleSectionCreationForm={handleSectionCreationForm}
					handleSectionCreation={handleSectionCreation}
					isSubSectionCreation={isSubSectionCreation}
					selectedParentSectionData={parentSectionDetailsData?.objects?.find(
						(section) => section?.id === selectedParentSection
					)}
					isEditSectionMode={!!temporaryPersistedData?.isEditSectionMode}
					editSectionId={temporaryPersistedData?.editSectionId}
					sectionsData={parentSectionDetailsData?.objects ?? []}
					handleDetailsEditSection={handleDetailsEditSection}
					menuDetailsData={menuDetailsData}
					parentSectionInfo={temporaryPersistedData?.parentSectionInfo ?? {}}
				/>
				<CopyMenu
					isOpen={isCopyMenuOpen}
					hideCopyMenuSection={hideCopyMenuSection}
					menuId={menuId}
					brandId={menuDetailsData?.brand}
					menuDetailsData={menuDetailsData}
					setCopyMenuAvailableMenusCount={setCopyMenuAvailableMenusCount}
					copyItemsToCategoryId={copyItemsToCategoryId}
				/>
				{temporaryPersistedData?.itemId && (
					<ItemEdit
						isFromMenuSection
						id={temporaryPersistedData?.itemId}
						itemOverrides={temporaryPersistedData?.menuItemInfo}
						close={handleItemEditClose}
						handleItemOverridesUpdate={handleItemOverridesUpdate}
						menuBrandId={menuDetailsData?.brand}
						handleModifierSortOrderUpdate={handleModifierSortOrderUpdate}
						handleEntityDissociation={handleEntityDissociation}
						sectionId={
							temporaryPersistedData?.menuItemInfo?.subCategoryId ||
							temporaryPersistedData?.menuItemInfo?.categoryId
						}
					/>
				)}
				{entityDeletionState?.isOpen && (
					<EntityDeleteConfirmationModal
						menuDetailsData={menuDetailsData}
						menuId={menuId}
						isOpen={entityDeletionState?.isOpen}
						entityType={entityDeletionState?.type}
						close={() => handleEntityDeletionForm(null, true)}
						id={entityDeletionState?.id}
						deletionInfo={entityDeletionState}
					/>
				)}
				<MenuPublish
					isOpen={menuPublishStates?.isOpen}
					showHideMenuPublish={showHideMenuPublishDrawer}
					menuId={menuId}
					publishType={menuPublishStates?.type}
					brandId={menuDetailsData?.brand}
					handleItemOverridesUpdate={handleItemOverridesUpdate}
					handleDetailsEditSection={handleDetailsEditSection}
					menuDetailsData={menuDetailsData}
					parentSectionDetailsData={parentSectionDetailsData}
					isPOSmenuType={isPOSmenuType}
					isEditSchedulePublishFlow={isEditSchedulePublishFlow}
				/>
				<PublishedVersionRestoreModal
					isOpen={restoreVersionModalState?.isOpen}
					close={(refresh = false) => renderRestoreVersionModal(false, undefined, refresh)}
					pendingChangesCount={menuDetailsData?.metadata?.pendingChangesCount}
					version={restoreVersionModalState?.menuVersion}
					menuId={menuId}
					setSelectedTab={setSelectedTab}
					switchToPublishHistoryListView={switchToPublishHistoryListView}
				/>
				<MenuPreviewConfigurationSelectionDrawer
					isOpen={isPreviewMenuConfigSelectionDrawerOpen}
					menuId={menuId}
					brandId={menuDetailsData?.brand}
					locationsData={
						selectedTab === "version"
							? {
									...menuPreviewPlatformsLocationsList?.locationsData,
									objects: menuPreviewPlatformsLocationsList?.locationsData?.objects?.filter((loc) =>
										selectedPublishedVersionDetails?.associatedLocations?.includes(
											loc?.name?.toLowerCase()
										)
									)
								}
							: menuPreviewPlatformsLocationsList?.locationsData
					}
					platformsData={
						selectedTab === "version"
							? {
									...menuPreviewPlatformsLocationsList?.platformsData,
									objects: menuPreviewPlatformsLocationsList?.platformsData?.objects?.filter((plf) =>
										selectedPublishedVersionDetails?.associatedPlatforms?.includes(
											plf?.platformName?.toLowerCase()
										)
									)
								}
							: menuPreviewPlatformsLocationsList?.platformsData
					}
					isLocationsLoading={menuPreviewPlatformsLocationsList?.isLocationsLoading}
					isPlatformsLoading={menuPreviewPlatformsLocationsList?.isPlatformsLoading}
					handleMenuPreviewLocationSelection={handleMenuPreviewLocationSelection}
					handleMenuPreviewPlatformSelection={handleMenuPreviewPlatformSelection}
					closePreviewDrawer={() => setPreviewMenuConfigSelectionDrawerOpen(false)}
					selectedLocation={menuPreviewPlatformsLocationsList?.selectedLocation}
					selectedPlatform={menuPreviewPlatformsLocationsList?.selectedPlatform}
					handleMenuPreview={handleMenuPreview}
				/>
				<CancelScheduledPublishModal
					isOpen={cancelScheduledPublishModalState?.isOpen}
					setCancelScheduledPublishModalState={setCancelScheduledPublishModalState}
					menuId={menuId}
				/>
				<PendingChangesWarningModal
					menuId={menuId}
					pendingChangesCount={menuDetailsData?.metadata?.pendingChangesCount}
					handleDestructiveSave={handleDestructiveSave}
					parentSectionSearchKey={parentSectionSearchKey}
				/>
				<CustomisableItemDrawer
					isOpen={isCustomisableItemDrawerOpen?.isOpen}
					showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
					itemId={isCustomisableItemDrawerOpen?.itemId}
					menuId={menuId}
					selectedLocation={menuPreviewPlatformsLocationsList?.selectedLocation}
					selectedPlatform={menuPreviewPlatformsLocationsList?.selectedPlatform}
					isMenuLocked={isMenuLocked || isPOSmenuType}
				/>
			</React.Fragment>
		</div>
	);
};
const mapStateToProps = (store) => ({
	menuDetailsState: store.menuDetailsState,
	parentSectionDetailsData: store.parentSectionsState.parentSectionDetailsData,
	selectedParentSection: store.parentSectionsState.selectedParentSection,
	isSectionsLoading: store.parentSectionsState.isLoading,
	publishHistoryListsData: store.publishHistoryListsData,
	menuPreviewPlatformsLocationsList: store.menuPreviewPlatformsLocationsList,
	menuPublishValidationStatus: store.menuPublishValidationStatus,
	dspIntegration: store.dspIntegration,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	menuTestCart: store.menuTestCart,
	flowData: store.atlasOnboardingState.flowData
});
export default connect(mapStateToProps)(MenuEdit);

const Header = ({
	match,
	title = "",
	subTitle = "",
	showHideCartPreview,
	showHideTestCart,
	isLoading,
	metadata,
	menuId,
	parentSectionSearchKey = "",
	isEmptySection,
	showHideMenuPublishDrawer,
	selectedPublishedVersion,
	renderRestoreVersionModal,
	scheduledPublishDetails,
	renderCancelScheduledPublishModal,
	isMenuLocked = false,
	menuLockedBy = "",
	isFromDspIntegration = false,
	dspIntegration,
	isMultibrandEnabled = false,
	isOnboardingFlowEnabled = false
}) => {
	const defaultActions = [
		{
			title: "Publish Now",
			clickHandler: () => {
				if (metadata?.pendingChangesCount > 0) {
					handleDestructiveSave(menuId, "SAVE", parentSectionSearchKey);
					trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
						type: "publish_triggered",
						no_of_changes: metadata?.pendingChangesCount
					});
				}
				showHideMenuPublishDrawer(true, "instant", "");
			}
		},
		{
			title: "Schedule Publish",
			clickHandler: () => {
				if (metadata?.pendingChangesCount > 0) {
					handleDestructiveSave(menuId, "SAVE", parentSectionSearchKey);
					trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
						type: "publish_triggered",
						no_of_changes: metadata?.pendingChangesCount
					});
				}
				showHideMenuPublishDrawer(true, "scheduled", "");
			}
		}
	];

	const scheduledActions = [
		{
			title: "Edit Scheduled Details",
			clickHandler: () => {
				if (metadata?.pendingChangesCount > 0) {
					handleDestructiveSave(menuId, "SAVE", parentSectionSearchKey);
					trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
						type: "publish_triggered",
						no_of_changes: metadata?.pendingChangesCount
					});
				}
				showHideMenuPublishDrawer(true, "scheduled", "", true);
			}
		},
		{
			title: "Publish Now",
			clickHandler: () => {
				if (metadata?.pendingChangesCount > 0) {
					handleDestructiveSave(menuId, "SAVE", parentSectionSearchKey);
					trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
						type: "publish_triggered",
						no_of_changes: metadata?.pendingChangesCount
					});
				}
				showHideMenuPublishDrawer(true, "instant", "");
			}
		},
		{
			title: <span style={{ color: "#D64949" }}>Cancel Scheduled Publish</span>,
			clickHandler: renderCancelScheduledPublishModal
		}
	];

	const handleMenuAction = async (action = "SAVE", type = "button") => {
		await handleDestructiveSave(menuId, action, parentSectionSearchKey);
		trackEvent(TRACKING_EVENT_NAMES?.[action === "SAVE" ? "MENU_CHANGES_SAVED" : "MENU_CHANGES_DISCARDED"], {
			type,
			no_of_changes: metadata?.pendingChangesCount
		});

		// if user came from dsp integration workflow, associate the menu to dsp location (location id from match.params)
		if (isFromDspIntegration && action === "SAVE") {
			// find location from dsp locations list view
			const location = dspIntegration?.data?.objects?.find((loc) => loc?.id === match?.params?.locId);
			if (location?.id && location?.location) {
				const newAssociationUpdates = { ...dspIntegration?.associationUpdates };
				newAssociationUpdates[location?.id] = {
					...(newAssociationUpdates[location?.id] ?? {}),
					id: location?.id,
					externalId: location?.externalId,
					locationName: location?.locationName,
					platformName: location?.platformName,
					location: location?.location || null,
					isStoreMapped: location?.isStoreMapped,
					associatedMenu: { name: title, menuId: menuId }
				};
				// update the association in dsp integration state
				store.dispatch({
					type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
					payload: {
						selectedTab: isMultibrandEnabled ? 3 : 2,
						associationUpdates: newAssociationUpdates
					}
				});
			}
			// redirect back to dsp integration edit page to continue with menu association
			history.push(`/hub/integrations/${match?.params?.dsp}/edit`);
		}

		if (isOnboardingFlowEnabled && action === "SAVE") {
			// update menu step progress in self-onboarding flow after menu changes are saved,
			handleOnboardingFlowAction("menu_created");
		}
	};

	const actionsAvailable = scheduledPublishDetails?.scheduledAt ? scheduledActions : defaultActions;

	return isLoading ? (
		<div className="menu-edit-container--header">
			<div className="shimmer-container">
				<div className="shimmer shimmer-small"></div>
				<div className="shimmer"></div>
			</div>
			<div className={`header-actions-container ${isLoading ? "disabled" : ""}`}>
				{metadata?.pendingChangesCount && metadata?.lockStatus === "INACTIVE" && (
					<>
						<span className="pending-changes-count">
							{metadata?.pendingChangesCount || 0} pending changes
						</span>
						<Button type="secondary" clickHandler={() => handleMenuAction("DISCARD")}>
							Discard
						</Button>
						<Button clickHandler={() => handleMenuAction("SAVE")}>
							{isFromDspIntegration ? "Save & Continue Integration" : "Save Changes"}
						</Button>
					</>
				)}
			</div>
		</div>
	) : (
		<>
			<div className="menu-edit-container--header">
				<div className="header-text-container">
					<div className="title" title={title}>
						{title}
					</div>
					<div className="sub-title">{subTitle}</div>
				</div>
				<div className={`header-actions-container ${isLoading ? "disabled" : ""}`}>
					{selectedPublishedVersion ? (
						<Button
							type="secondary"
							clickHandler={() => renderRestoreVersionModal(true, selectedPublishedVersion)}
						>
							Restore Menu Configuration
						</Button>
					) : (
						!!metadata?.pendingChangesCount &&
						metadata?.lockStatus === "INACTIVE" && (
							<>
								<span className="pending-changes-count">
									{metadata?.pendingChangesCount || 0} pending changes
								</span>
								<Button type="secondary" clickHandler={() => handleMenuAction("DISCARD")}>
									Discard
								</Button>
								<Button clickHandler={() => handleMenuAction("SAVE")}>
									{isFromDspIntegration ? "Save & Continue Integration" : "Save Changes"}
								</Button>
							</>
						)
					)}
					{!isEmptySection &&
						!selectedPublishedVersion &&
						!isFromDspIntegration &&
						!isOnboardingFlowEnabled && (
							<MultiActionsButton
								title="Publish"
								instantAction={() => {
									if (metadata?.pendingChangesCount > 0) {
										handleMenuAction("SAVE", "publish_triggered");
									}
									showHideMenuPublishDrawer(true, "instant", "");
								}}
								actions={actionsAvailable}
							/>
						)}
					{!isEmptySection && !selectedPublishedVersion && !isFromDspIntegration && (
						<Button type="secondary" clickHandler={() => showHideCartPreview(true)}>
							Preview
						</Button>
					)}
				</div>
			</div>
			{isMenuLocked &&
				(scheduledPublishDetails?.scheduledAt ? (
					<div className="menu-edit-container--preview-text-container">
						<div className="preview-text-content">
							<Image src="/assets/icons/info.png" alt="info" className="info-icon" />
							<div className="info-text-container">
								<div className="info-text-header">
									Menu is scheduled to be published at{" "}
									<strong>{formatDate(scheduledPublishDetails?.scheduledAt)}</strong>. You cannot edit
									menu while publish is scheduled
								</div>
							</div>
						</div>
					</div>
				) : metadata?.publishStatus?.toLowerCase() === "publish in progress" ? (
					<div className="menu-edit-container--preview-text-container">
						<div className="preview-text-content">
							<Image src="/assets/icons/info.png" alt="info" className="info-icon" />
							<div className="info-text-container">
								<div className="info-text-header">
									Menu is currently being published. You cannot edit menu until publish is complete.
								</div>
								<div className="info-text-subheader">
									Please check the publish log for more details on ongoing publish request.
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="menu-edit-container--preview-text-container">
						<div className="preview-text-content">
							<Image src="/assets/icons/info.png" alt="info" className="info-icon" />
							<div className="info-text-container">
								<div className="info-text-header">
									<strong>{menuLockedBy ? capitaliseText(menuLockedBy) : "Unknown user"}</strong> is
									currently editing the menu. You are viewing the last published version of this menu.
								</div>
								<div className="info-text-subheader">
									Menu editing is restricted until your collaborator finishes editing. Please contact
									them for take over requests.
								</div>
							</div>
						</div>
					</div>
				))}
		</>
	);
};
