// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_LOCATION_GROUPS_LIST, GET_LOCATION_GROUP, EDIT_LOCATION_GROUP } from "../graphql/locationGroups";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchLocationGroupsList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().locationGroupsListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_LOCATION_GROUPS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (f === "brand_id" && appliedFilters[f]?.id && appliedFilters[f]?.value != "all") {
				filtersObject.push({
					field: f,
					value: appliedFilters[f].id
				});
			} else if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_LOCATION_GROUPS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_LOCATION_GROUPS_LIST_SUCCESS,
			payload: { ...resp.data.locationGroups }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_LOCATION_GROUPS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchLocationGroupDetail = async (id, fetchPolicy = "no-cache") => {
	store.dispatch({
		type: ActionTypes.GET_LOCATION_GROUPS_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_LOCATION_GROUP,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_LOCATION_GROUPS_DETAIL_SUCCESS,
			payload: resp.data.locationGroup
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_LOCATION_GROUPS_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Location Group details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editLocationGroup = async (data) => {
	store.dispatch({
		type: ActionTypes.EDIT_LOCATION_GROUPS_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_LOCATION_GROUP,
			variables: data
		});
		if (resp.data.saveLocationGroup.status.success) {
			store.dispatch({
				type: ActionTypes.EDIT_LOCATION_GROUPS_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Location Group saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_LOCATION_GROUPS_LIST,
				payload: resp.data.saveLocationGroup.object
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.EDIT_LOCATION_GROUPS_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveLocationGroup.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_LOCATION_GROUPS_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Location Group details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const deleteLocationGroup = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_LOCATION_GROUP,
			variables: data
		});
		if (resp.data.saveLocationGroup.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveLocationGroup.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};
