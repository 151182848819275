// clients
import { store } from "../store/configureStore";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// clients
import history from "../history";

// constants
const MERAKI_API_URL = process.env.REACT_APP_MERAKI_API_URL;

export const fetchMerakiWebWorkspace = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_MERAKI_WEB_WORKSPACE_REQUEST
	});
	try {
		const token = store.getState().login.loginDetail.token;
		const url = `${MERAKI_API_URL}/web/workspace`;
		const resp = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		if (resp.ok) {
			store.dispatch({
				type: ActionTypes.GET_MERAKI_WEB_WORKSPACE_SUCCESS,
				payload: data
			});
		} else {
			if (resp.status === 404) {
				history.push("/meraki-web/themes");
			} else if (resp.status === 401 || resp.status === 403) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: data.message || "Yout session is expired, please login again.",
						timeout: 5000,
						error: true
					}
				});
				history.push("/");
			}
			store.dispatch({
				type: ActionTypes.GET_MERAKI_WEB_WORKSPACE_FAILURE,
				error: {
					message: "There was an error while fetching Meraki workspace"
				}
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_MERAKI_WEB_WORKSPACE_FAILURE,
			error: {
				message: "There was an error while fetching Meraki workspace"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const createMerakiWebWorkspace = async (theme = "") => {
	// workspace creation only allowed for internal users
	// const loginDetail = store.getState().login.loginDetail;
	// const isInternalUser =
	// 	loginDetail?.email.includes("@urbanpiper.com") || false;
	// if (!isInternalUser) {
	// 	store.dispatch({
	// 		type: ActionTypes.SHOW_GLOBAL_MESSAGE,
	// 		payload: {
	// 			message: "Please contact support to enable this.",
	// 			timeout: 5000,
	// 			error: false,
	// 		},
	// 	});
	// 	return;
	// }

	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/workspace`;
		const biz = store.getState().login.loggedInbizDetail;
		const payload = {
			theme,
			bizName: biz.name,
			logoUrl: biz.logo,
			favicon: biz.logo,
			apiKey: biz.meraki.apiKey,
			username: biz.meraki.username
		};
		// const payload = {
		// 	theme,
		// 	bizName: biz.name,
		// 	logoUrl: 'https://res.cloudinary.com/dmwvws7yf/image/upload/v1623293527/pipercafe-logo_urdoq9.png' || biz.logo,
		// 	favicon: 'https://github.githubassets.com/favicons/favicon.png' || biz.logo,
		// 	apiKey: 'cc836bc5232265cc8c1c3d540c1f1052aa8adf91' || biz.meraki.apiKey,
		// 	username: 'biz_adm_clients_zBUwJCrZgyZZ' || biz.meraki.username,
		// };
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "post",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		if (resp.ok) {
			// history.push("/meraki-web");
		} else {
			const data = await resp.json();
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: data.message || "Something went wrong.",
					timeout: 5000,
					error: true
				}
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const publishMerakiWebWorkspace = async (payload = {}, theme) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		// Need to send email info to trigger an email for initial domain (UrbanPiper managed) creation.
		const emailInfo = {};
		const bizDetails = store.getState().login;
		const token = bizDetails?.loginDetail?.token;
		const toEmailAddresses = [];
		if (bizDetails?.loginDetail?.email) {
			toEmailAddresses.push(bizDetails.loginDetail.email);
		}
		if (bizDetails?.loggedInbizDetail?.contactEmails) {
			toEmailAddresses.push(...bizDetails.loggedInbizDetail.contactEmails.split(","));
		}
		if (bizDetails?.loggedInbizDetail?.billingEmails) {
			toEmailAddresses.push(...bizDetails.loggedInbizDetail.billingEmails.split(","));
		}
		emailInfo.to = toEmailAddresses;
		payload.emailInfo = emailInfo;

		const url = `${MERAKI_API_URL}/web/workspace/publish?theme=${theme}`;
		const resp = await fetch(url, {
			method: "post",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		if (resp.ok) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: data.message,
					timeout: 2000,
					error: false
				}
			});
		} else {
			let message = "";
			if (data.subdomain) {
				message = "Domain must be unique";
			} else {
				if (data.message.includes("body.changelog")) {
					message = data.message.replace("body.changelog", "Change log");
				} else {
					message = data.message || "Something went wrong.";
				}
			}
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: message,
					timeout: 2000,
					error: true
				}
			});
		}
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchMerakiWebThemesList = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_MERAKI_WEB_THEMES_LIST_REQUEST
	});
	try {
		const url = `${MERAKI_API_URL}/web/themes`;
		const token = store.getState().login.loginDetail.token;
		let data = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		data = await data.json();
		store.dispatch({
			type: ActionTypes.GET_MERAKI_WEB_THEMES_LIST_SUCCESS,
			payload: data
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_MERAKI_WEB_THEMES_LIST_FAILURE,
			error: {
				message: "There was an error while fetching Meraki web themes"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchMerakiWebThemeDetails = async (name = "") => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_MERAKI_WEB_THEME_DETAILS_REQUEST
	});
	try {
		const url = `${MERAKI_API_URL}/web/themes/${name}`;
		const token = store.getState().login.loginDetail.token;
		let data = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		data = await data.json();
		store.dispatch({
			type: ActionTypes.GET_MERAKI_WEB_THEME_DETAILS_SUCCESS,
			payload: data
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_MERAKI_WEB_THEME_DETAILS_FAILURE,
			error: {
				message: "There was an error while fetching Theme details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchMerakiWebPagesList = async (theme) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/pages?theme=${theme}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const editMerakiWebPage = async (pageType, page, theme) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/pages/${pageType}/${page._id}?theme=${theme}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "put",
			body: JSON.stringify(page),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Page saved!",
				timeout: 2000,
				error: false
			}
		});
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
		throw error;
	}
};

export const editMerakiWebPageV2 = async (pageType, page) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/pagesV2/${pageType}/${page._id}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "put",
			body: JSON.stringify(page),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Page saved!",
				timeout: 2000,
				error: false
			}
		});
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
		throw error;
	}
};

export const createMerakiWebPage = async (page) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/pages/custom`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "post",
			body: JSON.stringify(page),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Custom page created!",
				timeout: 2000,
				error: false
			}
		});
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
		throw error;
	}
};

export const fetchMerakiWebVersions = async (params = {}) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/versions?${new URLSearchParams(params)}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const fetchMerakiWebVersionData = async (version) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/versions/${version}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const restoreMerakiWebVersion = async (versionNumber) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/versions/${versionNumber}/restore`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "post",
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: data.message || "Something went wrong, please try again!",
				timeout: 2000,
				error: false
			}
		});
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const fetchMerakiWebLabels = async (type = "default", lang = "en") => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/labels/${type}?lang=${lang}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const fetchMerakiWebConfig = async (themeName = "") => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/config?theme=${themeName}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const editMerakiWebConfig = async (payload) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/config`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "put",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Configurations saved!",
				timeout: 2000,
				error: false
			}
		});
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const editMerakiWebLabels = async (payload) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/labels/custom`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "post",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		if (resp.ok) {
			store.dispatch(toggleGlobalLoader(false));
			return data;
		}
		throw data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const addMerakiWebWorkspaceDomain = async (payload) => {
	try {
		const url = `${MERAKI_API_URL}/web/workspace/domains`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "post",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		if (resp.ok) {
			const data = await resp.json();
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Domain added",
					timeout: 2000,
					error: false
				}
			});
			return data;
		} else {
			const data = await resp.json();
			throw data;
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
		throw error;
	}
};

export const fetchMerakiWebWorkspaceDomain = async (domain) => {
	try {
		const url = `${MERAKI_API_URL}/web/workspace/domains/${domain.url}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "get",
			headers: {
				Authorization: token
			}
		});
		if (resp.ok) {
			const data = await resp.json();
			return data;
		} else {
			const data = await resp.json();
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: data.message || "Something went wrong",
					timeout: 5000,
					error: true
				}
			});
			throw data;
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const deleteMerakiWebWorkspaceDomain = async (domain) => {
	try {
		const url = `${MERAKI_API_URL}/web/workspace/domains/${domain.url}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "delete",
			headers: {
				Authorization: token
			}
		});
		if (resp.ok) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Domain deleted",
					timeout: 2000,
					error: false
				}
			});
		} else {
			const data = await resp.json();
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: data.message || "Something went wrong",
					timeout: 5000,
					error: true
				}
			});
			throw data;
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchMerakiWebApps = async () => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/apps`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const editMerakiWebApps = async (payload) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/apps`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "put",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const fetchMerakiWebSettings = async () => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/settings`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const editMerakiWebSettings = async (payload) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/web/settings`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "put",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const fetchMerakiWebSubscriptionDetails = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_REQUEST
	});
	try {
		const url = `${MERAKI_API_URL}/subscription`;
		const token = store.getState().login.loginDetail.token;
		let data = await fetch(url, {
			headers: {
				Authorization: token
			}
		});
		data = await data.json();
		store.dispatch({
			type: ActionTypes.GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_SUCCESS,
			payload: data
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_FAILURE,
			error: {
				message: "There was an error while fetching Subscription details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const createMerakiWebSubscription = async (plan, term) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/subscription/new/${plan}/${term}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "post",
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const fetchMerakiWebSubscriptionCustomerPortal = async () => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/subscription/customer-portal`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "post",
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const createAnnouncements = async (payload) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/announcements/`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "post",
			body: payload,
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		console.log(error);
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const checkBeforeCreateAnnouncements = async (payload) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/announcements/validate`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "post",
			body: payload,
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const fetchAnnouncements = async () => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/announcements/`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: token,
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Something went wrong.",
				timeout: 2000,
				error: true
			}
		});
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};

export const putAnnouncement = async (payload) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/announcements/${payload._id}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "put",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		});
		const data = await resp.json();

		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		console.log(error);
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};
export const deleteAnnouncements = async (id) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = `${MERAKI_API_URL}/announcements/${id}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			method: "delete",
			headers: {
				Authorization: token
			}
		});
		const data = await resp.json();
		store.dispatch(toggleGlobalLoader(false));
		return data;
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		throw error;
	}
};
