import React, { useEffect, useState } from "react";
import { scroll, trackEvent } from "../../../atlas-utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UpdateIcon from "../../../components/_icons/UpdateIcon";
import GlobeIcon from "../../../components/_icons/GlobeIcon";
import ClockIcon from "../../../components/_icons/ClockIcon";
import LocationIcon from "../../../components/_icons/LocationIcon";
import { saveConfigurationValues } from "../../../actions/periscope";
import { connect } from "react-redux";
import FreeTrial from "../../../components/_commons/FreeTrial";
import { store } from "../../../store/configureStore";
import { ActionTypes } from "../../../actions/_types";
import { fetchBizPlatforms } from "../../../actions/actions";

const PeriscopeFreeTrial = ({ configurationValues, configItems }) => {
	const freeTrial =
		(configurationValues || []).filter((value) => {
			return value.key === "periscope_enabled";
		})?.[0]?.value == "1"
			? true
			: false;
	const history = useHistory();
	const handleBack = () => {
		history.push("/periscope/early-access");
	};

	const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
	const saveConfiguration = async () => {
		setSubmitBtnLoading(true);
		try {
			const success = await saveConfigurationValues({
				configurations: [
					{
						key: "periscope_enabled",
						value: "1"
					}
				]
			});
			if (success) {
				trackEvent("periscope_free_trial_activated");
			}
		} catch (err) {
		} finally {
			setSubmitBtnLoading(false);
		}
	};

	const handleSelect = () => {
		if (!freeTrial) {
			saveConfiguration();
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
				payload: {
					dataSelected: {},
					locations: {},
					locationsPlatformMapping: [],
					offset: 0,
					isCheckedAll: false
				}
			});
			history.push({
				pathname: "/periscope/early-access/free-trial/select-location",
				state: { path: "/periscope/early-access/free-trial" }
			});
		}
	};

	useEffect(() => {
		scroll();
		(configItems?.bizPlatforms?.items || []).length == 0 && fetchBizPlatforms(true, true, true);
	}, []);
	return (
		<div className="periscope-container section-container-common">
			<FreeTrial
				heading={!freeTrial ? "Get free trial" : "Free trial started"}
				successIcon={freeTrial}
				submitTitle={!freeTrial ? "Confirm free trial" : "Select Locations"}
				submitBtn
				submitClass={"submit-class"}
				cancelBtn={!freeTrial}
				submitBtnLoading={submitBtnLoading}
				handleSubmit={handleSelect}
				handleCancel={handleBack}
				mainHeader="Periscope"
				infoText={freeTrial ? "" : "Enjoy uninterrupted access to the free trial until notified otherwise."}
			>
				<div className="plans">
					<div className="svg">
						<LocationIcon />
					</div>
					<div className="text">
						Upto <span className="text-bold">5 locations</span>
					</div>
				</div>
				<div className="plans">
					<div className="svg">
						<UpdateIcon />
					</div>
					<div className="text">
						Updates <span className="text-bold">every 30 min</span>
					</div>
				</div>
				<div className="plans">
					<div className="svg">
						<GlobeIcon />
					</div>
					<div className="text">
						Tracking includes <span className="text-bold">Zomato and Swiggy</span>
					</div>
				</div>
				<div className="plans">
					<ClockIcon />
					<div className="text">
						Data retention for <span className="text-bold">7 days </span>
					</div>
				</div>
			</FreeTrial>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configurationValues: state?.periscopeEarlyAccessData?.configurationValues,
	configItems: state?.configItems,
	periscopeEarlyAccessData: state?.periscopeEarlyAccessData
});
export default connect(mapStateToProps)(PeriscopeFreeTrial);
