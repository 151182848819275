import React, { useEffect, useState } from "react";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { CheckBox } from "../_commons/CheckBox";
import { InfiniteLoader } from "../SiteComp";
import Placeholder from "../_commons/Placeholder";
import { FormSidebar } from "../_commons/FormSidebar";
import { requestMenuPull } from "../../actions/menuPull";
import { GET_STORES_LIST } from "../../graphql/misc";
import { client } from "../../client";
import { debounce } from "lodash";

const ImportMenuDrawer = ({
	isOpen,
	handleClose = () => {},
	isMultibrandEnabled,
	brands,
	handleBrandsLabelOption,
	ongoingMenuPull,
	pos,
	canPullLocationMenu
}) => {
	const brandItemsWithoutAll = brands?.items?.filter?.((e) => e.id !== "all");
	const [selectedBrand, setSelectedBrand] = useState(
		brands?.selectedBrand?.id === "all" ? brandItemsWithoutAll?.[0] : brands?.selectedBrand
	);
	const [locationsList, setLocationsList] = useState({
		isLoading: false,
		objects: []
	});
	const [isImporting, setIsImporting] = useState(false);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [importAllLocations, setImportAllLocation] = useState(false);
	const [clearCatalogue, setClearCatalogue] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleBrandSelection = (field, value) => {
		setSelectedBrand(value);
	};

	const handleLocation = (field, value) => {
		const uniqueLocations = [...new Set(value)];
		setSelectedLocations(uniqueLocations);
	};
	const handleImportAllLocations = () => {
		setImportAllLocation(!importAllLocations);
	};
	const handleClearCatalogue = () => {
		setClearCatalogue(!clearCatalogue);
	};
	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			await requestMenuPull({
				locationIds: selectedLocations.map((location) => location.id),
				brandId: isMultibrandEnabled ? selectedBrand?.id : null,
				pos: pos?.id,
				clearCatalogue,
				allLocations: importAllLocations
			});

			setIsImporting(true);
		} catch (e) {
			console.log(e);
		}
		setIsLoading(false);
	};

	const fetchLocations = async (searchText, brandId = null) => {
		if (searchText !== undefined) {
			setLocationsList({
				...locationsList,
				isLoading: true
			});
			try {
				const variables = {
					limit: 50,
					sort: {
						field: "name",
						order: "ASC"
					}
				};
				if (brandId) {
					variables.brand = brandId;
				} else {
					variables.brand = null;
				}
				let filters = [
					{
						field: "is_active",
						value: true
					}
				];
				if (searchText) {
					filters.push({ field: "name", value: searchText });
				}
				variables.filters = filters;
				const resp = await client.query({
					query: GET_STORES_LIST,
					variables
				});
				setLocationsList({
					isLoading: false,
					objects: resp.data.stores.objects
				});
				return resp.data.stores.objects;
			} catch (error) {
				console.log(error);
				setLocationsList({
					...locationsList,
					isLoading: false
				});
			}
		}
	};

	const fetchLocationsDebounced = debounce((searchText, brandId) => fetchLocations(searchText, brandId), 300);

	useEffect(() => {
		fetchLocations("", isMultibrandEnabled ? selectedBrand?.id || null : null);
	}, [selectedBrand]);

	const FilterView = () => {
		return (
			<div className="import-menu-pos-wrapper">
				<div className="pos-menu-content">
					We see that <b>{pos?.name}</b> is connected to your business. Before proceeding, please choose{" "}
					{isMultibrandEnabled ? "brand and" : "the"} locations for which you want to import the menu.
				</div>
				{/* <p className="pos-name">{pos?.name}</p> */}
				<div className="menu-import-filters">
					{isMultibrandEnabled && (
						<>
							<SelectFilterCustom
								options={brandItemsWithoutAll}
								isLoading={brands.isLoading}
								field="brands"
								currValue={selectedBrand}
								setFilter={handleBrandSelection}
								labelKey={"name"}
								valueKey={"id"}
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
								title={"Brands"}
							/>
						</>
					)}
					{canPullLocationMenu && (
						<SelectFilter
							options={locationsList.objects || []}
							currValue={selectedLocations}
							setFilter={handleLocation}
							field={"location"}
							isClearable={true}
							title={"Locations"}
							isLoading={locationsList?.isLoading}
							multi={true}
							placeholder="Select Locations"
							isSearchable={true}
							handleSearch={(search) => fetchLocationsDebounced(search, selectedBrand?.id || null)}
							labelKey="name"
							valueKey="id"
							classes={importAllLocations ? "disabled" : ""}
						/>
					)}
				</div>
				<div className="checkbox-wrapper">
					{selectedLocations?.length > 0 && (
						<CheckBox checked={importAllLocations} clickHandler={handleImportAllLocations}>
							<span>Import for all locations</span>
						</CheckBox>
					)}
					<CheckBox checked={clearCatalogue} clickHandler={handleClearCatalogue}>
						<span>Clear existing catalogue</span>
					</CheckBox>
				</div>
				{clearCatalogue && (
					<div className="catalogue-alert">
						<img src={"/assets/icons/error-red-icon.svg"} />
						<p>
							This will archive your existing catalogue and replace it with the one imported from your POS
						</p>
					</div>
				)}
			</div>
		);
	};

	const getMenuImportIcon = (status) => {
		switch (status) {
			case "success":
				return "/assets/icons/success-green-icon.svg";
			case "failure":
				return "/assets/icons/error-red-icon.svg";
			default:
				return "/assets/icons/info.png";
		}
	};

	const getMenuImportText = (status) => {
		switch (status) {
			case "success":
				return "Menu Import Completed, You can close the drawer.";
			case "failure":
				return "Menu Import Failed, Please try again later.";
			default:
				return "This may take a minute. You can close this drawer and return later.";
		}
	};

	const PosMenuImportInProgress = ({ status }) => {
		return (
			<div className="pos-menu-import-inprogress-wrapper">
				{status == "inprogress" ? <InfiniteLoader /> : <></>}
				<div className="pos-menu-content">
					<div>
						<Placeholder
							placeholderImageUrl={"/assets/empty-states/verification-in-progress-state-2.svg"}
							placeholderText={"Importing Menu from POS"}
							placeholderSubtext={`We are importing your menu present on your POS Systems
						and syncing with Atlas`}
						/>
					</div>
					<div className="import-notice">
						<img src={getMenuImportIcon(status)} />
						<p>{getMenuImportText(status)}</p>
					</div>
				</div>
			</div>
		);
	};
	const getDisabledState = () => {
		if ((isMultibrandEnabled && selectedBrand?.id) || !isMultibrandEnabled) {
			if ((importAllLocations || selectedLocations.length > 0) && canPullLocationMenu) {
				return false;
			}
		}
		if (!isMultibrandEnabled && !canPullLocationMenu) {
			return false;
		}
		return true;
	};
	useEffect(() => {
		getDisabledState();
	}, [selectedBrand, importAllLocations, selectedLocations]);
	return (
		<FormSidebar
			close={handleClose}
			isOpen={isOpen}
			overlaySidebarClasses="medium"
			title="Import Menu from POS"
			subTitle="Menu will be imported from your connected POS system"
			submitTitle="Import"
			submit={handleSubmit}
			hideSubmitAction={isImporting}
			loading={isLoading}
			cancelTitle={isImporting ? "Close" : "Cancel"}
			disabled={getDisabledState()}
		>
			{isImporting ? <PosMenuImportInProgress status={ongoingMenuPull?.status} /> : <FilterView />}
		</FormSidebar>
	);
};

export default ImportMenuDrawer;
