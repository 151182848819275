import React, { Component, useState } from "react";

// third party
import { connect } from "react-redux";
import { Link, matchPath } from "react-router-dom";
import { Transition, config, animated } from "react-spring/renderprops";
import { HorizonBanner, HorizonText } from "@urbanpiper-engineering/horizon";
import moment from "moment";
import PubSub from "pubsub-js";

// clients
import { store } from "../store/configureStore";
import { client } from "../client";
import { closeOnboardingWsLink } from "../client-onboarding";
import history from "../history";

// utils
import {
	DEVELOPER_TOOLS_ENABLED_PRODUCTION_BIZ_IDS,
	getRandFlatColor,
	ATLAS_ONBOARDING_STEPS,
	ONBOARDING_FLOWS
} from "../client-config";
import { printCurrency, lS, formatDate, commifyNumbers, openFreshchatWidget, trackEvent } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// components
import { DownArrow } from "../components/_commons/DownArrow";
import { Button } from "./_commons/Button";
import CopyIcon from "./_commons/CopyIcon";
import { ButtonIcon } from "./_commons/ButtonIcon";
import { Modal } from "./_commons/Modal";
import Notifications from "../containers/Notifications";
import ProgressBar from "@urbanpiper-engineering/horizon/dist/atoms/progressbar";
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";

// actions
import { handleAuthUserLogout } from "../actions/actions";
import { getEncodedAnalyticsFilters } from "../actions/analytics";
import { unsubscribeToFlowSteps } from "../actions/onboarding";

// constants
import { TRIGGER_SURVEY, RESET } from "../atlas-utils/tracking";
const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;
const ULE_LOGIN_URL = process.env.REACT_APP_ULE_LOGIN;
const ULE_LOGOUT_URL = process.env.REACT_APP_ULE_LOGOUT;
const ULE_SWITCH_APP_URL = process.env.REACT_APP_ULE_SWITCH_APP;

// exports
export { printCurrency };

export const logout = async (authUser = null, loggedInUserId = null) => {
	if (authUser?.tokenId && loggedInUserId) {
		const variables = {
			appUserIds: [parseInt(loggedInUserId)],
			tokenId: authUser?.tokenId
		};
		const resp = await handleAuthUserLogout(variables);
		if (resp?.status?.success) {
			lS.remove("auth");
			lS.remove("brand");

			// onboarding
			unsubscribeToFlowSteps();

			await client.cache.reset();
			closeOnboardingWsLink();

			// close websocket connection
			if (window?.webSocket) {
				window.webSocket.forceClose = true;
				window.webSocket.close();
			}
			PubSub.publish(TRIGGER_SURVEY, {
				action: "logoutSurvey"
			});
			store.dispatch({
				type: "USER_LOGOUT"
			});
			window.location = ULE_LOGOUT_URL;
		}
	} else {
		lS.remove("auth");
		lS.remove("brand");

		// onboarding
		unsubscribeToFlowSteps();

		await client.cache.reset();
		closeOnboardingWsLink();

		// close websocket connection
		if (window?.webSocket) {
			window.webSocket.forceClose = true;
			window.webSocket.close();
		}
		PubSub.publish(TRIGGER_SURVEY, {
			action: "logoutSurvey"
		});
		store.dispatch({
			type: "USER_LOGOUT"
		});
		history.push("/login");
	}
	const eventName = "logout";
	trackEvent(eventName);
	PubSub.publish(RESET);
};

export const toggleSidebar = (toggle) => {
	store.dispatch({
		type: "TOGGLE_SIDE_BAR",
		payload: toggle
	});
};

export const SiteHeader = ({ login, location, sidebarState, verificationList = {}, timer, dimensions, biz }) => {
	const handleTab = (status = "") => {
		if (status && status !== "passing") {
			store.dispatch({
				type: "CATALOGUE_VERIFICATION_LIST_SEARCH",
				payload: { selectedTab: status }
			});
			store.dispatch({
				type: "CATALOGUE_VERIFICATION_LIST_STATE_CHANGE",
				payload: {
					currentFilters: {
						error_status: status
					},
					appliedFilters: {
						error_status: status
					},
					offset: 0
				}
			});
		}
		setTimeout(() => {
			history.push("/verification");
		}, 100);
	};
	return (
		<SiteHeaderContainer>
			<div className="header-section-container">
				<div className="header-section header-section--left">
					<div className="minimize-side-nav" onClick={() => toggleSidebar(!sidebarState.active)}>
						<img
							src={
								"/assets/header-icons/icon-menu-" +
								(!sidebarState.active ? "expand" : "collapsed") +
								".svg"
							}
						/>
					</div>
					{timer && dimensions.width >= 1050 && (
						<div className="message-container">
							{/* <div className="message">
								<img src="/assets/icons/alert.svg" alt="" />
								<div>Message</div>
							</div> */}
							{/* <div className="timer">
								<div>
									<div className="value">{timer.days}</div>
									<div className="unit">Days</div>
								</div>
								<div>
									<div className="value">{timer.hours}</div>
									<div className="unit">Hours</div>
								</div>
								<div>
									<div className="value">{timer.minutes}</div>
									<div className="unit">Minutes</div>
								</div>
								<div>
									<div className="value">{timer.seconds}</div>
									<div className="unit">Seconds</div>
								</div>
							</div> */}
						</div>
					)}
				</div>
				<div className="header-section header-section--right">
					<div className="meraki-changelog">
						<div className="icon-changelog">
							<img src="/assets/icons/icon-changelog.svg" alt="" />
						</div>
					</div>
					<Notifications />
					{/* {
						!biz.modulesEnabled.includes('PRISM') &&
						<div
							className={"header-section-icon " + (location.pathname.indexOf('/verification') > -1 ? "selected " : "") + (verificationList.data.status ? verificationList.data.status : "")}
							onClick={() => handleTab(verificationList.data.status)}
						>
							<img
								title="Aggregators have a set of rules for the menu catalogue, failing which can result in a publish failure or a broken UI/UX on the aggregator platform. View and fix verification errors and warnings here"
								src={"/assets/header-icons/icon-verification" + (location.pathname.indexOf('/verification') > -1 ? "-selected" : "") + ".svg"}
							/>
						</div>
					} */}
					<Link
						to="/piper-academy"
						onClick={() => trackEvent("clicked_get_started_with_atlas", {})}
						className="header-section-btn"
					>
						<div className="start-here">
							<div className="play"></div>
							<div>GET STARTED</div>
						</div>
					</Link>
					<UserBlock logout={logout} {...login} />
				</div>
				<div className="Cf" />
			</div>
		</SiteHeaderContainer>
	);
};

const TopNavBar = ({ biz = {}, access = {}, userEmail, location, merakiWebSubscription }) => {
	const routeConfig = [
		{
			matches: [
				"/menus",
				"/sections",
				"/categories",
				"/items",
				"/modifier-groups",
				"/modifiers",
				"/taxes",
				"/charges",
				"/tags",
				"/backups"
			],
			childPathsConfig: [
				{
					title: "Menus",
					to: "/menus",
					render: biz?.isMenuOverCatalogueEnabled
				},
				{
					title: "Sections",
					to: "/sections",
					render: biz?.isMenuOverCatalogueEnabled
				},
				{
					title: "Categories",
					to: "/categories",
					render: !biz?.isMenuOverCatalogueEnabled
				},
				{
					title: "Items",
					to: "/items"
				},
				{
					title: "Modifier Groups",
					to: "/modifier-groups"
				},
				{
					title: "Modifiers",
					to: "/modifiers"
				},
				{
					title: "Taxes",
					to: "/taxes"
				},
				{
					title: "Charges",
					to: "/charges"
				},
				{
					title: "Tags",
					to: "/tags"
				},
				{
					title: "Backups",
					to: "/backups",
					render: !biz?.isMenuOverCatalogueEnabled && (access?.isAdmin || access?.isCatalogueManagement)
				}
			]
		},
		{
			matches: ["/item-groups", "/location-groups", "/timing-groups"],
			childPathsConfig: [
				{
					title: "Item Groups",
					to: "/item-groups"
				},
				{
					title: "Location Groups",
					to: "/location-groups"
				},
				{
					title: "Schedules",
					to: "/timing-groups"
				}
			]
		},
		{
			matches: [
				"/analytics/home",
				"/analytics/revenue",
				"/analytics/orders",
				"/analytics/operations",
				"/analytics/catalogue",
				"/analytics/compare",
				"/reports",
				"/reports-v1"
			],
			childPathsConfig: [
				{
					title: "Home",
					to: "/analytics/home",
					render:
						access?.isSalesAnalysis ||
						access?.isOperationsAnalysis ||
						access?.isMarketing ||
						(!access?.isNonHqReport && access?.isNonHqAnalytics)
				},
				{
					title: "Revenue",
					to: `/analytics/revenue?filters=${getEncodedAnalyticsFilters()}`,
					render:
						access?.isSalesAnalysis ||
						access?.isMarketing ||
						(!access?.isNonHqReport && access?.isNonHqAnalytics)
				},
				{
					title: "Orders",
					to: `/analytics/orders?filters=${getEncodedAnalyticsFilters()}`,
					render:
						access?.isSalesAnalysis ||
						access?.isMarketing ||
						(!access?.isNonHqReport && access?.isNonHqAnalytics)
				},
				{
					title: "Operations",
					to: `/analytics/operations?filters=${getEncodedAnalyticsFilters()}`,
					render:
						access?.isOperationsAnalysis ||
						access?.isMarketing ||
						(!access?.isNonHqReport && access?.isNonHqAnalytics)
				},
				{
					title: "Catalogue",
					to: `/analytics/catalogue?filters=${getEncodedAnalyticsFilters()}`,
					render:
						access?.isSalesAnalysis ||
						access?.isMarketing ||
						(!access?.isNonHqReport && access?.isNonHqAnalytics)
				},
				{
					title: "Compare",
					to: `/analytics/compare?filters=${getEncodedAnalyticsFilters()}`,
					render:
						access?.isSalesAnalysis ||
						access?.isMarketing ||
						(!access?.isNonHqReport && access?.isNonHqAnalytics)
				},
				{
					title: "Reports",
					to: "/reports",
					render:
						access?.isSalesAnalysis ||
						access?.isOperationsAnalysis ||
						(!access?.isNonHqAnalytics && access?.isNonHqReport)
				}
			]
		},
		{
			matches: ["/summary", "/rhi", "/reports-v1"],
			childPathsConfig: [
				{
					title: "Sales",
					to: "/summary",
					render: !access?.isNonHqReport || access?.isNonHqAnalytics
				},
				{
					title: "Operations",
					to: "/rhi",
					render: !access?.isNonHqReport || access?.isNonHqAnalytics
				},
				{
					title: "Reports",
					to: "/reports-v1",
					render: !access?.isNonHqAnalytics || access?.isNonHqReport
				}
			]
		},
		{
			matches: ["/segments", "/campaigns"],
			childPathsConfig: [
				{
					title: "Segments",
					to: "/segments"
				},
				{
					title: "Campaigns",
					to: "/campaigns"
				}
			]
		},
		{
			matches: ["/payout-sheets", "/reconciliation-stats"],
			childPathsConfig: [
				{
					title: "Payouts",
					to: "/payout-sheets"
				},
				{
					title: "Stats",
					to: "/reconciliation-stats"
				}
			]
		},
		{
			matches: ["/discounts", "/coupons", "/referrals"],
			childPathsConfig: [
				{
					title: "Discounts",
					to: "/discounts"
				},
				{
					title: "Coupons",
					to: "/coupons"
				},
				{
					title: "Referrals",
					to: "/referrals"
				}
			]
		},
		{
			matches: ["/meraki-web", "/meraki", "/gallery", "/meraki-plans"],
			childPathsConfig: [
				{
					title:
						merakiWebSubscription?.subscription === null ||
						["cancelled", "not-subscribed"].includes(merakiWebSubscription?.subscription?.status) ? (
							<div className="tab-desc">
								<div>Web</div>
								<img
									src={`/assets/left-nav${
										matchPath(location.pathname, "/meraki-web") ? "-selected" : ""
									}/icon-lock.svg`}
								/>
							</div>
						) : (
							"Web"
						),
					to: "/meraki-web"
				},
				{
					title: "Settings",
					to: "/meraki",
					render: !(
						merakiWebSubscription?.subscription === null ||
						["cancelled", "not-subscribed"].includes(merakiWebSubscription?.subscription?.status)
					)
				},
				{
					title: "Gallery",
					to: "/gallery",
					render:
						biz?.modulesEnabled?.includes("Meraki") &&
						!(
							merakiWebSubscription?.subscription === null ||
							["cancelled", "not-subscribed"].includes(merakiWebSubscription?.subscription?.status)
						)
				},
				{
					title: "Plans & Billing",
					to: "/meraki-plans",
					render:
						merakiWebSubscription?.subscription === null ||
						merakiWebSubscription?.subscription?.status !== "legacy-billing"
				}
			]
		},
		{
			matches: [
				"/profile",
				"/billing",
				"/access",
				"/unified-access",
				"/api-access",
				"/hub",
				"/webhooks",
				"/activity-history",
				"/gallery",
				"/integrations",
				"/developer-tools"
			],
			childPathsConfig: [
				{
					title: "Profile",
					to: "/profile"
				},
				{
					title: "Billing",
					to: "/billing"
				},
				// {
				// 	title: "Users",
				// 	to: "/access",
				// 	render: access.isAdmin,
				// },
				{
					title: "Users",
					to: "/unified-access",
					render: access.isAdmin
				},
				{
					title: "API Access",
					to: "/api-access"
				},
				{
					title: "Hub",
					to: "/hub"
				},
				{
					title: "Webhooks",
					to: "/webhooks"
				},
				{
					title: "Activity History",
					to: "/activity-history",
					render: access?.isAdmin || access?.isOperationsAnalysis || access?.isSupport
				},
				{
					title: "Gallery",
					to: "/gallery",
					render:
						!biz?.modulesEnabled?.includes("Meraki") ||
						merakiWebSubscription?.subscription === null ||
						["cancelled", "not-subscribed"].includes(merakiWebSubscription?.subscription?.status)
				},
				{
					title: "POS Integrations",
					to: "/integrations",
					render: biz?.eisEnabled && access?.isAdmin
				},
				{
					title: "Developer Tools",
					to: "/developer-tools",
					render:
						access?.isAdmin &&
						(ENVIRONMENT_FLAG === "posint" ||
							(ENVIRONMENT_FLAG === "prod" && DEVELOPER_TOOLS_ENABLED_PRODUCTION_BIZ_IDS[biz?.id]))
				}
			]
		},
		{
			matches: [
				"/periscope/home",
				"/periscope/item-availability",
				"/periscope/storefront-availability",
				"/periscope/item-issues",
				"/periscope/activity-log"
			],
			childPathsConfig: [
				{
					title: "Home",
					to: "/periscope/home"
				},
				{
					title: "Item Availability",
					to: "/periscope/item-availability"
				},
				{
					title: "Storefront Availability",
					to: "/periscope/storefront-availability"
				},
				{
					title: "Item Issues",
					to: "/periscope/item-issues"
				},
				{
					title: "Activity Log",
					to: "/periscope/activity-log"
				}
			]
		}
	];
	const matchedPath = routeConfig.find(
		(config) =>
			config.matches.some((match) => matchPath(location.pathname, match)) &&
			(config.childPathsConfig.find((child) => child.to === location.pathname)?.render === false ? false : true)
	);
	const childPaths = matchedPath && matchedPath.childPathsConfig;
	if (childPaths) {
		return (
			<div className="top-nav-bar">
				<div className="tab-container">
					{childPaths.map((childPath) => {
						if (childPath.render !== undefined && !childPath.render) {
							return null;
						}
						return (
							<Link
								to={childPath.to}
								className={
									"tab " +
									(matchPath(
										location.pathname,
										childPath.to?.includes("?") ? childPath.to?.split("?")?.[0] : childPath.to
									)
										? "active"
										: "")
								}
								key={childPath.title}
							>
								{childPath.title}
							</Link>
						);
					})}
				</div>
			</div>
		);
	} else {
		return null;
	}
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	access: store.login.loginDetail.access,
	userEmail: store.login.loginDetail.email,
	merakiWebSubscription: store.merakiWebSubscription.data,
	showCustomBannerMessage: store.login.showCustomBannerMessage,
	isBannerMessageDismissed: store.login.isBannerMessageDismissed,
	periscopeEarlyAccessState: store.periscopeEarlyAccessState
});
export default connect(mapStateToProps)(TopNavBar);

class BannerMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			iconColors: {
				default: "#EAEAEA",
				neutral: "#363636",
				dark: "#EAEAEA",
				warning: "#363636",
				error: "#FFFFFF",
				success: "#FFFFFF",
				rewind: "#FFFFFF"
			},
			bannerStatusColorMap: {
				info: "#3B5FEB",
				warning: "#E5B917",
				error: "#D64949",
				success: "#0DA125"
			}
		};
	}

	handleOpenUrl = (url, target = "_self", eventType) => {
		if (url) {
			const { customBannerMessage } = this.props;
			if (customBannerMessage?.trackEvent && customBannerMessage?.topic && eventType) {
				const eventName = "top_banner";
				const eventMeta = {
					topic: customBannerMessage?.topic,
					type: eventType
				};
				trackEvent(eventName, eventMeta);
			}
			window.open(url, target).focus();
		}
	};

	handleCloseBanner = () => {
		const isBannerMessageDismissed = { state: true, timeStamp: moment() };
		store.dispatch({
			type: ActionTypes.UPDATE_LOGIN_STATE,
			payload: { isBannerMessageDismissed }
		});
		lS.set("isBannerMessageDismissed", isBannerMessageDismissed);
	};

	handleModal = (eventType) => {
		const { customBannerMessage } = this.props;
		if (!this.state.isModalOpen && customBannerMessage?.trackEvent && customBannerMessage?.topic && eventType) {
			const eventName = "top_banner";
			const eventMeta = {
				topic: customBannerMessage?.topic,
				type: eventType
			};
			trackEvent(eventName, eventMeta);
		}
		this.setState({ isModalOpen: !this.state.isModalOpen });
	};

	handleLink = (link) => {
		if (link.type === "open-url") {
			this.handleOpenUrl(link.url, link.target, link.eventType);
			return;
		}
		if (link.type === "page-redirect") {
			history.push(link.url);

			// track event
			if (link.trackEvent && link.eventName) {
				trackEvent(link.eventName, link.eventMeta);
			}
			return;
		}
		if (link.type === "open-modal") {
			this.handleModal(link.eventType);
			return;
		}
		if (link.type === "action" && link.actionType) {
			store.dispatch({
				type: link.actionType,
				payload: link.payload
			});

			// track event
			if (link.trackEvent && link.eventName) {
				trackEvent(link.eventName, link.eventMeta);
			}
			return;
		}
	};

	getButtonColor = (customBannerMessage) => {
		if (customBannerMessage.classes === "neutral") {
			return "#2f58f2";
		}
		return this.state.iconColors[customBannerMessage.classes];
	};

	handleHorizonBannerText = (banner) => {
		return (
			<span className="banner-text">
				<span>{banner?.message || ""}</span>
				{banner?.links?.map((link, i) => (
					<span key={i} className="action" onClick={() => this.handleLink(link)}>
						{link.text}
					</span>
				))}
			</span>
		);
	};

	render() {
		let {
			loading,
			pendingInvoices = [],
			bizState,
			merakiWebSubscription,
			showCustomBannerMessage,
			isBannerMessageDismissed,
			customBannerMessage = {},
			loginDetail
		} = this.props;
		let { isModalOpen, iconColors, bannerStatusColorMap } = this.state;
		if (
			showCustomBannerMessage &&
			!isBannerMessageDismissed?.state &&
			customBannerMessage?.message &&
			customBannerMessage.use === "horizon"
		) {
			return (
				<div className="banner-message horizon">
					<HorizonBanner
						className="h-full items-center !border-l-4"
						status={customBannerMessage?.type || "info"}
						bannerText={this.handleHorizonBannerText(customBannerMessage)}
						bannerTextVariant={TextVariant.BODY1}
						textClass="text-up-black-80"
						icon={
							customBannerMessage?.icon ? (
								<ButtonIcon
									icon={customBannerMessage?.icon}
									color={bannerStatusColorMap[customBannerMessage?.type || "info"]}
								/>
							) : null
						}
						onClose={customBannerMessage?.isDismissible && this.handleCloseBanner}
					/>
				</div>
			);
		} else if (showCustomBannerMessage && !isBannerMessageDismissed?.state && customBannerMessage?.message) {
			return (
				<div className={"banner-message " + (customBannerMessage.classes || "default")}>
					{customBannerMessage.icon && (
						<ButtonIcon
							icon={customBannerMessage.icon}
							classes="icon"
							color={iconColors[customBannerMessage.classes]}
						/>
					)}
					<div className="message">
						<span
							dangerouslySetInnerHTML={{
								__html: customBannerMessage?.message.replace(/\*{2}(.*?)\*{2}/g, "<b>$1</b>")
							}}
						></span>
					</div>
					{customBannerMessage?.links?.length > 0 && (
						<div className="links">
							{customBannerMessage?.links?.map((link, i) => (
								<div key={i} className="link" onClick={() => this.handleLink(link)}>
									{link.text}
									{link.icon && (
										<span>
											<ButtonIcon
												icon={link.icon}
												color={this.getButtonColor(customBannerMessage)}
											/>
										</span>
									)}
								</div>
							))}
						</div>
					)}
					{customBannerMessage.isDismissible && (
						<ButtonIcon
							icon="close"
							color={iconColors[customBannerMessage.classes]}
							clickHandler={this.handleCloseBanner}
						/>
					)}
				</div>
			);
		} else if (
			(!loading && pendingInvoices.length > 0) ||
			bizState === "pending_deactivation" ||
			bizState === "pending_activation"
		) {
			return (
				<div className="banner-message">
					{bizState === "pending_activation" ? (
						<div>
							Your account has been <i>provisionally</i> activated, pending payment confirmation.
						</div>
					) : (
						<div>
							&nbsp;New invoice generated for your account.&nbsp;
							<Link className="upiper-id" to={`/billing`} style={{ color: "black" }}>
								Pay
							</Link>
							&nbsp;before{" "}
							{pendingInvoices.length
								? formatDate(pendingInvoices[0].dueDate, "DD MMM, YYYY")
								: "due date"}{" "}
							to enjoy uninterrupted services.
						</div>
					)}
				</div>
			);
		} else if (merakiWebSubscription?.subscription?.status === "trialing") {
			const start = moment();
			const end = merakiWebSubscription?.subscription?.trial_ends_at
				? moment.unix(merakiWebSubscription?.subscription?.trial_ends_at)
				: undefined;
			const days = end ? end.diff(start, "days") : "Few";
			return (
				<div className="banner-message meraki">
					<div>
						&nbsp;{days} {days === 1 ? "day" : "days"} left on your Meraki subscription &nbsp;&bull; &nbsp;
						<Link className="upiper-id" to={`/meraki-plans`} style={{ color: "white" }}>
							Manage Subscription
						</Link>
					</div>
				</div>
			);
		}
		return null;
	}
}

@connect((store) => ({
	globalLoaderState: store.globalLoaderState,
	outstandingInvoicesState: store.outstandingInvoicesState,
	biz: store.login.loggedInbizDetail,
	loginDetail: store.login.loginDetail,
	sidebarState: store.sidebarState,
	merakiWebSubscription: store.merakiWebSubscription.data,
	showCustomBannerMessage: store.login.showCustomBannerMessage,
	isBannerMessageDismissed: store.login.isBannerMessageDismissed,
	dimensions: store.configItems.dimensions,
	banner: store.banner,
	periscopeEarlyAccessState: store.periscopeEarlyAccessState
}))
class SiteHeaderContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customBannerMessage: {
				topic: "",
				icon: "",
				message: "",
				classes: "",
				isClosable: false,
				trackEvent: false
			}
		};
	}
	render() {
		const {
			merakiWebSubscription,
			showCustomBannerMessage,
			isBannerMessageDismissed,
			banner,
			dimensions,
			loginDetail
		} = this.props;
		const { data, loading, error } = this.props.outstandingInvoicesState;
		const bizState = this.props.biz.currentState;
		let pendingInvoices = (data.objects || []).sort((a, b) => a.dueDate.valueOf() - b.dueDate.valueOf());
		let headerStyles = {};
		let globalLoaderStyles = {};
		if (showCustomBannerMessage && !isBannerMessageDismissed?.state && banner?.message) {
			// for temporary banner message (alert)
			headerStyles = { top: "57px" };
			globalLoaderStyles = { top: "114px" };
		} else if (
			(pendingInvoices && pendingInvoices.length > 0) ||
			bizState === "pending_deactivation" ||
			bizState == "pending_activation"
		) {
			// for pending invoices
			headerStyles = { top: "57px" };
			globalLoaderStyles = { top: "114px" };
		} else if (merakiWebSubscription?.subscription?.status === "trialing") {
			// for meraki web subscription
			headerStyles = { top: "57px" };
			globalLoaderStyles = { top: "114px" };
		}
		return (
			<div>
				{dimensions.width > 768 && (
					<BannerMessage
						loading={loading}
						pendingInvoices={pendingInvoices}
						bizState={bizState}
						merakiWebSubscription={merakiWebSubscription}
						customBannerMessage={banner}
						showCustomBannerMessage={showCustomBannerMessage}
						isBannerMessageDismissed={isBannerMessageDismissed}
						loginDetail={loginDetail}
					/>
				)}
				<div
					className={"site-header " + (this.props.sidebarState.active ? "minimized " : " ")}
					style={dimensions.width > 768 ? headerStyles : {}}
				>
					{this.props.children}
					<div
						className={
							(this.props.globalLoaderState.active ? "active" : "") +
							" global-loader-container " +
							(this.props.sidebarState.active ? "minimized " : " ")
						}
						style={dimensions.width > 768 ? globalLoaderStyles : {}}
					>
						<InfiniteLoader />
					</div>
				</div>
			</div>
		);
	}
}

export class SiteHeaderMobile extends Component {
	toggleSidebar() {
		store.dispatch({
			type: "TOGGLE_SIDE_BAR",
			payload: true
		});
	}

	render() {
		const { login } = this.props;
		return (
			<SiteHeaderContainer>
				<div className="header-mobile">
					<div className="header-mobile-menu"></div>
					{/* <div className="header-mobile-menu" onClick={this.toggleSidebar}>
						<img src="/assets/icons/burger.svg" />
					</div> */}
					<UserBlock logout={logout} {...login} />
				</div>
			</SiteHeaderContainer>
		);
	}
}

class UserBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			squareColor: "#000",
			expandMenu: false
		};
	}

	componentDidMount() {
		this.setState({
			squareColor: getRandFlatColor
		});
		window.addEventListener("click", this.closeMenu, false);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.closeMenu, false);
	}

	closeMenu = () => {
		if (this.state.expandMenu) {
			this.setState({
				expandMenu: false
			});
		}
	};

	flipMenu = (e) => {
		e.stopPropagation();
		// using nativeEvent to stop propagation
		// refer below URL for more details
		// https://stackoverflow.com/questions/36316846/react-onclick-and-preventdefault-link-refresh-redirect/36319301
		// e.nativeEvent.stopImmediatePropagation();
		// NOTE: Synthetic event lister is on doc. So it'll not work as expected if event is attached on doc.
		// Rather we can listen on windows.

		this.setState({
			expandMenu: !this.state.expandMenu
		});
	};

	copyTextToClipboard = (text, e) => {
		e.stopPropagation();
		navigator.clipboard.writeText(text);
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: `Business ID (${text}) has been copied to clipboard.`,
				timeout: 2000,
				error: false
			}
		});
	};

	openProfile = () => {
		history.push("/profile");
	};

	handleOpenUrl = (url, target = "_self") => {
		if (url) {
			// onboarding
			unsubscribeToFlowSteps();
			closeOnboardingWsLink();

			// track event
			const eventName = "switch_app";
			const eventMeta = {};
			trackEvent(eventName, eventMeta);

			window.open(url, target).focus();
		}
	};

	render() {
		const loggedInUserId = this.props?.loginDetail?.id || null;
		let name = this.props?.loginDetail?.name;
		const bizname = this.props?.loggedInbizDetail?.name;
		let bizLogo = this.props?.loggedInbizDetail?.logo;
		const userEmail = this.props?.loginDetail?.email;
		const bizId = this.props?.loggedInbizDetail?.id;
		const authServiceData = this.props?.authServiceData || null;
		bizLogo = bizLogo && "https://" + bizLogo.split("://")[1];
		let { expandMenu } = this.state;
		let style = {
			backgroundColor: this.state?.squareColor
		};
		return (
			<div className="siteheader-user-block">
				<div className="user-block-container" onClick={this.flipMenu}>
					{bizLogo ? (
						<img src={bizLogo} className="biz-logo-square" />
					) : (
						<div style={style} title={name} className="user-name-square">
							{name.split("")[0]}
						</div>
					)}
					<div className="biz-user-name" title={bizname}>
						<div className="biz-name">{bizname}</div>
						<div className="user-name">{name}</div>
					</div>
				</div>
				<Transition
					native
					items={expandMenu}
					from={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
					enter={{ opacity: 1, transform: "perspective(800px) rotateX(0deg)", transformOrigin: "top" }}
					leave={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
					config={config.stiff}
				>
					{(isOpen) =>
						isOpen &&
						((props) => (
							<animated.div style={props} className="user-menu">
								{(userEmail || name) && (
									<div className="user-info-container" onClick={this.openProfile}>
										<div className="user-info">
											{name && <div className="full-name">{name}</div>}
											{userEmail && (
												<div className="email" title={userEmail}>
													{userEmail}
												</div>
											)}
										</div>
										<div className="arrow">
											<img src="/assets/icons/ic_left_arrow_gray.svg" alt="" />
										</div>
									</div>
								)}
								<div className="biz-container">
									{/* <div className='biz-logo'>
										<div className='avatar' style={{ backgroundImage: `url(${bizLogo || '/assets/icons/platform-default-logo.svg'})`}}></div>
									</div> */}
									<div className="biz-info">
										<div className="biz-name">{bizname || "--"}</div>
										<div className="biz-id-container">
											<div className="biz-id-content">
												Business ID: <strong>{bizId || "--"}</strong>
											</div>
											{bizId && (
												<div
													title="Copy ID"
													className="biz-id-copy-logo"
													onClick={(e) => this.copyTextToClipboard(bizId, e)}
												>
													{" "}
													<CopyIcon />{" "}
												</div>
											)}
										</div>
										{authServiceData?.userBizSwitch && (
											<div
												className="link-text switch-biz"
												onClick={() => this.handleOpenUrl(ULE_SWITCH_APP_URL)}
											>
												Switch App
											</div>
										)}
									</div>
								</div>
								<div className="btn--logout">
									<div
										className="link-text error"
										onClick={() => this.props.logout(authServiceData, loggedInUserId)}
									>
										Log out
									</div>
								</div>
							</animated.div>
						))
					}
				</Transition>
			</div>
		);
	}
}

@connect((store) => ({
	login: store.login,
	dimensions: store.configItems.dimensions,
	outstandingInvoicesState: store.outstandingInvoicesState,
	sidebarState: store.sidebarState,
	access: store.login.loginDetail.access,
	userEmail: store.login.loginDetail.email,
	biz: store.login.loggedInbizDetail,
	merakiWebSubscription: store.merakiWebSubscription.data,
	showCustomBannerMessage: store.login.showCustomBannerMessage,
	isBannerMessageDismissed: store.login.isBannerMessageDismissed,
	freshChatWidgetState: store.freshChatWidgetState,
	isLoading: store.atlasOnboardingState.isLoading,
	flowData: store.atlasOnboardingState.flowData,
	flowSteps: store.atlasOnboardingState.flowSteps,
	periscopeEarlyAccessState: store.periscopeEarlyAccessState,
	periscopeEarlyAccessData: store.periscopeEarlyAccessData
}))
export class SideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: {},
			isPeriscopeEarlyAccessEnabled: undefined,
			isPeriscopeEnabled: undefined
		};
	}

	componentDidMount() {
		if (window?.posthog) {
			const isPeriscopeEnabled = window.posthog?.isFeatureEnabled("enable-periscope");
			const isEarlyAccessFeatureEnabled = window.posthog?.isFeatureEnabled("periscope-early-access");

			this.setState({
				isPeriscopeEarlyAccessEnabled: isEarlyAccessFeatureEnabled,
				isPeriscopeEnabled: isPeriscopeEnabled
			});
		}
	}

	expand = (e, i, sidebarActive, defaultPath) => {
		e.stopPropagation();
		if (!sidebarActive) {
			const { expanded } = this.state;
			Object.keys(expanded).map((tab) => {
				if (tab !== i) {
					expanded[tab] = false;
				}
			});
			expanded[i] = !expanded[i];
			this.setState({ expanded });
		} else {
			if (defaultPath) {
				history.push(defaultPath);
			}
		}
	};

	render() {
		const {
			dimensions,
			access = {},
			biz,
			userEmail = "",
			merakiWebSubscription,
			showCustomBannerMessage,
			isBannerMessageDismissed,
			freshChatWidgetState,
			flowData,
			flowSteps
		} = this.props;
		const { data, loading, error } = this.props.outstandingInvoicesState;
		const bizId = biz?.id;
		let outstandingInvoices = data.objects || [];
		const bizState = this.props.login.loggedInbizDetail.currentState;
		const isReconEnabled = biz.isReconEnabled;
		const isMultibrandEnabled = biz.isMultibrandEnabled;
		const isMenuOverCatalogueEnabled = biz.isMenuOverCatalogueEnabled;

		const isAdmin = access?.isAdmin;
		const isInternalUser = this.props?.login?.loginDetail?.email?.includes("@urbanpiper.com");

		let bannerStyles = {};
		if (showCustomBannerMessage && !isBannerMessageDismissed?.state) {
			bannerStyles = { bottom: "77px" };
		} else if (outstandingInvoices.length > 0 || bizState === "pending_activation") {
			bannerStyles = { bottom: "77px" };
		} else if (merakiWebSubscription?.subscription?.status === "trialing") {
			bannerStyles = { bottom: "77px" };
		}

		// onboarding flow checks
		const showFullNav = flowSteps?.find((step) => step?.step?.name === "SHOW_FULL_NAV") ? true : false;
		const isOnboardingFlowEnabled = flowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;
		const completedSteps = flowSteps?.filter((step) => step?.status === "complete").map((step) => step?.step?.name);
		const inprogressSteps = flowSteps
			?.filter((step) => step?.status === "inprogress")
			.map((step) => step?.step?.name);
		const isCompanyDetailsStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.COMPLETE_COMPANY_DETAILS);
		const isBrandStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.CREATE_BRANDS);
		const isLocationStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.CONFIGURE_LOCATIONS);
		const isMenuStepComplete =
			completedSteps?.includes(ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS) ||
			inprogressSteps?.includes(ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS);
		const isRTGLStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.GO_LIVE_ON_LOCATIONS);
		const isInviteUsersStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.INVITE_USERS);

		const getOnboardingProgressPercentage = () => {
			let percentage = 0;
			const weightageMap = {
				[ATLAS_ONBOARDING_STEPS.COMPLETE_COMPANY_DETAILS]: isMultibrandEnabled ? 5 : 10,
				[ATLAS_ONBOARDING_STEPS.CREATE_BRANDS]: 5,
				[ATLAS_ONBOARDING_STEPS.CONFIGURE_LOCATIONS]: 15,
				[ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS]: 30,
				[ATLAS_ONBOARDING_STEPS.TAXES_CHARGES]: 10,
				[ATLAS_ONBOARDING_STEPS.GO_LIVE_ON_LOCATIONS]: 30,
				[ATLAS_ONBOARDING_STEPS.INVITE_USERS]: 5
			};

			Object.keys(weightageMap).forEach((step) => {
				if (completedSteps.includes(step)) {
					percentage += weightageMap[step];
				}
			});

			return percentage;
		};
		const getPeriscopeSideNavURL = (props) => {
			const configValues = props.periscopeEarlyAccessData?.configurationValues || [];
			const periscopeConfig = configValues.find((val) => val.key === "periscope_enabled");
			if (periscopeConfig && periscopeConfig.value > 0) {
				return "/periscope/early-access/get-started";
			}
			return "/periscope/early-access";
		};

		return (
			<SidebarContainer>
				{dimensions.width > 768 && (
					<LogoBlock
						redirectTo={isOnboardingFlowEnabled ? "/onboarding" : "/"}
						sidebarActive={this.props.sidebarState.active}
					/>
				)}
				{!access.isNonHqReport && !access?.isNonHqAnalytics ? (
					<div>
						{isOnboardingFlowEnabled && (
							<div className="site-nav-onboarding-progress">
								<div
									className="site-nav-onboarding-progress-row"
									onClick={() => history.push("/onboarding")}
								>
									<img className="site-nav-onboarding-user-icon" src="/assets/icons/user-blue.svg" />
									{!this.props.sidebarState.active && (
										<React.Fragment>
											<Text variant={TextVariant.BODY1} className="site-nav-onboarding-text">
												Setup Guide
											</Text>
											<Text
												variant={TextVariant.BODY1}
												className="site-nav-onboarding-percentage-text"
											>
												{`${getOnboardingProgressPercentage()}%`}
											</Text>
										</React.Fragment>
									)}
								</div>
								<ProgressBar progress={getOnboardingProgressPercentage()} width={100} />
							</div>
						)}
						{(!isOnboardingFlowEnabled || showFullNav) &&
							(access.isSupport || access.isSalesAnalysis || access.isOperationsAnalysis) && (
								<SidebarNavComp
									to={"/orders"}
									title={"Orders"}
									icon={
										"/assets/left-nav" +
										(this.props.location.pathname.match(/^\/orders/g) !== null ? "-selected" : "") +
										"/icon-transactions.svg"
									}
									selected={this.props.location.pathname.match(/^\/orders/g) !== null ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
							)}
						{(!isOnboardingFlowEnabled || showFullNav) && (access.isSupport || access.isMarketing) && (
							<SidebarNavComp
								to={"/customers"}
								title={"Customers"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("customers") > -1 ? "-selected" : "") +
									"/icon-customers.svg"
								}
								selected={this.props.location.pathname.indexOf("customers") > -1 ? "active" : ""}
								toggleSidebar={toggleSidebar}
								sidebarActive={this.props.sidebarState.active}
							/>
						)}
						{(!isOnboardingFlowEnabled || showFullNav) && (access.isSupport || access.isMarketing) && (
							<SidebarNavComp
								to={biz.modulesEnabled.includes("Meraki") && "/feedback"}
								title={"Feedback"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("feedback") > -1 ? "-selected" : "") +
									"/icon-feedback.svg"
								}
								selected={this.props.location.pathname.indexOf("feedback") > -1 ? "active" : ""}
								toggleSidebar={toggleSidebar}
								sidebarActive={this.props.sidebarState.active}
								isLocked={!biz.modulesEnabled.includes("Meraki")}
							/>
						)}
						{isMultibrandEnabled && (!isOnboardingFlowEnabled || isBrandStepComplete || showFullNav) && (
							<SidebarNavComp
								to={"/brands"}
								title={"Brands"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("brands") > -1 ? "-selected" : "") +
									"/icon-brands.svg"
								}
								selected={this.props.location.pathname.indexOf("brands") > -1 ? "active" : ""}
								toggleSidebar={toggleSidebar}
								sidebarActive={this.props.sidebarState.active}
							/>
						)}
						{(!isOnboardingFlowEnabled || isLocationStepComplete || showFullNav) && (
							<SidebarNavComp
								to={"/locations"}
								title={"Locations"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("locations") > -1 ? "-selected" : "") +
									"/icon-locations.svg"
								}
								selected={this.props.location.pathname.indexOf("locations") > -1 ? "active" : ""}
								toggleSidebar={toggleSidebar}
								sidebarActive={this.props.sidebarState.active}
							/>
						)}
						{isMenuOverCatalogueEnabled &&
						(!isOnboardingFlowEnabled || isMenuStepComplete || showFullNav) ? (
							<SidebarNavComp
								expanded={this.state.expanded["menus"]}
								id={"menus"}
								handleClick={this.expand}
								title={"Menu Management"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("/menus") > -1 ||
									this.props.location.pathname.indexOf("/sections") > -1 ||
									this.props.location.pathname.match(/^\/items/g) !== null ||
									this.props.location.pathname.indexOf("/modifier-groups") > -1 ||
									this.props.location.pathname.indexOf("/modifiers") > -1 ||
									this.props.location.pathname.indexOf("/taxes") > -1 ||
									this.props.location.pathname.indexOf("/charges") > -1 ||
									this.props.location.pathname.indexOf("/tags") > -1 ||
									this.props.location.pathname.indexOf("/backups") > -1
										? "-selected"
										: "") +
									"/icon-menus.svg"
								}
								selected={
									this.props.location.pathname.indexOf("menus") > -1 ||
									this.props.location.pathname.indexOf("/sections") > -1 ||
									this.props.location.pathname.match(/^\/items/g) !== null ||
									this.props.location.pathname.indexOf("/modifier-groups") > -1 ||
									this.props.location.pathname.indexOf("/modifiers") > -1 ||
									this.props.location.pathname.indexOf("/taxes") > -1 ||
									this.props.location.pathname.indexOf("/charges") > -1 ||
									this.props.location.pathname.indexOf("/tags") > -1 ||
									this.props.location.pathname.indexOf("/backups") > -1
										? "-selected"
										: ""
								}
								sidebarActive={this.props.sidebarState.active}
								defaultPath="/menus"
							>
								<SidebarNavComp
									to={"/menus"}
									title={"Menus"}
									selected={this.props.location.pathname.indexOf("menus") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/sections"}
									title={"Sections"}
									selected={this.props.location.pathname.indexOf("/sections") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/items"}
									title={"Items"}
									selected={this.props.location.pathname.match(/^\/items/g) !== null ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/modifier-groups"}
									title={"Modifier Groups"}
									selected={
										this.props.location.pathname.indexOf("/modifier-groups") > -1 ? "active" : ""
									}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/modifiers"}
									title={"Modifiers"}
									selected={this.props.location.pathname.indexOf("/modifiers") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/taxes"}
									title={"Taxes"}
									selected={this.props.location.pathname.indexOf("/taxes") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/charges"}
									title={"Charges"}
									selected={this.props.location.pathname.indexOf("/charges") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/tags"}
									title={"Tags"}
									selected={this.props.location.pathname.indexOf("/tags") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
							</SidebarNavComp>
						) : (
							access.isCatalogueManagement &&
							!isOnboardingFlowEnabled && (
								<SidebarNavComp
									expanded={this.state.expanded["catalogue"]}
									id={"catalogue"}
									handleClick={this.expand}
									title={"Catalogue"}
									icon={
										"/assets/left-nav" +
										(this.props.location.pathname.indexOf("/categories") > -1 ||
										this.props.location.pathname.match(/^\/items/g) !== null ||
										this.props.location.pathname.indexOf("/modifier-groups") > -1 ||
										this.props.location.pathname.indexOf("/modifiers") > -1 ||
										this.props.location.pathname.indexOf("/taxes") > -1 ||
										this.props.location.pathname.indexOf("/charges") > -1 ||
										this.props.location.pathname.indexOf("/tags") > -1 ||
										this.props.location.pathname.indexOf("/backups") > -1
											? "-selected"
											: "") +
										"/icon-catalogue.svg"
									}
									selected={
										this.props.location.pathname.indexOf("/categories") > -1 ||
										this.props.location.pathname.match(/^\/items/g) !== null ||
										this.props.location.pathname.indexOf("/modifier-groups") > -1 ||
										this.props.location.pathname.indexOf("/modifiers") > -1 ||
										this.props.location.pathname.indexOf("/taxes") > -1 ||
										this.props.location.pathname.indexOf("/charges") > -1 ||
										this.props.location.pathname.indexOf("/tags") > -1 ||
										this.props.location.pathname.indexOf("/backups") > -1
											? "-selected"
											: ""
									}
									sidebarActive={this.props.sidebarState.active}
									defaultPath="/categories"
								>
									<SidebarNavComp
										to={"/categories"}
										title={"Categories"}
										selected={
											this.props.location.pathname.indexOf("/categories") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/items"}
										title={"Items"}
										selected={
											this.props.location.pathname.match(/^\/items/g) !== null ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/modifier-groups"}
										title={"Modifier Groups"}
										selected={
											this.props.location.pathname.indexOf("/modifier-groups") > -1
												? "active"
												: ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/modifiers"}
										title={"Modifiers"}
										selected={
											this.props.location.pathname.indexOf("/modifiers") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/taxes"}
										title={"Taxes"}
										selected={this.props.location.pathname.indexOf("/taxes") > -1 ? "active" : ""}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/charges"}
										title={"Charges"}
										selected={this.props.location.pathname.indexOf("/charges") > -1 ? "active" : ""}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/tags"}
										title={"Tags"}
										selected={this.props.location.pathname.indexOf("/tags") > -1 ? "active" : ""}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									{access.isAdmin && (
										<SidebarNavComp
											to={"/backups"}
											title={"Backups"}
											selected={
												this.props.location.pathname.indexOf("/backups") > -1 ? "active" : ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
								</SidebarNavComp>
							)
						)}

						{(!isOnboardingFlowEnabled || showFullNav) && access.isCatalogueManagement && (
							<SidebarNavComp
								expanded={this.state.expanded["groups"]}
								id={"groups"}
								handleClick={this.expand}
								title={"Groups"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("/item-groups") > -1 ||
									this.props.location.pathname.indexOf("/location-groups") > -1 ||
									this.props.location.pathname.indexOf("/timing-groups") > -1
										? "-selected"
										: "") +
									"/icon-platforms.svg"
								}
								selected={
									this.props.location.pathname.indexOf("/item-groups") > -1 ||
									this.props.location.pathname.indexOf("/location-groups") > -1 ||
									this.props.location.pathname.indexOf("/timing-groups") > -1
										? "active"
										: ""
								}
								sidebarActive={this.props.sidebarState.active}
								defaultPath="/item-groups"
							>
								<SidebarNavComp
									to={"/item-groups"}
									title={"Item Groups"}
									selected={this.props.location.pathname.indexOf("/item-groups") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/location-groups"}
									title={"Location Groups"}
									selected={
										this.props.location.pathname.indexOf("/location-groups") > -1 ? "active" : ""
									}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/timing-groups"}
									title={"Schedules"}
									selected={
										this.props.location.pathname.indexOf("/timing-groups") > -1 ? "active" : ""
									}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
							</SidebarNavComp>
						)}

						{(!isOnboardingFlowEnabled || showFullNav) &&
							(ENVIRONMENT_FLAG !== "prod" ||
								((access.isSalesAnalysis || access.isOperationsAnalysis || access.isMarketing) &&
									(access.analyticsAccess || access.tempAnalyticsAccess))) && (
								<SidebarNavComp
									expanded={this.state.expanded["adv_analytics"]}
									id={"adv_analytics"}
									handleClick={this.expand}
									title={"Analytics"}
									icon={
										"/assets/left-nav" +
										(this.props.location.pathname.indexOf("/analytics/home") > -1 ||
										this.props.location.pathname.indexOf("/analytics/revenue") > -1 ||
										this.props.location.pathname.indexOf("/analytics/orders") > -1 ||
										this.props.location.pathname.indexOf("/analytics/operations") > -1 ||
										this.props.location.pathname.indexOf("/analytics/catalogue") > -1 ||
										this.props.location.pathname.indexOf("/analytics/compare") > -1 ||
										this.props.location.pathname.indexOf("/reports") > -1 ||
										this.props.location.pathname.match(/^\/reports-v1$/g) !== null
											? "-selected"
											: "") +
										"/icon-adv-analytics.svg"
									}
									selected={
										this.props.location.pathname.indexOf("/analytics/home") > -1 ||
										this.props.location.pathname.indexOf("/analytics/revenue") > -1 ||
										this.props.location.pathname.indexOf("/analytics/orders") > -1 ||
										this.props.location.pathname.indexOf("/analytics/operations") > -1 ||
										this.props.location.pathname.indexOf("/analytics/catalogue") > -1 ||
										this.props.location.pathname.indexOf("/analytics/compare") > -1 ||
										this.props.location.pathname.indexOf("/reports") > -1 ||
										this.props.location.pathname.match(/^\/reports-v1$/g) !== null
											? "active"
											: ""
									}
									sidebarActive={this.props.sidebarState.active}
									defaultPath="/analytics/home"
								>
									<SidebarNavComp
										to={"/analytics/home"}
										title={"Home"}
										selected={
											this.props.location.pathname.indexOf("/analytics/home") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									{(access?.isSalesAnalysis || access?.isMarketing) && (
										<SidebarNavComp
											to={`/analytics/revenue?filters=${getEncodedAnalyticsFilters()}`}
											title={"Revenue"}
											selected={
												this.props.location.pathname.indexOf("/analytics/revenue") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
									{(access?.isSalesAnalysis || access?.isMarketing) && (
										<SidebarNavComp
											to={`/analytics/orders?filters=${getEncodedAnalyticsFilters()}`}
											title={"Orders"}
											selected={
												this.props.location.pathname.indexOf("/analytics/orders") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
									{(access?.isOperationsAnalysis || access?.isMarketing) && (
										<SidebarNavComp
											to={`/analytics/operations?filters=${getEncodedAnalyticsFilters()}`}
											title={"Operations"}
											selected={
												this.props.location.pathname.indexOf("/analytics/operations") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
									{(access?.isSalesAnalysis || access?.isMarketing) && (
										<SidebarNavComp
											to={`/analytics/catalogue?filters=${getEncodedAnalyticsFilters()}`}
											title={"Catalogue"}
											selected={
												this.props.location.pathname.indexOf("/analytics/catalogue") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}

									<SidebarNavComp
										to={`/analytics/compare?filters=${getEncodedAnalyticsFilters()}`}
										title={"Compare"}
										selected={
											this.props.location.pathname.indexOf("/analytics/compare") > -1
												? "active"
												: ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
										info={"New"}
										infoColor={"green"}
									/>
									{(access?.isSalesAnalysis || access?.isOperationsAnalysis) && (
										<SidebarNavComp
											to={"/reports"}
											title={"Reports"}
											selected={
												this.props.location.pathname.indexOf("/reports/") > -1 ? "active" : ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
								</SidebarNavComp>
							)}
						{(!isOnboardingFlowEnabled || showFullNav) &&
							this.state.isPeriscopeEnabled &&
							(this.props.periscopeEarlyAccessState?.bizSubscription ||
								lS?.get("periscope")?.bizSubscription) && (
								<SidebarNavComp
									expanded={this.state.expanded["periscope"]}
									id={"periscope"}
									handleClick={this.expand}
									title={"Periscope"}
									icon={
										"/assets/left-nav" +
										(this.props.location.pathname.indexOf("/periscope/home") > -1 ||
										this.props.location.pathname.indexOf("/periscope/item-availability") > -1 ||
										this.props.location.pathname.indexOf("/periscope/storefront-availability") >
											-1 ||
										this.props.location.pathname.indexOf("/periscope/item-issues") > -1 ||
										this.props.location.pathname.indexOf("/periscope/activity-log") > -1
											? "-selected"
											: "") +
										"/icon-periscope.svg"
									}
									selected={
										this.props.location.pathname.indexOf("/periscope/home") > -1 ||
										this.props.location.pathname.indexOf("/periscope/item-availability") > -1 ||
										this.props.location.pathname.indexOf("/periscope/storefront-availability") >
											-1 ||
										this.props.location.pathname.indexOf("/periscope/item-issues") > -1 ||
										this.props.location.pathname.indexOf("/periscope/activity-log") > -1
											? "active"
											: ""
									}
									sidebarActive={this.props.sidebarState.active}
									defaultPath="/periscope/home"
								>
									<SidebarNavComp
										to={"/periscope/home"}
										title={"Home"}
										selected={
											this.props.location.pathname.indexOf("/periscope/home") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/periscope/storefront-availability"}
										title={"Storefront Availability"}
										selected={
											this.props.location.pathname.indexOf("/periscope/storefront-availability") >
											-1
												? "active"
												: ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/periscope/item-availability"}
										title={"Item Availability"}
										selected={
											this.props.location.pathname.indexOf("/periscope/item-availability") > -1
												? "active"
												: ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/periscope/item-issues"}
										title={"Item Issues"}
										selected={
											this.props.location.pathname.indexOf("/item-issues") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
									<SidebarNavComp
										to={"/periscope/activity-log"}
										title={"Activity Log"}
										selected={
											this.props.location.pathname.indexOf("/activity-log") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								</SidebarNavComp>
							)}
						{/* show early access tab in sidenav only when
							- user is admin of biz
							- when periscope is enabled via feature flag
							- when user hasn't already subscribed to periscope */}
						{(!isOnboardingFlowEnabled || showFullNav) &&
							access.isAdmin &&
							this.state.isPeriscopeEnabled &&
							!lS?.get("periscope")?.bizSubscription && (
								<>
									<SidebarNavComp
										to={getPeriscopeSideNavURL(this.props)}
										title={"Periscope"}
										selected={
											this.props.location.pathname.indexOf("/early-access") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
										icon={
											"/assets/left-nav" +
											(this.props.location.pathname.indexOf("early-access") > -1
												? "-selected"
												: "") +
											"/icon-periscope-early-access.svg"
										}
									/>
								</>
							)}

						{(!isOnboardingFlowEnabled || showFullNav) && isReconEnabled && (
							<SidebarNavComp
								expanded={this.state.expanded["reconciliation"]}
								id={"reconciliation"}
								handleClick={this.expand}
								title={"Reconciliation"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("/payout-sheets") > -1 ||
									this.props.location.pathname.indexOf("/reconciliation-stats") > -1
										? "-selected"
										: "") +
									"/icon-reconciliation.svg"
								}
								selected={
									this.props.location.pathname.indexOf("/payout-sheets") > -1 ||
									this.props.location.pathname.indexOf("/reconciliation-stats") > -1
										? "active"
										: ""
								}
								sidebarActive={this.props.sidebarState.active}
								defaultPath="/payout-sheets"
							>
								<SidebarNavComp
									to={"/payout-sheets"}
									title={"Payouts"}
									selected={
										this.props.location.pathname.indexOf("/payout-sheets") > -1 ? "active" : ""
									}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/reconciliation-stats"}
									title={"Stats"}
									selected={
										this.props.location.pathname.indexOf("/reconciliation-stats") > -1
											? "active"
											: ""
									}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
							</SidebarNavComp>
						)}

						{(!isOnboardingFlowEnabled || showFullNav) && (
							<SidebarNavComp
								expanded={this.state.expanded["marketing"]}
								id={"marketing"}
								handleClick={this.expand}
								title={"Marketing"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("/segments") > -1 ||
									this.props.location.pathname.indexOf("/campaigns") > -1
										? "-selected"
										: "") +
									"/icon-marketing.svg"
								}
								selected={
									this.props.location.pathname.indexOf("campaigns") > -1 ||
									this.props.location.pathname.indexOf("segments") > -1
										? "active"
										: ""
								}
								sidebarActive={this.props.sidebarState.active}
								defaultPath="/segments"
							>
								<SidebarNavComp
									to={"/segments"}
									title={"Segments"}
									selected={this.props.location.pathname.indexOf("/segments") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								<SidebarNavComp
									to={"/campaigns"}
									title={"Campaign"}
									selected={this.props.location.pathname.indexOf("campaigns") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
							</SidebarNavComp>
						)}

						{(!isOnboardingFlowEnabled || showFullNav) && (
							<SidebarNavComp
								expanded={this.state.expanded["promotions"]}
								id={"promotions"}
								handleClick={this.expand}
								title={"Promotions"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("/discounts") > -1 ||
									this.props.location.pathname.indexOf("/coupons") > -1 ||
									this.props.location.pathname.indexOf("/referrals") > -1
										? "-selected"
										: "") +
									"/icon-promotions.svg"
								}
								selected={
									this.props.location.pathname.indexOf("/discounts") > -1 ||
									this.props.location.pathname.indexOf("/coupons") > -1 ||
									this.props.location.pathname.indexOf("/referrals") > -1
										? "active"
										: ""
								}
								sidebarActive={this.props.sidebarState.active}
								defaultPath="/discounts"
							>
								{access.isHubManagement && (
									<SidebarNavComp
										to={"/discounts"}
										title={"Discounts"}
										selected={
											this.props.location.pathname.indexOf("/discounts") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								)}
								<SidebarNavComp
									to={"/coupons"}
									title={"Coupons"}
									selected={this.props.location.pathname.indexOf("/coupons") > -1 ? "active" : ""}
									toggleSidebar={toggleSidebar}
									sidebarActive={this.props.sidebarState.active}
								/>
								{access.isHubManagement && (
									<SidebarNavComp
										to={"/referrals"}
										title={"Referrals"}
										selected={
											this.props.location.pathname.indexOf("/referrals") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								)}
							</SidebarNavComp>
						)}

						{(!isOnboardingFlowEnabled || showFullNav) && (
							<SidebarNavComp
								expanded={this.state.expanded["meraki"] && biz.modulesEnabled.includes("Meraki")}
								id={"meraki"}
								handleClick={this.expand}
								title={"Meraki"}
								icon={
									"/assets/left-nav" +
									(matchPath(this.props.location.pathname, "/meraki-web") ||
									matchPath(this.props.location.pathname, "/meraki") ||
									matchPath(this.props.location.pathname, "/meraki-plans") ||
									(biz.modulesEnabled.includes("Meraki") &&
										!(
											merakiWebSubscription?.subscription === null ||
											["cancelled", "not-subscribed"].includes(
												merakiWebSubscription?.subscription?.status
											)
										) &&
										matchPath(this.props.location.pathname, "/gallery"))
										? "-selected"
										: "") +
									"/icon-meraki.svg"
								}
								selected={
									matchPath(this.props.location.pathname, "/meraki-web") ||
									matchPath(this.props.location.pathname, "/meraki") ||
									matchPath(this.props.location.pathname, "/meraki-plans") ||
									(biz.modulesEnabled.includes("Meraki") &&
										!(
											merakiWebSubscription?.subscription === null ||
											["cancelled", "not-subscribed"].includes(
												merakiWebSubscription?.subscription?.status
											)
										) &&
										matchPath(this.props.location.pathname, "/gallery"))
										? "active"
										: ""
								}
								sidebarActive={this.props.sidebarState.active}
								defaultPath="/meraki-web"
								isLocked={!biz.modulesEnabled.includes("Meraki")}
							>
								{
									<SidebarNavComp
										to={"/meraki-web"}
										title={
											merakiWebSubscription?.subscription === null ||
											["cancelled", "not-subscribed"].includes(
												merakiWebSubscription?.subscription?.status
											) ? (
												<div className="side-bar-sub-nav">
													<div>Web</div>
													<img
														src={`/assets/left-nav${
															matchPath(this.props.location.pathname, "/meraki-web")
																? "-selected"
																: ""
														}/icon-lock.svg`}
													/>
												</div>
											) : (
												"Web"
											)
										}
										selected={
											matchPath(this.props.location.pathname, "/meraki-web") ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								}
								{!(
									merakiWebSubscription?.subscription === null ||
									["cancelled", "not-subscribed"].includes(
										merakiWebSubscription?.subscription?.status
									)
								) && (
									<SidebarNavComp
										to={"/meraki"}
										title={"Settings"}
										selected={matchPath(this.props.location.pathname, "/meraki") ? "active" : ""}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								)}
								{access.isAdmin &&
									!(
										merakiWebSubscription?.subscription === null ||
										["cancelled", "not-subscribed"].includes(
											merakiWebSubscription?.subscription?.status
										)
									) && (
										<SidebarNavComp
											to={"/gallery"}
											title={"Gallery"}
											selected={
												matchPath(this.props.location.pathname, "/gallery") ? "active" : ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
								{(merakiWebSubscription?.subscription === null ||
									merakiWebSubscription?.subscription?.status !== "legacy-billing") && (
									<SidebarNavComp
										to={"/meraki-plans"}
										title={"Plans & Billing"}
										selected={
											matchPath(this.props.location.pathname, "/meraki-plans") ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								)}
							</SidebarNavComp>
						)}

						{(access.isAdmin ||
							access.isBilling ||
							access.isHubManagement ||
							access.isOperationsAnalysis ||
							access.isSupport) && (
							<SidebarNavComp
								expanded={this.state.expanded["settings"]}
								id={"settings"}
								handleClick={this.expand}
								title={"Settings"}
								icon={
									"/assets/left-nav" +
									(matchPath(this.props.location.pathname, "/profile") ||
									matchPath(this.props.location.pathname, "/billing") ||
									matchPath(this.props.location.pathname, "/access") ||
									matchPath(this.props.location.pathname, "/unified-access") ||
									matchPath(this.props.location.pathname, "/api-access") ||
									matchPath(this.props.location.pathname, "/webhooks") ||
									matchPath(this.props.location.pathname, "/activity-history") ||
									this.props.location.pathname.match(/^\/integrations/g) !== null ||
									matchPath(this.props.location.pathname, "/developer-tools") ||
									matchPath(this.props.location.pathname, "/hub") ||
									((!biz.modulesEnabled.includes("Meraki") ||
										merakiWebSubscription?.subscription === null ||
										["cancelled", "not-subscribed"].includes(
											merakiWebSubscription?.subscription?.status
										)) &&
										matchPath(this.props.location.pathname, "/gallery"))
										? "-selected"
										: "") +
									"/icon-settings.svg"
								}
								selected={
									matchPath(this.props.location.pathname, "/profile") ||
									matchPath(this.props.location.pathname, "/billing") ||
									matchPath(this.props.location.pathname, "/access") ||
									matchPath(this.props.location.pathname, "/unified-access") ||
									matchPath(this.props.location.pathname, "/api-access") ||
									matchPath(this.props.location.pathname, "/webhooks") ||
									matchPath(this.props.location.pathname, "/activity-history") ||
									this.props.location.pathname.match(/^\/integrations/g) !== null ||
									matchPath(this.props.location.pathname, "/developer-tools") ||
									matchPath(this.props.location.pathname, "/hub") ||
									((!biz.modulesEnabled.includes("Meraki") ||
										merakiWebSubscription?.subscription === null ||
										["cancelled", "not-subscribed"].includes(
											merakiWebSubscription?.subscription?.status
										)) &&
										matchPath(this.props.location.pathname, "/gallery"))
										? "-selected"
										: ""
								}
								sidebarActive={this.props.sidebarState.active}
								defaultPath="/profile"
							>
								{(!isOnboardingFlowEnabled || isCompanyDetailsStepComplete || showFullNav) &&
									access.isAdmin && (
										<SidebarNavComp
											to={"/profile"}
											title={"Profile"}
											selected={
												this.props.location.pathname.indexOf("/profile") > -1 ? "active" : ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
								{(!isOnboardingFlowEnabled || showFullNav) && access.isBilling && (
									<SidebarNavComp
										to={"/billing"}
										title={"Billing"}
										selected={this.props.location.pathname.indexOf("/billing") > -1 ? "active" : ""}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								)}
								{(!isOnboardingFlowEnabled || showFullNav || isInviteUsersStepComplete) &&
									access.isAdmin && (
										<SidebarNavComp
											to={"/unified-access"}
											title={"Users"}
											selected={
												matchPath(this.props.location.pathname, "/unified-access")
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
								{(!isOnboardingFlowEnabled || showFullNav) && access.isAdmin && (
									<SidebarNavComp
										to={"/api-access"}
										title={"API Access"}
										selected={
											matchPath(this.props.location.pathname, "/api-access") ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								)}
								{(!isOnboardingFlowEnabled || showFullNav || isRTGLStepComplete) &&
									access.isHubManagement && (
										<SidebarNavComp
											to={"/hub"}
											title={"Platforms"}
											selected={this.props.location.pathname.indexOf("/hub") > -1 ? "active" : ""}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
								{(!isOnboardingFlowEnabled || showFullNav) && access.isAdmin && (
									<SidebarNavComp
										to={"/webhooks"}
										title={"Webhooks"}
										selected={
											this.props.location.pathname.indexOf("/webhooks") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								)}
								{(!isOnboardingFlowEnabled || showFullNav) &&
									(access.isAdmin || access.isOperationsAnalysis || access.isSupport) && (
										<SidebarNavComp
											to={"/activity-history"}
											title={"Activity History"}
											selected={
												this.props.location.pathname.indexOf("/activity-history") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
								{(!isOnboardingFlowEnabled || showFullNav) &&
									access.isAdmin &&
									(!biz.modulesEnabled.includes("Meraki") ||
										merakiWebSubscription?.subscription === null ||
										["cancelled", "not-subscribed"].includes(
											merakiWebSubscription?.subscription?.status
										)) && (
										<SidebarNavComp
											to={"/gallery"}
											title={"Gallery"}
											selected={
												this.props.location.pathname.indexOf("/gallery") > -1 ? "active" : ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
								{(!isOnboardingFlowEnabled || showFullNav) && access.isAdmin && biz.eisEnabled && (
									<SidebarNavComp
										to={"/integrations"}
										title={"POS Integrations"}
										selected={
											this.props.location.pathname.match(/^\/integrations/g) !== null
												? "active"
												: ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								)}
								{(!isOnboardingFlowEnabled || showFullNav) &&
									access.isAdmin &&
									(ENVIRONMENT_FLAG === "posint" ||
										(ENVIRONMENT_FLAG === "prod" &&
											DEVELOPER_TOOLS_ENABLED_PRODUCTION_BIZ_IDS[this.props?.biz?.id])) && (
										<SidebarNavComp
											to={"/developer-tools"}
											title={"Developer Tools"}
											selected={
												this.props.location.pathname.indexOf("/developer-tools") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
									)}
							</SidebarNavComp>
						)}
						{/* <SidebarNavComp
							title={"Support"}
							handleClick={() => window.FreshworksWidget('open')}
							icon={"/assets/left-nav" +
								(this.props.location.pathname.indexOf('support') > -1 ? "-selected" : "")
								+ "/icon-support.svg"}
							selected={(this.props.location.pathname.indexOf('support') > -1) ? "active" : ""}
							toggleSidebar={toggleSidebar}
							sidebarActive={this.props.sidebarState.active}
						/> */}
						{window.isMobile ? (
							<SidebarNavComp
								handleClick={logout}
								toggleSidebar={toggleSidebar}
								title={"Logout"}
								icon={"/assets/header-icons/icon-signout.svg"}
								selected={false}
								sidebarActive={this.props.sidebarState.active}
							/>
						) : null}
					</div>
				) : (
					<div>
						{
							<SidebarNavComp
								expanded={this.state.expanded["adv_analytics"]}
								id={"adv_analytics"}
								handleClick={this.expand}
								title={"Analytics"}
								icon={
									"/assets/left-nav" +
									(this.props.location.pathname.indexOf("/analytics/home") > -1 ||
									this.props.location.pathname.indexOf("/analytics/revenue") > -1 ||
									this.props.location.pathname.indexOf("/analytics/orders") > -1 ||
									this.props.location.pathname.indexOf("/analytics/operations") > -1 ||
									this.props.location.pathname.indexOf("/analytics/catalogue") > -1 ||
									this.props.location.pathname.indexOf("/analytics/compare") > -1 ||
									this.props.location.pathname.indexOf("/reports") > -1 ||
									this.props.location.pathname.match(/^\/reports-v1$/g) !== null
										? "-selected"
										: "") +
									"/icon-adv-analytics.svg"
								}
								selected={
									this.props.location.pathname.indexOf("/analytics/home") > -1 ||
									this.props.location.pathname.indexOf("/analytics/revenue") > -1 ||
									this.props.location.pathname.indexOf("/analytics/orders") > -1 ||
									this.props.location.pathname.indexOf("/analytics/operations") > -1 ||
									this.props.location.pathname.indexOf("/analytics/catalogue") > -1 ||
									this.props.location.pathname.indexOf("/analytics/compare") > -1 ||
									this.props.location.pathname.indexOf("/reports") > -1 ||
									this.props.location.pathname.match(/^\/reports-v1$/g) !== null
										? "active"
										: ""
								}
								sidebarActive={this.props.sidebarState.active}
								defaultPath={access.isNonHqAnalytics ? "/analytics/home" : "/reports"}
								isBeta={access.isNonHqAnalytics}
								betaColor="green"
							>
								{access.isNonHqAnalytics && (
									<React.Fragment>
										<SidebarNavComp
											to={"/analytics/home"}
											title={"Home"}
											selected={
												this.props.location.pathname.indexOf("/analytics/home") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
										<SidebarNavComp
											to={`/analytics/revenue?filters=${getEncodedAnalyticsFilters()}`}
											title={"Revenue"}
											selected={
												this.props.location.pathname.indexOf("/analytics/revenue") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
										<SidebarNavComp
											to={`/analytics/orders?filters=${getEncodedAnalyticsFilters()}`}
											title={"Orders"}
											selected={
												this.props.location.pathname.indexOf("/analytics/orders") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
										<SidebarNavComp
											to={`/analytics/operations?filters=${getEncodedAnalyticsFilters()}`}
											title={"Operations"}
											selected={
												this.props.location.pathname.indexOf("/analytics/operations") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
										<SidebarNavComp
											to={`/analytics/catalogue?filters=${getEncodedAnalyticsFilters()}`}
											title={"Catalogue"}
											selected={
												this.props.location.pathname.indexOf("/analytics/catalogue") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
										/>
										<SidebarNavComp
											to={`/analytics/compare?filters=${getEncodedAnalyticsFilters()}`}
											title={"Compare"}
											selected={
												this.props.location.pathname.indexOf("/analytics/compare") > -1
													? "active"
													: ""
											}
											toggleSidebar={toggleSidebar}
											sidebarActive={this.props.sidebarState.active}
											info={"New"}
											infoColor={"green"}
										/>
									</React.Fragment>
								)}
								{access.isNonHqReport && (
									<SidebarNavComp
										to={"/reports"}
										title={"Reports"}
										selected={
											this.props.location.pathname.indexOf("/reports/") > -1 ? "active" : ""
										}
										toggleSidebar={toggleSidebar}
										sidebarActive={this.props.sidebarState.active}
									/>
								)}
							</SidebarNavComp>
						}
					</div>
				)}
				<div className="spacing-for-help-button"></div>
				<div
					className={`${
						this.props.sidebarState.active
							? "freshchat-support-btn-container freshchat-left-nav-closed-get-help-btn"
							: "freshchat-support-btn-container"
					}`}
				>
					<div>
						<Transition
							native
							from={{ transform: "scale(0)", opacity: 0 }}
							enter={{ transform: "scale(1)", opacity: 1 }}
							leave={{ transform: "scale(0)", opacity: 0 }}
							items={!freshChatWidgetState.busy}
							config={{ ...config.stiff, ...{ duration: 500 } }}
						>
							{(isOpen) =>
								isOpen &&
								((props) => (
									<animated.div style={{ ...props }}>
										{this.props.sidebarState.active ? (
											<div onClick={openFreshchatWidget}>
												<img src="/assets/left-nav/icon-get-help.svg" />
											</div>
										) : (
											<Button
												clickHandler={openFreshchatWidget}
												classes="freshchat-help-button"
												loading={!freshChatWidgetState.loaded}
											>
												<ButtonIcon icon="freshchat" />
												<span>GET HELP</span>
											</Button>
										)}
									</animated.div>
								))
							}
						</Transition>
					</div>
				</div>
			</SidebarContainer>
		);
	}
}

@connect((store) => ({
	sidebarState: store.sidebarState,
	outstandingInvoicesState: store.outstandingInvoicesState,
	biz: store.login.loggedInbizDetail,
	merakiWebSubscription: store.merakiWebSubscription.data,
	showCustomBannerMessage: store.login.showCustomBannerMessage,
	isBannerMessageDismissed: store.login.isBannerMessageDismissed,
	dimensions: store.configItems.dimensions,
	periscopeEarlyAccessState: store.periscopeEarlyAccessState
}))
class SidebarContainer extends Component {
	toggleSidebar() {
		store.dispatch({
			type: "TOGGLE_SIDE_BAR",
			payload: false
		});
	}

	preventPropagation(e) {
		e.stopPropagation();
	}

	render() {
		const { merakiWebSubscription, showCustomBannerMessage, isBannerMessageDismissed, dimensions } = this.props;
		const { data, loading, error } = this.props.outstandingInvoicesState;
		let outstandingInvoices = data.objects || [];
		const bizState = this.props.biz.currentState;
		let bannerStyles = {};
		if (showCustomBannerMessage && !isBannerMessageDismissed?.state) {
			bannerStyles = { top: "57px" };
		} else if (outstandingInvoices.length > 0 || bizState === "pending_activation") {
			bannerStyles = { top: "57px" };
		} else if (merakiWebSubscription?.subscription?.status === "trialing") {
			bannerStyles = { top: "57px" };
		}
		return (
			<div
				onClick={(e) => {
					this.toggleSidebar(e);
				}}
				className={(this.props.sidebarState.active ? "active" : "") + " side-bar-holder"}
			>
				<div
					onClick={(e) => {
						this.preventPropagation(e);
					}}
					className="side-bar"
					style={{
						...(dimensions.width > 768 ? bannerStyles : {}),
						overflowY: !this.props.sidebarState.active ? "auto" : "unset"
					}}
				>
					{this.props.children}
				</div>
			</div>
		);
	}
}

const LogoBlock = (props) => {
	return (
		<div className="atlas-logo-container">
			<Link to={props.redirectTo}>
				<img className="atlas-logo-img img-first" src="/assets/icons/atlas-logo.svg" />
				{!props.sidebarActive && (
					<img className="atlas-logo-img" src="/assets/header-icons/atlas-text-logo.svg" alt="atlas-logo" />
				)}
			</Link>
		</div>
	);
};

const SidebarNavComp = (props) => {
	if (props.to) {
		const handleClickLink = (link, title) => {
			if (link.startsWith("/periscope/")) {
				if (link.startsWith("/periscope/early-access")) {
					trackEvent("periscope_module_accessed");
				} else {
					trackEvent("periscope_module_accessed", {
						module: title.toLowerCase().split(" ").join("_"),
						source: "direct"
					});
				}
			}
		};
		return (
			<Link to={props.to} className="side-bar-nav-link" onClick={() => handleClickLink(props.to, props?.title)}>
				<SidebarNavCompBlock to={props.to} {...props} />
			</Link>
		);
	}
	return <SidebarNavCompBlock {...props} />;
};

const SidebarNavCompBlock = connect((store) => ({
	menuDraftPendingChangesCount: store.menuDetailsState?.menuDetailsData?.metadata?.pendingChangesCount,
	isMenuLocked: store.menuDetailsState?.menuDetailsData?.metadata?.lockStatus === "ACTIVE",
	periscopeEarlyAccessState: store.periscopeEarlyAccessState
}))((props) => {
	const [isModalOpen, setModalState] = useState(false);
	const [hoveredState, setHoveredState] = useState(false);
	const currentPathname = window?.location?.pathname;

	const handleModalClose = () => {
		setModalState(false);
	};

	const handleSubmit = () => {
		window.open(
			"https://www.urbanpiper.com/meraki?utm_source=Atlas&utm_medium=referral&utm_campaign=Atlas-in-app&utm_id=In-app"
		);
	};

	const handleClick = (e) => {
		if (props.isMenuLocked) {
			props.handleClick && props.handleClick(e, props.id, props.sidebarActive, props.defaultPath);
		} else if (!!props?.menuDraftPendingChangesCount && currentPathname.includes("/menus/edit")) {
			e.preventDefault();

			store.dispatch({
				type: ActionTypes.MENU_PENDING_CHANGES_MODAL_STATE,
				payload: true
			});

			store.dispatch({
				type: ActionTypes.MENU_PENDING_CHANGES_TARGET_PATH_UPDATE,
				payload: props?.to || props?.defaultPath || ""
			});
			return;
		} else if (props.isLocked) {
			setModalState(true);
		} else {
			props.handleClick && props.handleClick(e, props.id, props.sidebarActive, props.defaultPath);
		}
	};

	const childComponents = props.children;

	return (
		<React.Fragment>
			<div
				className={
					(props.selected ? "selected" : "") +
					" side-bar-nav-comp " +
					(props.children ? "has-sub-modules" : "")
				}
			>
				<div
					onClick={handleClick}
					className="side-bar-nav-comp-block"
					onMouseEnter={() => setHoveredState(true)}
					onMouseMove={() => setHoveredState(true)}
					onMouseLeave={() => setHoveredState(false)}
					// style={props.sidebarActive && hoveredState ? { background: "#eaeaea" } : {}}
				>
					{props.icon && <img className="nav-item-icon" src={props.icon} />}
					<span className="title">
						{!props.sidebarActive && props.title}
						{!props.sidebarActive && props.isLocked && (
							<img className="locked-icon" src={`/assets/left-nav/icon-lock.svg`} />
						)}
					</span>
					{!props.sidebarActive && props.isBeta && (
						<span className={"side-bar-label beta " + (props.betaColor || "")}>Beta</span>
					)}
					{!props.sidebarActive && props?.info && (
						<span className={"side-bar-label info  " + props?.infoColor || ""}>{props?.info} </span>
					)}
					{!props.sidebarActive && props.isNew && <span className="side-bar-label new">New</span>}
					{!props.sidebarActive && props.children && !props.isLocked && (
						<DownArrow expanded={props.expanded} classes="side-bar-nav-dropdown" hover={false} />
					)}
					<Transition
						native
						from={{ opacity: 0 }}
						enter={{ opacity: 1 }}
						leave={{ opacity: 0, delay: 0 }}
						items={props.sidebarActive && hoveredState}
						config={{ ...config.stiff, ...{ duration: 100 } }}
					>
						{(isOpen) =>
							isOpen &&
							((prps) => (
								<animated.div className="side-bar-nav-comp-block-info" style={prps}>
									{props.title}
								</animated.div>
							))
						}
					</Transition>
				</div>
				{
					<Transition
						native
						from={{ height: 0, opacity: 0 }}
						enter={{ height: "auto", opacity: 1 }}
						leave={{ height: 0, opacity: 0, delay: 0 }}
						items={!props.sidebarActive && props.expanded}
						config={{ ...config.stiff, ...{ duration: 100 } }}
					>
						{(isOpen) =>
							isOpen &&
							((props) => (
								<animated.div className="side-bar-nav-comp-block-children" style={props}>
									{childComponents}
								</animated.div>
							))
						}
					</Transition>
				}
			</div>
			<Modal
				isOpen={isModalOpen}
				close={handleModalClose}
				title={"Trying to setup Meraki?"}
				classes="sidebar-modal"
				subTitle={"Enabling Meraki lets you generate customised website for your business"}
				showSubmitAction
				submitAction={handleSubmit}
				submitTitle="Learn more"
				submitButtonWidth="200"
				showCancelAction
				cancelAction={handleModalClose}
				cancelTitle="Cancel"
			>
				<div>
					<img src="/assets/icons/meraki-ad.png" className="meraki-ad-image" />
				</div>
				<div>
					Get your own <strong>branded website & app</strong> for your restaurant.
					<br /> Save on commissions and own your customer relationships.
				</div>
			</Modal>
		</React.Fragment>
	);
});

class GlobalMessageBox extends Component {
	componentDidMount() {
		if (this.props.timeout) {
			this.timer = setTimeout(() => {
				store.dispatch({
					type: ActionTypes.HIDE_GLOBAL_MESSAGE,
					payload: {
						message: ""
					}
				});
			}, this.props.timeout);
		}
	}

	handleMouseEnter() {
		clearInterval(this.timer);
	}

	handleMouseLeave() {
		this.timer = setTimeout(() => {
			store.dispatch({
				type: ActionTypes.HIDE_GLOBAL_MESSAGE,
				payload: {
					message: ""
				}
			});
		}, 1500);
	}

	render() {
		const { message, retry, error } = this.props;
		return (
			<div
				onMouseEnter={(e) => this.handleMouseEnter(e)}
				onMouseLeave={(e) => this.handleMouseLeave(e)}
				className={error === false ? "global-success-modal" : "global-error-modal"}
			>
				<div>
					<div className="error-message">
						{message.includes("GraphQL error") ? "Oops! Something went wrong." : message}
					</div>
				</div>
				{retry === false ? <div>Retry</div> : null}
			</div>
		);
	}
}

@connect((store) => ({
	globalMessage: store.globalMessage
}))
export class GlobalMessage extends Component {
	render() {
		const { globalMessage } = this.props;
		if (globalMessage.show) {
			return <GlobalMessageBox {...globalMessage} />;
		} else {
			return null;
		}
	}
}

@connect((store) => ({
	showCloseDrawerMessage: store.showCloseDrawerMessage
}))
export class ShowCloseDrawerMessage extends Component {
	componentDidMount() {
		if (this.props.showCloseDrawerMessage.timeout) {
			this.messageTimer = setTimeout(() => {
				store.dispatch({
					type: "HIDE_CLOSE_DRAWER_INSTRUCTION",
					payload: {
						message: ""
					}
				});
			}, this.props.showCloseDrawerMessage.timeout);
		}
	}

	componentWillUnmount() {
		clearInterval(this.messageTimer);
	}

	render() {
		return (
			<div className="close-drawer-modal">
				<div>
					<div className="message">
						Press <button>esc</button> to close drawer
					</div>
				</div>
			</div>
		);
	}
}

export const GlobalDeactivationBlocker = ({ show, outstandingInvoices, currencySymbol, currentPathname }) => {
	const totalOutstandingAmount = (outstandingInvoices || [])
		.map((x) => x.finalAmount || 0)
		.reduce((acc, x) => acc + x, 0);
	if (currentPathname.startsWith("/billing")) {
		show = false;
	}
	return (
		<div>
			{show && (
				<div className="global-deactivation-blocker-modal-container">
					<div
						className={(show ? "backlayer-show" : "") + " at-modal--backdrop"}
						style={{ opacity: "0.3" }}
					></div>

					<div className={(show ? "content-show" : "") + " global-deactivation-blocker-modal"}>
						<div className="global-deactivation-blocker-modal--content">
							<div className="header"> Account Deactivated </div>
							<div className="body">
								Your account has been placed in deactivated state due to non-payment of past invoices
								within the due date
							</div>
							<div className="body">
								You have <b>{outstandingInvoices.length} invoices </b> pending, amounting to a total of{" "}
								<b>
									{printCurrency(currencySymbol)}&nbsp;
									{commifyNumbers(totalOutstandingAmount, null, { minimumFractionDigits: 2 })}{" "}
								</b>
							</div>
						</div>
						<div className="global-deactivation-blocker-modal--btn">
							<Link to={`/billing`} style={{ textDecoration: "none" }} className="at-btn  W(250px)">
								CLEAR INVOICES
							</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export const GlobalConfirmModal = ({
	show,
	modalBusy,
	confirmBtnEnabled,
	customButtons,
	noBackdrop,
	backdropHandler,
	confirmHandler,
	cancelHandler,
	children
}) => (
	<div className={(noBackdrop ? "no-backdrop" : "") + " global-confirm-modal-container"}>
		{!noBackdrop ? (
			<div
				onClick={(e) => {
					backdropHandler(e);
				}}
				className={(show ? "backlayer-show" : "") + " at-modal--backdrop"}
			></div>
		) : null}
		<div className={(show ? "content-show" : "") + " global-confirm-modal"}>
			<div className="global-confirm-modal--content">{children}</div>
			{!customButtons && (
				<div className={(modalBusy ? "disabled" : "") + " global-confirm-modal--btn"}>
					<div
						onClick={(e) => confirmHandler(e, true)}
						className={(!confirmBtnEnabled ? "disabled" : "") + " at-btn  W(100px)"}
					>
						Confirm
					</div>
					<div
						onClick={(e) => cancelHandler(e, false)}
						className="at-btn at-btn--secondary Mstart(20px) W(100px)"
					>
						Cancel
					</div>
				</div>
			)}
		</div>
	</div>
);

export const InfiniteTopBar = ({ selected, clickHandler, tabs, isStickyOnTop, hiddenTabs = [] }) => (
	<div className={"at-top-bar-wrapper-v2 " + (isStickyOnTop ? "wrapper-sticky-top" : "")}>
		<div className="at-top-bar-v2">
			{tabs.map(
				(tab, i) =>
					!hiddenTabs.includes(tab) && (
						<div
							className={
								(i === selected ? "selected " : "") +
								" selectable topbar-column topbar-column--create-campaign"
							}
							key={tab}
							onClick={(e) => clickHandler(e, i)}
						>
							<div className="text text--small">{tab}</div>
						</div>
					)
			)}
		</div>
	</div>
);

export const InfiniteLoader = () => <div className="infinite-load-bar" />;
