import React, { useCallback, useState } from "react";

// third party
import ReactJson from "@microlink/react-json-view";

export const JsonInput = ({
	title = "",
	classes = "",
	requiredLabel = false,
	value = {},
	setFilter,
	field = "",
	validationMessage,
	readOnly = false,
	showCustomTooltip = false,
	tooltipInfo = "",
	tooltipPosition = "up-left",
	theme = "rjv-default",
	iconStyle = "triangle", // accepted values: "circle", triangle" or "square"
	collapsed = false,
	displayDataTypes = false,
	displayObjectSize = true,
	enableCopy = true,
	copyCallback,
	sortKeys = false,
	quotesOnKeys = true,
	displayArrayKey = false,
	loading = false
}) => {
	const [messageToDisplay, setMessageToDisplay] = useState("Oops, something went wrong!");

	const handleEdit = useCallback(
		(obj) => {
			setFilter(field, obj.updated_src);
			return true;
		},
		[setFilter]
	);

	const handleAdd = useCallback(
		(obj) => {
			setFilter(field, obj.updated_src);
			return true;
		},
		[setFilter]
	);

	const handleDelete = useCallback(
		(obj) => {
			setFilter(field, obj.updated_src);
			return true;
		},
		[setFilter]
	);

	const handleCopyCallback = useCallback(
		(copy) => {
			if (typeof copyCallback === "function") {
				copyCallback(copy);
			}
		},
		[copyCallback]
	);

	return (
		<div
			className={
				"at-json-input-container " +
				(classes || "") +
				(readOnly ? " read-only" : "") +
				(loading ? " disabled" : "")
			}
		>
			{title && (
				<div className={"meta-info"}>
					<span className={requiredLabel ? " required-sign" : ""}>{title}</span>
					{showCustomTooltip && tooltipInfo && (
						<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
							<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
						</span>
					)}
				</div>
			)}
			<ReactJson
				src={value}
				name={false}
				theme={theme}
				iconStyle={iconStyle}
				collapsed={collapsed}
				onEdit={readOnly ? false : setFilter ? handleEdit : false}
				onAdd={readOnly ? false : setFilter ? handleAdd : false}
				onDelete={readOnly ? false : setFilter ? handleDelete : false}
				displayDataTypes={displayDataTypes}
				displayObjectSize={displayObjectSize}
				sortKeys={sortKeys}
				quotesOnKeys={quotesOnKeys}
				displayArrayKey={displayArrayKey}
				validationMessage={messageToDisplay}
				enableClipboard={enableCopy && handleCopyCallback}
			/>
			{validationMessage && <div className="validation-message">{validationMessage}</div>}
		</div>
	);
};
