import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { SelectFilterCustom } from "../../../components/_commons/SelectFilterCustom";
import { extractInitials, lS, scroll } from "../../../atlas-utils";
import { fetchBizPlatforms, fetchBrands, fetchStores, fetchStoresDebounced } from "../../../actions/actions";
import { SelectFilter } from "../../../components/_commons/SelectFilter";
import { Filters } from "../../../components/_commons/Filters";
import { store } from "../../../store/configureStore";
import { SearchFilter } from "../../../components/_commons/SearchFilter";
import {
	fetchDebouncedItemsIssuesInfo,
	fetchDebouncedPersicopeEnabledCities,
	fetchItemsIssuesInfo,
	fetchItemsIssuesMetrics,
	fetchMenuCategoriesValues,
	fetchPeriscopeEnabledBrands,
	fetchPeriscopeEnabledCities,
	fetchPeriscopeEnabledLocations
} from "../../../actions/periscope";
import ItemIssuesStackedbar from "../../../components/Periscope/ItemIssues/ItemIssuesStackedbar";
import ItemIssuesTable from "./ItemIssuesTable";
import { getPlatformNames, periscopeFilterEvent } from "../../../helpers/periscope";
import EmptyImportantItemState from "../../../components/Periscope/EmptyImportantItemState";
import { ActionTypes } from "../../../actions/_types";
import MainHeader from "../../../components/Periscope/MainHeader";

const ItemIssues = ({
	isMultibrandEnabled,
	configItems,
	login,
	periscopeItemIssuesState,
	periscopeItemIssuesData,
	periscopeDataResponse,
	periscopeCountryWiseSupportedPlatforms,
	periscopeFilters
}) => {
	const firstRender = useRef(true);
	const brands = configItems?.brands?.items;
	const country = (login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country).toLowerCase();
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (periscopeCountryWiseSupportedPlatforms?.[country] ||
			lS.get("periscopeCountryWisePlatforms")?.[country])?.[platform.platformName.toLowerCase()]?.enabled;
	});
	const stores = configItems?.stores?.items;
	const appliedFilters = periscopeItemIssuesState?.appliedFilters;
	const menuCategoriesFilterValues = periscopeItemIssuesState?.menuCategoriesFilterValues;

	const cities = periscopeFilters?.cities;
	const periscopeBrandIds = periscopeFilters?.brands;
	const periscopeLocationIds = periscopeFilters?.locations;
	const [searchQuery, setSearchQuery] = useState("");

	const [filteredBrands, setFilteredBrands] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);

	useEffect(() => {
		if (!isMultibrandEnabled || !brands || !periscopeBrandIds) return;

		const filteredBrands = brands.filter((brand) => {
			return periscopeBrandIds.includes(String(brand?.id)) || brand?.id === "all";
		});
		setFilteredBrands(filteredBrands);
	}, [isMultibrandEnabled, brands, periscopeBrandIds]);

	useEffect(() => {
		if (!stores || !periscopeLocationIds) return;

		const locations = stores.filter((store) => {
			return periscopeLocationIds.includes(String(store?.id)) || store?.id == "all";
		});
		setFilteredLocations(locations);
	}, [stores, periscopeLocationIds]);

	const [showFilters, setShowFilters] = useState(false);

	const [selectedBrand, setSelectedBrand] = useState(isMultibrandEnabled ? periscopeFilters.selectedBrand : null);

	const handleBrand = (brand) => {
		periscopeFilterEvent("brand");
		setSelectedBrand(brand);
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { offset: 0 }
		});
		store.dispatch({
			type: ActionTypes.GET_PERISCOPE_FILTERS,
			payload: {
				selectedBrand: brand
			}
		});
		handleReset();
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};
	const [selectedCity, setSelectedCity] = useState(
		cities?.[0] || { value: "all", valueForDisplay: "All Cities", __typename: "CityType" }
	);
	const handleCityFilter = (val) => {
		periscopeFilterEvent("city");
		setSelectedCity(val);
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { offset: 0 }
		});
	};

	const [itemDropdown, setItemDropdown] = useState("all");
	const handleItemFilter = (val) => {
		periscopeFilterEvent("item");
		setItemDropdown(val.value);
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { offset: 0 }
		});
	};

	const filterCount = useMemo(() => {
		const allKeys = Object.keys(appliedFilters || {});
		return allKeys.reduce((acc, key) => {
			if (appliedFilters?.[key]?.value || appliedFilters?.[key]?.id) {
				acc += 1;
			}
			return acc;
		}, 0);
	}, [appliedFilters]);

	const applyFilters = () => {
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { offset: 0 }
		});
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_APPLY_FILTERS_CHANGE,
			payload: currentFilters
		});
		setShowFilters(false);
	};

	const filterSidebarCloseHandler = () => {
		setShowFilters(false);
		let currentFilters = { ...appliedFilters };
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { currentFilters }
		});
	};

	let currentFilters = periscopeItemIssuesState?.currentFilters;
	const clearFilters = () => {
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { offset: 0, limit: 10 }
		});
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { currentFilters: {}, appliedFilters: {} }
		});
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_INITIAL_SORT
		});
		setShowFilters(false);
	};

	const handleReset = () => {
		clearFilters();
		setItemDropdown("all");
		setSelectedCity({ value: "all", valueForDisplay: "All Cities", __typename: "CityType" });
	};

	const setFilterr = (field, value) => {
		let currentFilters = { ...periscopeItemIssuesState.currentFilters, [field]: value };
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { currentFilters }
		});
	};

	const getPlatformData = () => {
		const platformsData = [];
		bizPlatforms.forEach((platform) => {
			platformsData.push({
				value: platform?.platformName?.toLowerCase(),
				valueForDisplay: platform?.platformName
			});
		});

		platformsData.shift();
		return platformsData;
	};

	const issueTypeOptions = [
		{ valueForDisplay: "All", value: "all" },
		{ valueForDisplay: "Missing Image", value: "missing_image" },
		{ valueForDisplay: "Missing Description", value: "missing_description" },
		{ valueForDisplay: "Missing Image and Description", value: "missing_image_and_description" }
	];

	const menuCategories = useMemo(() => {
		return (menuCategoriesFilterValues || []).map((menu) => ({
			id: menu,
			name: menu
		}));
	}, [menuCategoriesFilterValues]);

	const handleFilterState = (isOpen, field) => {
		let filters = {
			cities: () => fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true)
		};
		if (isOpen) {
			const filterAction = filters[field];
			filterAction();
		}
	};

	const handleStoresDebounced = (searchText) => {
		let includeAll = true;
		if (searchText) {
			includeAll = false;
		}
		fetchStoresDebounced(searchText, 50, null, includeAll);
	};

	const filterOptions = [
		{
			hide: null,
			field: "platform",
			valueForDisplay: "Platform",
			type: "MULTIPLE",
			values: [...getPlatformData()]
		},
		{
			field: "location",
			valueForDisplay: "Location",
			values: filteredLocations,
			labelKey: "name",
			valueKey: "id",
			type: "SINGLE_STRICT",
			isAsync: true,
			handleAsyncSearch: fetchStoresDebounced,
			isLoading: configItems?.stores?.isLoading,
			handleAsyncSearch: handleStoresDebounced
		},
		{
			hide: null,
			field: "sortby",
			valueForDisplay: "Sort By",
			type: "SINGLE_STRICT",
			values: [
				{ value: "A-Z", valueForDisplay: "A-Z" },
				{ value: "Z-A", valueForDisplay: "Z-A" },
				{ valueForDisplay: "Lowest rated", value: "lowest_rated" },
				{ valueForDisplay: "Highest rated", value: "highest_rated" },
				{ valueForDisplay: "Most issues", value: "most_issues" }
			]
		},
		{
			hide: null,
			field: "issueType",
			valueForDisplay: "Issue type",
			type: "SINGLE_STRICT",
			values: issueTypeOptions
		},
		{
			field: "menuCategories",
			valueForDisplay: " Menu Category",
			values: menuCategories,
			labelKey: "name",
			valueKey: "id",
			type: "SINGLE_STRICT"
		}
	];
	const limit = periscopeItemIssuesState?.limit;
	const offset = periscopeItemIssuesState?.offset;
	const text = periscopeItemIssuesState?.text || "";
	const platformsSelected = periscopeItemIssuesState?.appliedFilters?.platform?.value;
	const locationSelected = periscopeItemIssuesState?.appliedFilters?.location?.id;
	const sorbyValue = periscopeItemIssuesState?.appliedFilters?.sortby?.value || "Most issues";
	const issueTypeValue = periscopeItemIssuesState?.appliedFilters?.issueType?.value || "all";
	const menuCategoriesValue = periscopeItemIssuesState?.appliedFilters?.menuCategories?.id || "";
	const sort = periscopeItemIssuesState?.sort;

	let allPlatformsNames = bizPlatforms.length > 0 ? getPlatformNames(bizPlatforms, true).join() : [];

	const getVariables = (text, offsetValue) => {
		const platformValues =
			bizPlatforms && bizPlatforms?.length > 0
				? platformsSelected && platformsSelected?.length > 0
					? platformsSelected?.split(",")
					: getPlatformNames(bizPlatforms, true)
				: [];

		const finalLocation =
			locationSelected === "All Locations" || !locationSelected ? "all" : String(locationSelected);

		return {
			bizId: String(login.loggedInbizDetail.id),
			brand: isMultibrandEnabled ? String(selectedBrand?.id || "all") : null,
			location: finalLocation,
			city: String(selectedCity?.value || "all"),
			platforms: platformValues,
			limit: limit || 10,
			offset: offsetValue,
			sortBy: sorbyValue,
			category: menuCategoriesValue,
			items: itemDropdown,
			issueType: issueTypeValue,
			sort: sort,
			search: searchQuery
		};
	};

	useEffect(() => {
		const { bizId, platforms, brand, location, city, category, items } = getVariables("", 0);
		const itemsRequestVariables = {
			bizId,
			brand,
			city,
			location,
			platforms,
			category,
			items
		};
		fetchItemsIssuesMetrics(itemsRequestVariables);

		if (platforms?.length > 1) {
			fetchItemsIssuesMetrics(itemsRequestVariables);
		}
	}, [locationSelected, platformsSelected, menuCategoriesValue, selectedBrand, selectedCity, itemDropdown]);

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		}
		fetchBrands("", true);
		fetchBizPlatforms(false, false, true);
		fetchStores("", 50, null, true);
		fetchMenuCategoriesValues({ bizId: String(login?.loggedInbizDetail?.id) }, "itemIssues");

		fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true);
		fetchPeriscopeEnabledBrands(String(login?.loggedInbizDetail?.id));
		fetchPeriscopeEnabledLocations(String(login?.loggedInbizDetail?.id));
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 157, left: 0 });
		}
	}, []);

	const previousFilterLocationRef = useRef(null);
	useEffect(() => {
		const locationValue = periscopeItemIssuesState.appliedFilters?.location?.id;
		if (
			!firstRender.current &&
			previousFilterLocationRef.current !== null &&
			previousFilterLocationRef.current !== locationValue
		) {
			periscopeFilterEvent("location");
		}
		previousFilterLocationRef.current = locationValue;
	}, [periscopeItemIssuesState.appliedFilters?.location?.id]);

	const previousFilterPlatformRef = useRef(null);
	useEffect(() => {
		const platformValue = periscopeItemIssuesState.appliedFilters?.platform;
		if (
			!firstRender.current &&
			previousFilterPlatformRef.current !== null &&
			previousFilterPlatformRef.current !== platformValue
		) {
			periscopeFilterEvent("platform");
		}
		previousFilterPlatformRef.current = platformValue;
	}, [periscopeItemIssuesState.appliedFilters?.platform]);

	const fetchItemIssuesTableData = (periscopeDataFetch = false) => {
		const platformValues =
			bizPlatforms && bizPlatforms?.length > 0
				? platformsSelected && platformsSelected?.length > 0
					? platformsSelected?.split(",")
					: getPlatformNames(bizPlatforms, true)
				: [];

		if (platformValues?.length > 0) {
			const variables = getVariables(text, offset || 0);
			fetchItemsIssuesInfo(variables, periscopeDataFetch);
		}
	};

	const textfetchDebouncedItemsIssuesInfo = (text) => {
		const variables = getVariables(text, 0);
		fetchDebouncedItemsIssuesInfo(variables);
	};

	useEffect(() => {
		fetchItemIssuesTableData();
	}, [
		platformsSelected,
		allPlatformsNames,
		selectedCity,
		selectedBrand,
		limit,
		offset,
		sort,
		itemDropdown,
		sorbyValue,
		menuCategoriesValue,
		locationSelected,
		issueTypeValue
	]);

	const periscopeDataFetch = periscopeDataResponse?.periscopeDataFetch;

	useEffect(() => {
		const { bizId, platforms, brand, location, city, category, items } = getVariables("", 0);
		const itemsRequestVariables = {
			bizId,
			brand,
			city,
			location,
			platforms,
			category,
			items
		};
		if (periscopeDataFetch) {
			fetchItemIssuesTableData(periscopeDataFetch);
			fetchItemsIssuesMetrics(itemsRequestVariables, periscopeDataFetch);
		}
	}, [periscopeDataFetch]);

	const handleSearch = (field, value) => {
		setSearchQuery(value);
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { text: value, offset: 0 }
		});
		textfetchDebouncedItemsIssuesInfo(value);
	};
	const tableRef = useRef(null);
	const data = periscopeItemIssuesData?.data?.objects?.data;
	const showDisplayNoImportantPage = data?.length == 0 && itemDropdown == "important";
	const handleCitySearch = (text) => {
		let includeAll = true;
		if (text) {
			includeAll = false;
		}
		fetchDebouncedPersicopeEnabledCities(String(login?.loggedInbizDetail?.id), includeAll, text);
	};
	return (
		<div className="periscope-container">
			<div className="section-container-common-periscope item-issues-periscope" ref={tableRef}>
				<MainHeader classname="header-item-issues-availability" headerText="Item Issues" />
				<div className="item-issues-filters filters-location">
					<div className="left-filters-item  brand-state-filters">
						{isMultibrandEnabled && (
							<SelectFilterCustom
								options={filteredBrands}
								isLoading={configItems?.brands?.isLoading}
								field="brands"
								currValue={selectedBrand}
								setFilter={(f, value) => handleBrand(value)}
								labelKey={"name"}
								valueKey={"id"}
								isSearchable={true}
								customLabel={true}
								customOptions={true}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
							/>
						)}
						<div className="city-filter">
							<SelectFilterCustom
								options={cities}
								field="cities"
								currValue={selectedCity || cities[0]}
								setFilter={(f, value) => {
									handleCityFilter(value);
								}}
								labelKey={"valueForDisplay"}
								valueKey={"value"}
								isSearchable={true}
								placeholder="All Cities"
								handleSearch={handleCitySearch}
								handleSelectFilterState={handleFilterState}
							/>
						</div>
						<div className="items" style={{ width: "180px" }}>
							<SelectFilter
								options={[
									{ value: "all", valueForDisplay: "All" },
									{ value: "important", valueForDisplay: "Important" }
								]}
								field="itemDropdown"
								currValue={{
									value: itemDropdown,
									valueForDisplay: (
										<span>
											<span className="status-heading" style={{ color: "#8A8A8A" }}>
												Items
											</span>
											<span className="item-status">
												{itemDropdown == "important" ? "Important" : "All"}
											</span>
										</span>
									)
								}}
								labelKey="valueForDisplay"
								valueKey="value"
								setFilter={(f, v) => handleItemFilter(v)}
								isSearchable={false}
								isClearable={false}
							/>
						</div>

						{configItems?.dimensions?.width > 768 && (
							<Filters
								isOpen={showFilters}
								close={filterSidebarCloseHandler}
								options={filterOptions}
								apply={applyFilters}
								clear={clearFilters}
								currentFilters={currentFilters}
								setFilter={(f, value) => setFilterr(f, value)}
							/>
						)}
						<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
							<div className="container" onClick={() => setShowFilters((prev) => !prev)}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div className="filter-title">
									Filter
									{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
								</div>
							</div>
						</div>
					</div>
					<div className="right-filters-location">
						<div className="right-filters-location">
							<SearchFilter
								placeholder="Search"
								filterOption={{
									field: "name"
								}}
								setFilter={handleSearch}
								value={searchQuery}
							/>
						</div>
					</div>
				</div>
				{showDisplayNoImportantPage ? (
					<>
						<EmptyImportantItemState setItemDropdown={setItemDropdown} />
					</>
				) : (
					<>
						<ItemIssuesStackedbar issueTypeOptions={issueTypeOptions} />
						<div>
							<ItemIssuesTable tableRef={tableRef} refreshBtn={fetchItemIssuesTableData} />
						</div>
					</>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	login: state.login,
	isMultibrandEnabled: state.login?.loggedInbizDetail?.isMultibrandEnabled,
	periscopeItemIssuesState: state.periscopeItemIssuesState,
	periscopeItemIssuesData: state.periscopeItemIssuesData,
	periscopeDataResponse: state.periscopeDataResponse,
	periscopeCountryWiseSupportedPlatforms: state.periscopeCountryWiseSupportedPlatforms,
	periscopeFilters: state.periscopeFilters
});

export default connect(mapStateToProps)(ItemIssues);
